<template lang="pug">
#page
  navbar

  login(:redirect='redirect' :role='role' :url='url', :heading="$t('.heading')")
</template>

<script>
import Navbar from "../../components/navbar.vue";
import Login from "../../tools/login.vue";

export default {
  components: {
    Navbar,
    Login
  },

  data() {
    return {
      defaultI18nScope: "realEstate.login",
      redirect: "/imobiliaria/gerenciamento",
      role: "realEstate",
      url: "real_estate/user"
    }
  }
};
</script>

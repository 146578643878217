<template lang="pug">
#page
  navbar

  account-confirmation(
    :redirect="redirect",
    url="/real_estate/user/confirmation",
    model="real_estate_user"
  )
</template>

<script>
import Navbar from "../../components/navbar.vue";
import AccountConfirmation from "../../tools/account-confirmation.vue";

export default {
  components: { Navbar, AccountConfirmation },

  data() {
    return {
      redirect: "/imobiliaria/login"
    }
  }
};
</script>

<style scoped lang="scss">
#page {
  background-color: $grey-color-high-light;
}
</style>

<template lang="pug">
.boa-vista-acerta-essencial
  .buttons
    button.button-primary(
      v-for="table in Object.keys(columns)",
      :class="currentTable != table && 'button-grey'",
      @click="currentTable = table"
    )
      | {{ $t(`.${table}.button`) }}

  step-table(
    :stepName="formattedStepName",
    :tableData="tableData",
    :columnNames="columnNames",
    :dateColumns="dateColumns",
    :currencyColumns="currencyColumns"
  )
</template>
<script>
import StepTable from './format/step-table.vue'

export default {
  components: { StepTable },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.scoreReports.scoreCard.scoreSteps.details.boaVistaAcertaEssencial",

      //table action
      currentTable: 'debits',

      // table info
      stepName: 'boaVistaAcertaEssencial',
      columns: {
        debits: [ 'value', 'occurrenceDate', 'informant', 'segment'],
        protestedTitles: [
          'value',
          'occurrenceDate',
          'city',
          'federativeUnit',
          'occurenceType',
          'registry'
        ]
      },
      dateColumns: ['occurrenceDate'],
      currencyColumns: ['value']
    }
  },

  props: {
    data: { type: Object, default: () => ({}) }
  },

  computed: {
    formattedStepName() {
      return `${this.stepName}.${this.currentTable}`
    },

    columnNames() {
      return this.columns[this.currentTable] || []
    },

    tableData() {
      return this.data[this.currentTable] || []
    }
  }
}
</script>
<style scoped lang="scss">
.boa-vista-acerta-essencial {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.buttons {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  button {
    margin: 0;
  }
}
</style>

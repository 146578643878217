<template lang="pug">
Modal(v-model="isShow", :close="close")
  .liquidate-modal
    .info-container
      h1 {{ $t('.title') }}

      date-field(
        :label="$t('.label.liquidationDate')",
        v-model="liquidationDate"
      )

      checkbox-field(
        :label="$t('.label.sendTransferStatement')",
        v-model:checked="sendTransferStatement"
      )

    .buttons-container
      button.close(@click="close")
        | {{ $t(".buttons.close") }}

      button.confirm(@click="confirm", :disabled="!liquidationDate")
        | {{ $t(".buttons.confirm") }}
</template>

<script>
import { ref } from 'vue'

export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "admin.financial.transfers.modals.liquidate",

      // data
      liquidationDate: null,
      transferStatement: null
    }
  },

  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  props: {
    callback: { type: Function, required: true },
    show: { type: Boolean, default: false }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  methods: {
    close() {
      this.liquidationDate = null

      this.$emit('close')
    },

    confirm() {
      this.callback(this.liquidationDate)
      this.close()
    }
  }
}
</script>

<style scoped lang="scss">
.liquidate-modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  height: fit-content;

  .info-container {
    min-height: 11em;

    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
    }

    p {
      font-size: 1.5rem;
      color: $second-color-light;
      overflow-wrap: break-word;
      white-space: pre-line;
    }
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}
</style>

<style lang="scss">
div .vue-universal-modal {
  background-color: #6f6f6f60;
}
</style>

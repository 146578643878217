<template lang="pug">
#page
  .account-confirmation.sheet
    h1 {{ $t('.heading') }}

    p {{ $t('.instructions') }}

    form(ref="form" action="/" @submit.prevent="submit")
      input(type="hidden" name="confirmation_token" :value="confirmationToken")

      .row
        .twelve.columns
          input-field(
            v-model="password"
            name="password"
            type="password"
            :label="$t('.label.password')"
            :placeholder="$t('.placeholder.password')"
            :error="errors?.password?.message"
          )

      .row
        .twelve.columns
          input-field(
            v-model="passwordConfirmation"
            name="passwordConfirmation"
            type="password"
            :label="$t('.label.passwordConfirmation')"
            :placeholder="$t('.placeholder.passwordConfirmation')"
            :error="errors?.passwordConfirmation?.message"
          )

      .row
        .twelve.columns
          button.button-primary.u-full-width(
            type="submit" :disabled="submitting"
          )
            | {{ submitBtnText }}

      p.password-requirements {{ $t('.passwordRequirements') }}
</template>

<script>
import objects from "lib/objects"

export default {
  props: {
    redirect: { type: String, required: true }, // default para rota "root"
    url: { type: String, required: true },
    model: { type: String, required: true }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "accountConfirmation",

      submitting: false,

      // form
      errors: {},
      confirmationToken: this.$route && this.$route.query.confirmation_token,
      email: this.$route && this.$route.query.email,
      password: null,
      passwordConfirmation: null,
    }
  },

  computed: {
    submitBtnText() {
      return this.$t(`.button.${this.submitting ? 'submitting' : 'submit'}`)
    }
  },

  methods: {
    submit() {
      const formData = new FormData(this.$refs.form)
      this.submitting = true

      this.$http
        .put(this.url, formData)
        .then(() => {
          // apaga possíveis notificações de erro anteriores
          this.errors = {}
          this.$notifications.clear()

          this.$router.replace({ path: this.redirect })
        })
        .catch((err) => {
          const response = err.response
          const data = response && response.data
          let errors = data && data.errors

          if (response.status == 401 || (errors && errors.email)) {
            const resendConfirmationPath = this.redirect.replace(
              'login', 'reenviar-confirmacao'
            )

            this.$notifications.error(this.$t(".notifications.expired"))
            this.$router.replace({ path: resendConfirmationPath })

            return
          }

          if (errors && (errors.password || errors.password_confirmation)) {
            this.$notifications.error(this.$t(".notifications.failure"))

            this.errors = objects.camelize(
              objects.dig(err, "response", "data", "errors")
            )

            return
          }

          this.$notifications.error(this.$t(".notifications.alreadyConfirmed"))
          this.$router.replace({ path: this.redirect })
        })
        .then(() => {
          // finally
          this.submitting = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
#page {
  background-color: $grey-color-high-light;
}
.sheet {
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  width: 340px;
  font-weight: 300;
  font-size: 16px;

  h1 {
    font-size: 28px;
  }

  .password-requirements {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    color: $grey-color-dark;
    margin: 0;
  }
}
</style>

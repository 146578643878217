<template lang="pug">
#page
  Navbar

  Login(:redirect="redirect" :role="role" :url="url" :heading="$t('.heading')")
</template>

<script>
import Navbar from "../../components/navbar.vue"
import Login from "../../tools/login.vue"

export default {
  components: { Navbar, Login },

  data() {
    return {
      defaultI18nScope: "admin.login",
      redirect: "/administracao/gerenciamento",
      role: "admin",
      url: "admin"
    }
  }
}
</script>
<style scoped lang="scss">
@import "styles/phone/variables";

#page {
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
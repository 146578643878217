<doc lang="markdown">
    Componente para campos textuais (`input`) em formulários, já derivando `label`
  e mensagem de `erro` (para validações).

  # Exemplo de uso:

  ## básico
  ```pug
  input-field(
    type="text",
    name="user[name]",
    v-model="user.name",
    :placeholder="$t('.something')",
    :error="errors.name"
  )
  ```

  ## avançado
  ```pug
  input-field(
    id="secret",
    type="number",
    name="special",
    label="Meu label específico",
    value="Valor inicial não reativo",
    :placeholder="$t('.something')",
    :disabled="flagControlandoEstadoDeDesabilitado",
    :error="mensagemDeErroControlandoEstadoDeErro"
  )
  ```

</doc>

<template lang="pug">
.input-field(:class="{ error, disabled, readonly }")
  label(v-if="!hideLabel", :for="inputId", :class="{ 'required': required }") {{ labelText }}
  input(
    :id="inputId",
    ref="input",
    :autocomplete="autocomplete",
    :autofocus="autofocus",
    :disabled="disabled",
    :readonly="readonly",
    :name="name",
    :placeholder="placeholder",
    :type="type",
    :pattern="pattern",
    :required="required",
    :value="modelValue",
    :max="max",
    :min="min",
    v-maska="mask",
    @input="$emit('update:modelValue', $event.target.value)"
  )
  input-error(:message="error")
</template>

<script>
import strings from "lib/strings";

export default {
  props: {
    autocomplete: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: String },
    id: { type: String },
    label: { type: String },
    hideLabel: { type: Boolean, default: false },
    mask: { type: [String, Object] },
    name: { type: String, required: true },
    placeholder: { type: String },
    readonly: { type: Boolean, default: false },
    type: { type: String, default: "text" },
    pattern: { type: String },
    modelValue: { type: [String, Number] },
    required: { type: Boolean, default: false },
    max: { type: String },
    min: { type: String }
  },

  computed: {
    attrName() {
      let match = /\[([^\]]+)\]$/.exec(this.name);

      return match ? strings.camelize(match[1]) : this.name;
    },

    modelName() {
      let match = /^([^[]+)\[/.exec(this.name);

      return match ? strings.camelize(match[1]) : 'default';
    },

    /**
     *   Attributo `id` do `input`, que é definido por property ou fallback
     * para a convenção.
     *
     *   Não é possível definir, de maneira simples, valores _default_ para
     * _properties_ usando outras _properties_ (ex: valor default para `id`
     * é inferido a partid de `name`).
     *   A alternativa foi mover os valores para _computed properties_, com
     * um prefixo `c`.
     *
     * @return {String} Atributo `id` a ser utilizado no `input`
     */
    inputId() {
      if (this.id) return this.id;

      return strings.snakeize(this.name);
    },

    /**
     *   Texto do `label`, que é definido por property ou fallback
     * para a convenção.
     *
     *   Não é possível definir, de maneira simples, valores _default_ para
     * _properties_ usando outras _properties_ (ex: valor default para `id`
     * é inferido a partid de `name`).
     *   A alternativa foi mover os valores para _computed properties_, com
     * um prefixo `c`.
     *
     * @return {String} Texto do `label` associado ao `input`
     */
    labelText() {
      if (this.label) return this.label;

      return this.$t(
        `models.${this.modelName}.attributes.${this.attrName}`
      );
    },
  },

  methods: {
    select() {
      this.$refs.input.select();
    },
  },
};
</script>

<style scoped lang="scss">
// TODO definir cor de erro!
$input-error-color: red;
$input-error-border-color: red;
$input-disabled-color: #ccc;
$input-disabled-border-color: #e3e3e3;

.input-field {
  margin-bottom: 1.5rem;
  label.required::after {
    content: ' *';
    color: #9b53d7;
  }

  input {
    width: 100%;
    height: 38px;
    margin-bottom: 0;
    padding: 6px 10px;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
  }
}

// error
.input-field.error {
  label {
    color: $input-error-color;
  }

  input {
    border-color: $input-error-border-color;
  }
}

// disabled
.input-field.disabled {
  label {
    color: $input-disabled-color;
  }

  input {
    border-color: $input-disabled-border-color;
    color: $input-disabled-color;
  }
}

// readonly
.input-field.readonly {
  input {
    color: $second-color-dark;
  }
}
</style>
<template lang="pug">
.navbar-container
  .navbar
    .content.flex.flex-row.justify-between.px-20.py-6
      router-link.logo.mx-4(to="/")
        img.w-36(src="@/assets/images/logo.svg")

      .desktop.flex.flex-row.justify-between.items-center
        router-link.mx-4(
          v-for="page in pages"
          :to="{ name: page }"
          :class="{ 'active': isCurrentRoute(page) }"
        )
          | {{ $t(`.${page}`) }}

        button.button-primary.mx-4.my-0.normal-case(@click="showSignUpModal = true")
          | {{ $t('.buttons.signUp') }}
        button.button-primary-outline.mx-4.my-0.normal-case(@click="accessPlatform")
          | {{ $t('.buttons.signIn') }}

      .mobile.flex.items-center
        i.fa.m-0(@click="expanded = !expanded" :class="expanded ? 'fa-times' : 'fa-bars'")

    .mobile-container.px-20.py-6.flex.flex-col(v-if="expanded")
      router-link.mx-4(
        v-for="page in pages"
        :to="{ name: page }"
        :class="{ 'active': isCurrentRoute(page) }"
      )
        | {{ $t(`.${page}`) }}

      button.button-primary.my-4.normal-case(@click="showSignUpModal = true")
        | {{ $t('.buttons.signUp') }}
      button.button-primary-outline.normal-case(@click="accessPlatform")
        | {{ $t('.buttons.signIn') }}

  sign-up-modal(:show="showSignUpModal" @close="showSignUpModal = false")
</template>
<script>
import SignUpModal from './sign-up-modal.vue';
import vueAuthenticate from "lib/vue-authenticate";

export default {
  mixins: [vueAuthenticate],

  components: { SignUpModal },

  data() {
    return {
      // i18n
      defaultI18nScope: "staticPages.components.navbar",

      expanded: false,
      showSignUpModal: false,

      pages: ['forRealEstateAgencies', 'forLandlords', 'forTenants', 'contact']
    }
  },

  methods: {
    accessPlatform() {
      this.$router.replace(
        { name: this.authenticated ? this.profileRoute : 'realEstateLogin' }
      )
    },

    isCurrentRoute(routeName) {
      return this.$route.name === routeName
    }
  },

  computed: {
    profileRoute() {
      if (this.camelizedRole == 'admin') return 'adminManagementIndex'

      return `realEstateManagementIndex`
    }
  }
}
</script>
<style lang="scss" scoped>
  .navbar {
    background-color: $white-color;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    position: fixed;
    width: 100vw;
    z-index: 2;

    a {
      cursor: pointer;
      color: $second-color;
      opacity: 0.7;

      &.active {
        color: $primary-color;
        font-weight: 700;
        opacity: 1;
      }

      &:hover, &.logo {
        opacity: 1;
      }
    }

    @media only screen and (min-width: 930px) {
      .desktop {
        display: flex !important;
      }

      .mobile {
        display: none !important;
      }
    }

    @media only screen and (max-width: 930px) {
      .desktop {
        display: none !important;
      }

      .mobile {
        display: flex !important;

        i {
          color: $primary-color;
          cursor: pointer;
        }
      }
    }

    @media only screen and (min-width: 1700px) {
      padding-inline: 10rem !important;
    }

    @media only screen and (min-width: 1800px) {
      padding-inline: 20rem !important;
    }

    @media only screen and (min-width: 2000px) {
      padding-inline: 30rem !important;
    }

    @media only screen and (min-width: 2150px) {
      padding-inline: 40rem !important;
    }

    @media only screen and (min-width: 2350px) {
      padding-inline: 50rem !important;
    }
  }
</style>
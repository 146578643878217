<template lang="pug">
#page.management-page
  navbar

  loading-div.admin-management-container
    .menu-container
      h2 {{ $t('.menu') }}

      .menu-items
        button(
          v-for="permission in permissions"
          :class="currentPage == permission && 'actived'"
          @click="() => { setCurrentPage(permission) }"
        )
          | {{ $t(`.menus.${permission}`) }}

    .content-container
      breadcrumbs(:items="generateBreadcrumb")

      score-reports(v-if="currentPage == 'scoreReports'")
      contracts(v-if="currentPage == 'contracts'")
      bills(v-if="currentPage == 'bills'")
      transfers(v-if="currentPage == 'transfers'")
      agreements(v-if="currentPage == 'agreements'")
</template>
<script>
import tool from "lib/vue-tool"
import vueAuthenticate from "lib/vue-authenticate"

import Navbar from "../../components/navbar.vue"
import Breadcrumbs from '../../../components/breadcrumbs.vue'
import ScoreReports from '../../components/score_reports/index.vue'
import Contracts from '../../components/contracts/index.vue'
import Bills from '../../components/bills/index.vue'
import Transfers from '../../components/transfers/index.vue'
import Agreements from '../../components/agreements/index.vue'

export default {
  mixins: [tool, vueAuthenticate],
  components: {
    Navbar, Breadcrumbs, ScoreReports, Contracts, Bills, Transfers, Agreements
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.management.index",

      // tool
      routeName: "adminManagementIndex",
      routePath: "administracao/gerenciamento",
      viewName: "show",
    }
  },

  methods: {
    fetch() {},

    parseRoute() { },

    setCurrentPage(anchor) {
      if (this.$route.hash.slice(1) == anchor) return

      this.$router.push({name: this.routeName, hash: `#${anchor}` })
    },
  },

  computed: {
    currentPage() {
      const hash = this.$route.hash.slice(1)

      if (this.permissions.includes(hash)) return hash

      if (hash) this.$router.replace({ hash: null })

      return this.permissions[0]
    },

    permissions() {
      const permissions = ['scoreReports', 'contracts', 'bills', 'transfers']

      this.agreementsPermission && permissions.push('agreements')

      return permissions
    },

    agreementsPermission() {
      return this.$permissions.hasPermission('agreements', 'get')
    },

    generateBreadcrumb() {
      return [
        {
          name: this.$t('.breadcrumbs.management'),
          url: this.routePath,
          hash: this.currentPage
        },
        {
          name: this.$t(`.breadcrumbs.${this.currentPage}`),
          url: this.routePath,
          hash: this.currentPage
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.admin-management-container {
  .content {
    display: flex;
    width: 100vw;
    overflow: hidden;
  }
}
</style>

<style lang="scss" scoped>
#page.management-page {
  overflow: hidden;
  height: 100%;
}

.admin-management-container {
  padding: 0;
  margin: 0 !important;
  width: 100%;
  max-width: fit-content;
  height: 100%;
  overflow: hidden;

  .menu-container {
    width: 25%;
    height: 100vh;
    overflow-y: visible;

    background-color: $white-color-dark;
    box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);

    padding: 0 1.5rem;

    h2 {
      color: $primary-color;
      font-size: 2rem;
      font-weight: 400;
      border-bottom: 1.5px solid $primary-color;
      margin-top: 2.5rem;
    }

    .menu-items {
      width: 100%;
      height: max-content;

      button {
        text-align: start;
        color: $second-color-light;
        font-weight: 400;
        border-radius: 0.5rem;
        border: none;
        padding: 0.5rem 1.5rem;
        height: auto;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: none;

        &.actived{
          background-color: $primary-high-light;
          color: $primary-color;
        }

        &:hover {
          background-color: darken($primary-high-light, 5%);
          color: $primary-color;
        }
      }
    }
  }

  .content-container {
    width: 75%;
    height: 100vh;
    padding: 2.5rem;
    overflow: scroll;
    overflow-y: visible;
    padding-bottom: 5rem;
  }
}
</style>
<template lang="pug">
.card
  .general-container
    .name-container
      h2.name {{ scoreData.name || '-' }}

      .type(v-if="scoreData.mainTenant") {{ $t('.mainTenant') }}
      .type.invisible(v-else)

    .info-container
      .infos
        .info(v-for="info in ['age', 'presumedIncomeValue', 'cpf']")
          h3.info-title {{ $t(`.infos.${info}`) }}
          p.info-content {{ infoText(scoreData[info]) }}

      .infos
        .info
          h3.info-title {{ $t('.infos.proprableProfession') }}
          p.info-content {{ infoText(proprableProfession) }}

        .info
          h3.info-title {{ $t(`.infos.bouncedCheck`) }}
          p.info-content {{ infoBoolean(scoreData.bouncedCheck) }}

  .buttons
    debits-modal(:disabled="debits.length == 0", :debits="debits")
      | {{ $t('.buttons.debits') }}

    trials-modal(:disabled="trials.length == 0", :trials="trials")
      | {{ $t('.buttons.trials') }}
</template>
<script>
import DebitsModal from './debits-modal.vue'
import TrialsModal from './trials-modal.vue'

export default {
  components: { DebitsModal, TrialsModal },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.components.scoreReport.scoreCard"
    }
  },

  props: {
    score: { type: Object, required: true },
  },

  computed: {
    debits() {
      return this.scoreData.debits || []
    },

    trials() {
      return this.scoreData.trials || []
    },

    proprableProfession() {
      const proprableProfession = this.scoreData.proprableProfession

      return proprableProfession && proprableProfession.titulo
    },

    scoreData() {
      return this.score.original ? this.score.original : this.score
    }
  },

  methods: {
    infoText(info) {
      return info ? info : this.$t('.infoDefault')
    },

    infoBoolean(info) {
      if (typeof info === 'boolean') {
        return this.$t(`.values.${info ? 'yes' : 'no'}`)
      }

      return this.$t('.infoDefault')
    }
  }
}
</script>
<style scoped lang="scss">
.card {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  position: relative;
  padding: 2rem;
  height: 100%;
  overflow: hidden;

  .buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 1rem;

    button {
      margin: 0;
    }
  }

  .general-container {
    position: relative;
    margin-bottom: 2rem;

    &::after {
      content: "";
      background-color: $primary-color;
      border-radius: 0 4px 4px 0;
      width: 0.75rem;
      height: 100%;
      position: absolute;
      top: 0;
      left: -2rem;
    }
  }

  .name-container {
    margin-bottom: 1.5rem;

    .name {
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    .type {
      margin: 0;
      width: fit-content;
      height: 3rem;
      padding: 0.5rem 1rem;
      border-radius: $global-radius;
      background-color: $primary-color;
      color: $white-color;
      font-size: 1.2rem;
      font-weight: 600;

      &.invisible {
        content: "";
        visibility: hidden;
      }
    }
  }

  .info-container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 2rem;
  }

  .infos {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;

    &:last-child {
      .info-content {
        font-size: 1.5rem;
      }
    }
  }

  .info-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0rem;
    color: $second-color-light;
  }

  .info-content {
    margin: 0;
    font-size: 2rem;
    font-family: Lato;
  }
}
</style>

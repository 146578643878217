<template lang="pug">
.index
  card
    .header-container
      h2 {{ $t('.title') }}

      .actions
        button.icon.button-blue(@click="showUploadModal = true")
          i.fa.fa-plus

    h3.no-data(v-if="documents.length == 0") {{ $t('.noData') }}

    .documents(v-else)
      .document(v-for="{ kind, fileName, fileSize, address, columnName } in formattedDocuments")
        .info
          i.fa(:class="documentIconClass(kind)")

          popper(
            class="popper-document-name"
            :content="fileName"
            hover
          )
            h5 {{ fileName }}

          p {{ `${kind.slice(1)} - ${fileSize}` }}

        .buttons
          a.button.button-blue.download(
            target="_blank"
            :href="address"
            :disabled="!address"
          )
            i.fa.fa-download

          button.button-red.delete(@click="destroyAttachment(columnName)")
            i.fa.fa-trash

upload-modal(
  :show="showUploadModal"
  :callback="addNewDocument"
  :documents="documents"
  :contractId="contractId"
  @close="showUploadModal = false"
)
</template>
<script>
import objects from "lib/objects"
import UploadModal from "./upload-modal.vue"

export default {
  components: { UploadModal },

  data() {
    return {
      // i18n
      defaultI18nScope: "components.documents.index",

      showUploadModal: false
    }
  },

  props: {
    documents: { type: Array, default: () => [] },
    onUpdate: { type: Function, default: () => {} },
    contractId: { type: Number, required: true }
  },

  methods: {
    documentIconClass(kind) {
      const defaultIcon = "fa-file-o"
      const fileExtensions = {
        ".pdf": "fa-file-pdf-o",
        ".doc": "fa-file-text-o",
        ".docx": "fa-file-text-o",
      }

      return fileExtensions[kind] || defaultIcon
    },

    destroyAttachment(attachmentType) {
      const data = { contract: {} }

      data.contract[attachmentType] = '_destroy'

      this.$http
        .put(`admins/contracts/${this.contractId}`, data)
        .then(() => {
          this.$notifications.info(this.$t(".notifications.destroy.success"))

          this.onUpdate()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.destroy.failure"))
        })
    },

    addNewDocument() {
      this.onUpdate()
    }
  },

  computed: {
    formattedDocuments() {
      return objects.camelize(this.documents)
    }
  }
}
</script>
<style lang="scss" scoped>
.index {
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 1.5rem;

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: $primary-color;
    }

    .actions {
      display: flex;

      button.opened {
        opacity: 0.5;
      }

      button.icon {
        font-size: 1.5rem;
        width: 4rem;
        padding: 0;
        margin-bottom: 0;
        margin-left: 1rem;
      }
    }
  }

  .no-data {
    font-size: 1.8rem;
    font-weight: 500;
    color: $primary-color;
    text-align: center;
  }

  .documents {
    max-height: 40vh;
    padding: 0.2rem;
    overflow-y: auto;

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;

    font-family: 'Open Sans';

    .document {
      height: fit-content;
      background-color: $white-color-dark;
      border-radius: 0.4rem;
      border: 1px solid $grey-color-light;
      padding: 1rem;
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;

      &::after {
        content: "";
        background-color: $primary-color;
        border-radius: 0 4px 4px 0;
        width: 0.5rem;
        height: 80%;
        position: absolute;
        top: 10%;
        left: 0;
      }

      .info {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 0.5rem;
        align-items: center;

        .popper-document-name {
          width: 100%;
          margin: 0 !important;
          border: 0 !important;

          overflow: hidden;
          text-overflow: ellipsis;

          h5 {
            width: 100%;
            margin: 0;
            font-size: 1.5rem;
            font-weight: 500;
            color: $second-color-dark;
            overflow: hidden;
            text-wrap: nowrap;
            text-overflow: ellipsis;

            margin: 0 !important;
            border-inline-width: 0 !important;

            div:first-child {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        p {
          grid-column: span 2;
          margin: 0;
          font-size: 1.2rem;
          font-weight: 400;
          color: $grey-color-dark;
          text-transform: uppercase;
        }
      }

      .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;

        button, .button {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          padding: 0;
          height: 3.8rem;
          width: 3.8rem;

          font-weight: 600;
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>
import axios from 'axios'
import qs from 'qs'
import authToken from './auth_token'
import loading from './loading'

// dados privados
const data = {
  count: 0
}

const router = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

// Dizendo que somos XHR!
router.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

router.interceptors.request.use(
  (config) => {
    // Adicionando HEADERS

    config.headers[authToken.accessTokenHEADER] = authToken.accessToken
    config.headers[authToken.clientHEADER] = authToken.client
    config.headers[authToken.uidHEADER] = authToken.uid

    loading.start()
    data.count++

    return config
  },

  // Quando houver erro no envio de requisição, queremos:
  //   - desativar estado de "carregamento" da app
  (error) => {
    loading.stop()
    data.count--

    if (error.response.status == 401) {
      // app.router.replace({ name: 'unauthorized' })
    }

    // TODO integrar com módulo de notificações e/ou router!
    // Use `error.response.status` para verificar:
    // - 400 - bad_request - falha em preenchimento de dados
    // - 403 - forbidden (não tem permissão)
    // - 404 - not found
    // - 422 - falha de verificação CSRF e CORS
    // - 500 - internal server error

    return Promise.reject(error)
  }
)

router.interceptors.response.use(
  // Quando uma requisição for bem sucedida obtendo uma resposta, queremos:
  //   - desativar estado de "carregamento" da app
  (response) => {
    loading.stop()
    data.count--

    return response
  },

  // Quando houver erro no processamento de uma resposta, queremos:
  //   - desativar estado de "carregamento" da app
  (error) => {
    const response = error.response
    const config = response.config
    const req_method = config && config.method

    loading.stop()
    data.count--

    const current_role = app.router.currentRoute.value.meta.role

    if (error.response.status !== 401) return Promise.reject(error)

    if (
      current_role != null && (
        current_role == app.store.get('role') || [
          'real-estate-admin', 'real-estate-manager', 'real-estate-attendant'
        ].includes(current_role)
      )
    ) {
      const prefix = app.auth.camelizedRole === 'admin' ? 'admin' : 'realEstate'

      app.router.replace({ name: `${prefix}Login` })
      app.auth.clear()

      return
    }

    if (req_method == 'get') {
      app.router.replace({ name: 'unauthorized' })
    }

    return Promise.reject(error)
  }
)

/**
 * Singleton para controle de requisições assíncronas (XHR).
 */
const http = {
  /**
   * Indica se há requisições em andamento
   * @return {Boolean} `true` se houver requisições em andamento
   */
  get active() {
    return data.count > 0
  },

  // expondo o router, para permitir a inclusão de interceptors
  router,

  // aliases para os métodos HTTP (que enviam XHR)
  request: router.request,
  get:     router.get,
  post:    router.post,
  put:     router.put,
  delete:  router.delete
}

export default http

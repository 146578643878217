<template lang="pug">
tr
  td#kind {{ kind || "-" }}
  td#status {{ $t(`.status.${status}`) || "-" }}
  td#date {{ createdAt || "-" }}

  td#details
    file-modal(
      :author="author",
      :fileSize="fileSize",
      :fileName="fileName",
      :errorLogs="errorLogs",
      :createdAt="createdAt",
      :downloadUrl="downloadUrl"
    )
      i.fa.fa-search
</template>
<script>
import FileModal from "./file-modal.vue"

export default {
  components: { FileModal },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.components.cnab.filesTableItem"
    }
  },

  props: {
    kind: { type: String },
    status: { type: String },
    author: { type: String },
    fileSize: { type: String },
    fileName: { type: String },
    errorLogs: { type: Array },
    createdAt: { type: String },
    downloadUrl: { type: String }
  }
}
</script>
<style lang="scss">
@import "src/styles/base/tables.scss";

td {
  max-width: 50rem;
  align-items: center;

  &#kind, &#status, &#date {
    max-width: 50rem;
    align-items: center;
    min-width: fit-content;
    width: 33%;
  }

  &#details {
    min-width: unset;
    padding: 1.5rem;
    display: flex;
    justify-content: center;

    button, .button {
      width: 3.8rem;
      margin: 0;
      padding: 0;
    }

    i {
      font-size: 1.8rem;
    }
  }
}
</style>
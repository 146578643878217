<template lang="pug">
footer.py-20.w-full
  .info-container.mb-20.flex
    .left-side(class="w-6/12")
      img.w-36(src="@/assets/images/logo.svg")

      .address-container
        h4.mt-10.mb-0.font-bold {{ $t('.address.title') }}

        a(href="https://maps.app.goo.gl/PMjkVEz7jTqzo4B68" target="_blank")
          | {{ $t('.address.address') }}

      .contact-container
        h4.mt-10.mb-0.font-bold {{ $t('.contact.title') }}

        a(:href="$env.VUE_APP_WHATSAPP_LINK" target="_blank")
          | {{ $t('.contact.phoneNumber') }}

        a(:href="`mailto:${$env.VUE_APP_EMAIL}`" target="_blank")
          | {{ $t('.contact.email') }}

      .social-media-container.mt-5
        a(href="https://www.facebook.com/alpop.aluguel", target="_blank")
          img.media(src="@/assets/images/acknowledgment/facebook-purple.png")
        a(href="https://www.instagram.com/alpop.aluguel", target="_blank")
          img.media(src="@/assets/images/acknowledgment/instagram-purple.png")
        a(href="https://twitter.com/alpop_aluguel", target="_blank")
          img.media(src="@/assets/images/acknowledgment/twitter-purple.png")

    .right-side(class="w-6/12").flex.flex-row-reverse
      .flex.flex-col
        router-link.font-bold.mx-4(:to="{ name: 'forTenants' }")
          | {{ $t('.buttons.tenants') }}

        router-link.font-bold.mx-4(:to="{ name: 'contact' }")
          | {{ $t('.buttons.contact') }}

      .flex.flex-col
        router-link.font-bold.mx-4(:to="{ name: 'forRealEstateAgencies' }")
          | {{ $t('.buttons.realEstates') }}

        router-link.font-bold.mx-4(:to="{ name: 'forLandlords' }")
          | {{ $t('.buttons.landlords') }}

  .rights-container.border-t.pt-10.w-full
    span.trademark {{ $t('.tradeMark', { year: this.currentYear }) }}
</template>
<script>
export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "staticPages.components.footer"
   }
  },

  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>
<style lang="scss" scoped>
  footer {
    background-color: #EEE5FB;
    padding-inline: 5rem;

    h4, p, a {
      font-size: 14px;
      color: #452763;
    }

    a {
      cursor: pointer;
    }

    .contact-container  a {
      display: block;
    }

    .social-media-container {
      display: flex;

      img {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 1rem;
      }
    }

    .rights-container {
      border-color: #452763;
    }

    @media only screen and (max-width: 630px) {
      .info-container {
        flex-direction: column;
      }

      .right-side {
        flex-direction: column !important;

        a {
          margin: 0.5rem 0 !important;
        }
      }

      .social-media-container {
        margin-bottom: 1.25rem;
      }
    }

    @media only screen and (min-width: 1700px) {
      padding-inline: 10rem !important;
    }

    @media only screen and (min-width: 1800px) {
      padding-inline: 20rem !important;
    }

    @media only screen and (min-width: 2000px) {
      padding-inline: 30rem !important;
    }

    @media only screen and (min-width: 2150px) {
      padding-inline: 40rem !important;
    }

    @media only screen and (min-width: 2350px) {
      padding-inline: 50rem !important;
    }
  }
</style>

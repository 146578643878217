<template lang="pug">
.navbar-content
  AgencyField(v-if="includeAgencyField")

  .menu
    router-link(
      v-for="{ routeName, title } in allowedItems"
      :to="{ name: routeName }"
      :class="{ active: isCurrentRoute(routeName) }"
    )
      | {{ title }}

  span.welcome-message(v-if="authenticated")
    | {{ $t('.welcome', { name: userName }) }}

  button.button-primary-outline(
    @click="authenticated ? logout() : login()"
  )
    | {{ $t(`.buttons.${button}`) }}
</template>

<script>
import vueAuthenticate from 'lib/vue-authenticate'
import AgencyField from './agency-field.vue'

export default {
  mixins: [vueAuthenticate],

  components: { AgencyField },

  data() {
    return {
      // i18n
      defaultI18nScope: 'components.navbar.content'
    }
  },

  props: {
    items: { type: Array, default: () => [] },
    currentPage: { type: String, default: '' },
    includeAgencyField: { type: Boolean, default: false }
  },

  computed: {
    button() {
      if (!this.authenticated) return 'login'

      return this.loggingOut ? 'loggingOut' : 'logout'
    },

    allowedItems() {
      if (!this.authenticated) {
        const defaultItems = [
          'forRealEstateAgencies', 'forLandlords', 'forTenants', 'contact'
        ]

        return defaultItems.map(routeName => (
          { routeName, title: this.$t(`.${routeName}`) }
        ))
      }

      return this.items
    }
  },

  methods: {
    isCurrentRoute(routeName) {
      return this.$route.name === routeName || this.currentPage === routeName
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-content {
  a {
    cursor: pointer;
    color: $second-color;
    opacity: 0.7;

    &.active {
      color: $primary-color;
      font-weight: 700;
      opacity: 1;
    }

    &:hover, &.logo {
      opacity: 1;
    }
  }

  .welcome-message {
    color: $grey-color-dark;
    font-style: italic;
    font-weight: 300;
  }

  button {
    text-transform: none;
    margin: 0;
  }

  @media only screen and (min-width: 930px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    .menu {
      height: 38px;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2rem;

      a {
        height: 100%;
        align-content: center;
      }
    }
  }

  @media only screen and (max-width: 930px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    align-items: center;

    .select-field {
      width: 100%;
      margin: 0;
    }

    .menu {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex-direction: column;
      align-items: center;

      a {
        width: 100%;
        text-align: center;
      }
    }

    button {
      width: 100%;
    }
  }
}
</style>
<template lang="pug">
.breadcrumbs
  router-link.item(
    v-for="item in items"
    :to="toRoute(item)"
  )
    | {{ item.name }}

</template>

<script>
export default {
  props: {
    items: { type: Array, required: true }
  },

  methods: {
    toRoute(item) {
      return {
        path: `/${item.url}`,
        hash: item.hash ? `#${item.hash}` : "",
        query: item.query ? item.query : null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.breadcrumbs {
  width: 100%;
  border-bottom: 1.5px solid $primary-color;
  margin-bottom: 1.5rem;
  display: flex;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    font-weight: 500;
    font-size: 1.5rem;
    font-style: italic;

    margin-right: 0.5rem;

    &::after {
      content: ' / ';
      color: $primary-color;
    }

    &:not(:last-child) {
      color: $second-color-light;
    }

    &:last-child::after {
      content: '';
      color: $primary-color;
    }
  }
}
</style>
<template lang="pug">
#page
  Navbar

  Edit(:redirect="redirect" url="/admin/password")
</template>

<script>
import Navbar from "../../../components/navbar.vue"
import Edit from "../../../tools/password/edit.vue"

export default {
  components: { Navbar, Edit },

  data() {
    return {
      redirect: "/administracao/login"
    }
  }
}
</script>

<template lang="pug">
Modal(v-model="isShow", :close="close")
  .modal
    .info-container
      h1 {{ $t('.title') }}

      form(ref="form", action="/")
        .create-item-container
          item-service-field(
            name="transfer_item[bill_service_id]",
            :required="true"
          )
          currency-field(
            name="transfer_item[value]",
            type="number",
            :placeholder="$t('.placeholder.value')",
            :label="$t('.label.value')",
            :required="true"
          )
          textarea-field(
            name="transfer_item[complement]",
            :label="$t('.label.complement')",
            :placeholder="$t('.placeholder.complement')"
          )

    .buttons-container
      button.close(
        :disabled="submitting",
        @click="close"
      )
        | {{ $t(".buttons.close") }}

      button.confirm(
        :class="submitting && 'button-progress'",
        :disabled="submitting",
        @click="submit"
      )
        | {{ $t(".buttons.confirm") }}
</template>

<script>
import { ref } from 'vue'

import ItemServiceField from '../item-service-field.vue'

export default {
  components: { ItemServiceField },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.transfersItems.createModal",

      submitting: false,
      errors: {},
    }
  },

  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
      this.submitting = false
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  props: {
    show: { type: Boolean, default: false },
    callback: { type: Function },
    transferId: { type: Number }
  },

  computed: {
    transferItemTypeOptions() {
      return [
        { id: 'debit', text: this.$t('.itemTypes.debit') },
        { id: 'credit', text: this.$t('.itemTypes.credit') }
      ]
    }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  methods: {
    close() {
      this.$emit('close')
    },

    formatCurrencyValue(value) {
      return value && value.replace('R$', '').trim()
    },

    submit() {
      const formData = new FormData(this.$refs.form)

      formData.set(
        'transfer_item[value]',
        this.formatCurrencyValue(formData.get('transfer_item[value]'))
      )
      formData.set('transfer_item[transfer_id]', this.transferId)

      this.submitting = true;

      this.$http
        .post(`/admins/transfers/${this.transferId}/transfer-items/`, formData)
        .then(() => {
          this.errors = {}
          this.$notifications.clear()
          this.$notifications.info(this.$t(".notifications.submit.success"))

          this.callback()

          this.close()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))

          this.submitting = false
        })
    },
  }
}
</script>

<style scoped lang="scss">
.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  height: fit-content;
  width: 27%;

  .info-container {
    min-height: 11em;

    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
    }

    p {
      font-size: 1.5rem;
      color: $second-color-light;
      overflow-wrap: break-word;
      white-space: pre-line;
    }
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }

    .button-progress {
      background-image: linear-gradient(90deg, $primary-color 45%, $primary-light 45%);
      background-size: 200%;
      animation: progress 20s ease forwards;
    }

    @keyframes progress {
      from { background-position: right; }
      to { background-position: left; }
    }
  }
}
</style>

<style lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}
</style>

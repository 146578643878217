<template lang="pug">
.contract-section
  input-field(
    type="text"
    pattern="[A-Za-z0-9]+"
    name="contract[code]"
    :placeholder="$t('.placeholder.code')"
    :error="errors?.code?.message"
    v-model="code"
    required
  )

  input-field(
    type="date"
    name="contract[start_date]"
    :placeholder="$t('.placeholder.startDate')"
    :min="getCurrentDate"
    :error="errors?.startDate?.message"
    v-model="startDate"
    required
  )

  input-field(
    type="number"
    name="contract[months_until_deadline]"
    :placeholder="$t('.placeholder.monthsUntilDeadline')"
    :error="errors?.monthsUntilDeadline?.message"
    v-model="monthsUntilDeadline"
    required
  )

  input-field(
    type="number"
    max="31"
    name="contract[due_day]"
    :placeholder="$t('.placeholder.dueDay')"
    :error="errors?.dueDay?.message"
    v-model="dueDay"
    required
  )

  months-field(
    name="contract[readjustment_month]"
    :placeholder="$t('.placeholder.readjustmentMonth')"
    :error="errors?.readjustmentMonth?.message"
    v-model="readjustmentMonth"
    required
  )

  currency-field(
    name="contract[rent_value]"
    :placeholder="$t('.placeholder.rentValue')"
    :error="errors?.rentValue?.message"
    @input="fetchNewFee"
    v-model="rentValue"
    required
  )

  select-field(
    name="contract[contract_type]"
    :options="contractTypeOptions"
    :error="errors?.contractType?.message"
    v-model="contractType"
    includeBlank
    required
  )

  select-field(
    name="contract[readjustment_index]"
    :options="readjustmentIndexOptions"
    :error="errors?.readjustmentIndex?.message"
    v-model="readjustmentIndex"
    includeBlank
    required
  )

  select-field(
    name="contract[payment_type]"
    :options="paymentTypeOptions"
    :error="errors?.paymentType?.message"
    v-model="paymentType"
    includeBlank
    required
  )

  file-field(
    type="file"
    name="contract[rental_contract]"
    accept=".doc,.docx,.pdf"
    :error="errors?.rentalContract?.message"
    v-model="rentalContract"
    destroyable
  )

  file-field(
    type="file"
    name="contract[admin_contract]"
    accept=".doc,.docx,.pdf"
    :error="errors?.adminContract?.message"
    v-model="adminContract"
    destroyable
  )

  file-field(
    type="file"
    name="contract[procuration]"
    accept=".doc,.docx,.pdf"
    :error="errors?.procuration?.message"
    v-model="procuration"
    destroyable
  )

  textarea-field(
    name="contract[observation]"
    :maxLength="1500"
    :placeholder="$t('.placeholder.observation')"
    :error="errors?.observation?.message"
    v-model="observation"
  )
</template>

<script>
export default {
  data() {
    return {
      // i18n
      defaultI18nScope: 'realEstate.management.contract.new',

      // form fields data
      code: null,
      startDate: (new Date()).toISOString().split('T')[0],
      monthsUntilDeadline: null,
      dueDay: null,
      readjustmentMonth: null,
      rentValue: null,
      contractType: null,
      readjustmentIndex: null,
      paymentType: null,
      rentalContract: null,
      adminContract: null,
      procuration: null,
      observation: null
    }
  },

  props: {
    errors: { type: Object, default: () => ({}) },
    fetchNewFee: { type: Function, default: () => {} }
  },

  computed: {
    getCurrentDate() {
      return new Date().toISOString().split('T')[0]
    },

    readjustmentIndexOptions() {
      return [
        { id: 'igpm', text: this.$t('.readjustmentIndex.igpm') },
        { id: 'inpc', text: this.$t('.readjustmentIndex.inpc') },
        { id: 'ipca', text: this.$t('.readjustmentIndex.ipca') },
        { id: 'igpm', text: this.$t('.readjustmentIndex.igpdi') }
      ]
    },

    contractTypeOptions() {
      return [
        { id: 'commercial', text: this.$t('.contractType.commercial') },
        { id: 'non_residential', text: this.$t('.contractType.nonResidential') },
        { id: 'residential', text: this.$t('.contractType.residential') }
      ]
    },

    paymentTypeOptions() {
      return [
        { id: 'month_to_expire', text: this.$t('.paymentType.monthToExpire') }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.contract-section {
  margin-bottom: 3rem;
}
</style>

<template lang="pug">
#page.cnab-page
  navbar

  loading-div.cnab-container-phone
    .cnab-container
      breadcrumbs(
        :items="currentPage == 'remittance' ? breadcrumbRemittance : breadcrumbReturn"
      )

      expansible-card.menu-container(:title="$t('.menu')", :expanded="true")
        .menu-items
          button(
            :class="currentPage == 'remittance' && 'actived'",
            @click="currentPage = 'remittance'"
          )
            | {{ $t('.remittance') }}

          button(
            :class="currentPage == 'return' && 'actived'",
            @click="currentPage = 'return'"
          )
            | {{ $t('.return') }}

      remittance-page(v-if="currentPage == 'remittance'")
      return-page(v-if="currentPage == 'return'")
</template>
<script>
import tool from "lib/vue-tool"
import vueAuthenticate from "lib/vue-authenticate"
import Navbar from "../../components/navbar.vue"
import Breadcrumbs from '../../../components/breadcrumbs.vue'
import ExpansibleCard from "../../../components/expansible-card.vue"
import RemittancePage from '../../components/cnab/remittance-page.vue'
import ReturnPage from '../../components/cnab/return-page.vue'

export default {
  mixins: [tool, vueAuthenticate],
  components: { Navbar, Breadcrumbs, ExpansibleCard, RemittancePage, ReturnPage },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.cnab.index",

      // tool
      routeName: "realEstateCnabIndex",
      routePath: "imobiliaria/cnab",
      viewName: "index",

      currentPage: 'remittance',

      loading: true
    }
  },

  created() {
    this.showCnab()
  },

  computed: {
    breadcrumbRemittance() {
      return [
        {
          name: this.$t('.cnab'),
          url: this.routePath
        },
        {
          name: this.$t('.remittance'),
          url: this.routePath
        }
      ]
    },

    breadcrumbReturn() {
      return [
        {
          name: this.$t('.cnab'),
          url: this.routePath
        },
        {
          name: this.$t('.return'),
          url: this.routePath
        }
      ]
    }
  },

  methods: {
    fetch() {},

    parseRoute() {},

    showCnab() {
      const config = this.$firebase.remoteConfigValue('enableCnabPage')
      const roleValue = config[app.auth.role]

      if (!roleValue || !roleValue.includes(app.auth.userId)) {
        this.$router.replace({ path: '/acesso-nao-autorizado' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cnab-container-phone {
  padding: 0rem;
  width: 100vw;

  .menu-container {
    .menu-items {
      width: 100%;
      height: max-content;

      button {
        text-align: start;
        color: $second-color-light;
        font-weight: 400;
        border-radius: 0.5rem;
        border: none;
        padding: 0.5rem 1.5rem;
        height: auto;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: none;

        &.actived{
          background-color: $primary-high-light;
          color: $primary-color;
        }
      }
    }
  }

  .cnab-container {
    width: 100%;
    padding: 2.5rem;
    padding-bottom: 10rem;
  }
}
</style>
<template lang="pug">
.bills-index
  expansible-card(:title="$t('.titles.overview')" :expanded="true")
    overview(:items="overviewItems")

  .bills-index-container
    card
      .filtering-container
        h2 {{ $t('.titles.index') }}

        .actions
          dropdown(
            :callbacks="dropDownCallbacks"
            icon="fa-download"
            orientation="left"
          )

          button.icon.button-primary(
            @click="filterOpened = !filterOpened"
            :class="{ 'opened': filterOpened }"
          )
            i.fa.fa-filter

          button.icon.button-blue(@click.prevent="showNewBillModal = true")
            i.fa.fa-plus

      filter-container(
        :filter="filter"
        :opened="filterOpened"
        :i18nPath="defaultI18nScope"
      )
        date-field(
          v-model="query.dueDate"
          name="due-date"
          :label="$t('.label.dueDate')"
          :range="true"
        )

        date-field(
          v-model="query.paymentDate"
          name="payment-date"
          :label="$t('.label.paymentDate')"
          :range="true"
        )

        status-field(
          name="status"
          v-model="query.status"
        )

        agency-field(
          v-if="!this.contractId"
          v-model="query.realEstateAgency"
          name="real_estate_agency"
          :label="$t('.label.realEstateAgency')"
          fetchUrl="/admins"
          path="real-estate/agencies"
        )
          option(value="-1") {{ $t('none') }}

        input-field(
          v-if="!this.contractId"
          v-model="query.contractCode"
          name="contract_code"
          :label="$t('.label.contractCode')"
          :placeholder="$t('.placeholder.contractCode')"
        )

        person-field(
          v-model="query.tenant"
          name="tenant"
          :label="$t('.label.tenant')"
          :placeholder="$t('.placeholder.tenant')"
        )

        select-field(
          v-model="query.payer"
          name="payer"
          :label="$t('.label.payer')"
          :options="payerOptions"
          includeBlank
        )

        select-field(
          v-model="query.issuer"
          name="issuer"
          :label="$t('.label.issuer')"
          :options="issuerOptions"
          multiple
        )

      .table-section
        .table-container(@scroll="autoLoad")
          table
            thead
              tr
                th(v-for="name in columnNames")
                  p {{ $t(`.table.columns.${name}`) }}

            tbody
              tr(v-for="bill in bills")
                td.contract(@click="openBill(bill.id)")
                  .contract-info
                    span
                      b {{ $t('.table.contractInfo.code', { contractCode: bill.contractCode }) }}
                    span
                      | {{ `, ${bill.realEstateAgencyName}, ` }}
                    span
                      | {{ bill.contractAddress }}

                td.due-date(@click="openBill(bill.id)")
                  | {{ $l('date.formats.default', bill.dueDate) }}

                td.payment-date(@click="openBill(bill.id)")
                  | {{ $l('date.formats.default', bill.paymentDate) }}

                td.value(@click="openBill(bill.id)")
                  | {{ $t('.table.currency', { value: bill.value }) }}

                td.payer(@click="openBill(bill.id)")
                  | {{ billTranslate(`payerOptions.${bill.payer}`) }}

                td.guaranteed(@click="openBill(bill.id)")
                  | {{ $t(`${bill.guaranteed ? 'yes' : 'no'}`) }}

                td.status(@click="openBill(bill.id)")
                  .status-container(:class="bill.status")
                    | {{ billTranslate(`statusOptions.${getStatus(bill)}`) }}

                td.observation(@click="openBill(bill.id)")
                  | {{ bill.observation || '-' }}

              tr.loading(v-if="loading")
                td(v-for="cell in columnNames")
                  .cell
        footer
          p {{ $t('.pagination.text', { length: bills.length, total: pagination.total }) }}

  create-modal(
    :show="showNewBillModal"
    :contractId="contractId"
    @close="showNewBillModal = false"
  )

  report-modal(
    :show="showReportModal",
    :reportApiUrl="currentReportUrl",
    reportPageUrl="/administracao/relatorios"
    :callback="() => showReportModal = false"
  )
</template>
<script>
import ExpansibleCard from "../expansible-card.vue"
import AgencyField from "../../../components/agency-field.vue"
import Overview from "../overview.vue"
import CreateModal from "./create-modal.vue"
import FilterContainer from "../../../components/filter-container.vue"
import StatusField from "./status-field.vue"
import Dropdown from "../../../components/dropdown.vue"
import ReportModal from "../../../../../components/report-modal.vue"

import objects from "lib/objects"

export default {
  components: {
    ExpansibleCard,
    AgencyField,
    Overview,
    CreateModal,
    FilterContainer,
    StatusField,
    Dropdown,
    ReportModal
  },

  mounted() {
    this.fetch()
    this.fetchOverview()
  },

  props: {
    contractId: { type: Number, default: null }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.bills.index",

      // filters
      filterOpened: false,

      query: {
        status: [
          'waiting', 'normal', 'paid', 'manual_payment', 'unpaid', 'agreement'
        ],
        paymentDate: null,
        realEstateAgency: null,
        contractCode: null,
        tenant: null,
        payer: null,
        issuer: null,
        dueDate: (() => {
          if (this.contractId) return

          const date = new Date()
          const firstDayOfMonth = new Date(
            date.getFullYear(), date.getMonth(), 1
          )
          const lastDayOfMonth = new Date(
            date.getFullYear(), date.getMonth() + 1, 0
          )

          return [firstDayOfMonth, lastDayOfMonth]
        })()
      },

      // table
      columnNames: [
        "contractInfo",
        "dueDate",
        "paymentDate",
        "value",
        "payer",
        "guaranteed",
        "status",
        "observation"
      ],
      bills: [],
      pagination: { total: 0 },
      loading: false,

      // modal
      showNewBillModal: false,

      // overview
      overviewData: {
        total: { count: 0 },
        normal: { count: 0 },
        paid: { count: 0 },
        unpaid: { count: 0 },
        agreement: { count: 0 }
      },

      // report modal
      showReportModal: false,
      currentReportUrl: null
    }
  },

  methods: {
    init(bills, pagination) {
      this.bills.push(...objects.camelize(bills))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total"),
      }
    },

    filter() {
      this.replaceQuery()

      this.bills = []

      this.fetch()
      this.fetchOverview()
      this.filterOpened = false
    },

    fetch(page = null) {
      this.loading = true

      this.$http
        .get("/admins/bills.json", { params: this.httpParams(page) })
        .then((response) => {
          const body = response.data
          const bills = body.data
          const pagination = body.pagination || {}

          this.init(bills, pagination)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.allPageChecked = false
          this.loading = false
        })
    },

    fetchOverview() {
      this.$http
        .get("/admins/infos/bills.json", { params: this.httpParams() })
        .then((response) => {
          const overview = response.data

          this.overviewData = overview
        })
        .catch(() => {
          this.overviewData = {
            total: { count: 0 },
            normal: { count: 0 },
            paid: { count: 0 },
            unpaid: { count: 0 },
            agreement: { count: 0 }
          }

          this.$notifications.error(
            this.$t(".notifications.fetchOverview.failure")
          )
        })
    },

    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target
      const loadingHeight = scrollHeight - 5

      if ((scrollTop + clientHeight < loadingHeight) || !this.pagination.next) {
        return
      }

      this.loading = true

      this.fetch(this.pagination.next)
    },

    formattedRangeFilter(range) {
      if (!range) return null

      return { start_date: range[0], end_date: range[1] }
    },

    httpParams(page = null) {
      return this.$params.toRouteParams({
        dueDate: this.formattedRangeFilter(this.query.dueDate),
        paymentDate: this.formattedRangeFilter(this.query.paymentDate),
        real_estate_agency: this.query.realEstateAgency,
        contract_code: this.query.contractCode,
        status: this.query.status,
        contractId: this.contractId,
        tenant: this.query.tenant,
        payer: this.query.payer,
        issuer: this.query.issuer,
        page: page
      })
    },

    openBill(billId) {
      const route = this.$router.resolve({
        path: `/administracao/gerenciamento/cobrancas/${billId}`
      })

      window.open(route.href, "_blank")
    },

    billTranslate(attribute) {
      return this.$t(`models.bill.attributes.${attribute}`)
    },

    getStatus(bill) {
      const billStatus = bill?.status

      if (!bill.bankSlipUrl && billStatus === 'waiting') {
        return 'waitingBankSlipCreate'
      }

      if (bill.awaitingBankSlipUpdate) return 'awaitingBankSlipUpdate'

      return billStatus
    },

    generateDelinquencyReport() {
      const params = this.httpParams()
      params["select_all"] = true

      this.$http
        .get('/admins/bills/downloads/delinquency-summary-csv', { params: params })
        .then((response) => {
          const report = objects.camelize(response.data.data)

          this.currentReportUrl = `/admins/reports/${report.id}`
          this.showReportModal = true
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.download.failure"))
        })
    }
  },

  computed: {
    overviewItems() {
      return [
        {
          description: this.$t(".overview.total"),
          quantity: this.overviewData.total.count
        },
        {
          description: this.$t(".overview.normal"),
          quantity: this.overviewData.normal.count
        },
        {
          description: this.$t(".overview.paid"),
          quantity: this.overviewData.paid.count
        },
        {
          description: this.$t(".overview.unpaid"),
          quantity: this.overviewData.unpaid.count
        },
        {
          description: this.$t(".overview.agreement"),
          quantity: this.overviewData.agreement.count
        }
      ]
    },

    dropDownCallbacks() {
      return [
        {
          name: this.$t(".dropDown.delinquencySummary"),
          callback: this.generateDelinquencyReport
        }
      ]
    },

    payerOptions() {
      const options = ['tenant', 'real_estate_agency']

      return options.map((option) => ({
        id: option,
        text: this.billTranslate(`payerOptions.${option}`)
      }))
    },

    issuerOptions() {
      const options = ['superlogica', 'iugu', 'itau', 'banco_do_brasil']

      return options.map((option) => ({
        id: option,
        text: this.billTranslate(`issuerOptions.${option}`)
      }))
    }
  }
}
</script>
<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: repeat(3, auto) repeat(2, max-content);
  column-gap: 0.5em;

  .button-primary {
    margin-bottom: 1.4rem;
    margin-top: auto;
  }

  .new-bill-container {
    margin-bottom: 1.4rem;
    margin-top: auto;

    padding-left: 0.5em;
    border-left: 2px solid $grey-color;

    .button-blue {
      margin: 0;
      padding: 0 1.5rem;
    }
  }
}

.filtering-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 2rem;
    font-weight: 500;
    color: $primary-color;
  }

  .actions {
    display: flex;

    button.opened {
      opacity: 0.5;
    }

    button.icon {
      font-size: 1.5rem;
      width: 4rem;
      padding: 0;
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 5rem;

  p,
  button {
    margin: 0;
  }
}

.table-section {
  margin-top: 1.5rem;
}

.table-container {
  height: 40vh !important;
}

table {
  tbody {
    tr {
      min-width: 0 !important;

      &:hover {
        td {
          background-color: darken($white-color-dark, 6%);

          &.contract,
          &.due-date,
          &.payment-date,
          &.value,
          &.guaranteed,
          &.observation,
          &.status {
            cursor: pointer;
          }
        }
      }
    }
  }

  td {
    text-transform: capitalize !important;

    .contract-info {
      display: inline-block;
      vertical-align: top;

      width: 28.2rem;

      overflow-wrap: break-word;
      line-break: anywhere;
      white-space: normal;
    }
  }

  .due-date,
  .payment-date,
  .value,
  .guaranteed,
  .status {
    min-width: 13rem !important;
  }

  .status-container {
    border-radius: 0.5rem;

    width: min-content;

    color: $white-color;
    text-transform: uppercase;
    font-size: 1.4rem;

    padding: 0.5rem 2rem;

    &.waiting {
      background-color: $grey-color;
    }

    &.paid,
    &.manual_payment {
      background-color: $primary-color;
    }

    &.unpaid {
      background-color: $red-color;
    }

    &.agreement {
      background-color: $blue-color;
    }

    &.normal {
      background-color: $yellow-color;
    }

    &.canceled, &.chargeback {
      background-color: $second-color;
    }
  }

  .checkbox {
    min-width: 0 !important;
    height: 100%;

    display: flex;
    align-items: center;

    padding-right: 1rem;
    border-right: 1.5px solid $grey-color-light;

    input[type="checkbox"] {
      -webkit-appearance: none;
      appearance: none;

      background-color: #fff;

      color: $primary-color;

      width: 2.5rem;
      height: 2.5rem;
      margin: 0;

      border: 0.16rem solid $second-color-light;
      border-radius: 0.2rem;

      transform: translateY(0.15rem);

      display: grid;
      place-content: center;

      &::before {
        content: "";
        width: 2.5rem;
        height: 2.5rem;

        transform: scale(0);
        transition: 120ms transform ease-in-out;

        box-shadow: inset 2.5em 2.5em $primary-color;
        border-radius: 0.2rem;
      }

      &:checked::before {
        transform: scale(1);
      }
    }
  }
}
</style>

<template lang="pug">
button.button-primary(@click="addItem") {{ $t('.addItem') }}

.table-section
  .table-container
    table
      thead
        tr
          th(v-for="name in columnNames")
            p {{ $t(`.table.${name}`) }}

      tbody
        tr(v-for="(item, index) in items" :key="index")
          td.service
            item-service-field(
              :hideLabel="true",
              v-model="item.billServiceId",
              :item="item"
              @update:description="(text) => { item.description = text }"
            )

          td.value
            currency-field(:hideLabel="true", v-model="item.value")

          td.complement
            input-field(:hideLabel="true", v-model="item.complement")

          td.actions
            button.button-red(@click="removeItem(index)")
              i.fa.fa-trash

      tfoot
        td {{ $t('.table.total') }}
        td {{ valueFormat(itemsValue) }}
        td
        td
</template>

<script>
import currencyFormatter from 'lib/currency-formatter'
import ItemServiceField from '../../../item-service-field.vue'

export default {
  components: { ItemServiceField },

  data() {
    return {
      // i18n
      defaultI18nScope: 'admin.agreements.createModal.items'
    }
  },

  props: {
    items: { type: Array, required: true },
    itemsValue: { type: Number, required: true }
  },

  computed: {
    columnNames() {
      return ['service', 'value', 'complement', 'actions']
    }
  },

  methods: {
    valueFormat(value) {
      return value ? currencyFormatter.format(value) : '-'
    },

    addItem() {
      this.items.push({ billServiceId: null, value: 0, complement: null })
    },

    removeItem(index) {
      this.items.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.table-container {
  height: auto !important;
  max-height: 40vh;

  table {
    tbody {
      tr {
        height: 5rem !important;
      }
    }

    td {
      text-transform: capitalize !important;

      &.complement {
        width: 100%;
      }

      &.actions {
        min-width: fit-content;

        button {
          margin: 0;
          width: 3.8rem;
          padding: 0;
        }

        i {
          font-size: 1.8rem;
        }
      }

      .select-field, .currency-field, .input-field {
        margin: 0;
      }
    }
  }
}
</style>

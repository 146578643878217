<template lang="pug">
#page
  Navbar

  New(:redirect="redirect" url="/admin/password" model="admin")
</template>

<script>
import Navbar from "../../../components/navbar.vue"
import New from "../../../tools/password/new.vue"

export default {
  components: { Navbar, New },

  data() {
    return {
      redirect: "/administracao/login"
    }
  }
};
</script>

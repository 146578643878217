<template lang="pug">
.field.select-field(:class="{ error, disabled }")
  label(:for="inputId") {{ labelText }}
  select2(
    :id="inputId"
    :disabled="disabled"
    :name="name"
    :options="managers"
    :overlay="overlay"
    :placeholder="thePlaceholder"
    type="integer"
    :value="modelValue"
    :includeBlank="true"
    :hide-search="false"
    @input="onInput"
  )
    slot
  input-error(:message="error")
</template>


<script>
import SelectField from "src/components/desktop/select-field.vue"

export default {
  extends: SelectField,

  data() {
    return {
      defaultI18nScope: "realEstate.components.managerField",

      initialized: false,
      managers: [],
      fetching: false
    }
  },

  props: {
    modelValue: { type: [String, Number], default: null }
  },

  created() {
    this.fetch()
  },

  computed: {
    thePlaceholder() {
      if (this.fetching) return this.$t("loading")

      return this.placeholder || this.$t("options.blank")
    }
  },

  methods: {
    fetch() {
      this.fetching = true

      return this.$http
        .get(`${this.$user.prefixUrlAgency}/managers`)
        .then((response) => {
          const body = response.data

          this.managers = body.data.map(({id, name}) => ({ id, text: name }))

          this.managers.unshift({ id: '-1', text: this.$t("none") })
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .then(() => {
          this.initialized = true
          this.fetching = false
        })
    },

    onInput(val) {
      this.$emit('update:modelValue', val == -1 ? null : val)
    }
  }
}
</script>

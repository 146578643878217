<template lang="pug">
Modal(v-model="isShow")
  .modal
    .info-container
      h1 {{ $t(`.title`) }}

      p(v-html="$t(`.steps.${step}`)")

    .content-container
      .tenant-bill(v-if="step == 'tenantBill'")
        date-field.date-field-input(
          v-model="tenantBillDate"
          :label="$t('.dateField.label')"
          required
        )

        items(:items="tenantItems" :itemsValue="tenantValue")

      .agency-bill(v-if="step == 'agencyBill'")
        date-field.date-field-input(
          v-model="agencyBillDate"
          :label="$t('.dateField.label')"
          required
        )

        items(:items="agencyItems" :itemsValue="agencyValue")

    .buttons-container
      .tenant-bill(v-if="step == 'tenantBill'")
        button.close(:disabled="submitting" @click="close")
          | {{ $t(".buttons.close") }}

        button.confirm(@click="step = 'agencyBill'")
          | {{ $t(".buttons.next") }}

      .agency-bill(v-if="step == 'agencyBill'")
        button.button-grey(@click="step = 'tenantBill'" :disabled="submitting")
          | {{ $t(".buttons.return") }}

        button.confirm(
          type="submit"
          @click="submit"
          :class="submitting && 'button-progress'"
          :disabled="submitting"
        )
          | {{ $t(".buttons.confirm") }}
</template>
<script>
import { ref } from 'vue'
import objects from "lib/objects"
import moment from 'moment'

import Items from '../financial/agreements/new/create_modal/items.vue'

export default {
  components: { Items },

  setup () {
    const isShow = ref(false)

    function showModal () {
      this.fetch()

      this.tenantBillDate = moment(this.dueDate).format()

      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  props: {
    show: { type: Boolean, default: false },
    billId: { type: Number, required: true },
    dueDate: { type: Date, required: true },
    onSuccess: { type: Function, required: true }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.bills.chargeBackModal",

      step: 'tenantBill',
      submitting: false,

      // form
      tenantBillDate: null,
      agencyBillDate: moment().format(),
      tenantItems: [],
      agencyItems: []
    }
  },

  computed: {
    tenantValue() {
      return this.itemsValue(this.tenantItems)
    },

    agencyValue() {
      return this.itemsValue(this.agencyItems)
    },

    params() {
      return {
        chargeback_bill: {
          agency_due_date: this.agencyBillDate,
          tenant_due_date: this.tenantBillDate,
          agency_items: this.agencyItems,
          tenant_items: this.tenantItems
        }
      }
    }
  },

  methods: {
    init(data) {
      const preChargeBack = objects.camelize(data)

      this.tenantItems = preChargeBack.tenantItems
      this.agencyItems = preChargeBack.agencyItems
    },

    close() {
      this.step = 'tenantBill'

      this.$emit('close')
    },

    itemsValue(items) {
      return this.formattedFloat(
        items.reduce((total, item) => { return total + item.value }, 0)
      )
    },

    formattedFloat(value) {
      return value ? parseFloat(value.toFixed(2)) : 0
    },

    fetch() {
      this.$http.post(`/admins/bills/${this.billId}/pre-chargeback.json`)
        .then((response) => {
          const body = response.data

          this.init(body)
        })
        .catch(() => {
          this.close()

          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
    },

    openChargeBackBill(id) {
      const path = `/administracao/gerenciamento/cobrancas/${id}`
      const route = this.$router.resolve({ path: path })

      window.open(route.href, '_blank')
    },

    submit() {
      this.submitting = true

      this.$http
        .post(`/admins/bills/${this.billId}/chargeback.json`, objects.snakeize(this.params))
        .then((response) => {
          this.$notifications.info(
            this.$t('.notifications.submit.success')
          )

          const bills = response.data.data

          this.onSuccess()
          this.openChargeBackBill(bills.agency.id)
          this.openChargeBackBill(bills.tenant.id)

          this.close()
        })
        .catch(() => {
          this.$notifications.error(
            this.$t('.notifications.submit.failure')
          )
        }).finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .modal {
    background-color: $white-color;
    border-radius: 0.7rem;
    box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
    padding: 2rem;
    height: fit-content;
    width: 90%;

    .date-field-input {
      width: max-content;
    }

    .info-container {
      h1 {
        font-size: 1.7rem;
        font-weight: 550;
        margin-bottom: 0.5rem;
        color: $second-color-dark;
      }

      p {
        font-size: 1.5rem;
        color: $second-color-light;
        overflow-wrap: break-word;
        white-space: pre-line;
      }
    }

    .buttons-container {
      .tenant-bill, .agency-bill {
        display: grid;
        justify-content: flex-end;
        grid-auto-flow: column;
        grid-column-gap: 1rem;
        width: 100%;
        margin-top: 2rem;
      }

      button {
        width: fit-content;
        margin-bottom: 0;
      }

      .confirm {
        color: $white-color;
        background-color: $primary-color;
      }

      .close {
        color: $red-color;
        border-color: $red-color;
        background-color: $white-color;
      }

      .button-progress {
        background-image: linear-gradient(90deg, $primary-color 45%, $primary-light 45%);
        background-size: 200%;
        animation: progress 20s ease forwards;
      }

      @keyframes progress {
        from { background-position: right; }
        to { background-position: left; }
      }
    }
  }
</style>

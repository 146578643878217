<template lang="pug">
.component.timeline
  .chrome
    h1.heading {{ $t('.title') }}
    p.created-at(v-if="propertyCreatedAt")
      | {{ $t('.createdAt', { time: $l('time.formats.micro', propertyCreatedAt) }) }}
    p.realtor-indicator(v-if="realtorIndicator")
      | {{ $t('.realtorIndicator', { name: realtorIndicator }) }}

    .owner-info
      .info(v-if="leader")
        label.label {{ $t('.leader') }}
        p.value {{ leader }}

      .info(v-if="realtor")
        label.label {{ $t('.realtor') }}
        p.value {{ realtor }}

  .list
    //- card dinâmico de acrodo com o tipo do evento (STI)
    component(
      v-for="event in events",
      :is="cardComponentName(event)",
      :key="event.id",
      :event="event"
    )
    span.empty(v-if="isEmpty") {{ $t('.empty') }}
</template>

<script>
import objects from "lib/objects";
import PropertyStateChangeCard from "./_timeline/property-state-change-card.vue";
import PropertyCommentCard from "./_timeline/property-comment-card.vue";
import PropertyNewRentCard from "./_timeline/property-new-rent-card.vue";
import PropertyCloseRentCard from "./_timeline/property-close-rent-card.vue";

export default {
  components: {
    PropertyStateChangeCard,
    PropertyCommentCard,
    PropertyNewRentCard,
    PropertyCloseRentCard,
  },

  props: {
    realtorIndicator: { type: String },
    leader: { type: String },
    realtor: { type: String },
    propertyCreatedAt: { type: String },
    url: { type: String, required: true },
  },

  data() {
    return {
      defaultI18nScope: "timeline",

      events: [],
    };
  },

  created() {
    // Necessário para requisisões sincronas, uma vez que o init() de
    // properties/show.vue é carregado antes do created deste componente.
    // Ou seja, ainda não existe um listener registrado neste componente
    this.fetch();

    // Necessário para atualizar lista sempre que um imóvel for atualizado.
    // Hoje temos o caso da atualização de situação.
    app.$on("property:init", () => this.fetch());
  },

  beforeUnmount() {
    app.$off("property:init");
  },

  methods: {
    fetch() {
      return this.$http
        .get(this.url)
        .then((response) => {
          this.events = response.data.map((event) => objects.camelize(event));
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"));
        });
    },

    cardComponentName(ev) {
      return `${ev.type}Card`;
    },
  },

  computed: {
    isEmpty() {
      return !(this.events && this.events.length);
    },
  },
};
</script>

<style scoped lang="scss">
.component {
  position: relative;
  background: white;
}

.chrome {
  position: sticky;
  top: 0;
  background: white;
  border-bottom: 1px solid #e1e1e1;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 16px;

  .heading {
    text-align: center;
    letter-spacing: 0.07rem;
    color: #797979;
    font-size: 24px;
  }

  .created-at,
  .realtor-indicator {
    text-align: center;
    color: #797979;
    font-size: 13px;

    margin-bottom: 0;
  }
}

.owner-info {
  margin-top: 18px;

  .label {
    display: inline-block;
    margin: 0;
    margin-right: 6px;
  }

  .value {
    display: inline-block;
    color: #797979;
    font-size: 13px;

    margin-bottom: 0;
  }
}

.list {
  overflow-y: auto;
  padding: 20px 16px;
}
</style>
<template lang="pug">
#page
  navbar

  .admin-management-container-phone
    breadcrumbs(:items="generateBreadcrumb")

    expansible-card.menu-container(:title="$t('.menu')" :expanded="true")
      .menu-items
        button(
          v-for="permission in permissions"
          :class="currentPage == permission && 'actived'"
          @click="() => { setCurrentPage(permission) }"
        )
          | {{ $t(`.menus.${permission}`) }}

    score-reports(v-if="currentPage == 'scoreReports'")
    bills(v-if="currentPage == 'bills'")
</template>
<script>
import tool from "lib/vue-tool"
import vueAuthenticate from "lib/vue-authenticate"

import Navbar from "../../components/navbar.vue"
import Breadcrumbs from '../../../components/breadcrumbs.vue'
import ExpansibleCard from "../../../components/expansible-card.vue"
import ScoreReports from '../../components/score_reports/index.vue'
import Bills from '../../components/bills/index.vue'

export default {
  mixins: [tool, vueAuthenticate],
  components: { Navbar, Breadcrumbs, ExpansibleCard, ScoreReports, Bills },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.management.index",

      // tool
      routeName: "adminManagementIndex",
      routePath: "administracao/gerenciamento",
      viewName: "show",

      permissions: ['scoreReports', 'bills']
    }
  },

  methods: {
    fetch() {},

    parseRoute() { },

    setCurrentPage(anchor) {
      if (this.$route.hash.slice(1) == anchor) return

      this.$router.push({name: this.routeName, hash: `#${anchor}` })
    },
  },

  computed: {
    currentPage() {
      const hash = this.$route.hash.slice(1)

      if (this.permissions.includes(hash)) return hash

      if (hash) this.$router.replace({ hash: null })

      return this.permissions[0]
    },

    generateBreadcrumb() {
      return [
        {
          name: this.$t('.breadcrumbs.management'),
          url: this.routePath,
          hash: this.currentPage
        },
        {
          name: this.$t(`.breadcrumbs.${this.currentPage}`),
          url: this.routePath,
          hash: this.currentPage
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-management-container-phone {
  padding: 2rem;
  width: 100%;

  .menu-container {
    .menu-items {
      width: 100%;
      height: max-content;

      button {
        text-align: start;
        color: $second-color-light;
        font-weight: 400;
        border-radius: 0.5rem;
        border: none;
        padding: 0.5rem 1.5rem;
        height: auto;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: none;

        &.actived{
          background-color: $primary-high-light;
          color: $primary-color;
        }
      }
    }
  }

  .content-container {
    width: 100%;
    padding: 2.5rem;
    padding-bottom: 10rem;
  }
}
</style>
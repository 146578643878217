import mobile from 'is-mobile'

const browserType = {
  isDesktop() {
    return !mobile();
  },

  isMobile() {
    return mobile();
  }
}

export default browserType

<template lang="pug">
Modal
  .modal
    h1.title {{ $t('.title') }}

    .file-container
      .file
        h3.file-name {{ file.name || "-" }}

        a(target="_blank", :href="attachedFileData.address")
          i.fa.fa-download
          | {{ $t(".download") }}

      .info-container
        .info
          i.fa.fa-file-o
          p.info-content {{ attachedFileData.fileSize || "-" }}

        .info
          i.fa.fa-calendar-o
          p.info-content {{ getFormattedTimeDate(file.createdAt) }}

        .info
          i.fa.fa-user-o
          p.info-content {{ file.authorName || "-" }}

    .errors-container
      expansible-content(:title="$t('.errors.title')", :expanded="true")
        .table-section
          .table-container
            table
              thead
                tr
                  th(v-for="header in errorsTableHeaders")
                    p {{ this.$t(`.errors.${header}`) }}

              tbody
                tr(v-for="error in errorLog")
                  td.line {{ error.line }}

                  td.message {{ $t(`.errors.status.${error.status}`) }}

    .bills-container
      expansible-content(:title="$t('.bills.title')", :expanded="true")
          .table-section
            .table-container
              table
                thead
                  tr
                    th(v-for="header in billsTableHeaders")
                      p {{ this.$t(`.bills.${header}`) }}

                tbody
                  tr(v-for="bill in bills")
                    td.id {{ bill.billId }}

                    td.dueDate {{ getFormattedDate(bill.dueDate) }}

                    td.value {{ getFormattedCurrency(bill.value) }}

                    td.details
                      i.fa.fa-external-link(@click="openBill(bill.billId)")

    .buttons-container
        button.close(@click="this.$emit('close')")
          | {{ $t('.buttons.close') }}

</template>
<script>
import objects from "lib/objects"
import currencyFormatter from 'lib/currency-formatter'
import expansibleContent from "../../expansible-content.vue";

export default {
  components: { expansibleContent },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.realEstateAgencies.billsMassiveUpdate.fileModal",

      file: {},
      attachedFileData: {},
      bills: [],
      errorLog: [],
      errorsTableHeaders: ['line', 'message'],
      billsTableHeaders: ['id', 'dueDate', 'value', 'details'],
    }
  },

  props: {
    show: { type: Boolean, default: false },
    agencyId: { type: String, required: true },
    fileId: { type: Number, required: true },
  },

  methods: {
    init(file) {
      this.file = objects.camelize(file)
      this.attachedFileData = this.file.attachedFileData
      this.errorLog = this.file.errorLog
      this.bills = this.file.bills
    },

    fetch() {
      this.loading = true

      this.$http
        .get(this.filePath)
        .then((response) => {
          const body = response.data
          const file = body.data

          this.init(file)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.loading = false
        })
    },

    getFormattedTimeDate(date) {
      return date ? this.$l('time.formats.micro', date) : '-'
    },

    getFormattedDate(date) {
      return date ? this.$l('date.formats.default', date) : '-'
    },

    getFormattedCurrency(value) {
      return value ? currencyFormatter.format(value) : '-'
    },

    openBill(billId) {
      const route = this.$router.resolve({
        path: `/administracao/gerenciamento/cobrancas/${billId}`
      })

      window.open(route.href, "_blank")
    }
  },

  computed: {
    filePath() {
      const path = `/admins/real-estate/agencies`

      return `${path}/${this.agencyId}/bulk-edit-bill/files/${this.fileId}`
    }
  }
}
</script>
<style scoped lang="scss">
.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  width: 50%;
  height: fit-content;
  max-height: 90rem;
  overflow: auto;

  .title {
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
    color: $primary-color;
    margin-bottom: 1rem;
  }

  .file-container {
    background-color: $white-color-dark;
    width: 100%;
    height: 10rem;
    border: 1px solid $grey-color;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 2rem;

    .file {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1rem;
      border-bottom: 1px solid $grey-color;
      margin-bottom: 1rem;

      .file-name {
        font-size: 1.8rem;
        font-weight: 500;
        margin: 0;
      }

      a {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-size: 1.8rem;
        font-weight: 600;
        text-decoration: none;
      }
    }

    .info-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      .info {
        white-space: nowrap;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        color: $second-color-light;

        .info-content {
          margin: 0
        }
      }
    }
  }

  .errors-container, .bills-container {
    margin-bottom: 2rem;
  }

  .table-section {
    .table-container {
      height: fit-content;
      max-height: 40rem;

      tbody {
        tr {
          height: fit-content;
        }

        td {
          &.details {
            min-width: 1rem;

            i {
              cursor: pointer;
              color: $primary-color;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;
    margin-top: 2rem;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}
</style>

<style lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}
</style>
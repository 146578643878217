<template lang="pug">
.cpf-cnpj-field(:class="{ error, disabled, readonly }")
  label(v-if="!hideLabel" :class="{ 'required': required }" :for="inputId")
    | {{ labelText }}
  input(
    type="text"
    :id="inputId"
    :autocomplete="autocomplete"
    :autofocus="autofocus"
    :disabled="disabled"
    :readonly="readonly"
    :name="name"
    :placeholder="placeholder"
    :required="required"
    :value="modelValue"
    v-maska="mask"
    @input="$emit('update:modelValue', $event.target.value)"
  )
  input-error(:message="error")
</template>

<script>
import InputField from "./input-field.vue";

export default {
  extends: InputField,

  data() {
    return {
      defaultI18nScope: "components.cpfCnpjField"
    }
  },

  props: {
    placeholder: { type: String, default: "" },
    label: { type: String, default: "" }
  },

  computed: {
    mask() {
      if (this.modelValue?.length > 14) return "##.###.###/####-##"

      return "###.###.###-##"
    },

    labelText() {
      return this.label ? this.label : this.$t(".label")
    }
  }
}
</script>

<style scoped lang="scss">
// TODO definir cor de erro!
$input-error-color: red;
$input-error-border-color: red;
$input-disabled-color: #ccc;
$input-disabled-border-color: #e3e3e3;

.cpf-cnpj-field {
  width: 100%;
  margin-bottom: 1.5rem;

  label.required::after {
    content: " *";
    color: #9b53d7;
  }

  input {
    width: 100%;
    height: 38px;
    margin-bottom: 0;
    padding: 6px 10px;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
  }
}

// error
.cpf-cnpj-field.error {
  label {
    color: $input-error-color;
  }

  input {
    border-color: $input-error-border-color;
  }
}

// disabled
.cpf-cnpj-field.disabled {
  label {
    color: $input-disabled-color;
  }

  input {
    border-color: $input-disabled-border-color;
    color: $input-disabled-color;
  }
}

// readonly
.cpf-cnpj-field.readonly {
  input {
    color: $second-color-dark;
  }
}
</style>

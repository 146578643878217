<template lang="pug">
.alert-container
  i.icon.fa.fa-exclamation-triangle
  .info-container
    p {{ message }}
    .contacts-cointainer(v-if="contacts")
      p {{ $t('.contacts.whatsapp') }}
        a(target="blank", :href="$env.VUE_APP_WHATSAPP_LINK")
          | {{ $env.VUE_APP_WHATSAPP }}
      p {{ $t('.contacts.email') }}
        a(:href="`mailto:${$env.VUE_APP_EMAIL}`")
          | {{ $env.VUE_APP_EMAIL }}
</template>

<script>
export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "alert",
    }
  },
  props: {
    message: { type: String, required: false},
    contacts: { type: Boolean, required: true }
  }
}
</script>

<style scoped lang="scss">
.alert-container {
  display: flex;
  align-items: center;
  background-color: $light-red-color;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);

  .icon {
    font-size: 5rem;
    color: $red-color;
    margin: 2rem;
  }

  .info-container {
    margin: 0.5rem;
    font-size: 1.3rem;

    p {
      display: flex;
      margin-bottom: 0.2rem;
      gap: 0.5rem;
      font-weight: 500;
      color: $red-color;
    }

    a {
      font-weight: 450;
      color: $red-color;
    }
  }
}
</style>

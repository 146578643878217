<template lang="pug">
select-field(
  :name="name",
  :options="options",
  :error="error",
  :label="$t('.label')"
  @input="(val) => { $emit('update:modelValue', val) }"
  multiple
  includeBlank
)
</template>
<script>
  export default {
    data() {
      return {
        // i18n
        defaultI18nScope: "admin.reports.statusField"
      }
    },

    computed: {
      options() {
        const statusOptions = ['todo', 'wip', 'done', 'error']

        return statusOptions.map(option => ({
          id: option,
          text: this.$t(`models.report.statusOptions.${option}`)
        }))         
      }
    },

    props: {
      name: { type: String },
      error: { type: String }
    }
  }
</script>
  
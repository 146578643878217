<template lang="pug">
.loading-card-container
  .name
  .cpf
  .flag
  .buttons-container
    .button.wild-card(v-if="wildCard")
    .button
</template>
<script>
export default {
  props: {
    wildCard: { type: Boolean, default: false }
  }
}
</script>
<style lang="scss">
.loading-card-container {
  background-color: $white-color;
  padding: 2rem;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);

  .name, .cpf, .flag, .button {
    background: $loading-gradient;
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  .name, .cpf {
    width: 100%;
    height: 5rem;
    margin-bottom: 2.5rem;
  }

  .flag {
    width: 30%;
    height: 4rem;
    margin-top: -2rem;
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;

    margin-top: 2.5rem;

    .button {
      margin: 0;
      width: 10rem;
      height: 3.5rem;
      margin-left: 1rem;
      border: none;
    }
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
</style>
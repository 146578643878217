<template lang="pug">
nav.navbar
  .content
    .first-content
      router-link.logo(to="/")
        img(src="@/assets/images/logo.svg")

      AgencyField.hide-on-small-screen

    .small-screen
      i.fa.m-0(
        :class="expanded ? 'fa-times' : 'fa-bars'"
        @click="expanded = !expanded"
      )

    NavbarContent.large-screen-content(
      v-bind="{ items, currentPage }"
    )

  NavbarContent.small-screen-content(
    v-if="expanded"
    v-bind="{ items, currentPage }"
    includeAgencyField
  )
</template>

<script>
import NavbarContent from './navbar/content.vue'
import AgencyField from './navbar/agency-field.vue'

export default {
  components: { NavbarContent, AgencyField },

  data() {
    return {
      expanded: false
    }
  },

  props: {
    items: { type: Array, default: () => [] },
    currentPage: { type: String, default: '' }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../components/overlay-wnd-variables";

nav.navbar {
  background-color: $white-color;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: $overlay-z-index - 1;

  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 1.5rem 5rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    .first-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      .select-field {
        border-left: 2px solid $primary-color;
        padding-left: 1rem;
        margin: 0;
      }
    }

    .logo img {
      width: 9rem;
    }
  }

  @media only screen and (max-width: 930px) {
    .large-screen-content {
      display: none !important;
    }

    .hide-on-small-screen {
      display: none !important;
    }

    .small-screen-content {
      display: flex !important;
    }

    .small-screen {
      display: flex !important;
      flex-direction: column;

      i {
        color: $primary-color;
        cursor: pointer;
      }
    }
  }

  @media only screen and (min-width: 930px) {
    .large-screen-content {
      display: flex !important;
    }

    .small-screen, .small-screen-content {
      display: none !important;
    }
  }

  @media only screen and (min-width: 1700px) {
    padding-inline: 10rem !important;
  }

  @media only screen and (min-width: 1800px) {
    padding-inline: 20rem !important;
  }

  @media only screen and (min-width: 2000px) {
    padding-inline: 30rem !important;
  }

  @media only screen and (min-width: 2150px) {
    padding-inline: 40rem !important;
  }

  @media only screen and (min-width: 2350px) {
    padding-inline: 50rem !important;
  }
}
</style>
<template lang="pug">
.card
  .line(@click="expanded = !expanded")
    h3.status(:class="statusDasherized") {{ translatedStatus }}

    .main-infos
      .info
        h3.info-title {{ $t('.infos.cpf') }}
        p.info-content {{ scoreData.cpf || '-' }}

      .info
        h3.info-title {{ $t('.infos.name') }}
        p.info-content {{ scoreData.name || '-' }}

      .info
        h3.info-title {{ $t('.infos.age') }}
        p.info-content {{ scoreData.age || '-' }}

    .info(v-if="!scoreData.approved && scoreData.disapprovalSituation")
      h3.info-title {{ $t('.infos.disapprovalSituation') }}
      p.info-content {{ disapprovalSituationTranslate }}

    .spoiler(v-if="score.original && !rerunned")
      button.button-spoiler(@click="expandedClone = !expandedClone")
        | {{ $t('.clone.buttonSpoiler') }}
        i.fa(:class="expandedClone ? 'fa-chevron-down' : 'fa-chevron-up'")

      .expansible(v-if="expandedClone")
        router-link.button.button-primary-outline(
          :to="showOriginal(score.original)"
          target="_blank"
        )
          | {{ $t('.clone.showOriginal') }}

        button.button-primary(@click="showRerunClonedScoreModal = true")
          | {{ $t('.clone.rerun') }}

    .spoiler
      button.button-spoiler(@click="expandedSteps = !expandedSteps")
        | {{ $t('.steps.buttonSpoiler') }}
        i.fa(:class="expandedSteps ? 'fa-chevron-down' : 'fa-chevron-up'")

      .expansible(v-if="expandedSteps")
        score-steps(
          :score="scoreData",
          :status="status",
          :clone="!!score.original",
          @re-fetch="$emit('re-fetch')"
        )

  confirmation-modal(
    @close="showRerunClonedScoreModal = false",
    :show="showRerunClonedScoreModal",
    :title="$t('.modal.rerunClonedScore.title')",
    :message="$t('.modal.rerunClonedScore.message')",
    :callback="rerunClonedScore"
  )
</template>
<script>
import strings from "lib/strings"

import ConfirmationModal from "../../../components/confirmation-modal.vue"
import ScoreSteps from "./score_card/score-steps.vue"

export default {
  components: { ConfirmationModal, ScoreSteps },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.scoreReports.scoreCard",

      // clone
      rerunned: false,

      // expanded
      expandedClone: false,
      expandedSteps: false,

      // modal
      showRerunClonedScoreModal: false
    }
  },

  props: {
    score: { type: Object, required: true },
    expanded: { type: Boolean, default: false }
  },

  computed: {
    scoreData() {
      return this.score.original || this.score
    },

    status() {
      if (this.scoreData.status == 'done') {
        return this.scoreData.approved ? 'approved' : 'disapproved'
      }

      return this.scoreData.status
    },

    statusDasherized() {
      return strings.dasherize(this.status)
    },

    translatedStatus() {
      return this.$t(`.status.${strings.camelize(this.status)}`)
    },

    disapprovalSituationTranslate() {
      const camelizedDisapprovalSituation = strings.camelize(
        this.scoreData.disapprovalSituation
      )

      return this.$t(`.disapprovalSituation.${camelizedDisapprovalSituation}`)
    }
  },

  methods: {
    showOriginal(original) {
      return {
        path: `/administracao/gerenciamento/consultas/${original.scoreReportId}`
      }
    },

    rerunClonedScore() {
      return this.$http
        .post(`/admins/scores/${this.score.id}/rerun.json`)
        .then(() => {
          this.showRerunClonedScoreModal = false
          this.rerunned = true

          this.$notifications.info(
            this.$t(".notifications.rerunClonedScore.success")
          )

          this.$emit('re-fetch')
        })
        .catch(() => {
          this.$notifications.error(
            this.$t(".notifications.rerunClonedScore.failure")
          )
        })
    }
  }
}
</script>
<style scoped lang="scss">
.card {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  background-color: $white-color;

  .line {
    padding: 1.5rem 2.5rem 2.5rem 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .status {
      margin: 0;
      font-size: 1.6rem;
      font-weight: 600;
      text-transform: uppercase;
      color: $second-color;

      &.approved {
        color: $green-color;
      }

      &.disapproved, &.not-found, &.error {
        color: $red-color;
      }

      &.todo {
        color: $blue-color;
      }

      &.wip {
        color: $yellow-color,
      }
    }

    .main-infos {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &::after {
        content: "";
        border-radius: 0 4px 4px 0;
        width: 0.5rem;
        height: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -2.5rem;
        background-color: $primary-color;
      }

      .info {
        display: flex;
        gap: 0.5rem;
        align-items: baseline;

        .info-title {
          color: $primary-color;
        }
      }
    }

    .info-title {
      margin-bottom: 0rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: $second-color-light;
    }

    .info-content {
      margin: 0;
      font-size: 1.8rem;
    }

    .button-spoiler {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      border: none;

      color: $primary-color;
      font-size: 1.3rem;
    }

    button, .button {
      margin: 0;
      width: 100%;
    }

    .expansible {
      padding-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
</style>

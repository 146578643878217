<template lang="pug">
#page
  navbar

  new(
    :redirect="redirect",
    url="/real_estate/user/password",
    model="real_estate_user"
  )
</template>

<script>
import Navbar from "../../../components/navbar.vue";
import New from "../../../tools/password/new.vue";

export default {
  components: { Navbar, New },

  data() {
    return {
      redirect: "/imobiliaria/login"
    }
  }
};
</script>

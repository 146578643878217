<template lang="pug">
#page.team-page
  navbar

  loading-div.team-container-desktop
    .menu-container
      h2 {{ $t('.menu') }}

      .menu-items
        button(
          :class="currentPage == 'attendants' && 'actived'",
          @click="currentPage = 'attendants'"
        )
          | {{ $t('.attendants') }}

        button(
          v-if="$user.isRealEstateAdmin",
          :class="currentPage == 'managers' && 'actived'",
          @click="currentPage = 'managers'"
        )
          | {{ $t('.managers') }}

    .team-container
      breadcrumbs(
        :items="currentPage == 'attendants' ? breadcrumbAttendant : breadcrumbManager"
      )

      attendants-page(v-if="currentPage == 'attendants'")
      managers-page(v-if="currentPage == 'managers'")
</template>
<script>
import tool from "lib/vue-tool"
import vueAuthenticate from "lib/vue-authenticate"
import Navbar from "../../components/navbar.vue"
import Breadcrumbs from '../../../components/breadcrumbs.vue'
import AttendantsPage from "../../components/team/attendants-page.vue"
import ManagersPage from "../../components/team/managers-page.vue"

export default {
  mixins: [tool, vueAuthenticate],
  components: { Navbar, Breadcrumbs, AttendantsPage, ManagersPage },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.team.index",

      // tool
      routeName: "realEstateTeamIndex",
      routePath: "imobiliaria/equipe",
      viewName: "index",

      currentPage: 'attendants',

      loading: true
    }
  },

  computed: {
    breadcrumbAttendant() {
      return [
        {
          name: this.$t('.team'),
          url: this.routePath
        },
        {
          name: this.$t('.attendants'),
          url: this.routePath
        }
      ]
    },

    breadcrumbManager() {
      return [
        {
          name: this.$t('.team'),
          url: this.routePath
        },
        {
          name: this.$t('.managers'),
          url: this.routePath
        }
      ]
    }
  },

  methods: {
    fetch() {},

    parseRoute() {}
  }
}
</script>
<style lang="scss">
.team-container-desktop {
  .content {
    display: flex;
    width: 100vw;
    overflow: hidden;
  }
}
</style>

<style lang="scss" scoped>
#page.team-page {
  overflow: hidden;
  height: 100%;
}

.team-container-desktop {
  padding: 0;
  margin: 0 !important;
  width: 100%;
  max-width: fit-content;
  height: 100%;
  overflow: hidden;

  .menu-container {
    width: 25%;
    height: 100vh;
    overflow-y: auto;

    background-color: $white-color-dark;
    box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);

    padding: 0 1.5rem;

    h2 {
      color: $primary-color;
      font-size: 2rem;
      font-weight: 400;
      border-bottom: 1.5px solid $primary-color;
      margin-top: 2.5rem;
    }

    .menu-items {
      width: 100%;
      height: max-content;

      button {
        text-align: start;
        color: $second-color-light;
        font-weight: 400;
        border-radius: 0.5rem;
        border: none;
        padding: 0.5rem 1.5rem;
        height: auto;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: none;

        &.actived{
          background-color: $primary-high-light;
          color: $primary-color;
        }

        &:hover {
          background-color: darken($primary-high-light, 5%);
          color: $primary-color;
        }
      }
    }
  }

  .team-container {
    width: 75%;
    height: 100vh;
    padding: 2.5rem;
    overflow-y: auto;
    padding-bottom: 10rem;
  }
}
</style>
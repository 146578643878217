<template lang="pug">
select-field(
  :name="name",
  :options="options",
  :error="error",
  :label="$t('.defaultTransferType')"
  :includeBlank="true",
  @input="(val) => { $emit('update:modelValue', val) }"
)

</template>

<script>
  export default {
    data() {
      return {
        // i18n
        defaultI18nScope: "models.billService.attributes",

        defaultTransferTypes: ['real_estate', 'alpop']
      }
    },

    computed: {
      options() {
        return this.defaultTransferTypes.map(defaultTransferType => {
          return {
            id: defaultTransferType,
            text: this.$t(`.defaultTransferTypeOptions.${defaultTransferType}`)
          }
        })
      }
    },

    props: {
      name: { type: String },
      error: { type: String }
    }
  }
</script>
<style lang="scss" scoped>
</style>

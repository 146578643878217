<doc lang="markdown">
    Componente para campos assinaláveis (`checkbox`) em formulários, já derivando
  `label` e mensagem de `erro` (para validações).

  # Exemplo de uso:
  ```pug
  switch-field(
    id="id",
    name="name",
    :checked="checked"
  )
  ```
</doc>

<template lang="pug">
.switch-field(:class="{ error, disabled, readonly }")
  input(
    type='checkbox',
    :id="id",
    :name="name",
    :checked="checked",
    :disabled="disabled",
    @input="(event) => $emit('update:checked', event.target.checked)"
  )
  label.label(:for="id") {{ labelText }}
  input-error(:message="error")
</template>

<script>
import InputField from "./input-field.vue"

export default {
  extends: InputField,

  props: {
    id: { type: String, required: true },
    name: { type: String, required: true },
    checked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    labelText: { type: String }
  }
}
</script>

<style scoped lang="scss">
.switch-field{
  margin-bottom: 1.5rem;

  input {
    position: absolute;
    display: none;
    margin: 8px 0 0 16px;
  }

  label {
    margin-bottom: 0;
    padding-bottom: 25px;
    text-align: justify;
    font-size: 1.3rem;
  }

  input + label {
    position: relative;
    line-height: 1.5em;
  }

  input + label:before {
    content: "";
    position: absolute;
    display: block;
    right: 0%;
    bottom: 0px;
    width: 40px; /* x*5 */
    height: 24px; /* x*3 */
    border-radius: 16px; /* x*2 */
    background: $white-color;
    border: 1px solid $grey-color-light;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  input + label:after {
    content: "";
    position: absolute;
    display: block;
    right: 0%;
    margin-right: 16px;
    bottom: 0px;
    width: 24px; /* x*3 */
    height: 24px; /* x*3 */
    border-radius: 16px; /* x*2 */
    background: $white-color;
    border: 1px solid $grey-color-light;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  input + label:hover:after {
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
  }

  input:checked + label:after {
    margin-right: 0;
  }

  input:checked + label:before {
    background: $primary-color;
  }
}

.switch-field.disabled {
  & > label {
    color: $grey-color-dark;
  }

  input + label:hover:after {
    box-shadow: none;
  }

  input:checked + label:before {
    opacity: 0.5;
  }
}

.switch-field.error {
  border-color: $red-color;

  & > label {
    color: $red-color;
  }

  input + label:before {
    background: $red-color;
  }
}
</style>
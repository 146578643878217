import auth from './auth'
import agency from './agency'

/**
 * Singleton para controle de usuario.
 *
 * @type {Object}
 */
const user = {
  get get() {
    return auth.user
  },

  get isRealEstateUser() {
    return auth.isRealEstateUserAuthenticated()
  },

  get isRealEstateAdmin() {
    return auth.isRealEstateAdminAuthenticated()
  },

  get isRealEstateManager() {
    return auth.isRealEstateManagerAuthenticated()
  },

  get isRealEstateAttendant() {
    return auth.isRealEstateAttendantAuthenticated()
  },

  get role() {
    return auth.role
  },

  get prefixUrl() {
    return `real_estate/users`
  },

  get prefixUrlAgency() {
    const agencyId = agency.get?.id

    return `${this.prefixUrl}/agencies/${agencyId}`
  },

  get isLocked() {
    const lockedAgencies = auth.user?.locked_agencies || []
    const agencyId = agency.get?.id

    return lockedAgencies.includes(agencyId)
  },
}

export default user

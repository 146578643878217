<template lang="pug">
.editable-date-field
  p.info-content(
    v-if="editingDisabled",
    @click="editingDisabled = false"
  )
    | {{ getValue }}

  .edit-container(v-else)
    form(ref="form", action="/", @submit.prevent="submit")
      date-field(
        v-model="inputValue",
        :name="name",
        :hideLabel="true",
        :range="false"
      )

      .buttons
        button.button-primary(
          type="submit",
          :disabled="submitting"
        )
          i.fa.fa-check

        button.button-primary.close(
          :disabled="submitting",
          @click="disableEditableField"
        )
          i.fa.fa-times
</template>

<script>
import InputField from "../input-field.vue"
import moment from "moment"

export default {
  extends: InputField,

  props: {
    name: { type: String, required: true },
    value: { type: String, optional: true },
    callback: { type: Function, default: () => {} },
    path: { type: String, required: true }
  },

  beforeMount() {
    this.setupComponent()
  },

  watch: {
    value() {
      this.setupComponent()
    }
  },

  data() {
    return {
      defaultI18nScope: "components.editableInputField",

      submitting: false,
      editingDisabled: true,
      inputValue: null,
      currentValue: null
    }
  },

  methods: {
    disableEditableField() {
      this.inputValue = this.currentValue
      this.editingDisabled = true

      this.callback()
    },

    setupComponent(){
      if (!this.value) {
        return this.currentValue = this.inputValue = this.value
      }

      const date = moment(this.value).format("DD/MM/YYYY")
      let dateParts = date.split('/')

      this.currentValue = this.inputValue = new Date(
        dateParts[2], dateParts[1] - 1, dateParts[0]
      )
    },

    submit() {
      this.submitting = true

      const formData = new FormData(this.$refs.form)
      const value = formData.get(this.name)

      this.$http
        .put(this.path, formData)
        .then(() => {
          if (value) {
            const [year, month, day] = value.split("/")

            this.currentValue = new Date(day, month - 1, year)
          } else {
            this.currentValue = null
          }

          this.errors = {}
          this.$notifications.clear()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))
        })
        .finally(() => {
          this.submitting = false

          this.disableEditableField()
          this.callback()
        })
    }
  },

  computed: {
    getValue() {
      return this.$l('date.formats.default', this.inputValue) || '-'
    }
  }
}
</script>

<style scoped lang="scss">
.editable-date-field {
  .edit-container {
    width: 100%;
    display: inline-flex;
    gap: 0.5rem;

    .date-field {
      margin-bottom: 1.5rem;
      width: 100%;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 0.3rem;
    }

    .buttons {
      display: flex;
      gap: 0.3rem;

      button {
        padding: 0 10px;
        width: 38px;

        &.close {
          background-color: $red-color;
        }

        .fa {
          font-size: 1.2rem;
          line-height: 32px;
        }
      }
    }
  }

  p {
    &.info-content {
      padding: 0px 0px;
      font-size: 1.5rem;
      font-family: Lato;

      &:hover {
        background-color: darken($white-color, 5%);
      }
    }
  }
}
</style>
<template lang="pug">
#page.financial-page
  navbar

  loading-div.admin-financial-container
    .menu-container
      h2 {{ $t('.menu') }}

      .menu-items
        button(
          v-if="transfersPermission"
          :class="currentPage == 'transfers' && 'actived'",
          @click="() => { setCurrentPage('transfers') }"
        )
          | {{ $t('.menus.transfers') }}

        button(
          v-if="billsPermission"
          :class="currentPage == 'bills' && 'actived'",
          @click="() => { setCurrentPage('bills') }"
        )
          | {{ $t('.menus.bills') }}
        button(
          v-if="billServicesPermission"
          :class="currentPage == 'billServices' && 'actived'",
          @click="() => { setCurrentPage('billServices') }"
        )
          | {{ $t('.menus.billServices') }}

    .financial-container(v-if="permissions.length")
      breadcrumbs(:items="generateBreadcrumb")

      transfers(v-if="currentPage == 'transfers'")
      bills(v-if="currentPage == 'bills'")
      bill-services(v-if="currentPage == 'billServices'")
</template>
<script>
import tool from "lib/vue-tool"
import vueAuthenticate from "lib/vue-authenticate"

import Breadcrumbs from '../../../components/breadcrumbs.vue'
import Navbar from "../../components/navbar.vue"
import Transfers from '../../components/financial/transfers/index.vue'

import Bills from '../../components/financial/bills/index.vue'
import BillServices from '../../components/financial/bill_services/index.vue'

export default {
  mixins: [tool, vueAuthenticate],
  components: { Breadcrumbs, Navbar, Transfers, BillServices, Bills },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.financial.index",

      // tool
      routeName: "adminFinancialIndex",
      routePath: "administracao/financeiro",
      viewName: "show",

      loading: true
    }
  },

  beforeCreate() {
    this.$permissions.someRequiredPermission([
      { moduleName: 'financial_transfers', permissions: ['get'] },
      { moduleName: 'financial_bills', permissions: ['get'] },
      { moduleName: 'bill_services', permissions: ['get'] }
    ])
  },

  methods: {
    fetch() {},

    parseRoute() {},

    setCurrentPage(anchor) {
      if (this.$route.hash.slice(1) == anchor) return

      this.$router.push({name: this.routeName, hash: `#${anchor}` })
    }
  },

  computed: {
    currentPage() {
      const hash = this.$route.hash.slice(1)

      if (this.permissions.includes(hash)) return hash

      if (hash) this.$router.replace({ hash: null })

      return this.permissions[0]
    },

    permissions() {
      const permissions = []

      this.transfersPermission && permissions.push('transfers')
      this.billsPermission && permissions.push('bills')
      this.billServicesPermission && permissions.push('billServices')

      return permissions
    },

    billServicesPermission() {
      return this.$permissions.hasPermission('bill_services', 'get')
    },

    transfersPermission() {
      return this.$permissions.hasPermission('financial_transfers', 'get')
    },

    billsPermission() {
      return this.$permissions.hasPermission('financial_bills', 'get')
    },

    generateBreadcrumb() {
      return [
        {
          name: this.$t('.breadcrumbs.financial'),
          url: this.routePath,
          hash: this.currentPage
        },
        {
          name: this.$t(`.breadcrumbs.${this.currentPage}`),
          url: this.routePath,
          hash: this.currentPage
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.admin-financial-container {
  .content {
    display: flex;
    width: 100vw;
    overflow: hidden;
  }
}
</style>

<style lang="scss" scoped>
#page.financial-page {
  overflow: hidden;
  height: 100%;
}

.admin-financial-container {
  padding: 0;
  margin: 0 !important;
  width: 100%;
  max-width: fit-content;
  height: 100%;
  overflow: hidden;

  .menu-container {
    width: 25%;
    height: 100vh;
    overflow-y: visible;

    background-color: $white-color-dark;
    box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);

    padding: 0 1.5rem;

    h2 {
      color: $primary-color;
      font-size: 2rem;
      font-weight: 400;
      border-bottom: 1.5px solid $primary-color;
      margin-top: 2.5rem;
    }

    .menu-items {
      width: 100%;
      height: max-content;

      button {
        text-align: start;
        color: $second-color-light;
        font-weight: 400;
        border-radius: 0.5rem;
        border: none;
        padding: 0.5rem 1.5rem;
        height: auto;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: none;

        &.actived{
          background-color: $primary-high-light;
          color: $primary-color;
        }

        &:hover {
          background-color: darken($primary-high-light, 5%);
          color: $primary-color;
        }
      }
    }
  }

  .financial-container {
    width: 75%;
    height: 100vh;
    padding: 2.5rem;
    overflow: scroll;
    overflow-y: visible;
    padding-bottom: 5rem;
  }
}
</style>
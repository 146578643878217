<template lang="pug">
.commercial-relations
  step-table(
    :stepName="stepName",
    :tableData="data",
    :columnNames="columnNames",
  )
</template>
<script>
import StepTable from './format/step-table.vue'

export default {
  components: { StepTable },

  data() {
    return {
      // table info
      stepName: 'commercialRelations',
      columnNames: ['corporateName', 'cpfcnpj']
    }
  },

  props: {
    data: { type: Array, default: () => ([]) }
  }
}
</script>
<style scoped lang="scss">
.commercial-relations {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
}
</style>

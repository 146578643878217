<template lang="pug">
.field.select-field
  label(for="liquidated-field")
      | {{ $t('.label') }}

  select-field(
    v-model="modelValue",
    :options="options",
    :required="false",
    :includeBlank="true",
    :hideLabel="true",
    name="liquidated-field",
    @update:modelValue="val => { $emit('update:modelValue', val) }",
  )
    option(value="-1") {{ $t('none') }}
</template>
<script>
  export default {
    props: {
      modelValue: { type: Boolean, default: null }
    },

    data() {
      return {
        defaultI18nScope: "admin.financial.transfers.filters.liquidatedField",
      }
    },

    computed: {
      options() {
        return [
          { id: true, text: this.$t('.true') },
          { id: false, text: this.$t('.false') },
        ]
      }
    }
  }
</script>
<style lang="scss" scoped>
.select-field {
  margin: 0;
}
</style>

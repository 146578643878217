import store from './store'

/**
 * Singleton para controle de access tokens
 *
 * @type {Object}
 */

const authToken = {
  get accessTokenHEADER() {
    return 'access-token'
  },

  get accessToken() {
    return store.get('access-token')
  },

  set accessToken(value) {
    store.set('access-token', value)
  },

  get clientHEADER() {
    return 'client'
  },

  get client() {
    return store.get('client')
  },

  set client(value) {
    store.set('client', value)
  },

  get uidHEADER() {
    return 'uid'
  },

  get uid() {
    return store.get('uid')
  },

  set uid(value) {
    store.set('uid', value)
  }
}

export default authToken

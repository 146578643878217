<template lang="pug">
.billing-card(:class="{ error: errors.length }")
  .line
    h2.title {{ $t('.title') }}

  .billing-card-content
    .buttons-container
      button.button-primary(@click="showCreateBillItemModal = true")
        | {{ $t('.buttons.newItem') }}

    .table-section
      .table-container
        table
          thead
            tr
              th(v-for="name in columnNames")
                p {{ $t(`.table.${name}`) }}

          tbody
            tr(v-for="billItem in billItems")
              td.bill-item {{ billItem.description }}

              td.bill-item-value {{ `R$ ${formatValue(billItem.value)}` }}

              td.bill-item-complement {{ billItem.complement }}

              td.actions
                button.button-blue(
                  :disabled="!billItem.editable"
                  @click="openActionsModal(billItem, 'edit')"
                )
                  i.fa.fa-edit

                button.button-red(
                  :disabled="!billItem.editable"
                  @click="openActionsModal(billItem, 'delete')"
                )
                  i.fa.fa-trash

          tfoot
            td {{ $t('.table.total') }}
            td {{ `R$ ${formatValue(bill.value)}` }}
            td
            td

    .button-container
      button.button-primary(@click="showConfirmationModal = true")
        | {{ $t('.buttons.submit') }}

  edit-bill-item-modal(
    :show="showEditBillItemModal"
    :billItem="billItem"
    :billItemsPath="billItemsPath"
    :callback="reloadBillItems"
    @close="showEditBillItemModal = false"
  )
  delete-bill-item-modal(
    :show="showDeleteBillItemModal"
    :callback="deleteBillItem"
    @close="showDeleteBillItemModal = false"
  )
  create-bill-item-modal(
    :show="showCreateBillItemModal"
    :billItemsPath="billItemsPath"
    :callback="reloadBillItems"
    @close="showCreateBillItemModal = false"
  )
  confirmation-modal(
    :show="showConfirmationModal"
    :title="$t('.confirmationModal.title')"
    :message="$t('.confirmationModal.message')"
    :callback="submit"
    :loading="submitting"
    @close="showConfirmationModal = false"
  )
</template>

<script>
import objects from 'lib/objects'
import CreateBillItemModal from './bills_items/create-modal.vue'
import EditBillItemModal from './bills_items/edit-modal.vue'
import DeleteBillItemModal from './bills_items/delete-modal.vue'
import ConfirmationModal from '../../../../components/confirmation-modal.vue'

export default {
  components: {
    CreateBillItemModal,
    EditBillItemModal,
    DeleteBillItemModal,
    ConfirmationModal
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.contract.new.billingCard",

      submitting: false,
      errors: {},

      // table
      columnNames: ['billItem', 'billItemValue', 'complement', 'actions'],
      billItems: [],
      bill: {},

      // modal
      billItem: {},
      showEditBillItemModal: false,
      showDeleteBillItemModal: false,
      showCreateBillItemModal: false,
      showConfirmationModal: false
    }
  },

  props: {
    billsPath: { type: String, required: true }
  },

  mounted() {
    this.fetchBill()
  },

  computed: {
    billId() {
      return this?.bill?.id
    },

    billPath() {
      return `${this.billsPath}/${this.billId}`
    },

    billItemsPath() {
      return `${this.billPath}/bill_items`
    }
  },

  methods: {
    fetchBill() {
      this.$http
        .get(`${this.billId ? this.billPath : this.billsPath}.json`)
        .then(({ data }) => {
          const bill = this.billId ? data : data?.data[0]

          this.bill = objects.camelize(bill)
          this.fetchItems()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetchBill.failure"))
        })
    },

    fetchItems() {
      this.$http
        .get(`${this.billItemsPath}.json`)
        .then(({ data }) => {
          const billItems = data?.data

          this.billItems.push(...objects.camelize(billItems))
        })
        .catch(() => {
          this.$notifications.error(
            this.$t(".notifications.fetchItems.failure")
          )
        })
    },

    deleteBillItem() {
      this.$http
        .delete(`${this.billItemsPath}/${this.billItem.id}`)
        .then(() => {
          this.errors = {}

          this.$notifications.info(
            this.$t(".notifications.deleteBillItem.success")
          )
          this.reloadBillItems()
        })
        .catch(() => {
          this.$notifications.error(
            this.$t(".notifications.deleteBillItem.failure")
          )
        })
        .finally(() => {
          this.showDeleteBillItemModal = false
        })
    },

    reloadBillItems() {
      this.billItems = []

      this.fetchBill()
    },

    openActionsModal(billItem, action) {
      if (!billItem.editable) return

      this.billItem = billItem

      if (action === 'edit') {
        this.showEditBillItemModal = true

        return
      }

      this.showDeleteBillItemModal = true
    },

    formatValue(value) {
      return value ? value.toString().replace('.', ',') : ''
    },

    submit() {
      this.submitting = true

      this.$http
        .post(`${this.billPath}/bank_slip`)
        .then(({ data }) => {
          this.$notifications.info(this.$t(".notifications.submit.success"))

          this.generateBankSlip(data.url)

          this.callback()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))
        })
        .finally(() => {
          this.showConfirmationModal = false
          this.submitting = false
        })
    },

    generateBankSlip(url) {
      this.$http
        .get(url, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })

          window.open(window.URL.createObjectURL(blob))
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.bankSlip.failure"))
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.billing-card {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  background-color: $white-color;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  &.error {
    border: 1px solid $red-color;

    .line {
      &::after {
        background-color: $red-color;
      }

      .title {
        color: $red-color;
      }
    }
  }

  .line {
    position: relative;
    padding: 1.5rem 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    &::after {
      content: "";
      border-radius: 0 4px 4px 0;
      width: 0.5rem;
      height: 60%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      background-color: $primary-color;
    }

    .title {
      margin: 0;
      font-size: 2rem;
      font-weight: 500;
      color: $primary-color;
    }

  }

  .billing-card-content {
    padding-inline: 2.5rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button, .button {
      margin: 0;
    }

    .table-container {
      height: auto !important;
      max-height: 40vh;

      @media (orientation: landscape) {
        max-height: 60vh;
      }

      table {
        tbody {
          tr {
            height: 5rem !important;
          }
        }

        td {
          text-transform: capitalize !important;
        }

        .bill-item, .bill-item-value {
          min-width: 13rem !important;
        }

        .bill-item-complement {
          width: 100%;
        }

        .actions {
          min-width: fit-content;
          display: flex;
          justify-content: flex-end;
          gap: 1rem;

          button {
            margin: 0;
            width: 3.8rem;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          i {
            font-size: 1.8rem;

            &.fa-edit {
              padding-top: 0.2rem;
              margin-left: 0.5rem;
            }
          }
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>

<template lang="pug">
.files-index
  card
    .header-container
      h2 {{ $t('.title') }}

      .actions
        button.icon.button-blue(@click="showUploadModal = true")
          i.fa.fa-plus

    .files-container
      .table-section
        .table-container(@scroll="autoLoad")
          table
            thead
              tr
                th(v-for="name in columnNames")
                  p {{ $t(`.table.columns.${name}`) }}

            tbody
              tr(v-for="file in files")
                td.id {{ file.id || '-' }}

                td.format {{ file.kind || '-' }}

                td.status {{ getTranslatedStatus(file.status) }}

                td.date {{ getFormattedDate(file.createdAt) }}

                td.details
                  i.fa.fa-external-link(@click="openFileModal(file.id)")

              tr.loading(v-if="loading")
                td(v-for="cell in columnNames")
                  .cell
        footer
          p {{ paginationText }}

  upload-modal(
    :show="showUploadModal"
    @close="showUploadModal = false"
    @reloadFiles="reloadFiles"
  )

  file-modal(
    v-if="currentFileId !== null"
    :show="showFileModal",
    :fileId="currentFileId",
    :agencyId="getAgencyId",
    @close="closeFileModal"
  )
</template>
<script>
import objects from "lib/objects"
import FileModal from "./file-modal.vue"
import UploadModal from "./upload-modal.vue"

export default {
  components: { UploadModal, FileModal},

  mounted() {
    this.fetch()
  },

  data() {
    return {
      defaultI18nScope: "admin.realEstateAgencies.billsMassiveUpdate.index",

      // table
      files: [],
      pagination: { total: 0 },
      columnNames: [
        "id",
        "format",
        "status",
        "date",
        "details"
      ],
      loading: false,

      // modal
      showUploadModal: false,
      showFileModal: false,
      currentFileId: null
    }
  },

  methods: {
    init(files, pagination) {
      this.files.push(...objects.camelize(files))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total"),
      }
    },

    openFileModal(id) {
      this.showFileModal = true
      this.currentFileId = id
    },

    closeFileModal() {
      this.showFileModal = false
      this.currentFileId = null
    },

    reloadFiles() {
      this.files = []

      this.fetch()
    },

    getTranslatedStatus(status) {
      return status ? this.$t(`.table.attributes.status.${status}`) : '-'
    },

    getFormattedDate(date) {
      return date ? this.$l('date.formats.default', date) : '-'
    },

    fetch(page = null) {
      this.loading = true

      this.$http
        .get(this.filesPath, { params: this.httpParams(page) })
        .then((response) => {
          const body = response.data
          const files = body.data
          const pagination = body.pagination || {}

          this.init(files, pagination)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.loading = false
        })
    },

    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target
      const loadingHeight = scrollHeight - 5

      if ((scrollTop + clientHeight < loadingHeight) || !this.pagination.next) {
        return
      }

      this.loading = true
      this.fetch(this.pagination.next)
    },

    httpParams(page = null) {
      return this.$params.toRouteParams({ page: page })
    }
  },

  computed: {
    getAgencyId() {
      return this.$route.params.id
    },

    filesPath() {
      return `/admins/real-estate/agencies/${this.getAgencyId}/bulk-edit-bill/files`
    },

    paginationText() {
      return this.$t(
        '.pagination.text',
        { length: this.files.length, total: this.pagination.total }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.files-index {
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: $primary-color;
    }

    .actions {
      display: flex;

      button.opened {
        opacity: 0.5;
      }

      button.icon {
        font-size: 1.5rem;
        width: 4rem;
        padding: 0;
        margin-bottom: 0;
        margin-left: 1rem;
      }
    }
  }

  .files-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table-section {
    width: 100%;

    .table-container {
      tbody {
        td {
          &.details {
            min-width: 1rem;

            i {
              cursor: pointer;
              color: $primary-color;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    p,
    button {
      margin: 0;
    }
  }
}
</style>
<template lang="pug">
select-field(
  :disabled="disabled",
  type="string",
  v-model="value",
  :includeBlank="true",
  :options="options"
  :label="$t('.label')"
  @update:modelValue="onInput",
)
</template>

<script>
import SelectField from "src/components/phone/select-field.vue"

export default {
  extends: SelectField,

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.components.approvedField"
    }
  },

  computed: {
    value() {
      return this.modelValue ? 'true' : 'false'
    },

    options() {
      return [
        { id: true, text: this.$t('.values.approved') },
        { id: false, text: this.$t('.values.disapproved') }
      ]
    }
  },

  methods: {
    onInput(id) {
      id === 'true' && this.$emit('update:modelValue', true)
      id === 'false' && this.$emit('update:modelValue', false)
    }
  }
}
</script>

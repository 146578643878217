const queries = {
  beforeMount() {
    if (this.query && this.hasActiveQuery) {
      this.query = _.cloneDeep(this.$route.query)

      return
    }

    this.replaceQuery()
  },

  computed: {
    activeQueryKeys() {
      return Object.keys(this.$route.query) || []
    },

    hasActiveQuery() {
      return !!this.activeQueryKeys.length
    }
  },

  methods: {
    replaceQuery() {
      if (this.query) {
        const validQuery = Object.fromEntries(
          Object.entries(this.query).filter(([, value]) => value)
        )

        const { name, params, hash, query } = this.$route

        if (validQuery == query) return

        this.$router.replace(
          { name: name, params: params, hash: hash, query: validQuery }
        )
      }
    },

    activeQueryLabels(i18nPath) {
      return this.activeQueryKeys.map(
        key => this.$t(`${i18nPath}.label.${key}`)
      )
    }
  }
}

export default queries

<template lang="pug">
Modal(v-model="isShow", :close="close")
  .modal
    .form-container
      h1 {{ $t('.title') }}

      form(id="create-user-form", ref="form", action="/", @submit.prevent="submit")
        input-field(
          type="text",
          name="real_estate_user[name]",
          v-model="name",
          :placeholder="$t('.placeholder.name')",
          :error="errors?.name?.message",
          :required="true"
        )

        input-field(
          type="email",
          name="real_estate_user[email]",
          v-model="email",
          :placeholder="$t('.placeholder.email')",
          :error="errors?.email?.message",
          :required="true"
        )

        select-field(
          name="real_estate_user[role]",
          v-model="role",
          :options="roles",
          :placeholder="$t('.placeholder.role')",
          :error="errors?.role?.message",
          :includeBlank="true",
          :required="true"
        )

        switch-field(
          name="real_estate_user[lock]"
          id="user_lock"
          :checked="locked"
          :error="errors?.lockedAt?.message"
          :labelText="$t('.inputs.lockedAt')"
        )

    .buttons-container
      button.close(:disabled="submitting", @click="close")
        | {{ $t(".buttons.close") }}

      button.confirm(
        form="create-user-form",
        :class="submitting && 'button-progress'",
        :disabled="submitting"
      )
        | {{ $t(".buttons.confirm") }}
</template>
<script>
import objects from "lib/objects"
import { ref } from 'vue'

export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "admin.realEstateAgencies.users.editModal",

      submitting: false,

      errors: {},

      // form
      name: null,
      email: null,
      role: null,
      roles: [
        { id: "attendant", text: this.roleTranslation("attendant") },
        { id: "manager", text: this.roleTranslation("manager") },
        { id: "admin", text: this.roleTranslation("admin") }
      ]
    }
  },

  props: {
    user: { type: Object, default: () => ({}) },
    show: { type: Boolean, default: false },
    agencyId: { type: Number, required: true },
    locked: { type: Boolean, required: true },
    callback: { type: Function, default: () => {} }
  },

  setup() {
    const isShow = ref(false)

    function showModal() {
      isShow.value = true
    }

    function closeModal() {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()

    if (this.user.id) {
      this.name = this.user.name
      this.email = this.user.email
      this.role = this.user.role
    }
  },

  methods: {
    close() {
      this.name = null
      this.email = null

      this.$emit('close')
    },

    roleTranslation(role) {
      return this.$t(`models.realEstateUser.roles.${role}`)
    },

    submit() {
      this.submitting = true

      this.$http
        .put(`/admins/real-estate/agencies/${this.agencyId}/users/${this.user.id}`, this.formData)
        .then((response) => {
          this.errors = {};
          this.$notifications.clear();
          this.$notifications.info(this.$t(".notifications.submit.success"))

          this.callback(response.data)

          this.close()
        })
        .catch((err) => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))

          this.errors = objects.camelize(
            objects.dig(err, "response", "data", "errors") || {}
          )
        })
        .finally(() => {
          this.submitting = false
        })
    }
  },

  computed: {
    formData() {
      let lockValue = new FormData(this.$refs.form).get("real_estate_user[lock]")

      return {
        user: {
          name: this.name,
          email: this.email,
          role: this.role,
          lock: lockValue == 'on'
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  height: fit-content;

  .form-container {
    width: 32rem;

    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.5rem;
      color: $second-color-light;
      overflow-wrap: break-word;
      white-space: pre-line;
      margin: 1rem 0;
    }
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;
    margin-top: 5rem;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}
</style>

<style lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}
</style>
<doc lang="markdown">
  Este componente representa o conteudo de uma `tab-content`, e realiza
  o controle de visibilidade do conteudo de acordo com a aba selecionada
  no componente `tab-bar`.

  IMPORTANTE: Este componente sempre deve ter um componente pai `tab-bar`
              para que funcione corretamente.

  Props:
  (obrigatório) id ->    Representa uma `id` para controle das abas em `tab-bar`
  (obrigatório) label -> Representa o texto que deve aparecer como titulo da tab

  Ex:
  -----
  tab-bar
    .content
      tab-content(id='tab1', :label="$t('.traducao.para.label')")
      tab-content(id='tab2', :label="$t('.traducao.para.label2')")
</doc>

<template lang="pug">
.component.tab-content(:class="{ active: isActive }", v-if="isActive")
  slot
</template>

<script>
export default {
  name: "tab-content",
  props: {
    id: { type: String, required: true },
    label: { type: String, required: true },
  },

  computed: {
    isActive() {
      if (this.$parent.$options.name !== "tab-bar") return false;
      return this.$parent.activeTab === this.id;
    },
  },
};
</script>

<template lang="pug">
select-field(
  :name="name",
  :options="options",
  :error="error",
  :label="$t('.serviceNature')"
  :includeBlank="true",
  @input="(val) => { $emit('update:modelValue', val) }"
)

</template>

<script>
  export default {
    data() {
      return {
        // i18n
        defaultI18nScope: "models.billService.attributes",

        serviceNatures: [
          'financial_insurance',
          'nctb',
          'property_insurance',
          'interest',
          'discount',
          'administration',
          'agreement',
          'alpop_discount',
          'contract_openning_value',
          'bank_slip_services',
          'cob_expense',
          'penalties_discount'
        ]
      }
    },

    computed: {
      options() {
        return this.serviceNatures.map(serviceNature => {
          return {
            id: serviceNature,
            text: this.$t(`.serviceNatureOptions.${serviceNature}`)
          }
        })
      }
    },

    props: {
      name: { type: String },
      error: { type: String }
    }
  }
</script>
<style lang="scss" scoped>
</style>

<template lang="pug">
#page
  Navbar

  ResendConfirmation(
    :redirect="redirect"
    url="/admin/resend_confirmation"
    model="admin"
  )
</template>

<script>
import Navbar from "../../components/navbar.vue"
import ResendConfirmation from "../../tools/resend-confirmation.vue"

export default {
  components: { Navbar, ResendConfirmation },

  data() {
    return {
      redirect: "/administracao/reenviar-confirmacao"
    }
  }
};
</script>

import { datadogRum } from '@datadog/browser-rum'

datadogRum.init({
  applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
  clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
  site: process.env.VUE_APP_DATADOG_SITE,
  service: process.env.VUE_APP_DATADOG_SERVICE,
  env: process.env.VUE_APP_DATADOG_ENV,
  version: '1.0.0',
  sessionSampleRate: parseInt(process.env.VUE_APP_DATADOG_SESSION_SAMPLE_RATE),
  sessionReplaySampleRate: parseInt(process.env.VUE_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE),
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [process.env.VUE_APP_DATADOG_ALLOWED_TRACING_URL]
})

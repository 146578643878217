<template lang="pug">
.agencies-index
  expansible-card(
    :title="$t('.titles.overview', { year: new Date().getFullYear()})"
    :expanded="true"
  )
    overview(:items="overviewItems")

  .agencies-index-container
    card
      .filtering-container
        h2 {{ $t('.titles.index') }}

        .actions
          button.icon.button-primary(
            @click="filterOpened = !filterOpened"
            :class="{ 'opened': filterOpened }"
          )
            i.fa.fa-filter

          button.icon.button-blue(@click="showCreateModal = true")
            i.fa.fa-plus

      filter-container(
        :filter="filter"
        :opened="filterOpened"
        :i18nPath="defaultI18nScope"
      )
        input-field(
          v-model="query.cnpj"
          name="cnpj"
          v-maska="'##.###.###/####-##'"
          :label="$t('.label.cnpj')"
          :placeholder="$t('.placeholder.cnpj')"
        )

        input-field(
          v-model="query.name"
          name="name"
          :label="$t('.label.name')"
          :placeholder="$t('.placeholder.name')"
        )

      .table-section
        .table-container(@scroll="autoLoad")
          table
            thead
              tr
                th(v-for="name in columnNames")
                  p {{ $t(`.table.columns.${name}`) }}

            tbody
              tr(
                v-for="agency in agencies"
                @click="openAgency(agency.id)"
              )
                td.id
                  span(@click.stop) {{ agency.id || '-' }}

                td.name
                  span(@click.stop) {{ agency.name || '-' }}

                td.cnpj
                  span(@click.stop) {{ agency.cnpj || '-' }}

                td.creci
                  span(@click.stop) {{ agency.creci || '-' }}

                td.identificator
                  span(@click.stop) {{ agency.identificator || '-' }}

                td.zip-code
                  span(@click.stop) {{ agency.zipCode || '-' }}

                td.state
                  span(@click.stop) {{ agency.state || '-' }}

                td.city
                  span(@click.stop) {{ agency.city || '-' }}

                td.created-at
                  span(@click.stop)
                    | {{ $l('date.formats.default', agency.createdAt) }}


              tr.loading(v-if="loading")
                td(v-for="cell in columnNames")
                  .cell
        footer
          p {{ paginationText }}

  create-modal(
    :show="showCreateModal"
    :callback="reloadAgencies"
    @close="showCreateModal = false"
  )
</template>
<script>
import ExpansibleCard from "../expansible-card.vue"
import Overview from "../overview.vue"
import FilterContainer from '../../../components/filter-container.vue'
import CreateModal from "./create-modal.vue"
import objects from "lib/objects"

export default {
  components: { ExpansibleCard, Overview, FilterContainer, CreateModal },

  mounted() {
    this.fetch()
    this.fetchOverview()
  },

  data() {
    return {
      defaultI18nScope: "admin.realEstateAgencies.index",

      // overview
      overviewData: {
        currentDay: { count: 0 },
        currentWeek: { count: 0 },
        currentMonth: { count: 0 },
        currentYear: { count: 0 },
        total: { count: 0 }
      },

      // filter
      filterOpened: false,

      query: {
        cnpj: null,
        name: null
      },

      // table
      agencies: [],
      pagination: { total: 0 },
      columnNames: [
        "id",
        "name",
        "cnpj",
        "creci",
        "identificator",
        "zipCode",
        "state",
        "city",
        "createdAt"
      ],
      loading: false,

      // modal
      showCreateModal: false,
    }
  },

  methods: {
    init(agencies, pagination) {
      this.agencies.push(...objects.camelize(agencies))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total"),
      }
    },

    reloadAgencies() {
      this.agencies = []

      this.fetch()
      this.fetchOverview()
    },

    filter() {
      this.replaceQuery()

      this.reloadAgencies()

      this.filterOpened = false
    },

    fetch(page = null) {
      this.loading = true

      this.$http
        .get(`/admins/real-estate/agencies`, { params: this.httpParams(page) })
        .then((response) => {
          const body = response.data
          const agencies = body.data
          const pagination = body.pagination || {}

          this.init(agencies, pagination)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.loading = false
        })
    },

    fetchOverview() {
      this.$http
        .get(
          `/admins/real-estate/infos/agencies.json`,
          { params: this.httpParams() }
        )
        .then((response) => {
          const overview = response.data

          this.overviewData = objects.camelize(overview)
        })
        .catch(() => {
          this.overviewData = {
            currentDay: { count: 0 },
            currentWeek: { count: 0 },
            currentMonth: { count: 0 },
            currentYear: { count: 0 },
            total: { count: 0 }
          }

          this.$notifications.error(
            this.$t(".notifications.fetchOverview.failure")
          )
        })
    },

    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target
      const loadingHeight = scrollHeight - 5

      if ((scrollTop + clientHeight < loadingHeight) || !this.pagination.next) {
        return
      }

      this.loading = true

      this.fetch(this.pagination.next)
    },

    httpParams(page = null) {
      return this.$params.toRouteParams(
        { name: this.query.name, cnpj: this.query.cnpj, page: page }
      )
    },

    openAgency(agencyId) {
      const route = this.$router.resolve({
        path: `/administracao/imobiliarias/${agencyId}`
      })

      window.open(route.href, "_blank")
    },
  },

  computed: {
    overviewItems() {
      return [
        {
          description: this.$t(".overview.currentDay"),
          quantity: this.overviewData.currentDay.count
        },
        {
          description: this.$t(".overview.currentWeek"),
          quantity: this.overviewData.currentWeek.count
        },
        {
          description: this.$t(".overview.currentMonth"),
          quantity: this.overviewData.currentMonth.count
        },
        {
          description: this.$t(".overview.currentYear"),
          quantity: this.overviewData.currentYear.count
        },
        {
          description: this.$t(".overview.total"),
          quantity: this.overviewData.total.count
        }
      ]
    },

    paginationText() {
      return this.$t(
        '.pagination.text',
        { length: this.agencies.length, total: this.pagination.total }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.filtering-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 2rem;
    font-weight: 500;
    color: $primary-color;
  }

  .actions {
    display: flex;

    button.opened {
      opacity: 0.5;
    }

    button.icon {
      font-size: 1.5rem;
      width: 4rem;
      padding: 0;
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 5rem;

  p,
  button {
    margin: 0;
  }
}

.table-section {
  margin-top: 1.5rem;
}

.table-container {
  height: 40vh !important;
}

table {
  tbody {
    tr {
      min-width: 0 !important;

      &:hover {
        td {
          background-color: darken($white-color-dark, 6%);

          &.id,
          &.name,
          &.cnpj,
          &.creci,
          &.identificator,
          &.zip-code,
          &.state,
          &.city,
          &.created-at {
            cursor: pointer;
          }
        }
      }

      td {
        &.id, &.creci, &.zip-code, &.created-at {
          min-width: fit-content;
        }
      }
    }
  }
}
</style>

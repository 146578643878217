// Veja como cria um mixin em https://vuejs.org/v2/guide/mixins.html

/**
 *   Define o comportamento de uma "tool".
 *   Uma Tool é um componente renderizado a partir de uma rota (VueRouter).
 *   Assim, ele sabe sua rota - e consequentemente os dados que a rota
 * disponibiliza via $route.query e $route.params.
 *
 *   Para isso, cria uma série de métodos e um hook `created()` padrão:
 *
 *   - Defina o data `routeName`, com o nome da rota da tool
 *     - Isso permite que a tool compute uma propriedade `route`
 *   - Defina um método `init(data = {})`, que é o "inicializador" de dados da
 *     ferramenta
 *     - Através dele, o mixin sabe fornecer dados para carregamento síncrono
 *       (`app.data`) ou disparar um `fetch()` para carregamento assíncrono
 *   - Implemente o método `fetch()`, para carregamento de dados assíncronos e,
 *     no sucesso, utilize o método `init(data = {})` para definir os dados na
 *     ferramenta
 *   - Implemente o método `parseRoute()` para "inicializar" os dados de
 *     `params` - o mixin tool define um `this.params = {}` em `data()` para
 *     ter um "namespace" para isso - que é invocado automaticamente no
 *     `created()` caso a rota seja a correta para a tool (método `inRoute()`).
 *
 *   Ainda, define uma flag `this.initialized`, que indica o carregamento inicial
 * da ferramenta automaticamente.
 *
 * @type {VueMixin}
 */
const tool = {
  // Dados "mergeados" no componente, para controle da ferramenta
  data() {
    return {
      initialized:        false,
      _initializePromise: null,
      params:             {},
      routeName:          undefined
    }
  },

  // hook inicial da ferramenta, responsável por definir:
  // - Parsing inicial de dados da rota
  // - Carregamento inicial correto, descobrindo se é síncrono ou assíncrono
  created() {
    // só inicializamos a tool caso a rota atual seja a sua rota!

    if (!this.inRoute()) return

    this.initialize()
  },

  computed: {
    // Rota da ferramenta
    route() {
      return app.findRouteByName(this.routeName)
    }
  },

  methods: {
    /**
     * Inicialização completa de uma tool, passando por:
     * - parsing da rota
     * - inicialização de dados, definindo se carregamento é assíncrono ou síncrono
     * - após inicialização, marca flag `initialized = true`
     *
     *  Extraído em um método para ser possível utilizá-lo programaticamente,
     * especialmente quando há rotas aninhadas e/ou carregamento assíncrono!
     *   - veja o exemplo da ferramenta `phone/tools/properties/index.vue`, uma vez
     *     que permitimos o acesso navegacional direto a `phone/properties#show`
     *     (que é aninhada, abrindo um overlay!).
     *     - atenção para o `watch: $route`!
     *   - veja o exemplo da ferramenta `desktop/tools/properties/show.vue`,
     *     que usa a Promise no `mounted()`, para construir um google maps
     *     após o carregamento/inicialização da tool
     *
     * @return {Promise}
     */
    initialize() {
      if (this.initialized) return Promise.resolve()
      if (this._initializePromise) return this._initializePromise

      this._initializePromise = new Promise((resolve) => {

        this.parseRoute()

        let initialization = null
        // Aqui precisamos testar `router.navigationalRoute` pois as interações
        // são dinâmicas, e o `router.referrer` só é atualizado com o uso
        // de `router.push()` - mas as interações podem definir `router.replace()`
        // para troca de rota!

        // if (!app.started && this.$router.navigationalRoute.name === this.routeName) {
          // bootstrap inicial:
          // - inicializa app
          // - inicializa tool com dados síncronos (app.data)
          // app.started = true
          // initialization = this.init(app.data)
        // } else {
          // carregamento "interativo" da tool
          // - a app já estará inicializada! (por outra ferramenta acessada anteriormente)
          // - carrega dados assíncronos (fetch) - *é opcional! pode ser um método vazio!
          // initialization = this.fetch()
        // }

        initialization = this.fetch()

        // se a inicialização for "assíncrona" (ex: carrega lib externa; acessa
        // serviço externo; ...), então resolva a Promise e aí sim marque a
        // tool como `initialized` (async)
        if (initialization && typeof initialization.then === 'function') {
          initialization.then(() => {
            this.initialized = true
            resolve()
          })
        } else {
          // senão, marque-a imediatamente! (sync)
          this.initialized = true
          resolve()
        }

      })

      return this._initializePromise
    },


    /**
     * Carregamento assíncrono de dados (ex: XHR para API JSON).
     *
     * IMPORTANTE: lembre-se de sempre usar o método `init()` no callback de
     * sucesso para inicializar os dados.
     * Ex:
     * ```javascript
     * fetch() {
     *   return this.$http.get(minhaUrl)
     *     .then((response) => {
     *       let items = response.data.items
     *       // Inicializando pelo método `init()`!
     *       this.init({ items })
     *     })
     *     .catch(err => feedback(err))
     * }
     * ````
     *
     * @return {Promise} carregamento de dados
     */
    fetch() {
      throw new Error('Vue mixin Tool - #fetch() not implemented')

      // SAMPLE
      // ----
      // if (this.initialized) {
      //   this.$router.replace({ name: 'interests', query: this.params })
      // }
      //
      // return this.$http.get('/operator/interests.json', { params: this.params })
      //   .then((response) => {
      //     let interests = response.data.map(interest => objects.camelize(interest))
      //     let pagination = parseLinkHeaders(response.headers.link) || {}
      //
      //     this.init({ interests, pagination })
      //   })
      //   .catch((_err) => {
      //     this.$notifications.error(this.$t('.notifications.fetch.failure'))
      //   })
    },

    /**
     * Inicializa os dados da ferramenta.
     * É automaticamente invocado no hook `created()` - após o carregamento de
     * dados síncrono (`app.data`) ou assíncrono, pela execução do método `fetch()`.
     *
     * IMPORTANTE: lembre-se de sempre usar o método `init()` no callback de
     * sucesso do carregamento assíncrono do `fetch()`.
     * Ex:
     * ```javascript
     * fetch() {
     *   return this.$http.get(minhaUrl)
     *     .then((response) => {
     *       let items = response.data.items
     *       // Inicializando pelo método `init()`!
     *       this.init({ items })
     *     })
     *     .catch(err => feedback(err))
     * }
     * ````
     *
     * @param  {Object} data Pacote de dados para inicialização da ferramenta
     * @return {void|Promise}
     */
    init() {
      throw new Error('Vue mixin Tool - #init() not implemented')
    },

    /**
     * Indica se a rota atual é a mesma esperada pela ferramenta
     * @return {Boolean} indica se a rota atual é a esperada pela ferramenta
     */
    inRoute() {
      return this.$route && this.routeName && this.$route.name === this.routeName
    },

    /**
     * Método de interpretação de dados da rota, para inicializar os dados da
     * ferramenta (invocado automaticamente no hook `created()` apenas se a
     * rota atual for a esperada pela ferramenta).
     *
     *  IMPORTANTE: reatribua o Objeto, como `this.params = novosParams`, para
     *  que "novas propriedades" (ex: this.params.novoDado) sejam definidas como
     *  reativas.
     *    - veja https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
     *
     * @return {void}
     */
    parseRoute() {
      throw new Error('Vue mixin Tool - #parseRoute() not implemented')

      // SAMPLE
      // ----
      // if (!this.inRoute()) return
      //
      // let params = {}
      //
      // // parse bounds - object = referência
      // params.bounds = this.$route.query.bounds || {}
      // Object.keys(params.bounds).forEach((prop) => {
      //   params.bounds[prop] = this.$params.asFloat(params.bounds[prop])
      // })
      //
      // // params simples
      // Object.assign(
      //   params,
      //   this.$params.parse(this.$route.query, {
      //     address:   'string',
      //     available: 'string',
      //     code:      'integer',
      //     phone:     'string',
      //     since:     'string',
      //     state:     'string',
      //     type:      'string',
      //
      //     // paging and sorting
      //     page:  'integer',
      //     sort:  'string',
      //     order: 'string'
      //   })
      // )
      //
      // // Temos que reatribuir o objeto todo, para que suas propriedades
      // // sejam reativas!
      // // Leia mais em https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
      // this.params = params
    }
  }
}

export default tool

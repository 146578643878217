<template lang="pug">
.email-logs-modal
  Modal(:show="show" @close="close")
    h1.title  {{ $t('.title') }}

    .table-section
      .table-container
        table
          thead
            tr
              th(v-for="name in columnNames")
                p {{ $t(`.details.table.${name}`) }}

          tbody
            tr(v-for="emailLog in logs")
              td {{ getEventTypeTranslation(emailLog.eventType) }}
              td {{ $l('time.formats.micro', emailLog.createdAt) }}
              td {{ emailLog.recipient || '-' }}
              td {{ emailLog.clientOs || '-' }}
              td {{ emailLog.clientName || '-' }}
              td {{ emailLog.city || '-' }}
              td {{ emailLog.region || '-' }}

      footer
        p {{ paginationText }}

    .closed-button
      button.button(@click="close") {{ $t('.button.close') }}

</template>
<script>
import Modal from '../../../../../components/modal.vue'

export default {
  components: { Modal },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.bills.emailLogsModal",

      columnNames: [
        'type',
        'date',
        'recipient',
        'clientOs',
        'clientName',
        'city',
        'region'
      ]
    }
  },

  props: {
    logs: { type: Array, default: () => [], required: true },
    show: { type: Boolean, default: false },
    close: { type: Function, default: () => {} }
  },

  methods: {
    getEventTypeTranslation(eventType) {
      return eventType ? this.$t(`.events.${eventType}`) : '-'
    }
  },

  computed: {
    paginationText() {
      const logsSize = this.logs.length || 0

      return this.$t('.pagination.text', { length: logsSize, total: logsSize })
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 1.7rem;
  font-weight: 550;
  color: $second-color-dark;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  padding: 1rem 0;
}

.closed-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

footer {
  p {
    margin: 0;
  }
}

.table-container {
  height: auto !important;
  max-height: 40vh;

  table {
    tbody {
      tr {
        height: 5rem !important;
      }
    }

    td {
      text-transform: none !important;
    }
  }
}
</style>
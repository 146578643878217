<template lang="pug">
#page.contract-show
  navbar(currentPage="realEstateManagementIndex")

  loading-div.contract-container-desktop
    .menu-container
      h2 {{ $t('.menu') }}

      .menu-items
        button(
          :class="currentPage == 'billing' && 'actived'",
          @click="currentPage = 'billing'"
        )
          | {{ $t('.menus.billing') }}

        button(
          :class="currentPage == 'overview' && 'actived'",
          @click="currentPage = 'overview'"
        )
          | {{ $t('.menus.overview') }}

    .contract-container
      breadcrumbs(
        :items="breadcrumbSelection"
      )

      alert-container(
        v-if="contract.discarded",
        :message="$t('.alert.message')",
        :contacts="true"
      )

      billing(
        v-if="currentPage == 'billing'"
        :contractId="getContractParam"
        :tenantName="contract.tenantAttributes && contract.tenantAttributes.name"
        :tenantEmail="getEmailAddresses"
      )
      overview(v-if="currentPage == 'overview'" :contract="contract")
</template>
<script>
import tool from "lib/vue-tool"
import objects from "lib/objects"
import vueAuthenticate from "lib/vue-authenticate"

import AlertContainer from "../../../../components/alert-container.vue"
import Breadcrumbs from '../../../../components/breadcrumbs.vue'
import Overview from '../../../components/contract/overview.vue'
import Billing from '../../../components/contract/billing.vue'
import Navbar from "../../../components/navbar.vue"

export default {
  mixins: [tool, vueAuthenticate],
  components: { Breadcrumbs, Navbar, Overview, Billing, AlertContainer },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.management.contract.show",

      // tool
      routeName: "realEstateContractShow",
      routePath: "imobiliaria/gerenciamento/contratos",
      viewName: "show",

      currentPage: 'billing',

      loading: true,

      contract: {}
    }
  },

  methods: {
    init(contract) {
      this.contract = objects.camelize(contract);
    },

    fetch() {
      return this.$http
        .get(`${this.$user.prefixUrlAgency}/contracts/${this.getContractParam}.json`)
        .then((response) => {
          let contract = response.data

          this.init(contract)

          this.loading = false
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
          this.loading = false
        });
    },

    parseRoute() {},
  },

  computed: {
    getContractParam() {
      return this.$route.params.id
    },

    getEmailAddresses() {
      const tenant = this.contract.tenantAttributes

      if (!tenant) return

      const emails = tenant.emailsAttributes
      const addresses = emails.map((item) => { return item.address })

      return addresses.join(', ')
    },

    breadcrumbSelection() {
      const managementPath = this.routePath.replace('/contratos', '')

      return [
        {
          name: this.$t('.breadcrumbs.management'),
          url: managementPath
        },
        {
          name: this.$t('.breadcrumbs.contracts'),
          url: managementPath,
          hash: 'contracts'
        },
        {
          name: this.$t(`.breadcrumbs.${this.currentPage}`, { contractId: this.getContractParam }),
          url: `${this.routePath}/${this.getContractParam}`
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.contract-container-desktop {
  .content {
    display: flex;
    width: 100vw;
    overflow: hidden;
  }
}
</style>

<style lang="scss" scoped>
#page.contract-show {
  overflow: hidden;
  height: 100%;
}

.contract-container-desktop {
  padding: 0;
  margin: 0 !important;
  width: 100%;
  max-width: fit-content;
  height: 100%;
  overflow: hidden;

  .menu-container {
    width: 25%;
    height: 100vh;
    overflow-y: auto;

    background-color: $white-color-dark;
    box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);

    padding: 0 1.5rem;

    h2 {
      color: $primary-color;
      font-size: 2rem;
      font-weight: 400;
      border-bottom: 1.5px solid $primary-color;
      margin-top: 2.5rem;
    }

    .menu-items {
      width: 100%;
      height: max-content;

      button {
        text-align: start;
        color: $second-color-light;
        font-weight: 400;
        border-radius: 0.5rem;
        border: none;
        padding: 0.5rem 1.5rem;
        height: auto;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: none;

        &.actived{
          background-color: $primary-high-light;
          color: $primary-color;
        }

        &:hover {
          background-color: darken($primary-high-light, 5%);
          color: $primary-color;
        }

        &.destroy-contract {
          color: $red-color;
          background-color: lighten($red-color, 54%);

          &:hover {
            background-color: lighten($red-color, 49%);
          }
        }
      }

      a {
        text-decoration: none;
        color: $second-color-light;
        font-weight: 400;
        border-radius: 0.5rem;
        border: none;
        // display: flex;
        padding: 0.5rem 1.5rem;
        height: auto;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: none;

        &.router-link-active {
          background-color: $primary-high-light;
          color: $primary-color;
        }
      }
    }
  }

  .inline-section {
    display: flex;
    justify-content: space-between;

    .one-column {
      width: 10%;
    }

    .two-columns {
      width: 47%;
    }

    .three-columns {
      width: 30%;
    }

    .four-columns {
      width: 52%;
    }

    .full-size {
      width: 100%;
    }
  }

  .contract-container {
    width: 75%;
    height: 100vh;
    padding: 2.5rem;
    overflow-y: auto;
    padding-bottom: 10rem;

    .loading-content {
      background: $loading-gradient;
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      width: 100%;
      height: 3.5rem;
      margin-bottom: 1.5rem;

      &.observation {
        height: 15rem;
      }
    }

    @keyframes shine {
      to {
      background-position-x: -200%;
      }
    }

    .info-title {
      font-size: 1.8rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      color: $second-color-light;
    }

    .info-content {
      font-size: 1.5rem;
      font-family: Lato;
    }
  }
}
</style>
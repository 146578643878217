import objects from 'lib/objects'
import strings from 'lib/strings'

import auth from './auth'
import store from './store'
import http from './http'
import loading from './loading'

/**
 * Singleton para controle de permissões.
 *
 * @type {Object}
 */
const permissions = {
  async validateOrFetch() {
    if (!auth.isAdminAuthenticated() || isValid()) return

    await fetchPermissions()
  },

  hasPermission(moduleName, permission) {
    if (wildCardPermission()) return true

    return modulePermissions(moduleName).includes(permission)
  },

  hasPermissions(modulesPermissions) {
    if (wildCardPermission()) return true

    return modulesPermissions.every(({ moduleName, permissions }) => {
      const savedPermissions = modulePermissions(moduleName)

      return permissions.every(
        permission => savedPermissions.includes(permission)
      )
    })
  },

  somePermission(modulesPermissions) {
    if (wildCardPermission()) return true

    return modulesPermissions.some(({ moduleName, permissions }) => {
      const savedPermissions = modulePermissions(moduleName)

      return permissions.some(
        permission => savedPermissions.includes(permission)
      )
    })
  },

  requiredPermissions(modulesPermissions) {
    if (!permissions.hasPermissions(modulesPermissions)) {
      app.router.replace({ name: 'unauthorized' })
    }
  },

  someRequiredPermission(modulesPermissions) {
    if (!permissions.somePermission(modulesPermissions)) {
      app.router.replace({ name: 'unauthorized' })
    }
  }
}

const local = {
  get data() {
    return store.get('permissions') || {}
  },

  get updatedAt() {
    return this.data.updatedAt
  },

  get permissions() {
    return this.data.permissions
  },

  get pluralRole() {
    return store.get('role') + 's'
  },

  setPermissions(value) {
    store.set('permissions', { permissions: value, updatedAt: new Date() })
  }
}

const wildCardPermission = () => {
  return modulePermissions('wild_card').length > 0
}

const isValid = () => {
  if (!local.permissions || !local.updatedAt) return false

  const timeInterval = Math.abs(new Date() - new Date(local.updatedAt))
  const fiveMinutes = 5 * 60 * 1000


  return timeInterval < fiveMinutes
}

const modulePermissions = (module) => {
  if (!local.permissions) return []

  const rolePrefix = strings.snakeize(local.pluralRole)
  const formattedName = `${rolePrefix}_${strings.snakeize(module)}`

  const moduleData = local.permissions.find(
    ({ moduleName }) => moduleName == formattedName
  )

  return moduleData && moduleData.permissions || []
}

const fetchPermissions = async () => {
  loading.start()

  const formattedRole = local.pluralRole.replace('real-estate-', 'real_estate/')

  const user = store.get('user')
  const params = { id: user.id, name: user.name, email: user.email }

  await http
    .get(`/${formattedRole}/permissions.json`, { params: params })
    .then(({ data }) => {
      const permissions = objects.camelize(data.data)

      local.setPermissions(permissions)
    })
    .catch(() => {
      auth.clear()
    })
    .finally(() => {
      loading.stop()
    })
}

export default permissions

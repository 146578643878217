<template lang="pug">
Modal(v-model="isShow", :close="close")
  .modal
    .info-container
      h1 {{ $t(error ? ".error.title" : ".title") }}
      p {{ $t(error ? ".error.description" : ".description") }}
    
    .buttons-container
      button.close(
        :disabled="submitting",
        @click="close"
      )
        | {{ $t(".buttons.close") }}
      
      button.confirm(
        v-if="!error", 
        :class="submitting && 'button-progress'",
        :disabled="submitting",
        @click="confirm"
      ) 
        | {{ $t(".buttons.confirm") }}
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    show: { type: Boolean, default: false },
    agreementId: { type: Number, required: true }
  },

  setup() {
    const isShow = ref(false)

    function showModal() {
      isShow.value = true 
    }

    function closeModal() { 
      isShow.value = false 
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  data() {
    return {
      defaultI18nScope: "admin.agreements.deleteModal",
      options: { closeClickDimmed: true },
      error: false,
      submitting: false
    }
  },

  updated() { 
    this.show ? this.showModal() : this.closeModal() 
  },

  methods: {
    close() { 
      this.$emit('close') 
    },

    disableDimmedClick() { 
      this.options['closeClickDimmed'] = false 
    },

    confirm() {
      this.disableDimmedClick()
      this.deleteAgreement()
    },

    deleteAgreement() {
      this.submitting = true

      this.$http
        .delete(`/admins/agreements/${this.agreementId}`)
        .then(() => {
          this.$notifications.info(this.$t(".notifications.success"))

          this.$router.replace({
            path:"/administracao/gerenciamento", hash: "#agreements"
          })
        })
        .catch(() => {
          this.error = true

          this.$notifications.error(this.$t(".notifications.failure"))
        })
        .finally(() => { 
          this.submitting = false 
        })
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  width: 27%;
  height: fit-content;

  .info-container {
    min-height: 11em;

    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
    }

    p {
      font-size: 1.5rem;
      color: $second-color-light;
      overflow-wrap: break-word;
      white-space: pre-line;
      text-align: justify; 
    }
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }

    .button-progress {
      background-image: linear-gradient(90deg, $primary-color 45%, $primary-light 45%);
      background-size: 200%;
      animation: progress 20s ease forwards;
    }

    @keyframes progress {
      from { background-position: right; }
      to { background-position: left; }
    }
  }
}
</style>

<style lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}
</style>
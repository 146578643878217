<template lang="pug">
.info-container
  .info
    h3.info-title {{ $t('.name') }}
    .loading-content(v-if="loading")
    p.info-content(v-else) {{ bill.name || '-' }}

  .info
    h3.info-title {{ $t('.email') }}
    .loading-content(v-if="loading")
    p.info-content(v-else) {{ bill.email || '-' }}

  .info
    h3.info-title {{ $t('.value') }}
    .loading-content(v-if="loading")
    p.info-content(v-else) {{ bill.value || '-' }}

  .info
    h3.info-title {{ $t('.status') }}
    .loading-content(v-if="loading")
    p(v-else, :class="bill.status ?  `flag ${bill.status}` : 'info-content'")
      | {{ bill.status ? $t(`.flag.${snakeToCamel(bill.status)}`) : '-'}}

  .info
    h3.info-title {{ $t('.transferDueDate') }}
    .loading-content(v-if="loading")
    p.info-content(v-else) {{ formatDate(bill.transferDate) || '-' }}

  .info
    h3.info-title {{ $t('.transfer') }}
    .loading-content(v-if="loading")
    p.info-content(v-else) {{ formatDate(bill.transferLiquidationDate) || '-' }}

  .info
    h3.info-title {{ $t('.dueDate') }}
    .loading-content(v-if="loading")
    p.info-content(v-else) {{ formatDate(bill.dueDate) || '-' }}

  .info
    h3.info-title {{ $t('.paymentDate') }}
    .loading-content(v-if="loading")
    p.info-content(v-else) {{ formatDate(bill.paymentDate) || '-' }}
</template>
<script>

export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.contract.bill.billDetails"
    }
  },

  props: {
    bill: { type: Object, required: true },
    loading: { type: Boolean, default: false }
  },

  methods: {
    formatDate(date) {
      return this.$l('date.formats.default', date)
    },

    snakeToCamel(snake) {
      return snake.replace(/(_\w)/g, (matches) => matches[1].toUpperCase())
    }
  }
}
</script>
<style scoped lang="scss">
.info-container {
  display: grid;
  grid-template-columns: 1;
  gap: 0.5rem;

  @media (orientation: landscape) {
    grid-template-columns: repeat(2, 1fr);
  }

  .info {
    word-break: break-word;
  }

  .info-title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: $second-color-light;
  }

  .info-content {
    font-size: 1.5rem;
    font-family: Lato;
  }

  .loading-content {
    background: $loading-gradient;
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    width: 100%;
    height: 3.5rem;
    margin-bottom: 1.5rem;

    &.observation {
      height: 15rem;
    }
  }

  .flag {
    color: $white-color;
    margin-bottom: 1rem;
    font-size: 11px;
    font-weight: 600;
    line-height: 38px;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0.5rem;

    &.normal, &.waiting {
      background-color: $blue-color;
    }

    &.paid, &.manual_payment {
      background-color: $green-color;
    }

    &.unpaid {
      background-color: $red-color;
    }

    &.agreement, &.canceled, &.chargeback {
      background-color: $yellow-color;
    }
  }
}
</style>
<template lang="pug">
.card
  .line(@click="expanded = !expanded")
    .infos-container
      .first-infos
        .status-container(:class="statusDasherized") {{ translatedStatus }}

        .info
          h3.info-title {{ $t('.infos.cpf') }}
          p.info-content {{ scoreData.cpf || '-' }}

        .info
          h3.info-title {{ $t('.infos.name') }}
          p.info-content {{ scoreData.name || '-' }}

        .info
          h3.info-title {{ $t('.infos.age') }}
          p.info-content {{ scoreData.age || '-' }}

      p.clone-tag(v-if="score.original") {{ $t('.clone.tag') }}

    i.fa(:class="expanded ? 'fa-angle-down' : 'fa-angle-up'")

  .expansible(v-if="expanded")
    .expansible-header
      .info(v-if="!scoreData.approved && scoreData.disapprovalSituation")
        h3.info-title {{ $t('.infos.disapprovalSituation') }}
        p.info-content {{ disapprovalSituationTranslate }}

      .clone-container(v-if="score.original && !rerunned")
        button.button-primary(@click="showRerunClonedScoreModal = true")
          | {{ $t('.clone.button') }}

        router-link(:to="showOriginal(score.original)" target="_blank")
          | {{ $t('.clone.showOriginal') }}

    score-steps(
      :score="scoreData",
      :status="status",
      :clone="!!score.original",
      @re-fetch="$emit('re-fetch')"
    )

  confirmation-modal(
    @close="showRerunClonedScoreModal = false",
    :show="showRerunClonedScoreModal",
    :title="$t('.modal.rerunClonedScore.title')",
    :message="$t('.modal.rerunClonedScore.message')",
    :callback="rerunClonedScore"
  )
</template>
<script>
import strings from "lib/strings"

import ConfirmationModal from "../../../components/confirmation-modal.vue"
import ScoreSteps from "./score_card/score-steps.vue"

export default {
  components: { ConfirmationModal, ScoreSteps },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.scoreReports.scoreCard",

      // clone
      rerunned: false,

      // modal
      showRerunClonedScoreModal: false
    }
  },

  props: {
    score: { type: Object, required: true },
    expanded: { type: Boolean, default: false }
  },

  computed: {
    scoreData() {
      return this.score.original || this.score
    },

    status() {
      if (this.scoreData.status == 'done') {
        return this.scoreData.approved ? 'approved' : 'disapproved'
      }

      return this.scoreData.status
    },

    statusDasherized() {
      return strings.dasherize(this.status)
    },

    translatedStatus() {
      return this.$t(`.status.${strings.camelize(this.status)}`)
    },

    disapprovalSituationTranslate() {
      const camelizedDisapprovalSituation = strings.camelize(
        this.scoreData.disapprovalSituation
      )

      return this.$t(`.disapprovalSituation.${camelizedDisapprovalSituation}`)
    }
  },

  methods: {
    showOriginal(original) {
      return {
        path: `/administracao/gerenciamento/consultas/${original.scoreReportId}`
      }
    },

    rerunClonedScore() {
      return this.$http
        .post(`/admins/scores/${this.score.id}/rerun.json`)
        .then(() => {
          this.showRerunClonedScoreModal = false
          this.rerunned = true

          this.$notifications.info(
            this.$t(".notifications.rerunClonedScore.success")
          )

          this.$emit('re-fetch')
        })
        .catch(() => {
          this.$notifications.error(
            this.$t(".notifications.rerunClonedScore.failure")
          )
        })
    }
  }
}
</script>
<style scoped lang="scss">
.card {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  background-color: $white-color;

  .line {
    cursor: pointer;
    position: relative;
    padding: 1.5rem 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    &::after {
      content: "";
      border-radius: 0 4px 4px 0;
      width: 0.5rem;
      height: 60%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      background-color: $primary-color;
    }

    .infos-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .first-infos {
        display: flex;
        gap: 2rem;

        .status-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 17rem;
          border-radius: 0.5rem;
          font-size: 1.3rem;
          font-weight: 600;
          text-transform: uppercase;
          color: $white-color;

          &.approved {
            background-color: $green-color;
          }

          &.disapproved, &.not-found, &.error {
            background-color: $red-color;
          }

          &.todo {
            background-color: $blue-color;
          }

          &.wip {
            background-color: $yellow-color,
          }
        }

        .info {
          display: flex;
          gap: 0.5rem;
          align-items: baseline;

          .info-title {
            margin-bottom: 0rem;
            font-size: 1.5rem;
            font-weight: 600;
            color: $primary-color;
          }

          .info-content {
            margin: 0;
            font-size: 1.8rem;
          }
        }
      }

      .clone-tag {
        margin: 0;
        width: fit-content;
        font-size: 1.3rem;
        font-weight: 600;
        text-transform: uppercase;
        color: $primary-color;
      }
    }

    i {
      font-size: 2.5rem;
      color: $primary-color;
    }
  }

  .expansible {
    padding: 2.5rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .expansible-header {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-template-areas: "a b";
      justify-content: space-between;
      gap: 2rem;

      .info {
        grid-area: a;
      }

      .clone-container {
        grid-area: b;
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
          margin: 0;
        }

        p {
          margin: 0;
          font-size: 1.3rem;
          font-weight: 600;
        }
      }
    }

    .info {
      .info-title {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 0rem;
        color: $second-color-light;
      }

      .info-content {
        margin: 0;
        font-size: 1.8rem;
      }
    }
  }
}
</style>

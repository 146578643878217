<template lang="pug">
.field.select-field(v-if="$user.isRealEstateUser" :class="{ error, disabled }")
  select2(
    :id="inputId",
    :disabled="disabled",
    :name="name",
    :options="selectedOption",
    :overlay="overlay",
    :placeholder="placeholder",
    :hide-search="true",
    type="integer",
    @input="setAgency"
    :url="url",
    :dataFormatting="dataFormatting",
    :processResults="processResults"
  )
    slot
  input-error(:message="error")
</template>

<script>
import vueAuthenticate from "lib/vue-authenticate"
import SelectField from "src/components/desktop/select-field.vue"

export default {
  mixins: [vueAuthenticate],
  extends: SelectField,

  mounted() {
    const agency = this.$agency.get

    if (agency) this.selectedOption = [{ id: agency.id, text: agency.name }]
  },

  data() {
    return {
      defaultI18nScope: "components.navbar.agencyField",

      selectedOption: [],

      url: "real_estate/users/agencies"
    }
  },

  methods: {
    processResults(response) {
      const body = response.data
      const agencies = body.data
      const pagination = body.pagination || {}

      return {
        results: agencies.map(({ id, name }) => ({ id, text: name })),
        pagination: { 'more': !!pagination.next }
      }
    },

    dataFormatting(params) {
      return { page: params.page || 1, name: params.term }
    },

    setAgency(id) {
      this.$agency.set(id).finally(() => {
        if (this.$route.name === 'realEstateManagementIndex') {
          this.$router.go()

          return
        }

        this.$router.replace({ name: 'realEstateManagementIndex' })
      })
    }
  }
}
</script>

<template lang="pug">
#page
  Navbar

  AccountConfirmation(
    :redirect="redirect"
    url="/admin/confirmation"
    model="admin"
  )
</template>

<script>
import Navbar from "../../components/navbar.vue"
import AccountConfirmation from "../../tools/account-confirmation.vue"

export default {
  components: { Navbar, AccountConfirmation },

  data() {
    return {
      redirect: "/administracao/login"
    }
  }
}
</script>

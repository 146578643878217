/*
 * Registra todos os componentes globais para dispositivos 'desktop'
 */

export default app => {
  // não recusrivo em sub diretórios - segundo argumento `false`
  const components = require.context('.', false, /\.vue$/)

  /**
    * Registra todos os componentes do diretório, como se fizesse:
    * ```javascript
    * import component from './action-input.vue'
    *
    * // nome do componente é idêntico ao nome do arquivo .vue
    * Vue.component('action-input', component)
    * ````
  */

  components.keys().forEach((filepath) => {
    const component = components(filepath)
    const basename = filepath.replace(/^(.+\/)*/, '')
    const componentName = basename.replace('.vue', '')

    app.component(componentName, component.default)
  })
}

<template lang="pug">
.page
  navbar

  section.first
    .animation.left.animated
      h1 {{ $t('.firstSection.title.firstPart') }} 
        span {{ $t('.firstSection.title.secondPart') }}

    .animation.right.animated
      p {{ $t('.firstSection.content') }}

      .buttons-container
        button.button-white(@click="showSignUpModal = true")
          | {{ $t('.buttons.signUp') }}

        router-link.button.button-white-outline(
          :to="{ name: 'contact' }"
        )
          | {{ $t('.buttons.knowMore') }}

    img.animation.bottom.animated(:src="mediaPath('first-section.webp')")

  section.second
    .animation.left
      h2 {{ $t('.secondSection.title') }}

    .animation.right
      p {{ $t('.secondSection.content') }}

    .infos
      .info.animation.bottom(v-for="(value, key) in iconsSecondSection")
        img(:src="mediaPath(value)")

        h3 {{ $t(`.secondSection.infos.${key}.title`) }}

        p {{ $t(`.secondSection.infos.${key}.content`) }}

  section.third
    .content
      .animation.left
        p.subtitle {{ $t('.thirdSection.subTitle') }}

        h2 {{ $t('.thirdSection.title.firstPart') }} 
          span {{ $t('.thirdSection.title.secondPart') }}

        p {{ $t('.thirdSection.content') }}

      .infos
        .info.animation.bottom(v-for="info in ['responsibility', 'ethic']")
          h3 {{ $t(`.thirdSection.infos.${info}.title`) }}

          p {{ $t(`.thirdSection.infos.${info}.content`) }}

    img.animation.right(:src="mediaPath('third-section.webp')")

  section.fourth
    .content.animation.top
      p.subtitle {{ $t('.fourthSection.subTitle') }}

      h2 {{ $t('.fourthSection.title') }}

      p {{ $t('.fourthSection.content') }}

    .infos
      .info.animation.bottom(v-for="(value, key) in iconsFourthSection")
        img(:src="mediaPath(value)")

        h3 {{ $t(`.fourthSection.infos.${key}.title`) }}

        p {{ $t(`.fourthSection.infos.${key}.content`) }}

    .buttons-container.animation.top
      button.button-primary(@click="showSignUpModal = true")
        | {{ $t('.buttons.register') }}

      router-link.button.button-primary-outline(
        :to="{ name: 'contact' }"
      )
        | {{ $t('.buttons.contact') }}

  section.fifth
    img.profile.animation.top(src="@/assets/images/new_home/first_testimonial.jpeg")

    .comment.animation.left
      .stars-container
        i.fa.fa-star(v-for="star in 5" :key="star")

      h3 {{ $t('.fifthSection.testimonial.text') }}

      .comment-owner
        .info
          p.name {{ $t('.fifthSection.testimonial.name') }}
          p.role {{ $t('.fifthSection.testimonial.role') }}

        .real-estate
          img(src="@/assets/images/new_home/cardinali.png")

  section.sixth
    .animation.left
      p.subtitle {{ $t('.sixthSection.subTitle') }}

      h2 {{ $t('.sixthSection.title') }}

      .buttons-container
        button.button-primary(@click="showSignUpModal = true")
          | {{ $t('.buttons.signUp') }}

    .infos
      .info.animation.bottom(v-for="(value, key) in iconsSixthSection")
        .img-container
          img(:src="mediaPath(value)")

        .content
          h3 {{ $t(`.sixthSection.infos.${key}.title`) }}

          p {{ $t(`.sixthSection.infos.${key}.content`) }}

  section.seventh
    .animation.left
      h2 {{ $t('.seventhSection.title') }}

      p {{ $t('.seventhSection.content') }}

      .buttons-container
        button.button-yellow(@click="showSignUpModal = true")
          | {{ $t('.buttons.register') }}

        router-link.button.button-white-outline(
          :to="{ name: 'contact' }"
        )
          | {{ $t('.buttons.contactUs') }}

  Footer

  sign-up-modal(:show="showSignUpModal" @close="showSignUpModal = false")
</template>

<script>
import Navbar from './components/navbar.vue'
import Footer from './components/footer.vue'
import SignUpModal from './components/sign-up-modal.vue'

export default {
  components: { Navbar, Footer, SignUpModal },

  data() {
    return {
      // i18n
      defaultI18nScope: "staticPages.forRealEstateAgencies",

      showSignUpModal: false,

      iconsSecondSection: {
        trustAnalysis: 'magnifying-glass-chart.svg',
        guaranteedRent: 'handshake.svg',
        bills: 'file-invoice.svg'
      },

      iconsFourthSection: {
        moreRenters: 'users-medical.svg',
        lowestVacancy: 'bars-staggered.svg',
        moreBilling: 'hand-holding-dollar.svg'
      },

      iconsSixthSection: {
        register: 'id-card.svg',
        contact: 'comment.svg',
        accessAndTraining: 'laptop-file.svg',
        disclosure: 'bullhorn.svg'
      }
    }
  },

  mounted() {
    window.addEventListener("scroll", this.animateElements);
  },

  methods: {
    animateElements() {
      const elements = document.querySelectorAll(".animation");

      elements.forEach((element) => {
        const windowHeight = window.innerHeight
        const elementTop = element.getBoundingClientRect().top
        const elementVisible = 50

        if (elementTop < (windowHeight - elementVisible) && !element.classList.contains('animated')) {
          element.classList.add("animated");
        }
      })
    },

    mediaPath(file) {
      return require(`@/assets/images/for_real_estate_agencies/${file}`)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../styles/static_pages/section_padding";

  $primary-high-light-color: #EEE5FB;
  $primary-light-color: #A575EC;
  $primary-color: #8E4EC5;
  $primary-dark-color: #452763;
  $yellow-color: #FFE040;

  .animation {
    opacity: 0;

    &.animated {
      opacity: 1;
      animation-timing-function: ease-out;
      animation-duration: 1.5s;

      &.left {
        animation-name: moveInLeft;
      }

      &.right {
        animation-name: moveInRight;
      }

      &.bottom {
        animation-name: moveInBottom;
      }

      &.top {
        animation-name: moveInTop;
      }
    }
  }

  section {
    width: 100%;
    padding: 12rem 6rem;
  }

  p {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.7rem;

    &.subtitle {
      margin-bottom: 1.6rem;
      font-size: 1.6rem;
      font-weight: 600;
    }
  }

  h2 {
    font-size: 4.8rem;
  }

  h1, h2, h3 {
    font-weight: 800;
  }

  h1, h2, h3, p {
    margin: 0;
    font-family: 'Open Sans';
    color: $primary-dark-color;

    span {
      color: $primary-color;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5rem;
    height: auto;

    button, .button {
      margin: 0;
      padding: 0 2.4rem;
      height: 4.8rem;
      line-height: 4.8rem;

      text-transform: none;
      font-size: 1.6rem;
      font-weight: 700;

      &.button-yellow {
        color: $primary-dark-color;
        border-color: $yellow-color;
        background-color: $yellow-color;

        &:hover, &:focus {
          color: $primary-dark-color;
          border-color: darken($yellow-color, 15%);
          background-color: darken($yellow-color, 15%);
        }
      }
    }
  }

  section.first {
    background-color: $primary-color;
    padding: 15rem 6rem 12rem 6rem;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;

    h1 {
      color: $primary-high-light-color;
      font-size: 5.6rem;
      line-height: 6.5rem;

      span {
        color: $yellow-color;
      }
    }

    p {
      margin-bottom: 4rem;
      color: $primary-high-light-color;
    }

    img {
      width: 100%;
      grid-column: span 2;
    }
  }

  section.second {
    background-color: $primary-dark-color;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;

    h2 {
      color: $primary-high-light-color;
      font-size: 4rem;
    }

    p {
      color: $primary-high-light-color;
    }

    .infos {
      width: 100%;
      grid-column: span 2;

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 4.8rem;

      .info {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;

        img {
          height: 4rem;
          width: 4rem;
        }

        h3 {
          color: $primary-high-light-color;
          font-size: 2.4rem;
        }

        p {
          color: $primary-high-light-color;
          font-size: 1.6rem;
        }
      }
    }
  }

  section.third {
    background-color: $white-color;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    align-items: center;

    .content {
      display: flex;
      flex-direction: column;
      gap: 3rem;

      h2 {
        margin-bottom: 2.4rem;
      }

      .infos {
        width: 100%;
        grid-column: span 2;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 4.8rem;

        .info {
          display: flex;
          flex-direction: column;
          gap: 1.6rem;

          h3 {
            font-size: 2.4rem;
          }

          p {
            font-size: 1.6rem;
          }
        }
      }
    }

    img {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left;
    }
  }

  section.fourth {
    background-color: $primary-high-light-color;
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    .content {
      margin-bottom: 8rem;
      width: 60%;

      h2 {
        margin-bottom: 2.4rem;
      }
    }

    .infos {
      margin-bottom: 6.4rem;
      width: 100%;

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 4.8rem;

      .info {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        align-items: center;

        img {
          height: 4rem;
          width: 4rem;
        }

        h3 {
          font-size: 2.4rem;
        }

        p {
          font-size: 1.6rem;
        }
      }
    }
  }

  section.fifth {
    background: linear-gradient(90deg, #9747FF, $primary-light-color);

    display: grid;
    grid-template-columns: 30% auto;
    gap: 8rem;
    align-items: center;

    .profile {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 4 / 5;
    }

    .comment {
      display: flex;
      flex-direction: column;
      gap: 3.2rem;

      .stars-container {
        display: flex;
        gap: 0.4rem;
        color: $yellow-color;

        i {
          font-size: 2rem;
        }
      }

      h3 {
        color: $primary-dark-color;
        font-size: 2.4rem;

        &::first-line {
          color: $white-color;
        }
      }

      p {
        color: $white-color;
        font-size: 1.4rem;
        line-height: 2.4rem;

        &.name {
          font-weight: 600;
        }
      }

      .comment-owner {
        display: flex;
        align-items: center;
        gap: 2rem;

        img {
          height: 4.8rem;
          padding-left: 2rem;
          border-left: $white-color 1px solid;
        }
      }
    }
  }

  section.sixth {
    background-color: $white-color;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;

    h2 {
      margin-bottom: 4.8rem;
    }

    .infos {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      .info {
        display: grid;
        grid-template-columns: 5rem 1fr;
        gap: 4rem;

        .img-container {
          height: 18rem;
          background-image: linear-gradient($primary-light-color, $primary-light-color);
          background-size: 2px 100%;
          background-repeat: no-repeat;
          background-position: center center;

          display: flex;
          align-items: flex-start;
          justify-content: center;

          img {
            background-color: $white-color;
            padding: 1rem 0;
            justify-self: center;
          }
        }

        .content {
          h3 {
            margin-bottom: 1rem;
            font-size: 2rem;
          }
        }

        &:last-child {
          .img-container {
            background: none;
            height: auto;
          }
        }
      }
    }
  }

  section.seventh {
    background-color: $primary-dark-color;

    .animation {
      width: 65%;
    }

    h2 {
      color: $white-color;
      margin-bottom: 2.4rem;
    }

    p {
      color: $white-color;
      margin-bottom: 4rem;
    }
  }

  @media only screen and (max-width: 1200px) {
    p {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
    }

    h2 {
      font-size: 3.6rem;
    }

    section.first {
      h1 {
        font-size: 4rem;
        line-height: 4.8rem;
      }
    }

    section.second {
      column-gap: 3rem;
    }

    section.third {
      .content .infos {
        grid-template-columns: 1fr;
      }
    }
  }

  @media only screen and (max-width: 930px) {
    section {
      padding: 6.4rem 2rem;
    }

    h1, h2, h3 {
      font-weight: 700;
    }

    section.first {
      padding: 12rem 2rem 6.4rem 2rem;
      grid-template-columns: auto;
      gap: 2rem;

      img {
        grid-column: auto;
      }

      .buttons-container {
        margin-bottom: 2.8rem;
      }
    }

    section.second {
      display: block;

      h2 {
        font-size: 3.2rem;
        margin-bottom: 2rem;
      }

      .infos {
        margin-top: 4.8rem;

        .info {
          gap: 1.2rem;
        }
      }
    }

    section.third {
      gap: 2rem;

      .content {
        h2 {
          line-height: 4.2rem;
        }

        .infos {
          grid-template-columns: 1fr;
          gap: 4.8rem;
        }
      }
    }

    section.fourth {
      .content {
        margin-bottom: 4.8rem;
        width: 100%;
      }

      .infos {
        grid-template-columns: 1fr 1fr;
        justify-items: center;

        .info:last-child {
          grid-column: span 2;
          width: 50%;
        }
      }
    }

    section.fifth {
      gap: 2rem;
    }

    section.sixth {
      grid-template-columns: 1fr;

      .infos {
        gap: 3.2rem;

        .info {
          .img-container {
            height: auto;
          }

          .content h3 {
            font-size: 1.8rem;
          }
        }
      }
    }

    section.seventh {
      .animation {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    section.second {
      .infos {
        grid-template-columns: 1fr;

        .info {
          h3 {
            font-size: 2rem;
          }
        }
      }
    }

    section.third {
      grid-template-columns: 1fr;
      gap: 4.8rem;

      .content .infos .info h3 {
        font-size: 2rem;
      }
    }

    section.fourth {
      .infos {
        grid-template-columns: 1fr;

        .info {
          h3 {
            font-size: 3.2rem;
          }

          &:last-child {
            grid-column: 1;
            width: auto;
          }
        }
      }
    }

    section.fifth {
      grid-template-columns: 1fr;
      gap: 4.8rem;

      .comment {
        order: -1;
        gap: 2.4rem;

        p {
          font-size: 1.6rem;
        }
      }

      .profile {
        margin: auto;
        min-width: auto;
        width: 60%;
        height: auto;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    section.fifth {
      .comment {
        h3 {
          font-size: 2rem;
        }

        .comment-owner {
          gap: 1.6rem;

          img {
            padding-left: 1.6rem;
            border-left: $white-color 1px solid;
          }
        }
      }

    }
  }
</style>

<template lang="pug">
.agreements-index
  card
    .filtering-container
      h2 {{ $t('.titles.index') }}

      .actions
        button.icon.button-primary(
          @click="filterOpened = !filterOpened"
          :class="{ 'opened': filterOpened }"
        )
          i.fa.fa-filter

        button.icon.button-blue(
          :disabled="disableNewButton"
          @click.prevent="showNewAgreementModal = true"
        )
          i.fa.fa-plus

    filter-container(
      :filter="filter"
      :opened="filterOpened"
      :i18nPath="defaultI18nScope"
    )
      date-field(
        v-model="query.period",
        :label="$t('.label.period')",
        :range="true"
      )

      agency-field(
        v-model="query.realEstateAgency",
        name="real_estate_agency",
        :label="$t('.label.realEstateAgency')",
        fetchUrl="/admins",
        path="real-estate/agencies"
        :disabled="!$permissions.hasPermission('real_estate_agencies', 'get')"
      )
        option(value="-1") {{ $t('none') }}

      input-field(
        v-model="query.contractCode",
        name="contract_code",
        :label="$t('.label.contractCode')",
        :placeholder="$t('.placeholder.contractCode')"
      )

      person-field(
          v-model="query.tenant"
          name="tenant"
          :label="$t('.label.tenant')"
          :placeholder="$t('.placeholder.tenant')"
      )

    .table-section
      .table-container(@scroll="autoLoad")
        table
          thead
            tr
              th(v-for="name in columnNames")
                p {{ $t(`.table.columns.${name}`) }}

          tbody
            tr(v-for="agreement in agreements")
              td.contract(@click="openAgreement(agreement.id)")
                .contract-info
                  span
                    b {{ $t('.table.contractInfo.code', { contractCode: agreement.contractCode }) }}
                  span
                    | {{ `, ${agreement.realEstateAgencyName}, ` }}
                  span
                    | {{ agreement.contractAddress }}

              td.due-date(@click="openAgreement(agreement.id)")
                | {{ $l('date.formats.default', agreement.createdAt) }}

              td.installments-count(@click="openAgreement(agreement.id)")
                | {{ agreement.installmentsCount || '-' }}

              td.paid-installments-count(@click="openAgreement(agreement.id)")
                | {{ agreement.paidInstallmentsCount || '-' }}

              td.first-installment-date(@click="openAgreement(agreement.id)")
                | {{ $l('date.formats.default', agreement.firstInstallmentDate) }}

              td.last-installment-date(@click="openAgreement(agreement.id)")
                | {{ $l('date.formats.default', agreement.lastInstallmentDate) }}

            tr.loading(v-if="loading")
              td(v-for="cell in columnNames")
                .cell
      footer
        p {{ $t('.pagination.text', { length: agreements.length, total: pagination.total }) }}

  new-modal(:show="showNewAgreementModal" @close="showNewAgreementModal = false")
</template>
<script>
import ExpansibleCard from "../expansible-card.vue"
import FilterContainer from "../../../components/filter-container.vue"
import AgencyField from '../../../components/agency-field.vue'
import NewModal from './new-modal.vue'

import objects from "lib/objects"

export default {
  components: { ExpansibleCard, FilterContainer, AgencyField, NewModal },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.agreements.index",

      // filters
      filterOpened: false,

      query: {
        realEstateAgency: null,
        contractCode: null,
        tenant: null,
        period: (() => {
          const date = new Date()
          const firstDayOfMonth = new Date(
            date.getFullYear(), date.getMonth(), 1
          )
          const lastDayOfMonth = new Date(
            date.getFullYear(), date.getMonth() + 1, 0
          )

          return [firstDayOfMonth, lastDayOfMonth]
        })()
      },

      // modal
      showNewAgreementModal: false,

      // table
      columnNames: [
        'contractInfo',
        'createdAt',
        'installmentsCount',
        'paidInstallmentsCount',
        'firstInstallmentsDate',
        'lastInstallmentsDate',
      ],
      agreements: [],
      pagination: { total: 0 },
      loading: false,
    }
  },

  methods: {
    init(agreements, pagination) {
      this.agreements.push(...objects.camelize(agreements))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total"),
      }
    },

    filter() {
      this.replaceQuery()

      this.agreements = []

      this.fetch()
      this.filterOpened = false
    },

    fetch(page = null) {
      this.loading = true

      this.$http
        .get(`/admins/agreements.json`, { params: this.httpParams(page) })
        .then((response) => {
          const body = response.data
          const agreements = body.data
          const pagination = body.pagination || {}

          this.init(agreements, pagination)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.allPageChecked = false
          this.loading = false
        })
    },

    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target
      const loadingHeight = scrollHeight - 5

      if ((scrollTop + clientHeight < loadingHeight) || !this.pagination.next) {
        return
      }

      this.loading = true

      this.fetch(this.pagination.next)
    },

    httpParams(page = null) {
      return this.$params.toRouteParams({
        installments: {
          start_date: this.query.period && this.query.period[0],
          end_date: this.query.period && this.query.period[1]
        },
        real_estate_agency_id: this.query.realEstateAgency,
        contract_code: this.query.contractCode,
        tenant: this.query.tenant,
        page: page
      })
    },

    openAgreement(agreementId) {
      this.$router.push({ path: `gerenciamento/acordos/${agreementId}` })
    }
  },

  computed: {
    disableNewButton() {
      return !this.$permissions.hasPermissions([
        { moduleName: 'financial_agreements_contracts', permissions: ['get'] },
        { moduleName: 'contracts', permissions: ['get'] },
        {
          moduleName: 'financial_agreements_contracts_bills',
          permissions: ['get']
        },
        { moduleName: 'pre_agreements', permissions: ['post'] },
        { moduleName: 'bill_services', permissions: ['get'] },
        { moduleName: 'agreements', permissions: ['post'] }
      ])
    }
  }
}
</script>
<style lang="scss" scoped>
.filtering-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 2rem;
    font-weight: 500;
    color: $primary-color;
  }

  .actions {
    display: flex;

    button.opened {
      opacity: 0.5;
    }

    button.icon {
      font-size: 1.5rem;
      width: 4rem;
      padding: 0;
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 5rem;

  p, button {
    margin: 0;
  }
}

.table-section {
  margin-top: 1.5rem;
}

.table-container {
  height: 40vh !important;
}

table {
  tbody {
    tr {
      min-width: 0 !important;

      &:hover {
        td {
          background-color: darken($white-color-dark, 6%);

          &.contractInfo,
          &.createdAt,
          &.installmentsCount,
          &.paidInstallmentsCount,
          &.firstInstallmentsDate,
          &.lastInstallmentsDate {
            cursor: pointer;
          }
        }
      }
    }
  }

  td {
    text-transform: capitalize !important;
    cursor: pointer;

    .contract-info {
      display: inline-block;
      vertical-align: top;

      width: 28.2rem;

      overflow-wrap: break-word;
      line-break: anywhere;
      white-space: normal;
    }
  }

  .contractInfo,
  .createdAt,
  .installmentsCount,
  .paidInstallmentsCount,
  .firstInstallmentsDate,
  .lastInstallmentsDate {
    min-width: 13rem !important;
  }
}
</style>
<template lang="pug">
.filter
  form(
    :class="{ 'hidden': !opened }"
    ref="form", action="/",
    @submit.prevent="filter"
  )
    .form-fields
      slot

    button.button-primary(type="submit")
      | {{ $t('.submit') }}

  .tags(:class="{ 'hidden': opened }")
    h5.tags-header(v-if="activeTags.length")
      | {{ $t('.filteringBy') }}

    .tags-container
      .tag(v-for="tag in activeTags" :key="tag")
        span {{ tag }}
</template>
<script>
export default {
  props: {
    filter: { type: Function, required: true },
    opened: { type: Boolean, default: false },
    defaultFilters: { type: Array, default: () => [] },
    i18nPath: { String }
  },

  watch: {
    opened() {
      this.loadTags()
    }
  },

  mounted() {
    if (this.hasActiveQuery) return

    this.defaultFilters.forEach(filter => this.tags.push(filter))
  },

  data() {
    return {
      defaultI18nScope: "components.filterContainer",
      tags: []
    }
  },

  computed: {
    activeTags() {
      return this.hasActiveQuery
        ? this.activeQueryLabels(this.i18nPath)
        : this.tags
    }
  },

  methods: {
    loadTags() {
      this.tags = []

      const inputs = this.$refs.form.elements

      inputs.forEach(input => {
        if (input.value) {
          const label = document.querySelector(
            `label[for="${input.name}"]`
          ).innerHTML

          this.tags.push(label)
        }
      });
    }
  }
}
</script>
<style scoped lang="scss">
  form {
    .form-fields {
      display: grid;
      grid-template-columns: repeat(3, 33.33%);
      column-gap: 0.5em;
    }

    .button-primary {
      margin-bottom: 0;
      margin-top: 1rem;
    }
  }

  .tags-header {
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 0;
  }

  .hidden {
    display: none;
  }

  .tags-container {
    display: flex;

    .tag {
      background-color: $primary-color;
      color: $white-color;

      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: 1px;

      margin-right: 1rem;
      padding: 0.5rem 1rem;
      border-radius: 0.7rem;

      i {
        margin-left: 1rem;
        cursor: pointer;
      }
    }
  }
</style>

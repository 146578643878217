<template lang="pug">
.presumed-income
  step-info(
    :info="formattedInfo"
  )
</template>
<script>
import StepInfo from './format/step-info.vue'

export default {
  components: { StepInfo },

  props: {
    data: { type: Object, default: () => ({}) }
  },

  computed: {
    formattedInfo() {
      return this.data.value && `R$ ${this.data.value}`
    }
  }
}
</script>

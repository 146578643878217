<template lang="pug">
.bounced-checks
  step-table(
    :stepName="stepName",
    :tableData="data",
    :columnNames="columnNames",
  )
</template>
<script>
import StepTable from './format/step-table.vue'

export default {
  components: { StepTable },

  data() {
    return {
      // table info
      stepName: 'bouncedChecks',
      columnNames: [
        'bankCode',
        'bankName',
        'lastOccurenceDate',
        'ocurrenceCount',
        'ocurrenceMotivation'
      ]
    }
  },

  props: {
    data: { type: Array, default: () => ([]) }
  }
}
</script>
<style scoped lang="scss">
.bounced-checks {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
}
</style>

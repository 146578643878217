<template lang="pug">
.currency-field(:class="{ error, disabled, readonly }")
  label(v-if="!hideLabel", :for="inputId", :class="{ 'required': required }") {{ labelText }}

  input(
    ref="inputRef",
    :id="inputId",
    type="text",
    :name="name",
    :placeholder="placeholder",
    :required="required",
    :disabled="disabled",
    :readonly="readonly"
  )
  input-error(:message="error")
</template>

<script>
import InputField from "./input-field.vue"
import { useCurrencyInput } from 'vue-currency-input'
import { watch } from 'vue'

export default {
  extends: InputField,

  props: {
    modelValue: { type: [String, Number] },
    options: { type: Object, default: () => {
      return {
        "locale": "en",
        "currency": "BRL",
        "currencyDisplay": "symbol",
        "valueRange": {
          "min": -20000,
          "max": 20000
        },
        "hideCurrencySymbolOnFocus": true,
        "hideGroupingSeparatorOnFocus": true,
        "hideNegligibleDecimalDigitsOnFocus": false,
        "autoDecimalDigits": true,
        "useGrouping": false,
        "accountingSign": false
      }
    }},
    name: { type: String },
    error: { type: String },
    placeholder: { type: String, default: 'R$0.00' },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false }
  },

  setup(props) {
    const { inputRef, setValue } = useCurrencyInput(props.options)

    watch(
      () => props.modelValue,
      (value) => { setValue(value) }
    )

    return { inputRef }
  }
}
</script>
<style scoped lang="scss">
// TODO definir cor de erro!
$input-error-color: red;
$input-error-border-color: red;
$input-disabled-color: #ccc;
$input-disabled-border-color: #e3e3e3;

.currency-field {
  margin-bottom: 1.5rem;
  label.required::after {
    content: ' *';
    color: #9b53d7;
  }

  input {
    width: 100%;
    height: 38px;
    margin-bottom: 0;
    padding: 6px 10px;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
  }
}

// error
.currency-field.error {
  label {
    color: $input-error-color;
  }

  input {
    border-color: $input-error-border-color;
  }
}

// disabled
.currency-field.disabled,
.currency-field.readonly {
  label {
    color: $grey-color-dark;
  }

  input {
    border-color: $grey-color-light;
    color: $grey-color-light;
  }
}
</style>
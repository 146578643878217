<template lang="pug">
.bad-debit-page
  section.first-section
    .call-to-action
      .logo
        img(src="@/assets/images/logo-white.png")
      .container
        h1 {{ $t('.firstSection.header') }}
        h2 {{ $t('.firstSection.subHeader') }}

        a.contact(href="https://api.whatsapp.com/send/?phone=5511937685205&text=Gostaria+de+conhecer+a+Alpop%21&type=phone_number&app_absent=0", target="_blank")
          | {{ $t('.buttons.contact') }}

    .men-image
      img.desktop(src="@/assets/images/marketing/bad-debit/man.png")
      img.mobile(src="@/assets/images/marketing/bad-debit/man-mobile.png")

  section.second-section
    .call-to-action
      h2
        | {{ $t('.secondSection.header') }}
        span
          | {{ $t('.secondSection.subHeader') }}
      a.contact(href="https://api.whatsapp.com/send/?phone=5511937685205&text=Gostaria+de+conhecer+a+Alpop%21&type=phone_number&app_absent=0", target="_blank")
        | {{ $t('.buttons.contact') }}

    .real-estates-image
      img.desktop(src="@/assets/images/marketing/bad-debit/real-estates.png")
      img.mobile(
        src="@/assets/images/marketing/bad-debit/real-estates-mobile.png"
      )

  section.third-section
    .questions-container
      .item.one
        | {{ $t('.thirdSection.questions.one') }}
      .item.two
        | {{ $t('.thirdSection.questions.two') }}
      .item.three
        | {{ $t('.thirdSection.questions.three') }}
    .answers-container
      .item.one
        | {{ $t('.thirdSection.answers.one') }}
      .item.two
        | {{ $t('.thirdSection.answers.two') }}
      .item.three
        | {{ $t('.thirdSection.answers.three') }}

  section.third-section.mobile
    .item.question.one
      | {{ $t('.thirdSection.questions.one') }}
    .item.answer.one
      | {{ $t('.thirdSection.answers.one') }}
    .item.question.two
      | {{ $t('.thirdSection.questions.two') }}
    .item.answer.two
      | {{ $t('.thirdSection.answers.two') }}
    .item.question.three
      | {{ $t('.thirdSection.questions.three') }}
    .item.answer.three
      | {{ $t('.thirdSection.answers.three') }}

  section.fourth-section
    .card
      .icon
        img(src="@/assets/images/marketing/bad-debit/family.png")
      .text
        | {{ $t('.fourthSection.cards.first') }}
    .card
      .icon
        img(src="@/assets/images/marketing/bad-debit/house.png")
      .text
        | {{ $t('.fourthSection.cards.second') }}
    .card
      .icon
        img(src="@/assets/images/marketing/bad-debit/alpop-icon.png")
      .text
        | {{ $t('.fourthSection.cards.third') }}

  section.fifth-section
    a.contact(href="https://api.whatsapp.com/send/?phone=5511937685205&text=Gostaria+de+conhecer+a+Alpop%21&type=phone_number&app_absent=0", target="_blank")
      | {{ $t('.buttons.contact') }}
    .headphones
      img.headphone-1(src="@/assets/images/marketing/bad-debit/headphone-1.svg")
      img.headphone-2(src="@/assets/images/marketing/bad-debit/headphone-2.svg")

  section.fifth-section-mobile
    .headphone
      img(src="@/assets/images/marketing/bad-debit/headphone-2.svg")

    a.contact(href="https://api.whatsapp.com/send/?phone=5511937685205&text=Gostaria+de+conhecer+a+Alpop%21&type=phone_number&app_absent=0", target="_blank")
      | {{ $t('.buttons.talkToAttendant') }}

  .footer
    .alpop-logo
      img(src="@/assets/images/logo-white.png")
    .social-media
      a(href="https://www.linkedin.com/company/alpop/" target="_blank")
        img(src="@/assets/images/marketing/bad-debit/linkedin.svg")
      a(href="https://api.whatsapp.com/send/?phone=5511937685205&text=Gostaria+de+conhecer+a+Alpop%21&type=phone_number&app_absent=0" target="_blank")
        img(src="@/assets/images/marketing/bad-debit/whatsapp.svg")
      a(href="https://www.facebook.com/Alpop.aluguel/" target="_blank")
        img(src="@/assets/images/marketing/bad-debit/facebook.svg")
      a(href="https://instagram.com/alpop.aluguel?igshid=YTQwZjQ0NmI0OA==" target="_blank")
        img(src="@/assets/images/marketing/bad-debit/instagram.svg")
</template>
<script>
export default {
  mounted() {
    window.addEventListener("scroll", this.animateSections);
  },

  data() {
    return {
      defaultI18nScope: "marketing.badDebit"
    }
  },

  methods: {
    animateSections() {
      const sections = document.querySelectorAll("section");

      sections.forEach((element) => {
        const windowHeight = window.innerHeight
        const elementTop = element.getBoundingClientRect().top
        const elementVisible = 400

        if (elementTop < (windowHeight - elementVisible) && !element.classList.contains('animated')) {
          element.classList.add("animated");
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.bad-debit-page {
  font-family: 'Lato';
  background-color: $white-color;

  a.contact {
    background-color: $accent-color;
    box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);

    color: $white-color;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.8rem;

    width: fit-content;
    border-radius: 2rem;
    padding: 1rem 10rem;
    margin-top: 1.5rem;
    border: none;
  }

  .first-section {
    padding-left: 10rem;
    display: grid;
    grid-template-columns: 50% 50%;
    height: 97.5vh;

    .call-to-action {
      width: 100%;

      .logo {
        background-color: $primary-color;
        width: min-content;
        padding: 4rem 2rem 1rem 2rem;
        border-radius: 0 0 2rem 2rem;

        img {
          width: 12rem;
        }
      }

      .container {
        margin-top: 25rem;

        h1 {
          color: $primary-color;
          font-weight: 600;
          font-size: 6rem;
          margin-bottom: 0;
          line-height: 60px;
          animation-name: moveInLeft;
          animation-timing-function: ease-out;
          animation-duration: 1s;
        }

        h2 {
          font-size: 5.5rem;
          line-height: 50px;
          color: #4B4B4B;
          margin-bottom: 5rem;
          animation-name: moveInRight;
          animation-timing-function: ease-out;
          animation-duration: 1s;
        }

        a.contact {
          animation: moveInBottom .5s ease-out .75s;
          // apply the styles to zero percent before the animation starts
          animation-fill-mode: backwards;
        }
      }
    }

    .men-image {
      img {
        width: 100%;
        object-fit: cover;

        &.mobile {
          display: none;
        }
      }
    }
  }

  .second-section {
    overflow: hidden;
    min-height: 60vh;
    padding-left: 10rem;
    margin-bottom: 5rem;

    display: grid;
    grid-template-columns: 40% 50%;
    column-gap: 10%;

    .call-to-action {
      h2 {
        font-size: 5rem;
        line-height: 52px;
        font-weight: 500;
        color: #4B4B4B;
        margin-bottom: 5rem;

        span {
          font-weight: 300;
        }
      }
    }

    .real-estates-image {
      margin-top: 2.5rem;

      img {
        width: 100%;
        object-fit: cover;

        &.mobile {
          display: none;
        }
      }
    }
  }

  .third-section {
    &.mobile {
      display: none;
    }

    margin-bottom: 10rem;
    padding: 0 10rem;

    display: grid;
    grid-template-columns: auto auto;
    column-gap: 5rem;

    .item {
      padding: 5rem;
      margin-top: 5rem;
      border: none;

      text-align: center;
      position: relative;
      font-size: 1.8rem;

      box-shadow: 0px 7px 10px 0px rgba(63, 63, 63, 0.1);
    }

    .questions-container {
      .item {
        background-color: #EBD3F6;
        border-radius: 2rem 2rem 2rem 0;

        &:first-child {
          margin-top: 0;
        }


        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 0;
          z-index: 1;
          border-style: solid;
          border-width: 3.5rem 4rem 0 0;
          border-color: #EBD3F6 transparent transparent transparent;
          bottom: -3.5rem;
          left: 0;
        }
      }
    }

    .answers-container {
      .item {
        background-color: $primary-color;
        color: $white-color;
        padding: 5rem;
        border-radius: 2rem 2rem 0 2rem;
        text-align: center;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 0;
          z-index: 1;
          border-style: solid;
          border-width: 0 4rem 3.5rem 0;
          border-color: transparent $primary-color transparent transparent;
          bottom: -3.5rem;
          right: 0;
        }
      }
    }
  }

  .fourth-section {
    margin-bottom: 10rem;
    padding: 0 10rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10rem;
    opacity: 0;

    .card {
      padding: 6rem 3rem;
      border-radius: 2rem;
      border: 2px solid #B052DC;

      box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .icon {
        margin-bottom: 4rem;

        img {
          width: 7rem;
          height: 7rem;
          object-fit: contain;
          color: $primary-color;
        }
      }

      .text {
        text-align: center;
        color: $primary-color;
        font-size: 1.8rem;
      }
    }

    &.animated {
      opacity: 1;

      .card {
        animation-name: moveInBottom;
        animation-timing-function: ease-out;
        animation-duration: .5s;
      }
    }
  }

  .fifth-section {
    background-color: #EBD3F6;
    background-size: cover;
    background-repeat: no-repeat;
    display: grid;
    grid-template-columns: 50% 50%;

    height: 16rem;
    border-radius: 4rem 4rem 0 0;

    padding: 5rem 10rem 13rem 10rem;

    .headphones {
      position: relative;
      display: flex;

      .headphone-1 {
        width: 25rem;
        position: absolute;
        top: auto;
        bottom: -12rem;
      }

      .headphone-2 {
        width: 22rem;
        position: absolute;
        top: -11rem;
        left: 17rem;
      }
    }
  }

  .fifth-section-mobile {
    display: none;
  }

  section {
    max-width: 100%;
  }

  .footer {
    margin-top: -3rem;
    padding: 2.5rem 10rem 1rem 10rem;
    border-radius: 3rem 3rem 0 0;
    z-index: 100;
    position: absolute;
    width: 100%;

    background-color: #71429E;

    display: flex;
    gap: 1.5rem;
    justify-content: space-between;
    align-items: center;

    .alpop-logo {
      img {
        align-items: center;
        width: 10rem;
      }
    }

    .social-media {
      display: flex;
      gap: 1.5rem;

      img {
        width: 2.5rem;
      }
    }
  }

  @media only screen and (max-width: 1150px) {
    a.contact {
      font-size: 1.6rem;
    }

    .second-section {
      .call-to-action {
        h2 {
          font-size: 4.2rem;
        }
      }
    }
  }

  @media only screen and (max-width: 1111px) {
    .first-section {
      grid-template-columns: 45% 55%;
      margin-bottom: 2rem;

      .call-to-action {
        .container {
          margin-top: 20rem;

          h1 {
            font-size: 5rem;
          }

          h2 {
            font-size: 4.5rem;
          }
        }
      }
    }

    .fourth-section {
      column-gap: 5rem;
    }
  }

  @media only screen and (max-width: 1045px) {
    .first-section {
      grid-template-columns: 45% 55%;
      margin-bottom: 4rem;
    }

    .fourth-section {
      column-gap: 2rem;
    }
  }

  @media only screen and (max-width: 1000px) {
    .first-section {
      padding-left: 5rem;
    }

    .second-section {
      padding-left: 5rem;
    }

    .third-section, .fourth-section {
      padding: 0 5rem;
    }

    .fifth-section {
      padding: 5rem 5rem 13rem 5rem;
    }
  }

  @media only screen and (max-width: 935px) {
    .first-section {
      grid-template-columns: 50% 50%;
      margin-bottom: 14rem;

      .call-to-action {
        .container {
          margin-top: 10rem;
        }
      }
    }

    .second-section {
      grid-template-columns: 50% 50%;
    }
  }

  @media only screen and (max-width: 850px) {
    .first-section {

      .call-to-action {
        .container {
          h1 {
            font-size: 4.5rem;
          }

          h2 {
            font-size: 4rem;
          }
        }
      }
    }

    a.contact {
      padding: 1rem 8rem;
    }
  }

  @media only screen and (max-width: 768px) {
    .fifth-section {
      padding: 5rem 5rem 13rem 5rem;

      .headphones {
        position: relative;
        display: flex;

        .headphone-1 {
          width: 25rem;
          position: absolute;
          top: auto;
          bottom: -12rem;
          left: -5rem;
        }

        .headphone-2 {
          width: 22rem;
          position: absolute;
          top: -11rem;
          left: 12rem;
        }
      }
    }

    .third-section {
      column-gap: 2.5rem;
    }

    .fourth-section {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 5rem;

      .card {
        padding: 3rem 6rem;

        flex-direction: row;
        justify-content: center;
        align-items: center;

        .icon {
          margin: 0;
        }

        .text {
          margin-left: 4rem;
          text-align: justify;
        }
      }
    }

    a.contact {
      padding: 1rem 7rem;
    }
  }

  @media only screen and (max-width: 700px) {
    .first-section {
      padding: 0;
      grid-template-columns: 1fr;
      height: auto;
      margin-bottom: 2rem;

      .call-to-action {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;

        .logo {
          padding: 3rem 1.5rem 0.5rem 1.5rem;

          img {
            width: 8rem;
          }
        }

        .container {
          margin: 0;
          padding: 0;
          text-align: center;

          h1 {
            font-size: 3.5rem;
            line-height: 40px;
          }

          h2 {
            font-size: 3rem;
            line-height: 40px;
            margin: 0;
          }

          a.contact {
            display: none;
          }
        }
      }

      .men-image {
        img {
          &.desktop {
            display: none;
          }

          &.mobile {
            display: block;
          }
        }
      }
    }

    .second-section {
      min-height: auto;
      padding: 0 1rem;
      margin-bottom: 4rem;

      grid-template-columns: 1fr;
      justify-items: center;
      gap: 1rem;

      .call-to-action {
        text-align: center;

        h2 {
          font-size: 2.5rem;
          line-height: 35px;
          margin-bottom: 2rem;

          animation-name: moveInRight;
          animation-timing-function: ease-out;
          animation-duration: 1s;

          span {
            font-weight: 300;
          }
        }
      }

      .real-estates-image {
        width: 90%;

        img {
          width: 100%;
          margin: 0;

          animation-name: moveInBottom;
          animation-timing-function: ease-out;
          animation-duration: 1s;

          &.desktop {
            display: none;
          }

          &.mobile {
            display: block;
          }
        }
      }
    }

    .third-section {
      display: none;
      padding: 0 1rem;
      margin-bottom: 4rem;

      &.mobile {
        display: flex;
        flex-direction: column;
        opacity: 0;

        .item {
          padding: 2rem;
          margin: 0;

          &.question {
            margin: 0 3rem 2rem 0;
            background-color: #EBD3F6;
            border-radius: 2rem 2rem 2rem 0;

            &:after {
              content: '';
              position: absolute;
              display: block;
              width: 0;
              z-index: 1;
              border-style: solid;
              border-width: 3.5rem 4rem 0 0;
              border-color: #EBD3F6 transparent transparent transparent;
              bottom: -1.5rem;
              left: 0;
            }
          }

          &.answer {
            margin: 0 0 4rem 3rem;
            color: $white-color;
            background-color: $primary-color;
            border-radius: 2rem 2rem 0 2rem;

            &:after {
              content: '';
              position: absolute;
              display: block;
              width: 0;
              z-index: 1;
              border-style: solid;
              border-width: 0 4rem 3.5rem 0;
              border-color: transparent $primary-color transparent transparent;
              bottom: -1.5rem;
              right: 0;
            }
          }
        }

        &.animated {
          opacity: 1;

          .item.question {
            animation-name: moveInLeft;

            &.one {
              animation-duration: 1s;
            }

            &.two {
              animation-duration: 2s;
            }

            &.three {
              animation-duration: 3s;
            }
          }
          .item.answer {
            animation-name: moveInRight;

            &.one {
              animation-duration: 1.5s;
            }

            &.two {
              animation-duration: 2.5s;
            }

            &.three {
              animation-duration: 3.5s;
            }
          }
        }
      }
    }

    .fourth-section {
      padding: 0 1rem;
      margin-bottom: 4rem;
      row-gap: 4rem;

      .card {
        padding: 2rem 3rem;

        .text {
          margin-left: 3rem;
          text-align: center;
        }
      }
    }

    .fifth-section {
      display: none;
    }

    .fifth-section-mobile {
      padding: 5rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .headphone {
        width: 90%;
        position: relative;
        height: 18rem;
        background-color: #EBD3F6;
        border-radius: 2rem;
        display: flex;
        justify-content: center;

        img {
          position: absolute;
          width: 30rem;
          bottom: -3rem;
        }
      }

      a.contact {
        width: 90%;
        padding: 1rem 0;
        text-align: center;
      }
    }

    .footer {
      justify-content: center;

      .alpop-logo {
        display: none;
      }
    }
  }

  @media only screen and (min-width: 700px) {
    .second-section {
      opacity: 0;

      &.animated {
        opacity: 1;

        .call-to-action {
          h2 {
            animation-name: moveInLeft;
            animation-timing-function: ease-out;
            animation-duration: 1.5s;
          }

          a.contact {
            animation: moveInBottom .5s ease-out .75s;
            animation-fill-mode: backwards;
          }
        }

        .real-estates-image {
          animation-name: moveInRight;
          animation-timing-function: ease-out;
          animation-duration: 1s;
        }
      }
    }

    .third-section {
      opacity: 0;

      &.animated {
        opacity: 1;

        .questions-container {
          .item {
            animation-name: moveInLeft;

            &.one {
              animation-duration: 1s;
            }

            &.two {
              animation-duration: 2s;
            }

            &.three {
              animation-duration: 3s;
            }
          }
        }

        .answers-container {
          .item {
            animation-name: moveInRight;

            &.one {
              animation-duration: 1.5s;
            }

            &.two {
              animation-duration: 2.5s;
            }

            &.three {
              animation-duration: 3.5s;
            }
          }
        }
      }
    }
  }

}
</style>

<template lang="pug">
.info(:class="color")
  p {{ info }}
</template>
<script>
export default {
  props: {
    info: { type: String, default: '-' },
    color: { type: String }
  }
}
</script>
<style scoped lang="scss">
.info {
  border-radius: 0.5rem;
  border: 2px solid;
  padding: 0.5rem 1rem;

  background-color: $alert-primary-background;
  border-color: $alert-primary-border;
  color: $alert-primary-text;

  &.green {
    background-color: $alert-green-background;
    border-color: $alert-green-border;
    color: $alert-green-text;
  }

  &.red {
    background-color: $alert-red-background;
    border-color: $alert-red-border;
    color: $alert-red-text;
  }

  p {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
  }
}
</style>

let data = {
  loadPromise: null,
  geocoder:    null
}

// helper function
function findComponentNameByType(type, components, { name = 'long_name' } = {}) {
  let component = components.find(cpt => cpt.types.indexOf(type) !== -1)
  return component ? component[name] : null
}

function findComponentNameByTypes(types, ...args) {
  let componentName = null
  types.find((type) => {
    componentName = findComponentNameByType(type, ...args)
    return !!componentName
  })

  return componentName
}

function findCity(...args) {
  return findComponentNameByTypes(
    [
      'administrative_area_level_2',
      'locality'
    ],
    ...args
  )
}

function findNeighborhood(...args) {
  return findComponentNameByTypes(
    [
      'sublocality_level_5',
      'sublocality_level_4',
      'sublocality_level_3',
      'sublocality_level_2',
      'sublocality_level_1',
      'neighborhood'
    ],
    ...args
  )
}


const geocoder = {
  load() {
    if (data.geocoder) return Promise.resolve(data.geocoder)
    if (data.loadPromise) return data.loadPromise

    return data.loadPromise
  },

  reverseGeocode(latitude, longitude) {
    return new Promise((resolve, reject) => {
      if (isNaN(latitude) || isNaN(longitude)) {
        reject()
      }

      let lat = (typeof (latitude) !== 'number') ? parseFloat(latitude) : latitude
      let lng = (typeof (longitude) !== 'number') ? parseFloat(longitude) : longitude

      this.load().then(() => {
        data.geocoder.geocode({ location: { lat, lng } }, (results, status) => {
          if (status === 'OK' && results[0]) {
            let place = results[0]
            let viewport = place.geometry.viewport
            let address = place.formatted_address

            // XXX tentativa de encontrar cidade e bairro. Podem não existir!
            let city = findCity(place.address_components)
            let neighborhood = findNeighborhood(place.address_components)

            let bounds = {}

            // XXX: after Google Places API update, there's a new way to fetch lat/lng of bounds
            if (typeof viewport.getNorthEast === 'function') {
              let northeast = viewport.getNorthEast()
              let southwest = viewport.getSouthWest()

              bounds = {
                north: northeast.lat(),
                east:  northeast.lng(),
                south: southwest.lat(),
                west:  southwest.lng()
              }

            } else {
              // old code. probably not working anymore after Google Maps API update
              bounds = {
                north: viewport.f.f,
                south: viewport.f.b,
                west:  viewport.b.b,
                east:  viewport.b.f
              }
            }

            resolve({
              address,
              bounds,
              city,
              neighborhood
            })
          } else {
            console.error(`Geocoder failed due to: ${status}`)
            reject()
          }
        })
      })
    })
  }
}
export default geocoder

<template lang="pug">
select-field(
  :name="name",
  :options="options",
  :includeBlank="false",
  :error="error",
  :label="$t('.label')"
  @input="(val) => { $emit('update:modelValue', val) }"
  :multiple="false"
)
</template>

<script>
  export default {
    data() {
      return {
        // i18n
        defaultI18nScope: "admin.bills.paymentType"
      }
    },

    computed: {
      options() {
        return [
          { id: 'pix', text: this.translateBillFlag('pix') },
          { id: 'bank_slip', text: this.translateBillFlag('bankSlip') }
        ]
      }
    },

    methods: {
      translateBillFlag(status) {
        return this.$t(`admin.bills.paymentType.flag.${status}`)
      }
    },

    props: {
      name: { type: String },
      error: { type: String }
    }
  }
</script>
<style lang="scss" scoped>
</style>

<template lang="pug">
.predictions
  step-table(
    :stepName="stepName",
    :tableData="formattedData",
    :columnNames="columnNames",
    :dateTimeColumns="dateTimeColumns"
  )
</template>
<script>
import StepTable from './format/step-table.vue'

export default {
  components: { StepTable },

  data() {
    return {
      // table info
      stepName: 'predictions',
      columnNames: ['label', 'approved', 'result', 'createdAt'],
      dateTimeColumns: ['createdAt']
    }
  },

  props: {
    data: { type: Array, default: () => ([]) }
  },

  computed: {
    formattedData() {
      return this.data.map(prediction => ({
        ...prediction, label: this.labelTranslate(prediction.label)
      }))
    }
  },

  methods: {
    labelTranslate(label) {
      return this.$t(`models.prediction.labelOptions.${label}`)
    }
  }
}
</script>
<style scoped lang="scss">
.predictions {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
}
</style>

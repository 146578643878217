<template lang="pug">
.billing-section
  .inline-section
    .three-columns
      h3.info-title {{ attributeTranslate('fireInsuranceAmount') }}

      .loading-content(v-if="loading")

      editable-currency-field(
        v-else-if="contract.editable"
        v-model="contract.fireInsuranceAmount"
        name="contract[fire_insurance_amount]"
        :value="contract.fireInsuranceAmount"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) R$ {{ contract.fireInsuranceAmount || '-' }}

    .three-columns
      h3.info-title {{ attributeTranslate('fireInsuranceInstallments') }}

      .loading-content(v-if="loading")

      editable-input-field(
        v-else-if="contract.editable"
        v-model="contract.fireInsuranceInstallments"
        name="contract[fire_insurance_installments]"
        type="number"
        :value="contract.fireInsuranceInstallments"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) {{ contract.fireInsuranceInstallments || '-' }}

    .three-columns
      h3.info-title {{ attributeTranslate('condominiumAmount') }}

      .loading-content(v-if="loading")

      editable-currency-field(
        v-else-if="contract.editable"
        v-model="contract.condominiumAmount"
        name="contract[condominium_amount]"
        :value="contract.condominiumAmount"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) R$ {{ contract.condominiumAmount || '-' }}

  .inline-section
    .three-columns
      h3.info-title {{ attributeTranslate('iptuAmount') }}

      .loading-content(v-if="loading")

      editable-currency-field(
        v-else-if="contract.editable"
        v-model="contract.iptuAmount"
        name="contract[iptu_amount]"
        :value="contract.iptuAmount"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) R$ {{ contract.iptuAmount || '-' }}

    .three-columns
      h3.info-title {{ attributeTranslate('iptuInstallments') }}

      .loading-content(v-if="loading")

      editable-input-field(
        v-else-if="contract.editable"
        v-model="contract.iptuInstallments"
        name="contract[iptu_installments]"
        type="number"
        :value="contract.iptuInstallments"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) {{ contract.iptuInstallments || '-' }}

    .three-columns
      h3.info-title {{ attributeTranslate('iptuExemptionMonths') }}

      .loading-content(v-if="loading")

      editable-input-field(
        v-else-if="contract.editable"
        v-model="contract.iptuExemptionMonths"
        name="contract[iptu_exemption_months]"
        type="number"
        :value="contract.iptuExemptionMonths"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) {{ contract.iptuExemptionMonths || '-' }}

  .inline-section
    .three-columns
      h3.info-title {{ attributeTranslate('rentDiscount') }}

      .loading-content(v-if="loading")

      editable-currency-field(
        v-else-if="contract.editable"
        v-model="contract.rentDiscount"
        name="contract[rent_discount]"
        :value="contract.rentDiscount"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) R$ {{ contract.rentDiscount || '-' }}

    .three-columns
      h3.info-title {{ attributeTranslate('rentDiscountMonths') }}

      .loading-content(v-if="loading")

      editable-input-field(
        v-else-if="contract.editable"
        v-model="contract.rentDiscountMonths"
        name="contract[rent_discount_months]"
        type="number"
        :value="contract.rentDiscountMonths"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) {{ contract.rentDiscountMonths || '-' }}

    .three-columns
      h3.info-title {{ attributeTranslate('closedMonth') }}

      .loading-content(v-if="loading")

      editable-select-field(
        v-else-if="contract.editable"
        name="contract[closed_month]"
        :v-model="contract.closedMonth"
        :value="contract.closedMonth"
        :options="closedMonthTypeOptions"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else)
        | {{ attributeTranslate(`closedMonthOptions.${contract.closedMonth}`)}}
</template>

<script>
import EditableCurrencyField from "../../../../../../components/desktop/editable-fields/editable-currency-field.vue"
import EditableInputField from "../../../../../../components/desktop/editable-fields/editable-input-field.vue"
import EditableSelectField from "../../../../../../components/desktop/editable-fields/editable-select-field.vue"

export default {
  components: {
    EditableCurrencyField,
    EditableInputField,
    EditableSelectField
  },

  props: {
    contract: { type: Object, required: true },
    contractPath: { type: String, required: true },
    loading: { type: Boolean, default: false },
    onUpdate: { type: Function, default: () => {} }
  },

  computed: {
    closedMonthTypeOptions() {
      const closedMonthTypes = ['closed', 'opened']

      return closedMonthTypes.map(closedMonthType => ({
        id: closedMonthType,
        text: this.attributeTranslate(`closedMonthOptions.${closedMonthType}`)
      }))
    }
  },

  methods: {
    attributeTranslate(path) {
      return this.$t(`models.contract.attributes.${path}`)
    }
  }
}
</script>
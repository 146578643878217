<template lang="pug">
.tenant-section
  .inline-section
    .three-columns
      h3.info-title {{ attributeTranslate('name') }}

      .loading-content(v-if="loading")

      p.info-content(v-else) {{ contract.tenantAttributes.name || '-' }}

    .three-columns
      h3.info-title {{ attributeTranslate('cpf') }}

      .loading-content(v-if="loading")

      p.info-content(v-else) {{ contract.tenantAttributes.cpf || '-' }}

    .three-columns
      h3.info-title {{ attributeTranslate('rg') }}

      .loading-content(v-if="loading")

      editable-input-field(
        name="tenant[rg]"
        :value="contract.tenantAttributes.rg"
        :path="tenantPath"
        :callback="update"
      )

  .inline-section
    .three-columns(v-for="(title, index) in emailTitles" :key="index")
      h3.info-title {{ attributeTranslate(`${title}`) }}

      .loading-content(v-if="loading")

      editable-input-field(
        name="tenant[emails_attributes][][address]"
        :value="emails[index]?.address || null"
        :id="emails[index]?.id || ''"
        :path="tenantPath"
        :callback="update"
      )

  .inline-section
    .three-columns(v-for="(title, index) in phoneTitles" :key="index")
      h3.info-title {{ attributeTranslate(`${title}`) }}

      .loading-content(v-if="loading")

      editable-input-field(
        name="tenant[phones_attributes][][number]"
        :value="phones[index]?.number || null"
        :id="phones[index]?.id || ''"
        :path="tenantPath"
        :callback="update"
        mask="(##) #####-####"
      )
</template>

<script>
import EditableInputField from "../../../../../../components/desktop/editable-fields/editable-input-field.vue"

export default {
  components: { EditableInputField },

  data() {
    return {
      emailTitles: ['firstEmail', 'secondEmail', 'thirdEmail'],
      phoneTitles: ['firstPhone', 'secondPhone', 'thirdPhone'],

      emails: [],
      phones: []
    }
  },

  props: {
    contract: { type: Object, required: true },
    contractPath: { type: String, required: true },
    loading: { type: Boolean, default: false },
    onUpdate: { type: Function, default: () => {} }
  },

  mounted() {
    this.setValues()
  },

  watch: {
    contract() {
      this.setValues()
    }
  },

  computed: {
    tenantPath() {
      return `${this.contractPath}/tenant`
    }
  },

  methods: {
    attributeTranslate(path) {
      return this.$t(`models.contract.attributes.${path}`)
    },

    createEmail(value = null) {
      return { id: '', address: value }
    },

    createPhone(value = null) {
      return { id: '', number: value }
    },

    setEmails() {
      this.emails = Array.from(
        this.contract?.tenantAttributes?.emailsAttributes || []
      )

      while (this.emails.length < 3) this.emails.push(this.createEmail())
    },

    setPhones() {
      this.phones = Array.from(
        this.contract?.tenantAttributes?.phonesAttributes || []
      )

      while (this.phones.length < 3) this.phones.push(this.createPhone())
    },

    setValues() {
      this.setEmails()
      this.setPhones()
    },

    clearValues() {
      this.emails = Array(3).fill().map(() => this.createEmail('-'))
      this.phones = Array(3).fill().map(() => this.createPhone('-'))
    },

    update() {
      this.clearValues()
      this.onUpdate()
    }
  }
}
</script>
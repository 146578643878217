<template lang="pug">
.field.select-field(:class="{ error, disabled }")
  label(v-if="!hideLabel", :for="inputId", :class="{ 'required': required }")
    | {{ $t('.label') }}

  select2(
    :id="inputId",
    :disabled="disabled",
    :name="name",
    :placeholder="thePlaceholder",
    type="string",
    :value="modelValue",
    :include-blank="true",
    :hide-search="false",
    :required="required",
    @input="onInput",
    url="real_estate/users/bill-services.json",
    :dataFormatting="dataFormatting",
    :processResults="processResults",
    :options="selectedOption"
  )
    slot
  input-error(:message="error")
</template>

<script>
import vueAuthenticate from "lib/vue-authenticate"
import SelectField from "src/components/desktop/select-field.vue"

export default {
  mixins: [vueAuthenticate],
  extends: SelectField,

  mounted() {
    if (this.item) {
      this.setSelectedOption(this.item)
    }
  },

  watch: {
    item(item) {
      this.setSelectedOption(item)
    }
  },

  data() {
    return {
      defaultI18nScope: "realEstate.itemServiceField",

      selectedOption: []
    }
  },

  props: {
    item: { type: Object }
  },

  computed: {
    thePlaceholder() {
      if (this.fetching) { return this.$t("loading") }

      return this.placeholder || this.$t("options.blank")
    }
  },

  methods: {
    setSelectedOption(item) {
      this.selectedOption = [{ id: item.billServiceId, text: item.description }]
    },

    onInput(id, text) {
      this.$emit('update:modelValue', id)
      this.$emit('update:description', text)
    },

    processResults (response) {
      const body = response.data
      const bill_services = body.data
      const pagination = body.pagination || {}

      return {
        results: bill_services.map((bill_service) => (
          { id: bill_service.id, text: bill_service.description }
        )),
        pagination: {
          'more': !!pagination.next
        }
      };
    },

    dataFormatting (params) {
      return { page: params.page || 1, description: params.term }
    }
  },
}
</script>

<style scoped lang="scss">
label.required::after {
  content: ' *';
  color: $primary-color
}
</style>

<template lang="pug">
.switch-container
  switch-field(
    :id="`bill-service-${billService.id}[enabled]`"
    :checked="billService.enabled"
    :disabled="disabled || submitting"
    @input="enableDisable"
  )
</template>

<script>
  export default {
    data() {
      return {
        // i18n
        defaultI18nScope: "admin.billServices.enableDisableSwitch",

        submitting: false
      }
    },

    props: {
      billService: { type: Object },
      disabled: { type: Boolean, default: false }
    },

    methods: {
      enableDisable({ target }) {
        this.submitting = true

        const checked = target.checked
        const submitType = checked ? 'enable' : 'disable'

        this.$http
          .put(
            `/admins/bill-services/${this.billService.id}/enable-disable`,
            { bill_service: { enabled: checked } }
          )
          .then(() => {
            this.$notifications.info(
              this.$t(`.notifications.${submitType}.success`)
            )

            this.billService.enabled = checked
          })
          .catch(() => {
            this.$notifications.error(
              this.$t(`.notifications.${submitType}.failure`)
            )

            target.checked = !checked
          })
          .finally(() => {
            this.submitting = false
          })
      },
    }
  }
</script>
<style lang="scss" scoped>
.switch-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .switch-field {
    width: 43px;
    margin-bottom: 0;
  }
}
</style>

<template lang="pug">
.manual-update-container
  expansible-card(:title="$t('.title')", :expanded="false")
    form(ref="form", action="/", @submit.prevent="showUpdateModal = true")
      approved-field(v-model="result.approved")

      fee-field(
        v-model="result.value"
        :disabled="!result.approved",
        @update:modelValue="updateContractOpeningValueByFee"
      )

      currency-field(
        v-model="result.contractOpeningValue",
        :disabled="!result.approved",
        :label="$t('.contractOpeningValue.label')",
        :placeholder="$t('.contractOpeningValue.placeholder')",
        @input="changedContractOpeningValue = true"
      )

      button.button-primary(:disabled="disableUpdateButton")
        | {{ $t('.button') }}

confirmation-modal(
  @close="showUpdateModal = false",
  :show="showUpdateModal",
  :title="$t('.updateModal.title')",
  :message="$t('.updateModal.message')",
  :callback="update"
)
</template>
<script>
import objects from "lib/objects"

import ExpansibleCard from "../../../components/expansible-card.vue"
import ApprovedField from "../approved-field.vue"
import FeeField from "../fee-field.vue"
import ConfirmationModal from "../../../components/confirmation-modal.vue"

export default {
  components: {
    ExpansibleCard,
    ApprovedField,
    FeeField,
    ConfirmationModal,
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.scoreReports.manualUpdate",

      // data
      submitting: false,
      showUpdateModal: false,

      changedContractOpeningValue: false,
      valuesFixedByFee: {
        14.0: 119.00,
        12.5: 99.00,
        11.5: 89.00,
        10.5: 59.00,
        9.5: 45.00,
        8.5: 29.00
      }
    }
  },

  props: {
    result: { type: Object, required: true },
    scoreReportId: { type: Number, required: true}
  },

  computed: {
    disableUpdateButton() {
      if (this.submitting) return true

      if (!this.result.approved) return false

      return !(this.result.value && this.result.contractOpeningValue !== null)
    },

    updateParams() {
      if (this.result.approved) {
        return {
          approved: this.result.approved,
          fee: this.result.value,
          contractOpeningValue: this.result.contractOpeningValue
        }
      }

      return {
        approved: this.result.approved,
        fee: null,
        contractOpeningValue: null
      }
    }
  },

  methods: {
    updateContractOpeningValueByFee() {
      if (this.changedContractOpeningValue) return

      this.result.contractOpeningValue = this.valuesFixedByFee[
        this.result.value
      ]
    },

    update() {
      this.submitting = true

      return this.$http
        .put(
          `/admins/score-reports/${this.scoreReportId}.json`,
          objects.snakeize(this.updateParams)
        )
        .then(({ data }) => {
          this.$emit('updateScoreReport', data)

          this.changedContractOpeningValue = false
          this.showUpdateModal = false

          this.$notifications.info(this.$t(".notifications.success"))
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.failure"))
        })
        .finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>
<style scoped lang="scss">
.manual-update-container {
  form {
    button {
      width: 100%;
      margin: 0;
    }
  }
}
</style>

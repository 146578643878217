<template lang="pug">
.expansible-container(:class="expanded && 'expanded'")
  .title(@click="toggle")
    h2
      | {{ title }}
    i.fa(:class="expanded ? 'fa-angle-down' : 'fa-angle-up'")
  .expansible
    slot
</template>
<script>
export default {
  props: {
    title: { type: String, required: true },
    expanded: { type: Boolean, default: false }
  },

  methods: {
    toggle({ currentTarget }) {
      const icon = currentTarget.getElementsByClassName('fa')[0]

      currentTarget.parentElement.classList.toggle('expanded')

      icon.classList.toggle('fa-angle-up')
      icon.classList.toggle('fa-angle-down')
    },
  }
}
</script>
<style lang="scss" scoped>
.expansible-container {
  background-color: $white-color;
  padding: 2rem 1.5rem;
  padding-bottom: 0;
  border-radius: 0.5rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  margin-bottom: 3rem;

  .expansible {
    display: none;
  }

  &.expanded {
    padding-bottom: 2rem;

    .expansible {
      display: block;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    i {
      font-size: 2.5rem;
      color: $primary-color;
    }

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: $primary-color;
    }
  }
}
</style>
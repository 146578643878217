<template lang="pug">
#page.agreement-page
  navbar(currentPage="adminManagementIndex")

  .agreement-container-desktop
    .menu-container
      h2 {{ $t('.menu') }}

      .menu-items
        button(class="actived")
          | {{ $t('.menus.details') }}

    .agreement-container
      breadcrumbs(:items="breadcrumbItems")

      .buttons-container
        button.destroy-agreement(
          @click="showDeleteAgreementModal = true"
        )
          | {{ $t('.actions.buttons.destroy') }}

      .overview-container
        expansible-card(:title="$t('.agreementInfo')", :expanded="true")
          .inline-section
            .three-columns
              h3.info-title {{ $t('models.agreement.attributes.createdAt') }}
              .loading-content(v-if="loading")
              p.info-content(v-else)
                | {{ $l('date.formats.default',agreement.createdAt) }}

            .three-columns
              h3.info-title {{ $t('models.agreement.attributes.totalValue') }}
              .loading-content(v-if="loading")
              p.info-content(v-else) {{ valueFormat(agreement.totalValue) }}

            .three-columns
              h3.info-title {{ $t('models.agreement.attributes.installmentsCount') }}
              .loading-content(v-if="loading")
              p.info-content(v-else) {{ agreement.installmentsCount }}

          .inline-section
            .three-columns
              h3.info-title {{ $t('models.agreement.attributes.firstInstallmentDate') }}
              .loading-content(v-if="loading")
              p.info-content(v-else)
                | {{ $l('date.formats.default',agreement.firstInstallmentDate) }}

            .three-columns
              h3.info-title {{ $t('models.agreement.attributes.lastInstallmentDate') }}
              .loading-content(v-if="loading")
              p.info-content(v-else)
                | {{ $l('date.formats.default',agreement.lastInstallmentDate) }}

            .three-columns
              h3.info-title {{ $t('models.agreement.attributes.contractCode') }}
              .loading-content(v-if="loading")
              p.info-content(v-else) {{ agreement.contractCode }}

          .inline-section
            .three-columns
              h3.info-title {{ $t('models.agreement.attributes.realEstateAgencyName') }}
              .loading-content(v-if="loading")
              p.info-content(v-else) {{ agreement.realEstateAgencyName }}

            .three-columns
              h3.info-title {{ $t('models.agreement.attributes.contractAddress') }}
              .loading-content(v-if="loading")
              p.info-content(v-else) {{ agreement.contractAddress }}
            .three-columns

        expansible-card(:title="$t('.installments')", :expanded="true")
          .table-section
            .table-container
              table
                thead
                  tr
                    th(v-for="name in billsColumns")
                      p {{ $t(`.billsTable.columns.${name}`) }}

                tbody
                  tr(v-for="installment in agreement.installmentBills")
                    td.due-date
                      | {{ $l('date.formats.default', installment.dueDate) }}

                    td.payment-date
                      | {{ $l('date.formats.default', installment.paymentDate) }}

                    td.value
                      | {{ valueFormat(installment.value) }}

                    td.observation
                      | {{ installment.observation || '-' }}

                    td.open-bill
                      i.fa.fa-external-link(
                        @click="() => { openBill(installment.id) }"
                      )

        expansible-card(:title="$t('.originalBills')", :expanded="true")
          .table-section
              .table-container
                table
                  thead
                    tr
                      th(v-for="name in billsColumns")
                        p {{ $t(`.billsTable.columns.${name}`) }}

                  tbody
                    tr(v-for="bill in agreement.originalBills")
                      td.due-date
                        | {{ $l('date.formats.default', bill.dueDate) }}

                      td.payment-date
                        | {{ $l('date.formats.default', bill.paymentDate) || '-' }}

                      td.value
                        | {{ valueFormat(bill.value) }}

                      td.observation
                        | {{ bill.observation || '-' }}

                      td.open-bill
                        i.fa.fa-external-link(
                          @click="() => { openBill(bill.id) }"
                        )
  
  delete-agreement-modal(
    :show="showDeleteAgreementModal",
    :agreementId="agreement.id",
    @close="showDeleteAgreementModal = false"
  )
</template>

<script>
import Navbar from "../../../components/navbar.vue"
import Breadcrumbs from '../../../../components/breadcrumbs.vue'
import ExpansibleCard from "../../../components/expansible-card.vue"
import DeleteAgreementModal from "../../../components/agreements/delete-modal.vue"

import objects from "lib/objects"
import currencyFormatter from 'lib/currency-formatter'

export default {
  components: { 
    Navbar, Breadcrumbs, ExpansibleCard, DeleteAgreementModal
  },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.financial.agreement.show",

      // tool
      routePath: "administracao/financeiro/acordos",

      loading: true,

      agreement: {},

      // modals
      showDeleteAgreementModal: false,

      // tables
      billsColumns: [
        'dueDate',
        'paymentDate',
        'value',
        'observation',
        'open'
      ]
    }
  },

  methods: {
    init(agreement) {
      this.agreement = objects.camelize(agreement)
    },

    openBill(billId) {
      const route =  this.$router.resolve({
        path: `/administracao/gerenciamento/cobrancas/${billId}`
      })

      window.open(route.href, '_blank')
    },

    fetch() {
      this.$http
        .get(`/admins/agreements/${this.getAgreementIdParam}.json`)
        .then((response) => {
          this.init(response.data)

          this.loading = false
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
    },

    valueFormat(value) {
      return value ? currencyFormatter.format(value) : 'R$ 0,00'
    },
  },

  computed: {
    getAgreementIdParam() {
      return this.$route.params.id
    },

    breadcrumbItems() {
      return [
        {
          name: this.$t('.breadcrumbs.financial'),
          url: this.routePath.replace("/acordos", '')
        },
        {
          name: this.$t('.breadcrumbs.agreement'),
          url:  this.routePath.replace("/acordos", ''),
          hash: 'agreements'
        },
        {
          name: `#${this.getAgreementIdParam}`,
          url: `${this.routePath}/${this.getAgreementIdParam}`
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
#page.agreement-page {
  overflow: hidden;
  height: 100%;
}

.agreement-container-desktop {
  padding: 0;
  margin: 0 !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;

  .menu-container {
    width: 25%;
    height: 100vh;
    overflow-y: auto;

    background-color: $white-color-dark;
    box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);

    padding: 0 1.5rem;

    h2 {
      color: $primary-color;
      font-size: 2rem;
      font-weight: 400;
      border-bottom: 1.5px solid $primary-color;
      margin-top: 2.5rem;
    }

    .menu-items {
      width: 100%;
      height: max-content;

      button {
        text-align: start;
        color: $second-color-light;
        font-weight: 400;
        border-radius: 0.5rem;
        border: none;
        padding: 0.5rem 1.5rem;
        height: auto;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: none;

        &.actived{
          background-color: $primary-high-light;
          color: $primary-color;
        }

        &:hover {
          background-color: darken($primary-high-light, 5%);
          color: $primary-color;
        }

        &.destroy-contract {
          color: $red-color;
          background-color: lighten($red-color, 54%);

          &:hover {
            background-color: lighten($red-color, 49%);
          }
        }
      }
    }
  }

  .agreement-container {
    width: 75%;
    height: 100vh;
    padding: 2.5rem;
    overflow-y: auto;
    padding-bottom: 10rem;

    .loading-content {
      background: $loading-gradient;
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      width: 100%;
      height: 3.5rem;
      margin-bottom: 1.5rem;

      &.observation {
        height: 15rem;
      }
    }

    @keyframes shine {
      to {
        background-position-x: -200%;
      }
    }

    .info-title {
      font-size: 1.8rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      color: $second-color-light;
    }

    .info-content {
      font-size: 1.5rem;
      font-family: Lato;
    }
  }

  .buttons-container {
    display: flex;
    gap: 1rem;
    overflow-x: scroll;
    padding-right: 1rem;
  }

    button {
      font-size: 1.5rem;
      text-transform: none;

      &.destroy-agreement {
        border: none;
        color: $red-color;
        background-color: lighten($red-color, 54%);

        &:hover {
          background-color: lighten($red-color, 49%);
        }
      }
    }

  .inline-section {
    display: flex;
    justify-content: space-between;
    word-break: break-word;

    .one-column {
      width: 10%;
    }

    .two-columns {
      width: 47%;
    }

    .three-columns {
      width: 30%;
    }

    .four-columns {
      width: 52%;
    }

    .eleven-columns {
      width: 90%;
    }

    .full-size {
      width: 100%;
    }
  }

  .table-container {
    height: min-content !important;

    table {
      .open-bill {
        min-width: 1rem;

        i {
          cursor: pointer;
          color: $primary-color;
          font-weight: 700;
        }
      }
    }
  }
}
</style>

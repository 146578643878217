<template lang="pug">
#page
  navbar

  Login(:redirect="redirect" :role="role" :url="url", :heading="$t('.heading')")

  app-footer.footer
</template>

<script>
import Navbar from "../../components/navbar.vue";
import Login from "../../tools/login.vue";
import AppFooter from "../../footer.vue";

export default {
  components: {
    Navbar,
    Login,
    AppFooter
  },

  data() {
    return {
      defaultI18nScope: "realEstate.login",
      redirect: "/imobiliaria/gerenciamento",
      role: "realEstate",
      url: "real_estate/user"
    }
  }
};
</script>
<style scoped lang="scss">
@import "styles/desktop/variables";

#page {
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>

<template lang="pug">
.landlord-section
  editable-fields-group(
    :components="components",
    :path="landlordPath",
    :callback="onUpdate"
  )
</template>

<script>
import EditableFieldsGroup from "../../../../../../components/desktop/editable-fields/editable-fields-group.vue"

export default {
  components: { EditableFieldsGroup },

  data() {
    return {
      components: []
    }
  },

  props: {
    contract: { type: Object, required: true },
    contractPath: { type: String, required: true },
    loading: { type: Boolean, default: false },
    onUpdate: { type: Function, default: () => {} }
  },

  mounted() {
    this.setComponents(this.contract)
  },

  watch: {
    contract: {
      handler(contract) {
        this.setComponents(contract)
      },
      deep: true
    }
  },

  computed: {
    landlordPath() {
      return `${this.contractPath}/landlord`
    },

    transferToLandlord() {
      return this.contract.transferTo === 'landlord'
    }
  },

  methods: {
    attributeTranslate(path) {
      return this.$t(`models.contract.attributes.${path}`)
    },

    setComponents(contract) {
      if (!contract) return

      this.components = [
        [
          {
            name: 'landlord[name]',
            value: contract.landlordAttributes.name,
            label: this.attributeTranslate('landlordName'),
            errorPath: 'name'
          },
          {
            name: 'landlord[cpf_cnpj]',
            value: contract.landlordAttributes.cpfCnpj,
            label: this.attributeTranslate('landlordCpfCnpj'),
            inputType: 'cpf-cnpj-field',
            errorPath: 'cpf_cnpj'
          },
          {
            name: 'landlord[bank_account_attributes][agency]',
            value: contract.landlordAttributes.agency,
            label: this.attributeTranslate('landlordBankAccountAgency'),
            errorPath: 'bank_account.agency'
          }
        ],

        [
          {
            name: 'landlord[bank_account_attributes][account]',
            value: contract.landlordAttributes.account,
            label: this.attributeTranslate('landlordBankAccountAccount'),
            errorPath: 'bank_account.account'
          },
          {
            name: 'landlord[bank_account_attributes][code]',
            value: contract.landlordAttributes.code,
            label: this.attributeTranslate('landlordBankAccountCode'),
            errorPath: 'bank_account.code'
          },
          {
            name: 'landlord[bank_account_attributes][pix]',
            value: contract?.landlordAttributes?.pix,
            label: this.attributeTranslate('landlordBankAccountPix'),
            errorPath: 'bank_account.pix'
          }
        ]
      ]
    }
  }
}
</script>
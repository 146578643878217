<template lang="pug">
.trials-modal
  button.open-modal.button-primary(
    :disabled="disabled",
    @click="openModal"
  )
    slot

  Modal(v-model="isShow", :close="close")
    .modal
      h1.title {{ $t(`.title`) }}

      .trials(:class="trials && trials.length > 0 || 'hasntError'")
        .table-section
          .table-container
            table
              thead
                tr
                  th(v-for="header in headers")
                    p {{ $t(`.headers.${header}`) }}

              tbody
                tr(v-for="trial in trials")
                  td.processNumber
                    button.button-primary(
                      @click="copyText(trial.trialNumber)"
                    )
                     i.fa.fa-clone
                  td.uf {{ trial.uf || '-' }}
                  td.tribunal {{ trial.court || '-' }}
                  td {{ $l('date.formats.default', trial.deliveryDate) || '-' }}
                  td.defendantOrPlaintiff {{ defendantOrPlaintiff(trial) }}
                  td.area {{ trial.area || '-' }}
                  td.proceduralClass {{ trial.trialClassName || '-' }}

          footer {{ $t('.footer', { count: trials.length }) }}

      .buttons-container
        button.close(@click="close") {{ $t(".close") }}
</template>
<script>
import { ref } from 'vue'

export default {
  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.components.scoreReport.trialsModal",

      headers: [
        'processNumber', 'uf', 'tribunal', 'distribuitionDate',
        'defendantOrPlaintiff', 'area', 'proceduralClass'
      ]
    }
  },

  props: {
    disabled: { type: Boolean, default: false },
    trials: { type: Array }
  },

  methods: {
    defendantOrPlaintiff(trial) {
      if (trial.defendant) return this.$t('.fields.defendant')

      return this.$t('.fields.plaintiff')
    },

    openModal(event) {
      event.preventDefault()
      this.showModal()
    },

    close(event) {
      event.preventDefault()
      this.closeModal()
    },

    copyText(text) {
      navigator.clipboard.writeText(text);

      this.$notifications.info(this.$t(".copyText", { text: text }))
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/styles/base/tables.scss";

button {
  margin-bottom: 0;
}

.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  width: 80%;
  height: fit-content;

  .title {
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
    color: $primary-color;
    margin-bottom: 1rem;
  }

  .trials {
    width: 100%;

    .title {
      font-size: 1.8rem;
      font-weight: 500;
      border-bottom: 1px solid $primary-color;
    }

    .table-container {
      height: 50vh;

      td {
        min-width: fit-content;
      }

      .processNumber {
        text-align: center;

        button {
          width: 3.8rem;
          padding: 0;
        }

        i {
          font-size: 1.8rem;
        }
      }

      .area {
        width: 40%;
        min-width: 25rem;
        white-space: normal;
      }

      .proceduralClass {
        width: 60%;
        min-width: 30rem;
        white-space: normal;
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 4rem;
  }
}
</style>

<template lang="pug">
Modal(v-model="isShow", :close="close")
  .modal
    .info-container
      h1 {{ $t('.title') }}

      form(ref="form", action="/")
        contract-field(
          url="/admins/financial/agreements/contracts.json"
          v-model="contract",
          name="bill[contract_id]",
          :required="true"
        )

      .buttons-container
        button.close(@click="close")
          | {{ $t(".buttons.close") }}

        button.confirm(
          @click="submit",
          :disabled="!contract")
          | {{ $t(".buttons.confirm") }}
</template>

<script>
import { ref } from 'vue'

import ContractField from '../contract-field.vue'

export default {
  components: { ContractField },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.agreements.newModal",

      // form
      contract: null
    }
  },

  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  props: {
    show: { type: Boolean, default: false }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  methods: {
    close() {
      this.contract = null

      this.$emit('close')
    },

    submit() {
      this.$router.push({
        name: 'adminAgreementNew', query: { contract_id: this.contract }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  height: fit-content;

  .info-container {
    min-height: 11em;

    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
    }
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}
</style>

<style lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}
</style>

<template lang="pug">
.property-section
  select-field(
    name="contract[property_attributes][property_type]"
    :options="propertyTypeOptions"
    :error="errors?.propertyPropertyType?.message"
    v-model="propertyType"
    includeBlank
    required
  )

  input-field(
    type="text"
    name="contract[property_attributes][zip_code]"
    :placeholder="$t('.placeholder.zipCode')"
    :error="errors?.propertyZipCode?.message"
    @input="getAddress"
    v-model="cep"
    v-maska="'#####-###'"
    required
  )

  input-field(
    type="text"
    name="contract[property_attributes][state]"
    :placeholder="$t('.placeholder.state')"
    :error="errors?.propertyState?.message"
    v-model="state"
    required
  )

  input-field(
    type="text"
    name="contract[property_attributes][city]"
    :placeholder="$t('.placeholder.city')"
    :error="errors?.propertyCity?.message"
    v-model="city"
    required
  )

  input-field(
    type="text"
    name="contract[property_attributes][neighborhood]"
    :placeholder="$t('.placeholder.neighborhood')"
    :error="errors?.propertyNeighborhood?.message"
    v-model="neighborhood"
    required
  )

  input-field(
    type="text"
    name="contract[property_attributes][street]"
    :placeholder="$t('.placeholder.street')"
    :error="errors?.propertyStreet?.message"
    v-model="street"
    required
  )

  input-field(
    type="number"
    name="contract[property_attributes][number]"
    :placeholder="$t('.placeholder.number')"
    :error="errors?.propertyNumber?.message"
    v-model="number"
    required
  )

  input-field(
    type="text"
    name="contract[property_attributes][complement]"
    :placeholder="$t('.placeholder.complement')"
    :error="errors?.propertyComplement?.message"
    v-model="complement"
  )
</template>

<script>
import cep from 'cep-promise'

export default {
  data() {
    return {
      // i18n
      defaultI18nScope: 'realEstate.management.contract.new',

      // form fields data
      propertyType: null,
      cep: null,
      state: null,
      city: null,
      neighborhood: null,
      street: null,
      number: null,
      complement: null
    }
  },

  props: {
    errors: { type: Object, default: () => ({}) }
  },

  computed: {
    propertyTypeOptions() {
      return [
        { id: 'apartment', text: this.$t('.propertyTypeOptions.apartment') },
        { id: 'house', text: this.$t('.propertyTypeOptions.house') },
        { id: 'condominium_house', text: this.$t('.propertyTypeOptions.condominiumHouse') },
        { id: 'roof_top', text: this.$t('.propertyTypeOptions.roofTop') },
        { id: 'commercial', text: this.$t('.propertyTypeOptions.commercial') },
        { id: 'flat', text: this.$t('.propertyTypeOptions.flat') }
      ]
    }
  },

  methods: {
    getAddress(value) {
      let cepValue = value.target.value.replace('-', '')

      if (cepValue.length < 8) return

      cep(cepValue).then((result) => {
        this.state = result.state
        this.neighborhood = result.neighborhood
        this.city = result.city
        this.street = result.street
        this.cep = `${result.cep.slice(0, 5)}-${result.cep.slice(5)}`
      })
    }
  }
}
</script>

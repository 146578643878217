<template lang="pug">
.contract-section
  .inline-section
    .three-columns
      h3.info-title {{ attributeTranslate('code') }}

      .loading-content(v-if="loading")

      editable-input-field(
        v-else-if="contract.editable"
        name="contract[code]"
        :value="contract.code"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) {{ contract.code || '-' }}

    .three-columns
      h3.info-title {{ attributeTranslate('createdAt') }}

      .loading-content(v-if="loading")

      p.info-content(v-else-if="contract.createdAt")
        | {{ $l('date.formats.default', contract.createdAt) }}

      p.info-content(v-else) -

    .three-columns
      h3.info-title {{ attributeTranslate('startDate') }}

      .loading-content(v-if="loading")

      editable-date-field(
        v-else-if="contract.editable"
        name="contract[start_date]"
        :value="contract.startDate"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else-if="contract.createdAt")
        | {{ $l('date.formats.default', contract.startDate) }}

      p.info-content(v-else) -

  .inline-section
    .three-columns
      h3.info-title {{ attributeTranslate('monthsUntilDeadline') }}

      .loading-content(v-if="loading")

      editable-input-field(
        v-else-if="contract.editable"
        type="number"
        name="contract[months_until_deadline]"
        :value="contract.monthsUntilDeadline"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) {{ contract.monthsUntilDeadline || '-' }}

    .three-columns
      h3.info-title {{ attributeTranslate('dueDay') }}

      .loading-content(v-if="loading")

      editable-input-field(
        v-else-if="contract.editable"
        type="number"
        name="contract[due_day]"
        :value="('0' + contract.dueDay).slice(-2)"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else)
        | {{ contract.dueDay ? ("0" + contract.dueDay).slice(-2) : '-' }}

    .three-columns
      h3.info-title {{ attributeTranslate('readjustmentMonth') }}

      .loading-content(v-if="loading")

      editable-months-field(
        v-else-if="contract.editable"
        name="contract[readjustment_month]"
        :value="contract.readjustmentMonth"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else-if="contract.readjustmentMonth")
        | {{ $t('date.month_names')[contract.readjustmentMonth] }}

      p.info-content(v-else) -

  .inline-section
    .three-columns
      h3.info-title {{ attributeTranslate('rentValue') }}

      .loading-content(v-if="loading")

      editable-currency-field(
        v-else-if="contract.editable"
        v-model="contract.rentValue"
        name="contract[rent_value]"
        :value="contract.rentValue"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) R$ {{ contract.rentValue || '-' }}

    .three-columns
      h3.info-title {{ attributeTranslate('contractType') }}

      .loading-content(v-if="loading")

      editable-select-field(
        v-else-if="contract.editable"
        name="contract[contract_type]"
        :v-model="contract.contractType"
        :value="contract.contractType"
        :options="contractTypeOptions"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else-if="contract.contractType")
        | {{ contractTranslate(`contractType.${contract.contractType}`) }}

      p.info-content(v-else) -

    .three-columns
      h3.info-title {{ attributeTranslate('readjustmentIndex') }}

      .loading-content(v-if="loading")

      editable-select-field(
        v-else-if="contract.editable"
        name="contract[readjustment_index]"
        :value="contract.readjustmentIndex"
        :options="readjustmentIndexOptions"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else-if="contract.readjustmentIndex")
        | {{ contractTranslate(`readjustmentIndex.${contract.readjustmentIndex}`) }}

      p.info-content(v-else) -

  .inline-section
    .three-columns
      h3.info-title {{ attributeTranslate('paymentType') }}

      .loading-content(v-if="loading")

      editable-select-field(
        v-else-if="contract.editable"
        name="contract[payment_type]"
        :value="contract.paymentType"
        :options="paymentTypeOptions"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else-if="contract.paymentType")
        | {{ contractTranslate(`paymentType.${contract.paymentType}`) }}

      p.info-content(v-else) -

    .three-columns
      h3.info-title {{ attributeTranslate('status') }}

      .loading-content(v-if="loading")

      editable-select-field(
        v-else-if="contract.editable"
        name="contract[status]"
        :value="contract.status"
        :options="statusOptions"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else-if="contract.status")
        | {{ contractTranslate(`status.${contract.status}`) }}

      p.info-content(v-else) -

    .three-columns
      h3.info-title {{ attributeTranslate('terminationDate') }}

      .loading-content(v-if="loading")

      editable-date-field(
        v-else-if="contract.editable"
        name="contract[termination_date]"
        :value="contract.terminationDate"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else-if="contract.terminationDate")
        | {{ $l('date.formats.default', contract.terminationDate) }}

      p.info-content(v-else) -

  .inline-section
    .full-size
      h3.info-title {{ attributeTranslate('observation') }}

      .loading-content.observation(v-if="loading")

      editable-text-area-field(
        v-else-if="contract.editable"
        name="contract[observation]"
        :value="contract.observation"
        :path="contractPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) {{ contract.observation || '-' }}

</template>

<script>
import EditableInputField from "../../../../../../components/desktop/editable-fields/editable-input-field.vue"
import EditableDateField from "../../../../../../components/desktop/editable-fields/editable-date-field.vue"
import EditableMonthsField from "../../../../../../components/desktop/editable-fields/editable-months-field.vue"
import EditableCurrencyField from "../../../../../../components/desktop/editable-fields/editable-currency-field.vue"
import EditableSelectField from "../../../../../../components/desktop/editable-fields/editable-select-field.vue"
import EditableTextAreaField from "../../../../../../components/desktop/editable-fields/editable-text-area-field.vue"

export default {
  components: {
    EditableInputField,
    EditableDateField,
    EditableMonthsField,
    EditableCurrencyField,
    EditableSelectField,
    EditableTextAreaField
  },

  props: {
    contract: { type: Object, required: true },
    contractPath: { type: String, required: true },
    loading: { type: Boolean, default: false },
    onUpdate: { type: Function, default: () => {} }
  },

  computed: {
    contractTypeOptions() {
      const contractTypes = [
        'residential', 'non_residential', 'commercial', 'industrial',
        'temporary_house', 'by_order', 'mixed'
      ]

      return contractTypes.map(contractType => ({
        id: contractType,
        text: this.contractTranslate(`contractType.${contractType}`)
      }))
    },

    readjustmentIndexOptions() {
      const readjustmentIndexes = ['igpm', 'inpc', 'ipca']

      return readjustmentIndexes.map(readjustmentIndex => ({
        id: readjustmentIndex,
        text: this.contractTranslate(`readjustmentIndex.${readjustmentIndex}`)
      }))
    },

    paymentTypeOptions() {
      const paymentTypes = ['expired_month', 'month_to_expire']

      return paymentTypes.map(paymentType => ({
        id: paymentType,
        text: this.contractTranslate(`paymentType.${paymentType}`)
      }))
    },

    statusOptions() {
      const statuses = [
        'active', 'inactive', 'to_be_vacant', 'eviction_process',
        'paused', 'under_verification', 'preparing_eviction'
      ]

      return statuses.map(status => ({
        id: status,
        text: this.contractTranslate(`status.${status}`)
      }))
    }
  },

  methods: {
    contractTranslate(path) {
      return this.$t(`models.contract.${path}`)
    },

    attributeTranslate(path) {
      return this.contractTranslate(`attributes.${path}`)
    }
  }
}
</script>
<template lang="pug">
label.visit-state-tag(:class="[value, size]") {{ label }}
</template>

<script>
export default {
  props: {
    value: { type: String, required: true },
    size: { type: String, default: "normal" }, // available: "normal", "small"
  },

  data() {
    return {
      defaultI18nScope: "components.visit-state-tag",
    };
  },

  computed: {
    label() {
      return this.$t(`models.visit.enums.state.${this.value}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./src/styles/visit_colors";

.visit-state-tag {
  display: inline-block;
  padding: 0 25px;
  margin: 0;

  height: 38px;
  line-height: 38px;

  font-size: 16px;
  font-weight: 400;
  color: white;

  // size options
  // ----

  &.small {
    font-size: 12px;
    padding: 0 12px;
    height: 28px;
    line-height: 28px;
    border-radius: 4px;
  }

  // state value options based on SASS $visit-colors map (_visit_colors.scss)
  // @see https://www.sitepoint.com/using-sass-maps/
  // ----
  // // sample with state == 'requested'
  // // ----
  // &.requested {
  //   background-color: $requested-bg-color;
  // }
  //
  @each $state, $color in $visit-colors {
    &.#{$state} {
      background-color: $color;
    }
  }
}
</style>
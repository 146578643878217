<template lang="pug">
.tenant-section
  select-field(
    v-if="!!scoreReport"
    name="contract[tenant]"
    :label="$t('.form.fields.tenant')"
    :options="tenantOptions"
    @update:modelValue="selectTenant"
    v-model="selectedTenant"
    includeBlank
    required
  )

  input-field(
    type="text"
    name="contract[tenant_attributes][name]"
    :placeholder="$t('.placeholder.name')"
    :error="errors?.tenantName?.message"
    v-model="name"
    readonly
    required
  )

  input-field(
    type="text"
    name="contract[tenant_attributes][cpf]"
    v-maska="'###.###.###-##'"
    :placeholder="$t('.placeholder.cpf')"
    :error="errors?.tenantCpf?.message"
    v-model="cpf"
    readonly
    required
  )

  input-field(
    type="text"
    name="contract[tenant_attributes][rg]"
    :placeholder="$t('.placeholder.rg')"
    :error="errors?.tenantRg?.message"
    v-model="rg"
    required
  )

  h3 {{ $t('.form.emails') }}

  input-field(
    type="email"
    name="contract[tenant_attributes][emails_attributes][][address]"
    :label="$t('models.contract.attributes.firstEmail')"
    :placeholder="$t('.placeholder.email')"
    :error="errors?.tenantEmailsAddress?.message"
    v-model="firstEmail"
    required
  )

  input-field(
    type="email"
    name="contract[tenant_attributes][emails_attributes][][address]"
    :label="$t('models.contract.attributes.secondEmail')"
    :placeholder="$t('.placeholder.email')"
    :error="errors?.tenantEmailsAddress?.message"
    v-model="secondEmail"
  )

  input-field(
    type="email"
    name="contract[tenant_attributes][emails_attributes][][address]"
    :label="$t('models.contract.attributes.thirdEmail')"
    :placeholder="$t('.placeholder.email')"
    :error="errors?.tenantEmailsAddress?.message"
    v-model="thirdEmail"
  )

  h3 {{ $t('.form.phones') }}

  phone-field(
    name="contract[tenant_attributes][phones_attributes][][number]"
    :label="$t('models.contract.attributes.firstPhone')"
    required
    :placeholder="$t('.placeholder.phone')"
    :error="errors?.tenantPhonesNumber?.message"
    v-model="firstPhone"
  )

  phone-field(
    name="contract[tenant_attributes][phones_attributes][][number]"
    :label="$t('models.contract.attributes.secondPhone')"
    :placeholder="$t('.placeholder.phone')"
    :error="errors?.tenantPhonesNumber?.message"
    v-model="secondPhone"
  )

  phone-field(
    name="contract[tenant_attributes][phones_attributes][][number]"
    :label="$t('models.contract.attributes.thirdPhone')"
    :placeholder="$t('.placeholder.phone')"
    :error="errors?.tenantPhonesNumber?.message"
    v-model="thirdPhone"
  )
</template>

<script>
export default {
  data() {
    return {
      // i18n
      defaultI18nScope: 'realEstate.management.contract.new',

      // form fields data
      selectedTenant: null,
      name: null,
      cpf: null,
      rg: null,
      firstEmail: null,
      secondEmail: null,
      thirdEmail: null,
      firstPhone: null,
      secondPhone: null,
      thirdPhone: null
    }
  },

  props: {
    errors: { type: Object, default: () => ({}) },
    scoreReport: { type: Object, default: () => ({}) }
  },

  methods: {
    selectTenant(value) {
      const score = this.scores?.filter(({ id }) => id == value)[0]

      this.name = score.name
      this.cpf = score.cpf
    }
  },

  computed: {
    scores() {
      return this.scoreReport?.scores
    },

    tenantOptions() {
      if (!this.scores) return []

      return this.scores.map(({ id, cpf, name }) => ({
        id, text: `${cpf} - ${name}`
      }))
    }
  }
}
</script>

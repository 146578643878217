import { createRouter, createWebHistory } from 'vue-router'

import NotFound from '../views/static_pages/not-found.vue'
import Unauthorized from '../views/static_pages/unauthorized.vue'

// import RealEstateAgencyCandidates from '../views/desktop/real_estate_agency_candidates/RealEstateAgencyCandidates.vue'
// import About from '../views/desktop/static_pages/about.vue'
// import FAQ from '../views/desktop/static_pages/faq.vue'
// import Acknowledgment from '../views/desktop/static_pages/acknowledgment.vue'

// Admin
import AdminLogin from 'src/views/desktop/admin/tools/login.vue'
import AdminConfirmation from 'src/views/desktop/admin/tools/account-confirmation.vue'
import AdminResendConfirmation from 'src/views/desktop/admin/tools/resend-confirmation.vue'
import AdminNewPassword from 'src/views/desktop/admin/tools/password/new.vue'
import AdminEditPassword from 'src/views/desktop/admin/tools/password/edit.vue'
import AdminManagementIndex from 'src/views/desktop/admin/tools/management/index.vue'
import AdminReportIndex from 'src/views/desktop/admin/tools/report/index.vue'
import AdminScoreReportShow from 'src/views/desktop/admin/tools/management/score_report/show.vue'
import AdminContractShow from 'src/views/desktop/admin/tools/management/contract/show.vue'
import AdminTransferShow from 'src/views/desktop/admin/tools/management/transfer/show.vue'
import AdminBillShow from 'src/views/desktop/admin/tools/management/bill/show.vue'
import AdminFinancialIndex from 'src/views/desktop/admin/tools/financial/index.vue'
import AdminAgreementNew from 'src/views/desktop/admin/tools/management/agreement/new.vue'
import AdminAgreementShow from 'src/views/desktop/admin/tools/management/agreement/show.vue'
import AdminRealEstateAgencyIndex from 'src/views/desktop/admin/tools/real_estate_agency/index.vue'
import AdminRealEstateAgencyShow from 'src/views/desktop/admin/tools/real_estate_agency/show.vue'

// RealEstate
import RealEstateLogin from 'src/views/desktop/real_estate/tools/login.vue'
import RealEstateConfirmation from 'src/views/desktop/real_estate/tools/account-confirmation.vue'
import RealEstateResendConfirmation from 'src/views/desktop/real_estate/tools/resend-confirmation.vue'
import RealEstateNewPassword from 'src/views/desktop/real_estate/tools/password/new.vue'
import RealEstateEditPassword from 'src/views/desktop/real_estate/tools/password/edit.vue'
import RealEstateManagementIndex from 'src/views/desktop/real_estate/tools/management/index.vue'
import RealEstateScoreReportShow from 'src/views/desktop/real_estate/tools/management/score_report/show.vue'
import RealEstateContractNew from 'src/views/desktop/real_estate/tools/management/contract/new.vue'
import RealEstateContractShow from 'src/views/desktop/real_estate/tools/management/contract/show.vue'
import RealEstateContractBillShow from 'src/views/desktop/real_estate/tools/management/contract/bill/show.vue'
import RealEstateCnabIndex from 'src/views/desktop/real_estate/tools/cnab/index.vue'
import RealEstateTeamIndex from 'src/views/desktop/real_estate/tools/team/index.vue'

// marketing
import marketingRoutes from './marketing'
import staticPagesRoutes from './static_pages'

const routes = [
  // Public routes
  // {
  //   path: '/',
  //   name: 'realEstateAgencyCandidates',
  //   component: RealEstateAgencyCandidates
  // },
  // {
  //   name:      'about',
  //   path:      '/about',
  //   component: About,
  // },
  // {
  //   name:      'howItWorks',
  //   path:      '/como-funciona',
  //   component: About,
  // },
  // {
  //   name:      'faq',
  //   path:      '/faq',
  //   component: FAQ,
  // },
  // {
  //   name:      'acknowledgment',
  //   path:      '/obrigado',
  //   component: Acknowledgment
  // },

  // Admin Routes

  {
    name:      'adminLogin',
    path:      '/administracao/login',
    component: AdminLogin
  },
  {
    name:      'adminConfirmation',
    path:      '/administracao/confirmar-conta',
    component: AdminConfirmation
  },
  {
    name:      'adminResendConfirmation',
    path:      '/administracao/reenviar-confirmacao',
    component: AdminResendConfirmation
  },
  {
    name:      'adminNewPassword',
    path:      '/administracao/recuperar-senha',
    component: AdminNewPassword
  },
  {
    name:      'adminEditPassword',
    path:      '/administracao/nova-senha',
    component: AdminEditPassword
  },
  {
    name:      'adminManagementIndex',
    path:      '/administracao/gerenciamento',
    component: AdminManagementIndex,
    meta: {
      auth: true,
      role: 'admin'
    }
  },
  {
    name:     'adminReportIndex',
    path:     '/administracao/relatorios',
    component: AdminReportIndex,
    meta: {
      auth: true,
      role: 'admin'
    }
  },
  {
    name:      'adminScoreReportShow',
    path:      '/administracao/gerenciamento/consultas/:id',
    component: AdminScoreReportShow,
    meta: {
      auth: true,
      role: 'admin'
    }
  },
  {
    name:      'adminContractShow',
    path:      '/administracao/gerenciamento/contratos/:id',
    component: AdminContractShow,
    meta: {
      auth: true,
      role: 'admin'
    }
  },
  {
    name:      'adminTransferShow',
    path:      '/administracao/gerenciamento/repasses/:id',
    component: AdminTransferShow,
    meta: {
      auth: true,
      role: 'admin'
    }
  },
  {
    name:      'adminBillShow',
    path:      '/administracao/gerenciamento/cobrancas/:id',
    component: AdminBillShow,
    meta: {
      auth: true,
      role: 'admin'
    }
  },
  {
    name:      'adminFinancialIndex',
    path:      '/administracao/financeiro',
    component: AdminFinancialIndex,
    meta: {
      auth: true,
      role: 'admin'
    }
  },
  {
    name:      'adminAgreementNew',
    path:      '/administracao/gerenciamento/acordos/criar',
    component: AdminAgreementNew,
    meta: {
      auth: true,
      role: 'admin'
    }
  },
  {
    name:      'adminAgreementShow',
    path:      '/administracao/gerenciamento/acordos/:id',
    component: AdminAgreementShow,
    meta: {
      auth: true,
      role: 'admin'
    }
  },
  {
    name:      'adminRealEstateAgencyIndex',
    path:      '/administracao/imobiliarias',
    component: AdminRealEstateAgencyIndex,
    meta: {
      auth: true,
      role: 'admin'
    }
  },
  {
    name:      'adminRealEstateAgencyShow',
    path:      '/administracao/imobiliarias/:id',
    component: AdminRealEstateAgencyShow,
    meta: {
      auth: true,
      role: 'admin'
    }
  },

  // Real Estate Routes

  {
    name:      'realEstateLogin',
    path:      '/imobiliaria/login',
    component: RealEstateLogin
  },
  {
    name:      'realEstateConfirmation',
    path:      '/imobiliaria/confirmar-conta',
    component: RealEstateConfirmation
  },
  {
    name:      'realEstateResendConfirmation',
    path:      '/imobiliaria/reenviar-confirmacao',
    component: RealEstateResendConfirmation
  },
  {
    name:      'realEstateNewPassword',
    path:      '/imobiliaria/recuperar-senha',
    component: RealEstateNewPassword
  },
  {
    name:      'realEstateEditPassword',
    path:      '/imobiliaria/nova-senha',
    component: RealEstateEditPassword
  },
  {
    name:      'realEstateManagementIndex',
    path:      '/imobiliaria/gerenciamento',
    component: RealEstateManagementIndex,
    meta: {
      auth: true,
      role: 'real-estate'
    }
  },
  {
    name:      'realEstateScoreReportShow',
    path:      '/imobiliaria/gerenciamento/consultas/:id',
    component: RealEstateScoreReportShow,
    meta: {
      auth: true,
      role: 'real-estate'
    }
  },
  {
    name:      'realEstateContractNew',
    path:      '/imobiliaria/gerenciamento/contratos/criar',
    component: RealEstateContractNew,
    meta: {
      auth: true,
      role: 'real-estate'
    }
  },
  {
    name:      'realEstateContractShow',
    path:      '/imobiliaria/gerenciamento/contratos/:id',
    component: RealEstateContractShow,
    meta: {
      auth: true,
      role: 'real-estate'
    }
  },
  {
    name:      'realEstateContractBillShow',
    path:      '/imobiliaria/gerenciamento/contratos/:contractId/cobrancas/:billId',
    component: RealEstateContractBillShow,
    meta: {
      auth: true,
      role: 'real-estate'
    }
  },
  {
    name:      'realEstateCnabIndex',
    path:      '/imobiliaria/cnab',
    component: RealEstateCnabIndex,
    meta: {
      auth: true,
      role: 'real-estate'
    }
  },
  {
    name:      'realEstateTeamIndex',
    path:      '/imobiliaria/equipe',
    component: RealEstateTeamIndex,
    meta: {
      auth: true,
      role: 'real-estate'
    }
  },

  // alias for backend autentication routers
  {
    path: '/real_estate/user/confirmation',
    redirect: { name: 'realEstateConfirmation' }
  },
  {
    path: '/real_estate/user/resend-confirmation',
    redirect: { name: 'realEstateResendConfirmation' }
  },
  {
    path: '/real_estate/user/password/edit',
    redirect: { name: 'realEstateEditPassword' }
  },

  {
    path: '/admin/confirmation',
    redirect: { name: 'adminConfirmation' }
  },
  {
    path: '/admin/resend-confirmation',
    redirect: { name: 'adminResendConfirmation' }
  },
  {
    path: '/admin/password/edit',
    redirect: { name: 'adminEditPassword' }
  },

  {
    path: '/real_estate/admin/confirmation',
    redirect: { name: 'realEstateAdminConfirmation' }
  },
  {
    path: '/real_estate/admin/password/edit',
    redirect: { name: 'realEstateAdminEditPassword' }
  },

  {
    path: '/real_estate/attendant/confirmation',
    redirect: { name: 'realEstateAttendantConfirmation' }
  },
  {
    path: '/real_estate/attendant/resend-confirmation',
    redirect: { name: 'realEstateAttendantResendConfirmation' }
  },
  {
    path: '/real_estate/attendant/password/edit',
    redirect: { name: 'realEstateAttendantEditPassword' }
  },

  {
    path: '/real_estate/manager/confirmation',
    redirect: { name: 'realEstateManagerConfirmation' }
  },
  {
    path: '/real_estate/manager/resend-confirmation',
    redirect: { name: 'realEstateManagerResendConfirmation' }
  },
  {
    path: '/real_estate/manager/password/edit',
    redirect: { name: 'realEstateManagerEditPassword' }
  },

  { path: '/acesso-nao-autorizado', name: 'unauthorized', component: Unauthorized },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound }
]

routes.push(...marketingRoutes)
routes.push(...staticPagesRoutes)

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  app.permissions.validateOrFetch().finally(() => {
    if (app.auth.isRealEstateUserAuthenticated()) {
      app.agency.initialize().finally(() => {
        if (to.name === 'realEstateLogin') {
          next({ name: 'realEstateManagementIndex' })

          return
        }

        next()

        return
      })
    } else {
      if (to.meta?.role === 'real-estate') {
        next({ name: 'realEstateLogin' })

        return
      }

      if ((to.name === 'adminLogin') && app.auth.isAdminAuthenticated()) {
        next({ name: 'adminManagementIndex', params: { id: app.auth.user.id } })

        return
      }

      if (to.matched.some(record => record.meta.auth) && !app.auth.isAuthenticated()) {
        let redirectTo = `${_.camelCase(to.meta.role)}Login`

        next({ name: redirectTo })

        return
      }

      next()
    }
  })
})

// Guardando a rota acessada de maneira navegacional, permitindo que dados
// sejam carregados de maneira síncrona
router.afterEach((to) => {
  if (!router.navigationalRoute) {
    router.navigationalRoute = to
  }
})

export default router

<template lang="pug">
.dropdown-container.py-7(@click="expanded = !expanded")
  .title-container.flex.items-center.justify-between
    h2.m-0.font-semibold {{ title }}
    i.fa(:class="expanded ? 'fa-chevron-up' : 'fa-chevron-down'")

  .content-container(v-if="expanded")
    p.m-0 {{ text }}

</template>
<script>
export default {
  props: {
    title: { type: String, required: true },
    text: { type: String, required: true }
  },

  data() {
    return {
      expanded: false
    }
  },
}
</script>
<style lang="scss" scoped>
  .dropdown-container {
    cursor: pointer;

    h2, p {
      font-size: 18px;
      color: #452763;
    }

    i {
      color: $primary-color
    }
  }
</style>

<doc lang="markdown">
  # usage
  ```pug
  action-input(v-model="message", @action-click="say")
    i.fa.fa-comment-o
  ```

  ## events
  - @input -> @input on `<input>`
  - @esc -> @keyup esc on `<input>`
  - @enter -> @keypress enter on `<input>`
  - @focus -> @focus on `<input>`
  - @click -> @click on `<input>`
  - @action-click -> @click on `<button>`
</doc>

<template lang="pug">
.action-input
  input(ref="input", type="text",
    :value="value",
    :disabled="disabled",
    :readonly="readonly",
    :name="name",
    :placeholder="placeholder",
    @input="val => $emit('input', val)",
    @keyup.esc.prevent.stop="$emit('esc')",
    @keypress.enter.prevent.stop="$emit('enter')",
    @focus="$emit('focus')",
    @click="$emit('click')",
    :class="{ disabled, readonly }"
  )
  button.btn-action(type="button", @click="$emit('action-click')")
    slot
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
.action-input {
  position: relative;
}

input {
  padding-right: 1.4em;
  width: 100%;
  margin-bottom: 0;
}

input.disabled,
input.readonly {
  user-select: none;
  cursor: pointer;
}

.btn-action {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.6em;
  padding: 0 0.4em;
  line-height: 1em;
  outline: 0;
  border: 0;
  cursor: pointer;
}
</style>
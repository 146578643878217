<template lang="pug">
#page
  .resend-confirmation.sheet
    h1 {{ $t('.heading.expired') }}

    form(ref="form" action="/" @submit.prevent="submit")
      .row
        .twelve.columns
          input-field(
            v-model="email"
            name="email"
            type="email"
            :label="$t('.instructions')"
            :placeholder="$t('.placeholder.email')"
            :error="errors?.email?.message"
          )

      .row
        .twelve.columns
          button.button-primary.u-full-width(
            type="submit" :disabled="submitting"
          )
            | {{ submitBtnText }}
</template>

<script>
import objects from "lib/objects"

export default {
  props: {
    redirect: { type: String, required: true },
    url: { type: String, required: true }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "resendConfirmation",

      submitting: false,
      submitted: false,

      // form
      errors: {},
      resendConfirmation: this.$route && this.$route.query.resend_confirmation,
      email: null
    }
  },

  computed: {
    submitBtnText() {
      return this.$t(`.button.${this.submitting ? 'submitting' : 'submit'}`)
    }
  },

  methods: {
    submit() {
      const formData = new FormData(this.$refs.form)
      this.submitting = true

      this.$http
        .put(this.url, formData)
        .then(() => {
          this.errors = {}
          this.$notifications.clear()
          this.$notifications.info(this.$t(".notifications.submit.success"))

          this.submitted = true

          this.$router.replace({ path: '/' })
        })
        .catch((err) => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))

          this.errors = objects.camelize(
            objects.dig(err, "response", "data", "errors") || {}
          )
        })
        .then(() => {
          this.submitting = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
#page {
  background-color: $grey-color-high-light;
}

.error {
  background: inherit;
  border: none;
  text-align: start;
  padding: none;
}

.sheet {
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  width: 340px;
  font-weight: 300;
  font-size: 16px;

  h1 {
    font-size: 28px;
  }
}
</style>

<template lang="pug">
.page
  navbar

  section.first
    .content.animation.left.animated
      p.subtitle {{ $t('.subTitle') }}

      h2 {{ $t('.title') }}

      p.description {{ $t('.description') }}

      form.contact(ref="form" action="/" @submit.prevent="submit")
        input-field(
          type="text"
          v-model="contact.name"
          name="contact[name]"
          :label="$t('.form.name')"
          :placeholder="$t('.placeholder.name')"
          :error="errors.name"
        )

        email-field(
          type="email"
          v-model="contact.email"
          name="contact[email]"
          :label="$t('.form.email')"
          :placeholder="$t('.placeholder.email')"
          :error="errors.email"
        )

        phone-field(
          name="contact[phone]"
          :label="$t('.form.phone')"
          :error="errors.phone"
        )

        textarea-field(
          type="text"
          name="contact[content]"
          :rows="5"
          v-model="contact.content"
          :label="$t('.form.content')"
          :placeholder="$t('.placeholder.content')"
          :error="errors.content"
        )

        button.button-primary(type="submit" :disabled="submiting")
          | {{ $t(`.button.${submiting ? 'submiting' : 'submit'}`) }}

    .img.animation.right.animated

  section.second
    .infos
      .info.animation.bottom(v-for="({ icon, url }, key) in infos")
        .img-container
          img(:src="mediaPath(icon)")

        .content
          h4 {{ $t(`.infos.${key}.title`) }}

          p {{ $t(`.infos.${key}.description`) }}

        a(:href="url" target="_blank") {{ $t(`.infos.${key}.info`) }}

  Footer
</template>

<script>
import objects from "lib/objects"

import Navbar from "./components/navbar.vue"
import Footer from "./components/footer.vue"

export default {
  components: { Navbar, Footer },

  data() {
    return {
      // i18n
      defaultI18nScope: "contact",

      submiting: false,
      errors: {},

      contact: {
        phone: null,
      },

      infos: {
        email: {
          icon: "email.svg",
          url: `mailto:${this.$env.VUE_APP_EMAIL}`
        },
        phone: {
          icon: "phone.svg",
          url: this.$env.VUE_APP_WHATSAPP_LINK
        },
        office: {
          icon: "map.svg",
          url: "https://maps.app.goo.gl/PMjkVEz7jTqzo4B68"
        }
      }
    }
  },

  mounted() {
    window.addEventListener("scroll", this.animateElements)
  },

  methods: {
    submit() {
      const formData = new FormData(this.$refs.form)
      this.submiting = true

      this.$http
        .post("/contact/message", formData)
        .then(() => {
          this.errors = {}
          this.$notifications.clear()

          this.contact = {}

          this.$notifications.info(this.$t(".notifications.submit.success"))
        })
        .catch((err) => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))

          let errors = objects.camelize(
            objects.dig(err, "response", "data", "errors") || {}
          )
          this.errors = app.i18n.errify(errors, { model: "contact" })
        })
        .finally(() => {
          this.submiting = false
        })
    },

    animateElements() {
      const elements = document.querySelectorAll(".animation")

      elements.forEach((element) => {
        const windowHeight = window.innerHeight
        const elementTop = element.getBoundingClientRect().top
        const elementVisible = 50

        if (
          elementTop < (windowHeight - elementVisible) &&
          !element.classList.contains('animated')
        ) {
          element.classList.add("animated")
        }
      })
    },

    mediaPath(file) {
      return require(`@/assets/images/contact/${file}`)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/static_pages/section_padding";

$primary-high-light-color: #EEE5FB;
$primary-light-color: #A575EC;
$primary-color: #8E4EC5;
$primary-dark-color: #452763;
$yellow-color: #FFE040;

.animation {
  opacity: 0;

  &.animated {
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 1.5s;

    &.left {
      animation-name: moveInLeft;
    }

    &.right {
      animation-name: moveInRight;
    }

    &.bottom {
      animation-name: moveInBottom;
    }

    &.top {
      animation-name: moveInTop;
    }
  }
}

section {
  width: 100%;
  padding: 12rem 6rem;
}

h2, h4, p, a {
  font-family: 'Open Sans';
  color: $primary-dark-color;
}

a {
  text-decoration: underline;
}

section.first {
  background-color: $primary-high-light-color;
  padding-top: 18rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8rem;

  .content {
    .subtitle {
      font-size: 1.6rem;
      font-weight: 600;
      color: $primary-dark-color;

      margin-bottom: 1.6rem;
    }

    h2 {
      font-size: 4.8rem;
      font-weight: 800;
      color: $primary-dark-color;

      margin-bottom: 2.4rem;
    }

    .description {
      font-size: 1.8rem;
      font-weight: 400;

      margin-bottom: 3.2rem;
    }

    button {
      margin: 0;
    }
  }

  .img {
    background-image: url('~@/assets/images/contact/first-section.webp');
    background-size: cover;
    background-position: 10% 40%;
  }
}

section.second {
  background-color: $white-color;

  .infos {
    width: 100%;
    grid-column: span 2;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4.8rem;

    .info {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;

      img {
        height: 4rem;
        width: 4rem;
      }

      p, a {
        margin-bottom: 0;
        font-size: 1.6rem;
        font-weight: 400;
      }

      h4 {
        margin-bottom: 1.6rem;
        font-size: 3.2rem;
        font-weight: 800;
      }
    }
  }
}

@media only screen and (max-width: 930px) {
  section {
    padding: 6.4rem 2rem;
  }

  section.first {
    padding-top: 12rem;

    grid-template-columns: auto;

    .img {
      // background-size: contain;
      width: 100%;
      height: 33.5rem;
    }
  }

  section.second {
    .infos {
      grid-template-columns: auto;

      .info {
        gap: 2rem;

        h4 {
          margin-bottom: 1.2rem;
          font-size: 2.4rem;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
<template lang="pug">
.field.select-field(:class="{ error, disabled }")
  label(v-if="!hideLabel", :for="inputId", :class="{ 'required': required }")
    | {{ $t('.label') }}

  select2(
    :id="inputId",
    :disabled="disabled",
    :name="name",
    :placeholder="thePlaceholder",
    type="integer",
    :value="modelValue",
    :include-blank="true",
    :hide-search="false",
    :required="required",
    @input="(val) => { $emit('update:modelValue', val) }",
    :url="url || '/admins/contracts.json'",
    :dataFormatting="dataFormatting",
    :processResults="processResults"
  )
    slot
  input-error(:message="error")
</template>

<script>
import vueAuthenticate from "lib/vue-authenticate"
import SelectField from "src/components/desktop/select-field.vue"

export default {
  mixins: [vueAuthenticate],
  extends: SelectField,

  data() {
    return {
      defaultI18nScope: "admin.components.contractField"
    }
  },

  props: {
    url: { type: String, required: false}
  },

  computed: {
    thePlaceholder() {
      if (this.fetching) { return this.$t("loading") }

      return this.placeholder || this.$t("options.blank")
    }
  },

  methods: {
    processResults (response) {
      const body = response.data
      const contracts = body.data
      const pagination = body.pagination || {}

      return {
        results: contracts.map((contract) => (
          { id: contract.id, text: contract.code }
        )),
        pagination: {
          'more': !!pagination.next
        }
      };
    },

    dataFormatting (params) {
      return { page: params.page || 1, code: params.term }
    }
  },
}
</script>

<style scoped lang="scss">
label.required::after {
  content: ' *';
  color: $primary-color
}
</style>

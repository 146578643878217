<!-- TODO Remover dependencia do tamanho do footer -->
<!-- XXX Ao editar este arquivo, devemos pegar a nova altura do componente -->
<!-- para atualizar a variavel $footer-height em desktop/layout/_variables.scss -->
<template lang="pug">
footer
  .trademark-content
    .logo
      img.image(src="@/assets/images/logo-gray.svg")
      span.trademark {{ this.getTrademark }}

    ol.links
      li
        a.link(target="blank", :href="$env.VUE_APP_WHATSAPP_LINK")
          i.icon.whatsapp.fa.fa-whatsapp
      li
        a.link(target="blank", href="https://www.facebook.com/alpop.aluguel")
          i.icon.facebook.fa.fa-facebook-f
      li
        a.link(target="blank", href="https://www.instagram.com/alpop.aluguel")
          i.icon.instagram.fa.fa-instagram
      li
        a.link(target="blank", href="https://www.twitter.com/alpop_aluguel")
          i.icon.twitter.fa.fa-twitter
</template>

<script>
export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "home.footer",
    }
  },

  computed: {
    getTrademark() {
      const year = new Date().getFullYear()

      return `© 2018 - ${year}, Alpop`
    }
  }
}
</script>

<style scoped lang="scss">
footer {
  background-color: $grey-color-light;
  padding: 20px 42px;
}

.logo {
  list-style-type: none;
  display: inline-block;
  vertical-align: middle;
  width: 240px;
  margin: 0;
  padding: 0;
  overflow: hidden;

  .image {
    vertical-align: middle;
    margin-right: 8px;
    height: 26px;
  }

  .trademark {
    vertical-align: middle;
    font-size: 14px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    color: $second-color-light;
    font-weight: 300;
  }
}

.links {
  list-style-type: none;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  overflow: hidden;
  float: right;

  > li {
    float: left;
    padding-left: 16px;
    margin-bottom: 0;
  }

  .link {
    font-size: 13px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    color: $second-color-light;
    font-weight: 300;
  }

  .icon {
    font-size: 26px;
    color: $second-color-light;
  }

  .link,
  .link:active,
  .link:visited {
    text-decoration: none;
  }

  .link:hover {
    color: $primary-color;
  }

  i:hover {
    color: $primary-color;
  }
}

hr {
  margin-top: 16px;
  margin-bottom: 24px;
  border-width: 0;
  border-top: 2px solid $grey-color;
}
</style>
const currencyFormatter = {
  format(value) {
    const numberFormat = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return numberFormat.format(value)
  },
}

export default currencyFormatter

<template lang="pug">
.block-list
  step-info(
    :color="approved ? 'green' : 'red'",
    :info="$t(`.approved.${approved}`)"
  )
</template>
<script>
import StepInfo from './format/step-info.vue'

export default {
  components: { StepInfo },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.scoreReports.scoreCard.scoreSteps.details.blockList",
    }
  },

  props: {
    data: { type: Object, default: () => ({}) }
  },

  computed: {
    approved() {
      return this.data.disapprovalSituation != 'blocked_cpf'
    }
  }
}
</script>

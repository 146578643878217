<template lang="pug">
Navbar(
  :items="items"
  :currentPage="currentPage"
)
</template>
<script>
import Navbar from "../../components/navbar.vue"

export default {
  components: { Navbar },

  props: {
    currentPage: { type: String, default: '' }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.components.navbar"
    }
  },

  computed: {
    items() {
      return [
        {
          routeName: 'adminManagementIndex',
          title: this.$t('.adminManagementIndex')
        }
      ]
    }
  }
}
</script>
<template lang="pug">
.file-modal
  button.open-modal.button-blue(
    :disabled="disabled",
    @click="openModal"
  )
    slot

  Modal(v-model="isShow", :close="close")
    .modal
      h1.title {{ $t(".title") }}

      .file-container
        .file
          h3.file-name {{ fileName || "-" }}

          a(target="_blank", :href="downloadUrl")
            i.fa.fa-download
            | {{ $t(".download") }}

        .info-container
          .info
            i.fa.fa-file-o
            p.info-content {{ fileSize || "-" }}

          .info
            i.fa.fa-calendar-o
            p.info-content {{ createdAt || "-" }}

          .info
            i.fa.fa-user-o
            p.info-content {{ author || "-" }}

      .errors(:class="errorLogs && errorLogs.length > 0 || 'hasntError'")
        h3.title {{ $t(".errors.title") }}

        .table-section
          .table-container(@scroll="autoLoad")
            table
              thead
                tr
                  th {{ $t(".errors.line") }}
                  th {{ $t(".errors.message") }}

              tbody
                tr(v-for="error in errorLogs")
                  td.line {{ error.line || "-" }}
                  td.message {{ error.message || "-" }}

      .buttons-container
        button.close(@click="close") {{ $t(".close") }}
</template>
<script>
import { ref } from 'vue'

export default {
  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.components.cnab.fileModal",
    }
  },

  props: {
    disabled: { type: Boolean, default: false },
    author: { type: String, required: true },
    fileSize: { type: String, required: true },
    fileName: { type: String, required: true },
    errorLogs: { type: Array, required: false },
    createdAt: { type: String, required: true },
    downloadUrl: { type: String, required: true }
  },

  methods: {
    openModal(event) {
      event.preventDefault()
      this.showModal()
    },

    close(event) {
      event.preventDefault()
      this.closeModal()
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/styles/base/tables.scss";

.open-modal {
  width: 3.8rem;
  padding: 0;
  margin: 0;
}

.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  min-width: fit-content;
  width: 90%;
  height: fit-content;

  .title {
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
    color: $primary-color;
    margin-bottom: 1rem;
  }

  .file-container {
    background-color: $white-color-dark;
    width: 100%;
    border: 1px solid $grey-color;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 2rem;

    .file {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1rem;
      border-bottom: 1px solid $grey-color;
      margin-bottom: 1rem;

      .file-name {
        font-size: 1.8rem;
        font-weight: 500;
        margin: 0;
      }

      a {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-size: 1.8rem;
        font-weight: 600;
        text-decoration: none;
      }
    }

    .info-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      .info {
        white-space: nowrap;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        color: $second-color-light;

        .info-content {
          margin: 0
        }
      }
    }
  }

  .errors {
    width: 100%;

    &.hasntError {
      visibility: hidden;
    }

    .title {
      font-size: 1.8rem;
      font-weight: 500;
      border-bottom: 1px solid $primary-color;
    }

    .table-container {
      height: 60vh;

      @media (orientation: portrait) {
        height: 40vh;
      }

      td {
        min-width: fit-content;
        max-width: 50rem;
        align-items: center;
        white-space: normal;

        &.line {
          width: 7rem;
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 4rem;

    button {
      margin-bottom: 0;
    }
  }
}
</style>

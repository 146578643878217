
import BadDebit from '../views/marketing/bad-debit.vue'

const routes = [
  {
    path: '/campanhas/diga-adeus-a-inadimplencia',
    name: 'badDebit',
    component: BadDebit
  }
]

export default routes

<template lang="pug">
.property-section
  .inline-section
    .three-columns
      h3.info-title {{ attributeTranslate('propertyType') }}

      .loading-content(v-if="loading")

      editable-select-field(
        v-else-if="contract.editable"
        name="property[property_type]"
        :v-model="contract.propertyAttributes.propertyType"
        :value="contract.propertyAttributes.propertyType"
        :options="propertyTypeOptions"
        :path="propertyPath"
        :callback="onUpdate"
      )

      p.info-content(v-else)
        | {{ propertyTypeTranslate(contract.propertyAttributes.propertyType) }}

    .three-columns
      h3.info-title {{ attributeTranslate('zipCode') }}

      .loading-content(v-if="loading")

      p.info-content(
        v-else-if="!editZipCode"
        :class="{ clickable: contract.editable }"
        @click="contract.editable ? editZipCode = true : null"
      )
        | {{ contract.propertyAttributes.zipCode || '-' }}

      .edit-container(v-else)
        form(ref="form" action="/" @submit.prevent="submitAddress")
          input-field(
            :modelValue="contract.propertyAttributes.zipCode"
            @input="getAddress"
            mask="#####-###"
            hideLabel
          )

          .buttons
            button.button-primary(
              type="submit"
              :class="submitting && 'button-progress'"
              :disabled="waitingZipCodeInput || submitting"
            )
              i.fa.fa-check

            button.button-primary.close(
              type="button"
              :disabled="submitting"
              @click="resetAddressValues"
            )
              i.fa.fa-times

    .three-columns
      h3.info-title {{ attributeTranslate('state') }}

      .loading-content(v-if="loading")

      p.info-content(v-else)
        | {{ address.state || contract.propertyAttributes.state || '-' }}

  .inline-section
    .three-columns
      h3.info-title {{ attributeTranslate('city') }}

      .loading-content(v-if="loading")

      p.info-content(v-else)
        | {{ address.city || contract.propertyAttributes.city || '-' }}

    .three-columns
      h3.info-title {{ attributeTranslate('neighborhood') }}

      .loading-content(v-if="loading")

      p.info-content(v-else)
        | {{ address.neighborhood || contract.propertyAttributes.neighborhood || '-' }}

    .three-columns
      h3.info-title {{ attributeTranslate('street') }}

      .loading-content(v-if="loading")

      p.info-content(v-else)
        | {{ address.street || contract.propertyAttributes.street || '-' }}

  .inline-section
    .three-columns
      h3.info-title {{ attributeTranslate('number') }}

      .loading-content(v-if="loading")

      editable-input-field(
        v-if="contract.editable"
        ref="propertyNumberField"
        name="property[number]"
        :value="contract.propertyAttributes.number"
        :path="propertyPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) {{ contract.propertyAttributes.number || '-'}}

    .three-columns
      h3.info-title {{ attributeTranslate('complement') }}

      .loading-content(v-if="loading")

      editable-input-field(
        v-if="contract.editable"
        name="property[complement]"
        :value="contract.propertyAttributes.complement"
        :path="propertyPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) {{ contract.propertyAttributes.complement || '-' }}

    .three-columns
</template>

<script>
import cep from 'cep-promise'
import objects from "lib/objects"

import EditableSelectField from "../../../../../../components/desktop/editable-fields/editable-select-field.vue"
import EditableInputField from "../../../../../../components/desktop/editable-fields/editable-input-field.vue"

export default {
  components: { EditableSelectField, EditableInputField },

  data() {
    return {
      address: {
        zipCode: null,
        state: null,
        neighborhood: null,
        city: null,
        street: null
      },

      editZipCode: false,
      waitingZipCodeInput: true,
      submitting: false
    }
  },

  props: {
    contract: { type: Object, required: true },
    contractPath: { type: String, required: true },
    loading: { type: Boolean, default: false },
    onUpdate: { type: Function, default: () => {} }
  },

  computed: {
    propertyPath() {
      return `${this.contractPath}/property`
    },

    propertyTypeOptions() {
      const propertyTypes = [
        'house', 'condominium_house', 'commercial', 'apartment', 'roof_top',
        'flat'
      ]

      return propertyTypes.map(propertyType => ({
        id: propertyType,
        text: this.propertyTypeTranslate(propertyType)
      }))
    }
  },

  methods: {
    attributeTranslate(path) {
      return this.$t(`models.contract.attributes.${path}`)
    },

    propertyTypeTranslate(type) {
      if (type) return this.attributeTranslate(`propertyTypeOptions.${type}`)

      return '-'
    },

    editPropertyNumber() {
      this.$refs.propertyNumberField.editingDisabled = false
      this.$refs.propertyNumberField.$el.querySelector('input').focus()
    },

    resetAddressValues() {
      this.address = {
        state: null,
        neighborhood: null,
        city: null,
        street: null,
        zipCode: null
      }

      this.editZipCode = false
      this.waitingZipCodeInput = true
    },

    getAddress(value) {
      let zipCodeValue = value.target.value.replace('-', '')

      if (zipCodeValue.length < 8) return this.waitingZipCodeInput = true

      this.submitting = true

      cep(zipCodeValue)
        .then((result) => {
          this.address = {
            state: result.state,
            neighborhood: result.neighborhood,
            city: result.city,
            street: result.street,
            zipCode: value.target.value
          }

          this.waitingZipCodeInput = false
        })
        .finally(() => {
          this.submitting = false
        })
    },

    submitAddress() {
      this.submitting = true

      const updateParams = { property: objects.snakeize(this.address) }

      this.$http
        .put(this.propertyPath, updateParams)
        .then(() => {
          this.errors = {}
          this.$notifications.clear()

          this.editPropertyNumber()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))

          this.resetAddressValues()
        })
        .finally(() => {
          this.editZipCode = false
          this.submitting = false

          this.onUpdate()
        })
    }
  }
}
</script>
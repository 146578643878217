<template lang="pug">
.landlord-section
  input-field(
    type="text"
    name="contract[landlord_attributes][name]"
    :placeholder="$t('.placeholder.landlordName')"
    :error="errors?.landlordName?.message"
    v-model="landlordName"
    required
  )

  cpf-cnpj-field(
    name="contract[landlord_attributes][cpf_cnpj]"
    :placeholder="$t('.placeholder.landlordCpfCnpj')"
    :error="errors?.landlordCpfCnpj?.message"
    v-model="landlordCpfCnpj"
    required
  )

  select-field(
    v-if="landlordTransferAllowed"
    :label="$t('.label.transferToLandlord')"
    name="contract[transfer_to]"
    :options="transferToOptions"
    v-model="transferTo"
  )

  template(v-if="landlordTransferAllowed && transferTo === 'landlord'")
    input-field(
      type="text"
      name="contract[landlord_attributes][bank_account_attributes][agency]"
      :label="$t('.label.landlordBankAccountAgency')"
      :placeholder="$t('.placeholder.landlordBankAccountAgency')"
      :error="errors?.landlordBankAccountAgency?.message"
      v-model="landlordBankAccountAgency"
      required
    )

    input-field(
      type="text"
      name="contract[landlord_attributes][bank_account_attributes][account]"
      :label="$t('.label.landlordBankAccountAccount')"
      :placeholder="$t('.placeholder.landlordBankAccountAccount')"
      :error="errors?.landlordBankAccountAccount?.message"
      v-model="landlordBankAccountAccount"
      required
    )

    input-field(
      type="text"
      name="contract[landlord_attributes][bank_account_attributes][code]"
      :label="$t('.label.landlordBankAccountCode')"
      :placeholder="$t('.placeholder.landlordBankAccountCode')"
      :error="errors?.landlordBankAccountCode?.message"
      v-model="landlordBankAccountCode"
      required
    )

    input-field(
      type="text"
      name="contract[landlord_attributes][bank_account_attributes][pix]"
      :label="$t('.label.landlordBankAccountPix')"
      :placeholder="$t('.placeholder.landlordBankAccountPix')"
      :error="errors?.landlordBankAccountPix?.message"
      v-model="landlordBankAccountPix"
    )
</template>

<script>
import CpfCnpjField from '../../../../../../../components/phone/cpf-cnpj-field.vue'

export default {
  components: { CpfCnpjField },

  data() {
    return {
      // i18n
      defaultI18nScope: 'realEstate.management.contract.new',

      // form fields data
      landlordName: null,
      landlordCpfCnpj: null,
      transferTo: 'real_estate',
      landlordBankAccountAgency: null,
      landlordBankAccountAccount: null,
      landlordBankAccountCode: null,
      landlordBankAccountPix: null,

      transferToOptions: [
        { id: 'real_estate', text: this.$t('false') },
        { id: 'landlord', text: this.$t('true') }
      ]
    }
  },

  props: {
    errors: { type: Object, default: () => ({}) },
    landlordTransferAllowed: { type: Boolean, default: false }
  }
}
</script>

<template lang="pug">
button.button-primary(@click="addInstallment()") {{ $t('.addInstallment') }}

.table-section
  .table-container
    table
      thead
        tr
          th(v-for="name in columnNames")
            p {{ $t(`.table.${name}`) }}

      tbody
        tr(v-for="(installment, index) in installments" :key="index")
          td.due-date
            date-field(
              :hideLabel="true",
              v-model="installment.dueDate"
            )

          td.value
            currency-field(
              :hideLabel="true",
              v-model="installment.value",
              @input="$emit('update:hasChanged', true)"
            )

          td.actions
            button.button-red(@click="removeInstallment(index)")
              i.fa.fa-trash

      tfoot
        td {{ $t('.table.total') }}
        td {{ valueFormat(installmentsValue) }}
        td

.difference(v-if="differenceValue != 0") {{ $t('.total', differenceParams) }}
</template>

<script>
import currencyFormatter from 'lib/currency-formatter'

export default {
  data() {
    return {
      // i18n
      defaultI18nScope: 'admin.agreements.createModal.installments',
    }
  },

  beforeMount() {
    if (this.installmentsAreEmpty) {
      this.$emit('update:hasChanged', false)

      this.addInstallment()
    }
  },

  props: {
    installments: { type: Array, required: true },
    installmentsValue: { type: Number, required: true },
    itemsValue: { type: Number, required: true },
    differenceValue: { type: Number, required: true },
    hasChanged: { type: Boolean, required: true }
  },

  computed: {
    columnNames() {
      return ['dueDate', 'value', 'actions']
    },

    differenceParams() {
      return {
        differenceValue: this.valueFormat(this.differenceValue),
        itemsValue: this.valueFormat(this.itemsValue)
      }
    },

    installmentsAreEmpty() {
      return this.installments.length == 0
    },

    defaultDueDate() {
      const date = new Date().getDate()
      const thirty_days_ahead = new Date().setDate(date + 30)

      return new Date(thirty_days_ahead)
    },

    generateDueDate() {
      if (this.installmentsAreEmpty) return this.defaultDueDate

      const [lastInstallment] = this.installments.slice(-1)
      const lastDueDate = lastInstallment.dueDate

      if (!lastDueDate) return this.defaultDueDate

      return new Date(
        lastDueDate.getFullYear(),
        lastDueDate.getMonth() + 1,
        lastDueDate.getDate()
      )
    }
  },

  methods: {
    valueFormat(value) {
      return value ? currencyFormatter.format(value) : 'R$ 0,00'
    },

    addInstallment() {
      this.installments.push({ dueDate: this.generateDueDate, value: 0 })

      this.updateValuesIfUnchanged()
    },

    removeInstallment(index) {
      this.installments.splice(index, 1)

      this.installmentsAreEmpty && this.$emit('update:hasChanged', false)

      this.updateValuesIfUnchanged()
    },

    updateValuesIfUnchanged() {
      if (!this.hasChanged) {
        const valuePerInstallment = parseFloat(
          Math.round((this.itemsValue / this.installments.length * 100) / 100)
        )

        this.installments.forEach(installment =>
          installment.value = valuePerInstallment
        )
      }
    }
  }
}
</script>

<style scoped lang="scss">
.table-container {
  height: auto !important;
  max-height: 40vh;

  table {
    tbody {
      tr {
        height: 5rem !important;
      }
    }

    td {
      text-transform: capitalize !important;

      &.due-date, &.value {
        min-width: fit-content;
        width: 50%;
      }

      &.actions {
        min-width: fit-content;

        button {
          margin: 0;
          width: 3.8rem;
          padding: 0;
        }

        i {
          font-size: 1.8rem;
        }
      }

      .date-field, .currency-field {
        margin: 0;
      }
    }

    .disabled-row {
      td {
        background-color: $white-color-dark;
        color: $grey-color-light;
      }
    }
  }
}

.difference {
  margin-top: 1rem;
  padding: 1rem 2rem;
  border-radius: 4px;
  border: 1px solid;
  font-family: Lato;

  background-color: $alert-yellow-background;
  border-color: $alert-yellow-border;
  color: $alert-yellow-text;
}
</style>

<template lang="pug">
modal(:show="show" :onClose="closeModal")
  .report-container
    h1 {{ $t('.title') }}
    p {{ $t('.description') }}

    progress-bar(:loaded="report.status == 'done'")

    .buttons-container
      a.button.reportPage(
        v-if="reportPageUrl"
        :href="reportPageUrl"
        target="_blank"
      )
        | {{ $t(".buttons.accessReportPage") }}

      button.button.button-primary(
        @click="downloadReport" :disabled="!report.fileUrl"
      )
        | {{ $t(".buttons.download") }}
</template>

<script>
import Modal from "./modal.vue"
import ProgressBar from "./progress-bar.vue"

import objects from "lib/objects"

export default {
  components: {
    Modal,
    ProgressBar
  },

  props: {
    show: { type: Boolean, required: true },
    reportPageUrl: { type: String },
    reportApiUrl: { type: String, required: true },
    callback: { type: Function, default: () => {} }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "reportModal",
      report: {},
      fetchInterval: null
    }
  },

  updated() {
    if (this.fetchInterval || !this.show) return

    this.fetchInterval = setInterval(() => {
      this.fetch()
    }, 2000)
  },

  methods: {
    init(data) {
      this.report = objects.camelize(data)

      if (this.report.status == "done") {
        clearInterval(this.fetchInterval)
      }
    },

    fetch() {
      this.$http.get(this.reportApiUrl).then(response => {
        this.init(response.data.data)
      })
    },

    downloadReport() {
      if (this.report.fileUrl) {
        window.open(this.report.fileUrl, "_blank")

        this.closeModal()
      }
    },

    closeModal() {
      this.fetchInterval = null
      this.report = {}

      this.callback()
    }
  }
}
</script>

<style lang="scss" scoped>
  .report-container {
    width: 100%;

    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
      margin: 0;
      text-transform: uppercase;
    }

    p {
      margin-top: 0.5rem;
      font-size: 1.5rem;
      color: $second-color-light;
      overflow-wrap: break-word;
      white-space: pre-line;
    }

    .buttons-container {
      margin-top: 8rem;
      display: flex;
      width: 100%;
      justify-content: flex-end;

      .reportPage {
        margin-right: 1rem;
      }
    }
  }
</style>
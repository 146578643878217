<template lang="pug">
#page
  navbar

  resend-confirmation(
    :redirect="redirect",
    url="/real_estate/user/resend_confirmation",
    model="real_estate_user"
  )
</template>

<script>
import Navbar from "../../components/navbar.vue";
import ResendConfirmation from "../../tools/resend-confirmation.vue";

export default {
  components: { Navbar, ResendConfirmation },

  data() {
    return {
      redirect: "/imobiliaria/reenviar-confirmacao"
    }
  }
};
</script>

<style scoped lang="scss">
#page {
  background-color: $grey-color-high-light;
}
</style>
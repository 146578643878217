<template lang="pug">
.expansible-content(:class="isExpanded && 'expanded'")
  .title(@click="toggle")
    h1(v-if="title") {{ title }}

    i.fa(:class="isExpanded ? 'fa-angle-down' : 'fa-angle-up'")

  .expansible
    slot

</template>
<script>
export default {
  props: {
    title: { type: String, required: true },
    expanded: { type: Boolean, default: false },
  },

  data() {
    return {
      isExpanded: this.expanded
    }
  },

  watch: {
    isExpanded() {
      if (this.isExpanded) {
        this.$emit('expanding')
      }
    }
  },

  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded
    }
  }
}
</script>
<style lang="scss" scoped>
.expansible-content {
  width: 100%;

  h1 {
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
    color: $primary-color;
    margin-bottom: 1rem;
  }

  .expansible {
    display: none;
  }

  &.expanded {
    max-height: 100%;

    .expansible {
      display: block;
    }
  }

  .title {
    display: flex;
    margin-bottom: 1rem;
    border-bottom: 1px solid $primary-color;
    justify-content: space-between;
    cursor: pointer;

    i {
      font-size: 2.5rem;
      color: $primary-color;
    }
  }
}
</style>
import objects from 'lib/objects'


function buildContainer() {
  let container = document.createElement('pre')
  container.classList.add('http-logger')
  container.style.backgroundColor = '#FDF8E4'
  container.style.border = '2px solid #FBF2CE'
  container.style.minHeight = '100px'
  container.style.padding = '12px 20px'
  container.style.fontSize = '12px'
  container.style.marginBottom = '0'
  container.style.marginTop = '40px'

  document.body.appendChild(container)
  // iniciando com uma quebra de linha para o HTML ficar mais legível
  container.textContent = "\n\n"

  return container
}


class HTMLLogger {
  constructor() {
    this.container = buildContainer()
  }

  log(message, prefix = '') {
    // let entry = document.createElement('label')
    // entry.style.whiteSpace = 'pre'
    let text = `${prefix} ${message}`.trim()

    this.container.textContent += `${text}\n\n`

    // entry.textContent = content
    // this.container.appendChild(entry)
  }

  error(message, prefix = '') {
    this.log(message, prefix)
  }
}



// inicializando o logger
// ---

const logger = new HTMLLogger()

// criando interceptor para XHRs
const router = app.http.router

router.interceptors.request.use(
  (config) => {
    let message = `${config.method.toUpperCase()} ${config.url}`
    logger.log(message)

    return config
  },

  (error) => {
    let prefix = null
    let message = `${error.name}: ${error.message}`

    if (error.config) {
      prefix = `(${error.config.method.toUpperCase()} ${error.config.url})`
    }

    logger.error(message, prefix)

    return Promise.reject(error)
  }
)

router.interceptors.response.use(
  (response) => {
    let prefix = `(${response.config.method.toUpperCase()} ${response.config.url})`
    let message = `${response.status} ${response.statusText}`

    logger.log(message, prefix)

    return response
  },

  (error) => {
    let prefix = null
    let data = objects.dig(error, 'response', 'data')

    let message = `${error.name}: ${error.message}`
    if (data) {
      message += `\n    - data: ${typeof data === 'object' ? JSON.stringify(data) : data}`
    }

    if (error.config) {
      prefix = `(${error.config.method.toUpperCase()} ${error.config.url})`
    }

    logger.error(message, prefix)

    return Promise.reject(error)
  }
)



// criando hooks para VueRouter

// HACK como o app.router só é atachado nas apps, temos que "esperar" por isso.
function attachToRouter() {
  if (!app.router) return setTimeout(attachToRouter, 100)

  app.router.afterEach((to, from) => {
    logger.log(`
      VueRouter
        - from: ${from.fullPath}
        - to:   ${to.fullPath}
    `)
  })
}

attachToRouter()

<doc lang="markdown">
  Componente para campos de arquivos (`input` com type `file`) em formulários, já derivando `label`
  e mensagem de `erro` (para validações).

  Properties
  ----
    - destroyable - `Boolean` Define se deve mostrar o botão para remover arquivo.
    - accept      - Tipos permitidos de arquivos a serem adicionados
    - value       - É o nome do arquivo.
    - downloadUrl - Link para download caso exista um arquivo

  IMPORTANT sabemos que o arquivo existe (está persistido no servidor) pela property `downloadUrl`.

  Remoção de arquivo
  ----
    Temos um _hidden input_ do tipo `boolean` que informa se o arquivo deve ser excluído
    ou não do servidor, seguindo as definições do CarrierWave. Esta _flag_ é marcada ao clicar em
    remover algum arquivo que tenha vindo do servidor (tenha um downloadUrl),

  # Exemplo de uso:
  ## básico
  ```pug
  file-field(
    name="model[document]",
    v-model="document"
  )
  ```

  ## avançado
  ```pug
  file-field(
    name="model[document]",
    accept='image/*,application/pdf',
    v-model="document",
    :downloadUrl="documentUrl",
    :destroyable: "true"
  )
  ```

</doc>

<template lang="pug">
.file-field(:class="{ error, disabled, readonly }")
  label(v-if="!hideLabel", :for="inputId", :class="{ 'required': required }") {{ labelText }}
  .field-container
    .content
      template(v-if="file")
        i.fa(:class="fileIconClass")
        span.value {{ file.name }}
      template(v-else)
        span.value.empty {{ $t('.empty') }}
    .toolbar
      a.button.button-primary.tooltip-bottom(
        v-if="isDownloadable",
        download,
        :href="downloadUrl",
        :aria-label="$t('.button.download.tooltip', { name: labelText })"
      )
        i.fa.fa-download

      button.button.button-primary.tooltip-bottom(
        v-if="!file",
        type="button",
        @click="choose",
        :aria-label="$t('.button.attach.tooltip')"
      )
        i.fa.fa-paperclip

      button.button.destroy.button-primary.tooltip-bottom(
        v-if="isDestroyable",
        type="button",
        @click="destroy",
        :aria-label="$t('.button.destroy.tooltip', { name: labelText })"
      )
        i.fa.fa-trash

    .hidden-inputs
      //- XXX como usamos FormData para submissões de formulários, estamos controlando a "existência"
      //- desse file input pelo atributo "disabled", evitando que campos deixados em branco, ao
      //- serem processados pelo FormData, gerem uma entrada "em branco" (arquivo binário sem conteúdo),
      //- o que causa problemas no CarrierWave do servidor!
      input(
        ref="fileInput",
        type="file",
        :id="inputId",
        :name="name",
        :accept="accept",
        :required="required",
        @change="onChange"
      )

      //- Controlando remoção do arquivo no servidor, a la "_destroy", mas seguindo a definição
      //- do CarrierWave: "remove_${attr_name}"
      input(
        v-if="markedForDestruction",
        type="hidden",
        :name="markedForDestructionAttrName",
        value="true"
      )
</template>

<script>
import InputField from "./input-field.vue"
import strings from "lib/strings"

export default {
  extends: InputField,

  props: {
    accept: String,
    downloadUrl: String,
    destroyable: { type: Boolean, default: false },
    error: { type: String },
    required: { type: Boolean, default: false },
    reset: { type: Boolean }
  },

  watch: {
    reset(reset) {
      reset === true && this.destroy() && this.$emit("reseted")
    }
  },

  data() {
    return {
      defaultI18nScope: "components.file-field",

      choosingFile: false,
      file: null,
      markedForDestruction: false,
    }
  },

  methods: {
    onChange() {
      const fileInput = this.$refs.fileInput
      const files = fileInput.files
      const file = files[0]

      if (!file) return

      this.file = file

      // Arquivo anexado. Não deve ser removido.
      this.markedForDestruction = false

      this.$emit("input", file.name)
    },

    choose() {
      this.choosingFile = true

      // XXX como estamos evitando o envio do <input type="file"> em branco, controlando o
      // atributo `disabled` do input, precisamos controlar a flag `choodingFile` - da lógica
      // do componente - mas também a propriedade do DOMElement `.disabled` para garantir
      // a sincronia do disparo do `click()`, pois senão o browser ignora esse click - e não
      // abre o diálogo de seleção de arquivo.
      //  - Por questões de segurança, o browser só "respeita" um `.click()` num elemento se ele
      //    for executado na mesma _thread_ de um event handler de um click nativo - de usuário.
      //    Por isso não podemos usar `nextTick()`, `setTimeout()`, xhr, ... e usamos o `.disabled`
      //    direto!
      const prevDisabled = this.$refs.fileInput.disabled

      this.$refs.fileInput.disabled = false

      this.$refs.fileInput.click()

      this.$refs.fileInput.disabled = prevDisabled

      this.choosingFile = false
    },

    confirmDestroy() {
      const message = this.$t(".button.destroy.confirmation", {
        name: this.labelText,
      })

      if (window.confirm(message)) this.destroy()
    },

    destroy() {
      this.file = null

      // XXX Isso é necessário para zerar o FileList do input.
      this.$refs.fileInput.type = "text"
      this.$refs.fileInput.type = "file"
      this.$refs.fileInput.value = ""

      // Se é "baixável", então está persistido no servidor.
      // Precisamos marcá-lo para remoção/destruição.
      if (this.isDownloadable) this.markedForDestruction = true

      this.$emit("input", null)
    },
  },

  computed: {
    markedForDestructionAttrName() {
      const snakeizedAttrName = strings.snakeize(this.attrName)

      if (!this.modelName) return `remove_${snakeizedAttrName}`

      const snakeizedModelName = strings.snakeize(this.modelName)

      return `${snakeizedModelName}[remove_${snakeizedAttrName}]`
    },

    fileIconClass() {
      if (isBlank(this.file.name)) return null

      const filename = this.file.name.toLowerCase()

      if (/\.pdf/.test(filename)) {
        return "fa-file-pdf-o"
      } else if (/\.(png|jpe?g|gif|bmp)/.test(filename)) {
        return "fa-file-image-o"
      }

      // default
      return "fa-file-o"
    },

    // Indica se o arquivo existe:
    // - seja persistido no servidor
    // - seja recém-anexado no client
    hasFile() {
      return !!this.file && !this.markedForDestruction
    },

    // Indica se o arquivo está recém-anexado no client!
    hasFileAttached() {
      return !!this.file
    },

    isDownloadable() {
      return !!this.downloadUrl && !this.markedForDestruction
    },

    isDestroyable() {
      return this.destroyable && this.hasFile
    },
  },
}
</script>

<style scoped lang="scss">
// TODO definir cor de erro!
$file-error-color: red;
$file-error-border-color: red;
$file-disabled-color: #ababab;
$file-disabled-border-color: #d9d6d5;

.file-field {
  width: 100%;
  margin-bottom: 1.5rem;

  label.required::after {
    content: ' *';
    color: $primary-color;
  }

  .field-container {
    width: 100%;
    height: 38px;
    padding-left: 12px;
    border: 1px solid $grey-color;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    align-items: center;

    .content {
      overflow: hidden;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      > .fa {
        font-size: 14px;
      }

      > .value {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;
        line-height: 38px;

        &.empty {
          font-style: italic;
          color: $grey-color-dark;
        }
      }
    }

    > .toolbar {
      .button {
        padding: 0 10px;
        line-height: 30px;
        height: 38px;
        margin: 0;

        &.destroy {
          background-color: $red-color;
        }

        .fa {
          font-size: 1.8rem;
          line-height: 32px;
        }
      }
    }
  }

  .hidden-inputs {
    display: none;
  }
}

// error
.file-field.error {
  label {
    color: $file-error-color;
  }

  .field-container {
    border-color: $file-error-border-color;
  }
}
</style>
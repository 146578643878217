<doc lang="markdown">
  Componente para campos assinaláveis (`checkbox`) em formulários, já derivando
`label` e mensagem de `erro` (para validações).

# Exemplo de uso:
```pug
checkbox-field(
  :id="id",
  :name="name",
  :disabled="disabled",
  :required="required"
  v-model:checked="checked",
)
```
</doc>

<template lang="pug">
.checkbox-field(:class="{ error: error && !checked, disabled, readonly }")
  .container
    input(
      type='checkbox',
      :id="id",
      :name="name",
      :checked="checked",
      :disabled="disabled",
      :required="required"
      @input="(event) => $emit('update:checked', event.target.checked)"
    )
    label.label(:for="id", :class="{ 'required': required }") {{ labelText }}
  input-error(v-if="!checked" :message="error")
</template>

<script>
import InputField from "./input-field.vue"

export default {
  extends: InputField,

  props: {
    id: { type: String, required: true },
    name: { type: String, required: true },
    checked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false }
  }
}
</script>

<style scoped lang="scss">
.checkbox-field{
  margin-bottom: 1.5rem;

  .container {
    padding: 0;
    width: auto;
    display: grid;
    grid-template-columns: 1.5rem auto;
    column-gap: 0.5em;
  }

  label {
    margin-bottom: 0;
    text-align: justify;
    font-size: 1.3rem;
    line-height: 1.8rem;

    &.required::after {
      content: ' *';
      color: #9b53d7;
    }
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    color: $primary-color;
    width: 1.3rem;
    height: 1.3rem;
    border: 0.16rem solid $primary-color;
    border-radius: 0.2rem;
    transform: translateY(0.15rem);

    display: grid;
    place-content: center;

    &::before {
      content: "";
      width: 1.3rem;
      height: 1.3rem;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em $primary-color;
      border-radius: 0.2rem;
    }

    &:checked::before {
      transform: scale(1);
    }
  }


  &.disabled {
    label {
      color: $grey-color-dark;
    }

    input[type="checkbox"] {
      border-color: $grey-color-light
    }
  }

  &.error {
    label {
      color: #ff0000;
    }

    input[type="checkbox"] {
      border-color: #ff0000;
    }
  }
}
</style>
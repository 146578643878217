<template lang="pug">
Modal(v-model="isShow",  :close="close")
  .modal
    button.close-modal(@click="close")
      i.fa.fa-close-thin(aria-hidden="true")

    .modal-slot-container
      slot
</template>
<script>

import { ref } from 'vue'

export default {
  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false

      this.onClose
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  props: {
    show: { type: Boolean, default: false },
    onClose: { type: Function, default: () => {} }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
  .modal {
    background-color: $white-color;
    border-radius: 0.7rem;
    box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
    height: fit-content;
    max-height: 85vh;
    min-width: 50%;
    max-width: 90%;
    display: flex;
    position: relative;
    padding: 2.5rem 4rem;

    .modal-slot-container {
      width: 100%;
      overflow-y: auto;
    }

    .close-modal {
      position: absolute;

      border: 0;
      outline: 0;
      padding: 0;
      margin: 0;
      right: -0.9rem;
      top: -0.9rem;
      width: 3rem;
      height: 3rem;
      border-radius: 3rem;

      background-color: $white-color;
      color: $primary-color;
      box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);

      i {
        margin-right: -0.2rem;
        margin-top: -0.5rem;
        font-size: 2.5rem;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .modal {
      max-width: 90%;
      padding: 2.5rem 3rem 2.5rem 3rem;
    }
  }
</style>

<template lang="pug">
.provenir-big-data-corp
  .buttons
    button.button-primary(
      v-for="table in Object.keys(columns)",
      :class="currentTable != table && 'button-grey'",
      @click="currentTable = table"
    )
      | {{ $t(`.${table}.button`) }}

  step-table(
    v-if="currentTable == 'financialDatum'"
    :stepName="formattedStepName",
    :tableData="financialDatumTableData",
    :columnNames="columnNames"
  )

  step-table(
    v-if="currentTable == 'relatedPerson'"
    :stepName="formattedStepName",
    :tableData="relatedPersonTableData",
    :columnNames="columnNames"
  )

  step-table(
    v-if="currentTable == 'businessRelationship'"
    :stepName="formattedStepName",
    :tableData="businessRelationshipTableData",
    :columnNames="columnNames"
  )

  step-table(
    v-if="currentTable == 'process'"
    :stepName="formattedStepName",
    :tableData="processTableData",
    :columnNames="columnNames",
    :dateColumns="dateColumns"
  )
</template>
<script>
import StepTable from './format/step-table.vue'

export default {
  components: { StepTable },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.scoreReports.scoreCard.scoreSteps.details.provenirBigDataCorp",

      //table action
      currentTable: 'financialDatum',

      // table info
      stepName: 'provenirBigDataCorp',
      columns: {
        financialDatum: ['name', 'value'],
        relatedPerson: ['name', 'cpf', 'degreeOfKinship'],
        businessRelationship: ['name', 'cpfcnpj'],
        process: [
          'lawsuitNumber', 'state', 'courtName', 'noticeDate',
          'mainSubject', 'lawsuitType', 'defendant', 'disapproved'
        ]
      },
      dateColumns: ['noticeDate']
    }
  },

  props: {
    data: { type: Object, default: () => ({}) }
  },

  computed: {
    formattedStepName() {
      return `${this.stepName}.${this.currentTable}`
    },

    currentStepData() {
      return this.data[this.currentTable] || []
    },

    columnNames() {
      return this.columns[this.currentTable] || []
    },

    financialDatumTableData() {
      const incomeEstimate = this.currentStepData.incomeEstimate

      if (!incomeEstimate) return []

      delete incomeEstimate['id']

      return Object.entries(incomeEstimate).map((item) => {
        return { name: item[0], value: item[1] }
      })
    },

    relatedPersonTableData() {
      const personalRelationships = this.currentStepData.personalRelationships

      if (!personalRelationships) return []

      return _.cloneDeep(personalRelationships).map(relationship => {
        const data = {
          ...relationship,
          degreeOfKinship: this.$t(
            `.relatedPerson.degreeOfKinship.${relationship.degreeOfKinship}`
          )
        }

        if (relationship.scoreReportId) {
          data['openData'] = {
            path: `/administracao/gerenciamento/consultas/${relationship.scoreReportId}`
          }
        }

        return data
      })
    },


    businessRelationshipTableData() {
      return this.currentStepData.items
    },

    processTableData() {
      return this.currentStepData.lawsuits
    }
  }
}
</script>
<style scoped lang="scss">
.provenir-big-data-corp {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.buttons {
  overflow-x: auto;
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  button {
    margin: 0;
  }
}
</style>

<template lang="pug">
Modal(:show="show" :close="close")
  .modal
    .info-container
      h1 {{ $t('.title') }}

      form(id="new-score-report" ref="form" action="/" @submit.prevent="submit")
        input-field(
          v-model="scoreReport.mainTenantCpf"
          name="score_report[main_tenant_cpf]"
          mask="###.###.###-##"
          :label="$t('.fields.mainTenantCpf')"
          :placeholder="$t('.placeholders.mainTenantCpf')"
          :error="errors?.mainTenantCpf?.message"
          required
        )

        input-field(
          v-model="scoreReport.mainTenantEmail"
          name="score_report[main_tenant_email]"
          type="email"
          :label="$t('.fields.mainTenantEmail')"
          :placeholder="$t('.placeholders.mainTenantEmail')"
          :error="errors?.mainTenantEmail?.message"
          required
        )

        phone-field(
          name="score_report[main_tenant_phone]"
          :label="$t('.fields.mainTenantPhone')"
          :placeholder="$t('.placeholders.mainTenantPhone')"
          :error="errors?.mainTenantPhone?.message"
          required
        )

        input-field(
          v-model="scoreReport.mainTenantZipCode"
          name="score_report[main_tenant_zip_code]"
          :placeholder="$t('.placeholders.mainTenantZipCode')"
          :label="$t('.fields.mainTenantZipCode')"
          v-maska="'#####-###'"
          :error="errors?.mainTenantZipCode?.message"
          required
        )

        h3 {{ $t('.menu.otherInfos') }}

        cpfs-field(
          name="score_report[cpfs]"
          :label="$t('.fields.cpf.label')"
          :cpfs="scoreReport.cpfs"
          :error="errors?.cpfs?.message"
          :placeholder="$t('.placeholders.cpfs')"
          @change="onCpfFieldChange"
        )

        select-field(
          name="score_report[property_type]"
          :value="scoreReport.propertyType"
          :options="propertyTypeOptions"
          :error="errors?.propertyType?.message"
          required
        )

        checkbox-field(
          id="score_report[responsibility_term]"
          name="score_report[responsibility_term]"
          :label="$t('.fields.responsibilityTerm', { name: userName })"
          :error="errors?.responsibilityTerm?.message"
          v-model:checked="responsibilityTerm"
          required
        )

    .buttons-container
      button.close(@click="close") {{ $t(".buttons.close") }}

      button.button-primary.confirm(
        form="new-score-report"
        type="submit"
        :disabled="!responsibilityTerm || submitting || writting"
      )
        | {{ btnSubmitText }}
</template>

<script>
import vueAuthenticate from "lib/vue-authenticate"
import objects from "lib/objects"
import Modal from '../../../../../components/modal.vue'
import CpfsField from '../cpfs-field.vue'

export default {
  mixins: [vueAuthenticate],

  components: { Modal, CpfsField },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.scoreReport.newModal",

      // form
      responsibilityTerm: false,
      submitting: false,
      writting: false,
      scoreReport: { cpfs: [] },
      errors: {}
    }
  },

  props: {
    callBack: { type: Function, default: () => {} },
    show: { type: Boolean, default: false }
  },

  computed: {
    propertyTypeOptions() {
      return [
        { id: 'house', text: this.$t('.propertyType.house') },
        { id: 'apartment', text: this.$t('.propertyType.apartment') },
        { id: 'commercial', text: this.$t('.propertyType.commercial') },
        { id: 'kitnet', text: this.$t('.propertyType.kitnet') },
      ]
    },

    btnSubmitText() {
      return this.$t(`.buttons.${this.submitting ? 'submitting' : 'submit'}`)
    }
  },

  methods: {
    submit() {
      this.submitting = true
      const formData = new FormData(this.$refs.form)

      formData.set('score_report[responsibility_term]', this.responsibilityTerm)

      return this.$http
        .post(`${this.$user.prefixUrlAgency}/score-reports`, formData)
        .then(() => {
          this.errors = {}
          this.$notifications.clear()
          this.$notifications.info(this.$t(".notifications.submit.success"))

          this.scoreReport = { cpfs: [] }
          this.$refs.form.reset()

          this.$emit('submitted')
          this.close()
        })
        .catch((err) => {
          this.errors = objects.camelize(
            objects.dig(err, "response", "data", "errors") || {}
          )

          this.$notifications.error(this.$t(`.notifications.submit.invalid`))
        })
        .finally(() => {
          this.submitting = false
        })
    },


    formatCurrencyValue(value) {
      return value && value.replace('R$', '').trim()
    },

    onCpfFieldChange(element) {
      const target = element.target
      const value = target.value

      this.writting = value.trim().length != 0
    },

    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  .info-container {
    min-height: 11em;

    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
    }

    h3 {
      color: $primary-color;
      font-size: 1.5rem;
      font-weight: 500;
      border-bottom: 1.5px solid $primary-color;
      margin-top: 0rem;
    }
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}
</style>

<style lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}
</style>

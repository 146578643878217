<template lang="pug">
#page.bill-page
  navbar(currentPage="adminManagementIndex")

  .bill-container-phone
    breadcrumbs(:items="breadcrumbItems")

    .buttons-container(v-if="bill.status")
      button.destroy-bill(
        v-if="hasStatus('waiting') && !hasBankSlip"
        @click="destroyBillAction"
      )
        | {{ $t('.actions.buttons.destroy') }}

      //- TODO
      //- button.view-transfer(
      //-   v-if="hasStatus('agreement')",
      //-   @click="redirectToAgreementPage"
      //- )
      //-   | {{ $t('.actions.buttons.viewAgreement') }}

      button.cancel-bank-slip(
        v-if="hasManualChangeStatus && hasBankSlip",
        @click="showCancelBankSlipModal = true"
      )
        | {{ $t('.actions.buttons.cancelBankSlip') }}

      button.charge-back(
        v-if="hasStatus('normal') || hasStatus('unpaid')",
        @click="showChargeBackModal = true"
      )
        | {{ $t('.actions.buttons.chargeBack') }}

      button.manual-payment(
        v-if="hasManualChangeStatus",
        @click="showManualPaymentModal = true"
      )
        | {{ $t('.actions.buttons.manualPayment') }}

      //- TODO
      //- button.view-transfer(
      //-   v-if="bill.transferId",
      //-   @click="redirectToTransferPage"
      //- )
      //-   | {{ $t('.actions.buttons.viewTransfer') }}

      button.show-bank-slip(
        v-if="hasBankSlip && !bill.awaitingBankSlipUpdate",
        @click="generateBankSlip"
      )
        | {{ $t('.actions.buttons.bankSlip') }}

      button.button-primary(
        v-if="canGenerateBankSlip",
        @click="showCreateBankSlipModal = true"
      )
        | {{ $t(`.actions.buttons.${bill.openingBill ? 'newBankSlip' : 'generateBankSlip'}`) }}

      button.button-primary(
        v-if="!bill.agreementId && !bill.openingBill",
        @click="showBillCloneModal = true"
      )
        | {{ $t('.actions.buttons.billClone') }}

    .bill-show-container
      expansible-card(:title="$t('.titles.overview')" expanded)
        .info
          h3.info-title {{ $t('.overview.dueDate') }}
          editable-date-field(
            v-if="billLoaded && hasStatus('waiting')",
            name="bill[due_date]",
            :value="bill.dueDate",
            :path="billPath"
          )
          p.info-content(v-else)
            | {{ $l('date.formats.default', bill.dueDate) || '-' }}

        .info
          h3.info-title {{ $t('.overview.paymentDate') }}
          editable-date-field(
            v-if="billLoaded && hasStatus('manual_payment')",
            name="bill[payment_date]",
            :value="bill.paymentDate",
            :path="billPath"
          )
          p.info-content(v-else)
            | {{ $l('date.formats.default', bill.paymentDate) || '-' }}

        .info
          h3.info-title {{ $t('.overview.totalValue') }}
          p.info-content {{ `R$ ${formatValue(bill.value)}` || '-' }}

        .info
          h3.info-title {{ $t('.overview.contractCode') }}
          p.info-content {{ bill.contractCode || '-' }}

        .info
          h3.info-title {{ $t('.overview.agency') }}
          p.info-content {{ bill.realEstateAgencyName || '-' }}

        .info
          h3.info-title {{ $t('.overview.status') }}
          .flag(:class="bill.status")
            | {{ billTranslate(`statusOptions.${getStatus}`) }}

        .info
          h3.info-title {{ $t('.overview.tenantName') }}
          p.info-content {{ bill.name || '-' }}

        .info
          h3.info-title {{ $t('.overview.propertyAddress') }}
          p.info-content {{ bill.propertyAddress || '-' }}

        .info
          h3.info-title {{ $t('.overview.payer') }}
          p.info-content {{ billTranslate(`payerOptions.${bill.payer}`) }}

        .info
          h3.info-title {{ $t('.overview.alpopFee') }}
          p.info-content
            | {{ `R$ ${bill.alpopFee ? formatValue(bill.alpopFee) : '0.00'}` }}
        .info
          h3.info-title {{ $t('.overview.emailVisualized') }}
          p.info-content
            .open-modal-button
              | {{ $t(`.bankSlipOpened.${!!this.bill.bankSlipOpened}`) }}

              button.icon.button-primary(
                @click="showEmailLogsModal = true"
                v-if="bill?.emailLogs?.length > 0"
              )
                i.fa.fa-external-link
        .info
          h3.info-title {{ $t('.overview.digisacMessage') }}
          p.info-content
            .open-modal-button(v-if="bill?.digisacMessages")
              button.icon.button-primary(
                @click="showDigisacMessagesModal = true"
              )
                i.fa.fa-external-link
            span(v-else) -

        .info
          h3.info-title {{ $t('.overview.guaranteed') }}
          p.info-content
            | {{ billTranslate(`guaranteedOptions.${bill.guaranteed}`) }}

        .info
          h3.info-title {{ $t('.overview.observation') }}
          editable-input-field(
            v-if="billLoaded",
            name="bill[observation]",
            :value="bill.observation",
            :path="billPath"
          )

    .bill-item-index-container
      expansible-card(:title="$t('.titles.details')", :expanded="true")
        .buttons-container
          button.button-primary(
            :disabled="!hasStatus('waiting')",
            @click="showCreateBillItemModal = true"
          )
            | {{ $t('.actions.buttons.newItem') }}
        .table-section
          .table-container
            table
              thead
                tr
                  th(v-for="name in columnNames")
                    p {{ $t(`.details.table.${name}`) }}

              tbody
                tr(v-for="billItem in billItems")
                  td.bill-item
                    | {{ billItem.description }}
                  td.bill-item-value
                    | {{ `R$ ${formatValue(billItem.value)}` }}
                  td.bill-item-complement
                    | {{ billItem.complement }}
                  td.actions
                    button.button-blue(
                      :disabled="!editableBillItems"
                      @click="openActionsModal(billItem, 'edit')",
                    )
                      i.fa.fa-edit
                    button.button-red(
                      :disabled="!hasStatus('waiting')",
                      @click="openActionsModal(billItem, 'delete')"
                    )
                      i.fa.fa-trash

              tfoot
                td {{ $t('.details.table.total') }}
                td
                  | {{ `R$ ${formatValue(bill.value)}` }}

                  span.credited-value {{ creditedValue }}
                td
                td

  parent-agreement-redirection-modal(
    :show="showParentAgreementRedirectionModal",
    :agreementId="bill.agreementId",
    @close="showParentAgreementRedirectionModal = false"
  )

  delete-bill-modal(
    :show="showDeleteBillModal",
    :callback="deleteBill",
    @close="showDeleteBillModal = false"
  )
  cancel-bank-slip-modal(
    :show="showCancelBankSlipModal",
    :billId="bill.id",
    :callback="fetch",
    @close="showCancelBankSlipModal = false"
  )
  edit-bill-item-modal(
    :show="showEditBillItemModal",
    :billItem="billItem",
    :billId="bill.id",
    :billStatus="bill.status",
    :callback="reloadBillItems",
    @close="showEditBillItemModal = false"
  )
  delete-bill-item-modal(
    :show="showDeleteBillItemModal",
    :callback="deleteBillItem",
    @close="showDeleteBillItemModal = false"
  )
  create-bill-item-modal(
    :show="showCreateBillItemModal",
    :billId="bill.id",
    :callback="reloadBillItems",
    @close="showCreateBillItemModal = false"
  )
  create-bank-slip-modal(
    :show="showCreateBankSlipModal",
    :billId="bill.id",
    :callback="reloadBillItems",
    @close="showCreateBankSlipModal = false"
  )
  manual-payment-modal(
    :show="showManualPaymentModal",
    :bill="bill",
    :callback="fetch",
    @close="showManualPaymentModal = false"
  )
  clone-bill-modal(
    :show="showBillCloneModal",
    :billId="bill.id",
    :contractCode="bill.contractCode",
    :dueDate="bill.dueDate",
    @close="showBillCloneModal = false"
  )

  charge-back-modal(
    :show="showChargeBackModal"
    :billId="bill.id"
    :dueDate="bill.dueDate"
    :onSuccess="fetch"
    @close="showChargeBackModal = false"
  )

  email-logs-modal(
    :logs="bill.emailLogs"
    :show="showEmailLogsModal"
    :close="closeEmailLogsModal"
  )

  digisac-messages-modal(
    :show="showDigisacMessagesModal"
    :billId="bill.id",
    @close="closeDigisacMessagesModal"
  )
</template>

<script>
import ExpansibleCard from "../../../../components/expansible-card.vue"
import CheckboxField from "../../../../../../components/phone/checkbox-field.vue"

import EditBillItemModal from "../../../components/bills_items/edit-modal.vue"
import DeleteBillModal from "../../../components/bills/delete-modal.vue"
import CancelBankSlipModal from "../../../components/bills/cancel-bank-slip-modal.vue"
import CreateBankSlipModal from "../../../components/bills/create-bank-slip-modal.vue"
import DeleteBillItemModal from "../../../components/bills_items/delete-modal.vue"
import ParentAgreementRedirectionModal from '../../../components/bills/parent-agreement-redirection-modal.vue'
import CreateBillItemModal from "../../../components/bills_items/create-modal.vue"
import ManualPaymentModal from "../../../components/bills/manual-payment-modal.vue"
import CloneBillModal from "../../../components/bills/clone-modal.vue"
import ChargeBackModal from "../../../components/bills/charge-back-modal.vue"
import EditableDateField from "../../../../../../components/phone/editable-fields/editable-date-field.vue"
import EditableInputField from "../../../../../../components/phone/editable-fields/editable-input-field.vue"
import EmailLogsModal from "../../../components/bills/email-logs-modal.vue"
import DigisacMessagesModal from "../../../components/bills/digisac-messages-modal.vue"

import Overview from "../../../components/overview.vue"
import Navbar from "../../../components/navbar.vue"
import Breadcrumbs from "../../../../components/breadcrumbs.vue"

import objects from "lib/objects"

export default {
  components: {
    ExpansibleCard, CheckboxField, EditBillItemModal, Overview, Navbar,
    Breadcrumbs, DeleteBillItemModal, CreateBillItemModal, DeleteBillModal,
    CancelBankSlipModal, CreateBankSlipModal, ManualPaymentModal,
    CloneBillModal, ChargeBackModal, EditableDateField, EditableInputField,
    EmailLogsModal, ParentAgreementRedirectionModal, DigisacMessagesModal
  },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.management.bill.show",

      // tool
      routePath: "administracao/gerenciamento/cobrancas",

      bill: {},
      billItems: [],
      emailLogs: [],

      // modal
      showDeleteBillModal: false,
      showParentAgreementRedirectionModal: false,
      showCancelBankSlipModal: false,
      showEditBillItemModal: false,
      showDeleteBillItemModal: false,
      showCreateBillItemModal: false,
      showCreateBankSlipModal: false,
      showManualPaymentModal: false,
      showBillCloneModal: false,
      showChargeBackModal: false,
      showEmailLogsModal: false,
      showDigisacMessagesModal: false,

      billLoaded: false,

      billItem: {}
    }
  },

  methods: {
    init(bill) {
      this.bill = objects.camelize(bill)
      this.billLoaded = true
    },

    initBillItems(billItems) {
      this.billItems.push(...objects.camelize(billItems))
    },

    openActionsModal(billItem, modal) {
      this.billItem = billItem

      if (modal == 'edit' ) {
        this.showEditBillItemModal = true

        return
      }

      this.showDeleteBillItemModal = true
    },

    generateBankSlip() {
      this.$http
        .get(this.bill.bankSlipUrl, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')

          link.href = window.URL.createObjectURL(blob)
          link.download = this.$t('.blob.name', { id: this.bill.id })

          document.body.appendChild(link)

          link.click()

          document.body.removeChild(link)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.bankSlip.failure"))
        })
    },

    fetch() {
      this.$http
        .get(`${this.billPath}.json`)
        .then((response) => {
          const bill = response.data

          this.init(bill)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.fetchItems()
        })
    },

    fetchItems() {
      this.$http
        .get(`${this.billPath}/bill-items.json`)
        .then((response) => {
          const billItems = response.data

          this.initBillItems(billItems)
        })
        .catch(() => {
          this.$notifications.error(
            this.$t(".notifications.fetchItems.failure")
          )
        })
    },

    reloadBillItems() {
      this.billItems = []

      this.fetch()
    },

    deleteBillItem() {
      this.$http
        .delete(`/admins/bills/${this.bill.id}/bill-items/${this.billItem.id}`)
        .then(() => {
          this.errors = {}

          this.$notifications.info(
            this.$t(".notifications.deleteBillItem.success")
          )
          this.reloadBillItems()
        })
        .catch(() => {
          this.$notifications.error(
            this.$t(".notifications.deleteBillItem.failure")
          )
        })
        .finally(() => {
          this.showDeleteBillItemModal = false
        })
    },

    deleteBill() {
      this.$http
        .delete(`/admins/bills/${this.bill.id}`)
        .then(() => {
          this.errors = {}

          this.$notifications.info(this.$t(".notifications.deleteBill.success"))
          this.$router.replace({
            path:"/administracao/gerenciamento", hash: "#bills"
          })
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.deleteBill.failure"))
        })
        .finally(() => {
          this.showDeleteBillModal = false
        })
    },

    destroyBillAction() {
      if (this.hasAgreement) {
        this.showParentAgreementRedirectionModal = true

        return
      }

      this.showDeleteBillModal = true
    },

    //TODO: waiting creation of contracts page
    // redirectToContractPage() {
    //   this.$router.push({
    //     path: `/administracao/gerenciamento/contratos/${this.bill.contractId}`
    //   })
    // },

    // redirectToAgreementPage() {
    //   const { enteredAgreementId } = this.bill

    //   this.$router.push({
    //     path: `/administracao/gerenciamento/acordos/${enteredAgreementId}`
    //   })
    // },

    // redirectToTransferPage() {
    //   this.$router.push({
    //     path: `/administracao/gerenciamento/repasses/${this.bill.transferId}`
    //   })
    // },

    formatValue(value) {
      return value ? value.toString().replace('.', ',') : ''
    },

    parseRoute() { },

    hasStatus(status) {
      return this.bill.status == status
    },

    billTranslate(attribute) {
      return this.$t(`models.bill.attributes.${attribute}`)
    },

    closeEmailLogsModal() {
      this.showEmailLogsModal = false
    },

    closeDigisacMessagesModal() {
      this.showDigisacMessagesModal = false
    }
  },

  computed: {
    hasBankSlip() {
      return this.bill.bankSlipUrl
    },

    canGenerateBankSlip() {
      return this.hasStatus('waiting') && !this.hasBankSlip && (
        this.bill.openingBill || this.isWithinNextFifteenDays
      )
    },

    isWithinNextFifteenDays() {
      const currentDate = new Date()
      const dateToCheck = new Date(this.bill.dueDate)
      const limitDate = new Date()

      limitDate.setDate(currentDate.getDate() + 15)

      return dateToCheck >= currentDate && dateToCheck <= limitDate
    },

    hasAgreement() {
      return !!this.bill?.agreementId
    },

    getStatus() {
      const billStatus = this?.bill?.status

      if (!this.hasBankSlip && billStatus === 'waiting') {
        return 'waitingBankSlipCreate'
      }

      if (this.bill.awaitingBankSlipUpdate) return 'awaitingBankSlipUpdate'

      return billStatus
    },

    hasManualChangeStatus() {
      return this.hasStatus('normal') ||
             this.hasStatus('unpaid') ||
             this.hasStatus('waiting')
    },

    billPath() {
      return `/admins/bills/${this.getBillParam}`
    },

    columnNames() {
      return ['billItem', 'billItemValue', 'complement', 'actions']
    },

    getBillParam() {
      return this.$route.params.id
    },

    breadcrumbItems() {
      return [
        {
          name: this.$t('.breadcrumbs.management'),
          url: this.routePath.replace("/cobrancas", '')
        },
        {
          name: this.$t('.breadcrumbs.bill'),
          url:  this.routePath.replace("/cobrancas", ''),
          hash: 'bills'
        },
        {
          name: `#${this.getBillParam}`,
          url: `${this.routePath}/${this.getBillParam}`
        }
      ]
    },

    editableBillItems() {
      return ['waiting', 'normal', 'unpaid', 'paid'].includes(this.bill.status)
    },

    creditedValue() {
      return this.$t(
        '.details.table.values.creditedValue',
        { value: this.formatValue(this.bill?.creditedValue) }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.bill-container-phone {
  padding: 2rem;
  width: 100%;
}

.credited-value {
  color: $grey-color;

  font-size: 1.2rem;
  margin-left: 0.3rem;
}

.open-modal-button {
  display: flex;
  align-items: center;

  button {
    border: none;
    background-color: transparent !important;
    color: $primary-color !important;
    text-align: center;
    padding-left: 1rem;
    margin: 0;
  }
}

.info {
  word-break: break-word;

  .flag {
    padding: 1rem;

    font-size: 1.3rem;
    font-weight: 500;
    color: $white-color;
    width: fit-content;
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
    border-radius: 0.6rem;

    &.waiting {
      background-color: $grey-color;
    }

    &.paid, &.manual_payment {
      background-color: $primary-color;
    }

    &.unpaid {
      background-color: $red-color;
    }

    &.agreement {
      background-color: $blue-color;
    }

    &.normal {
      background-color: $yellow-color;
    }

    &.canceled, &.chargeback {
      background-color: $second-color;
    }
  }
}

.info-title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: $second-color-light;
}

.info-content {
  font-size: 1.5rem;
  font-family: Lato;

  &.sent {
    background-color: $primary-color;
  }

  &.pending {
    background-color: $red-color;
  }
}

.buttons-container {
  display: flex;
  gap: 1rem;
  overflow-x: scroll;
  padding-right: 1rem;
}

button {
  font-size: 1.5rem;
  text-transform: none;

  &.destroy-bill, &.cancel-bank-slip {
    border: none;
    color: $red-color;
    background-color: lighten($red-color, 54%);

    &:hover {
      background-color: lighten($red-color, 49%);
    }
  }

  &.view-transfer,
  &.add-new-item,
  &.show-bank-slip,
  &.manual-payment,
  &.charge-back {
    border: none;
    color: $blue-color;
    background-color: lighten($blue-color, 36%);

    &:hover {
      background-color: lighten($blue-color, 31%);
    }
  }

  &.button-red, &.button-blue {
    padding: 0;
    height: 38px;
    width: 38px;
  }
}

.table-container {
  height: auto !important;
  max-height: 40vh;

  @media (orientation: landscape) {
    max-height: 60vh;
  }

  table {
    tbody {
      tr {
        height: 5rem !important;
      }
    }

    td {
      text-transform: capitalize !important;
    }

    .bill-item, .bill-item-value {
      min-width: 13rem !important;
    }

    .bill-item-complement {
      width: 100%;
    }

    .actions {
      min-width: fit-content;
      display: flex;
      justify-content: flex-end;
      gap: 1rem;

      button {
        margin: 0;
        width: 3.8rem;
        padding: 0;
      }

      i {
        font-size: 1.8rem;
      }
    }

    .status-container {
      background-color: $primary-color;
      color: $white-color;
      padding: 0.5rem 2rem;
      width: min-content;
      border-radius: 0.5rem;
      text-transform: uppercase;
      font-size: 1.4rem;
    }
  }
}
</style>
<template lang="pug">
.debits-modal
  button.open-modal.button-primary(
    :disabled="disabled",
    @click="openModal"
  )
    slot

  Modal(v-model="isShow", :close="close")
    .modal
      h1.title {{ $t(`.title`) }}

      .debits(:class="debits && debits.length > 0 || 'hasntError'")
        .table-section
          .table-container
            table
              thead
                tr
                  th(v-for="header in headers")
                    p {{ $t(`.headers.${header}`) }}

              tbody
                tr(v-for="debit in debits")
                  td {{ `R$ ${debit.value || '-'}` }}
                  td {{ $l('date.formats.default', debit.occurrenceDate) || '-' }}
                  td.informant {{ debit.informant || '-' }}

          footer {{ $t('.footer', { count: debits.length }) }}

      .buttons-container
        button.close(@click="close") {{ $t(".close") }}
</template>
<script>
import { ref } from 'vue'

export default {
  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.components.scoreReport.debitsModal",

      headers: ['value', 'occurrenceDate', 'informant']
    }
  },

  props: {
    disabled: { type: Boolean, default: false },
    debits: { type: Array }
  },

  methods: {
    openModal(event) {
      event.preventDefault()
      this.showModal()
    },

    close(event) {
      event.preventDefault()
      this.closeModal()
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/styles/base/tables.scss";

button {
  margin-bottom: 0;
}

.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  width: 80%;
  height: fit-content;

  .title {
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
    color: $primary-color;
    margin-bottom: 1rem;
  }

  .debits {
    width: 100%;

    .title {
      font-size: 1.8rem;
      font-weight: 500;
      border-bottom: 1px solid $primary-color;
    }

    .table-container {
      height: 50vh;

      td {
        min-width: fit-content;
      }

      .informant {
        width: 100%;
        min-width: 30rem;
        white-space: normal;
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 4rem;
  }
}
</style>

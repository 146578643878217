<template lang="pug">
Modal(v-model="isShow", :close="close")
  .modal
    h1 {{ $t('.title') }}

    form(ref="form" @submit.prevent="submit")
      file-field(
        type="file",
        name="document",
        :accept="acceptedFormats",
        :label="$t('.label.document')",
        :error="errors?.document?.message",
        :reset="resetDocument",
        @reseted="resetedDocument"
        @input="val => { document = val }"
        required
        destroyable
      )

    .buttons-container
      button.close(@click="close" :disabled="submitting")
        | {{ $t(".buttons.close") }}

      button.confirm(@click="submit" :disabled="disableSubmit")
        | {{ $t(".buttons.confirm") }}
</template>

<script>
import objects from "lib/objects"
import { ref } from 'vue'

export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "components.documents.uploadModal",

      // form
      resetDocument: false,
      acceptedFormats: ".pdf,.docx,.png,.jpeg,.odt,.doc,.jpg,.rtf",

      // submit
      document: null,
      submitting: false,
      errors: {}
    }
  },

  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  props: {
    show: { type: Boolean, default: false },
    path: { type: String, required: true },
    callback: { type: Function, default: () => {} }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  computed: {
    disableSubmit() {
      return !this.document || this.submitting
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },

    submit() {
      this.submitting = true

      const formData = new FormData(this.$refs.form)
      const config = { headers: { "Content-Type": "multipart/form-data" } }

      this.$http
        .post(this.path, formData, config)
        .then(({ data }) => {
          const document = data.data

          this.callback(document)

          this.$emit('close')

          this.resetDocument = true

          this.$notifications.clear()
          this.$notifications.info(this.$t(".notifications.submit.success"))
        })
        .catch((err) => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))

          this.errors = objects.camelize(
            objects.dig(err, "response", "data", "errors") || {}
          )

          this.resetFile = true
        })
        .finally(() => {
          this.submitting = false
        })
    },

    resetedDocument() {
      this.resetDocument = false
      this.document = null
    }
  }
}
</script>

<style scoped lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}

.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  height: fit-content;
  max-width: 60%;

  h1 {
    font-size: 1.7rem;
    font-weight: 550;
    color: $second-color-dark;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.5rem;
    color: $second-color-light;
    overflow-wrap: break-word;
    white-space: pre-line;
    margin: 1rem 0;
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;
    margin-top: 5rem;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}
</style>

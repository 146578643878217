import Dropzone from 'dropzone'

// Dropzone
Dropzone.autoDiscover = false
// Para sobrecarregar o diálogo de confirm, implemente:
// ----
// Dropzone.confirm = function(question, accepted, rejected) {
//   // Ask the question, and call accepted() or rejected() accordingly.
//   // CAREFUL: rejected might not be defined. Do nothing in that case.
// };

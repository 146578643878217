<template lang="pug">
select-field(
  :options="options",
  :required="false",
  :includeBlank="true",
  :hideLabel="true",
  :placeholder="this.$t('tableSelectionField.placeholder')",
  @update:modelValue="callback"
)
</template>
<script>
  export default {
    props: {
      callback: { type: Function, required: true }
    },

    data() {
      return {
        options: [
          { id: 1, text: this.$t('tableSelectionField.all') },
          { id: 2, text: this.$t('tableSelectionField.none') },
        ]
      }
    }
  }
</script>
<style lang="scss" scoped>
.select-field {
  margin: 0;
}
</style>

<template lang="pug">
Modal(v-model="isShow", :close="close")
  .modal
    .kind-container(v-if="!billStep")
      h1 {{ $t('.kind.title') }}
      p {{ $t('.kind.description') }}

      select-field(
          v-model="kind"
          :options="kindOptions"
          :hideLabel="true"
          :includeBlank="true"
          required
        )

      .buttons-container
        button.close(@click="close", :disabled="submitting")
          | {{ $t(".buttons.close") }}

        button.confirm(
          @click="billStep = true",
          :disabled="!kind"
        )
          | {{ $t(".buttons.next") }}

    .form-container(v-else)
      h1 {{ $t('.title') }}

      form(ref="form", action="/")
        contract-field(
          v-if="!contractId"
          v-model="contract",
          name="bill[contract_id]",
          :required="true",
          :error="errors?.contract?.message"
        )

        date-field(
          v-model="dueDate",
          name="bill[due_date]",
          :label="$t('.label.dueDate')",
          :required="true",
          :error="errors?.dueDate?.message"
        )

        select-field(
          v-model="payer"
          name="bill[payer]"
          :label="$t('.label.payer')"
          :error="errors?.payer?.message"
          :options="payerOptions"
          required
        )

      .buttons-container
        button.return(@click="billStep = false", :disabled="submitting")
          | {{ $t(".buttons.return") }}

        button.confirm(
          @click="submit",
          :disabled="disableConfirmButton")
          | {{ $t(".buttons.confirm") }}
</template>

<script>
import objects from "lib/objects"
import { ref } from 'vue'

import ContractField from '../contract-field.vue'

export default {
  components: { ContractField },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.bills.modal",

      submitting: false,
      errors: {},

      // form
      contract: null,
      dueDate: null,
      payer: 'tenant',
      kind: null,
      billStep: false
    }
  },

  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  props: {
    show: { type: Boolean, default: false },
    contractId: { type: Number, default: null }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  computed: {
    payerOptions() {
      return [
        {
          id: 'tenant',
          text: this.$t(`.payerOptions.tenant`)
        },
        {
          id: 'real_estate_agency',
          text: this.$t(`.payerOptions.realEstateAgency`)
        }
      ]
    },

    kindOptions() {
      return [
        {
          id: 'guaranteed',
          text: this.$t(`.kindOptions.guaranteed`)
        },
        {
          id: 'with_transfer',
          text: this.$t(`.kindOptions.withTransfer`)
        },
        {
          id: 'no_transfer',
          text: this.$t(`.kindOptions.noTransfer`)
        }
      ]
    },

    disableConfirmButton() {
      return this.submitting ||
        (!this.contractId && !this.contract) ||
        !this.dueDate ||
        !this.payer
    },

    isTenantPayer() {
      return this.payer == 'tenant'
    }
  },

  methods: {
    close() {
      this.contract = null
      this.dueDate = null
      this.payer = 'tenant'
      this.kind = null
      this.billStep = false

      this.$emit('close')
    },

    hasTransfer() {
      return this.kind != 'no_transfer'
    },

    isGuaranteed() {
      return this.kind == 'guaranteed'
    },

    formData() {
      return {
        contract_id: this.contractId || this.contract,
        due_date: this.dueDate.toDateString(),
        payer: this.payer,
        with_transfer: this.isTenantPayer ? this.hasTransfer() : false,
        guaranteed: this.isTenantPayer ? this.isGuaranteed() : false
      }
    },

    submit() {
      this.submitting = true

      this.$http
        .post(`/admins/bills`, this.formData())
        .then((response) => {
          const data = response.data
          const path = `/administracao/gerenciamento/cobrancas/${data.id}`
          const route = this.$router.resolve({ path: path })

          window.open(route.href, '_blank')
        })
        .catch((err) => {
          this.$notifications.error(this.$t(".notifications.create.failure"))

          this.errors = objects.camelize(
            objects.dig(err, "response", "data", "errors") || {}
          )
        })
        .finally(() => {
          this.submitting = false

          this.close()
        })
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  height: fit-content;
  width: 90%;

  @media (orientation: landscape) {
    width: 60%;
  }

  .form-container, .kind-container {
    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.5rem;
      color: $second-color-light;
      overflow-wrap: break-word;
      white-space: pre-line;
      margin: 1rem 0;
    }
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;
    margin-top: 5rem;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}
</style>

<style lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}
</style>

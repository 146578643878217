<template lang="pug">
.table-section
  .table-container(@scroll="autoLoad")
    table
      thead
        tr
          th(v-for="name in columnNames")
            p {{ $t(`.file.${name}`) }}

      tbody
        tr.loading(v-if="loading")
          td(v-for="cell in cellsLoader" :key="cell")
            .cell

        slot
  footer
    p {{ $t(".pagination.text", { length: showingNow, total: pagination.total }) }}
</template>
<script>
export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.components.cnab.filesTable"
    }
  },

  props: {
    loading: { type: Boolean, default: false },
    showingNow: { type: Number, default: 0 },
    pagination: { type: Object, default: () => ({ total: '0' }) }
  },

  computed: {
    columnNames() {
      return ['kind', 'status', 'date', 'details']
    },

    cellsLoader() {
      return document.getElementsByTagName('th')
    }
  },

  methods: {
    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target

      if ((scrollTop + clientHeight < (scrollHeight - 5)) || !this.pagination.next) {
        return
      }

      this.$emit("nextPage", this.pagination.next)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/styles/base/tables.scss";

.table-section {
  p {
    margin: 0;
  }

  @media (orientation: portrait) {
    footer {
      text-align: center;
    }
  }
}
</style>
<template lang="pug">
.status
  .status-container(
    v-if="['lost', 'success'].includes(lead.status)"
    :class="lead.status"
  )
    | {{ translateStatus(lead.status) }}

  select-field(
    v-else
    :options="statusOptions(lead.status)"
    v-model="selectedStatus"
    @input="showSubmitModal = true"
    hideLabel
  )

  confirmation-modal(
    :show="showSubmitModal"
    :title="$t('.submitModal.title')"
    :message="submitModalMessage"
    :loading="submitting"
    :callback="submit"
    @close="closeModal"
  )
</template>

<script>
import ConfirmationModal from "../../../../components/confirmation-modal.vue"

export default {
  components: { ConfirmationModal },
  data() {
    return {
      defaultI18nScope: "admin.realEstateAgencies.leadsKurole.status",

      selectedStatus: null,

      showSubmitModal: false,
      submitting: false
    }
  },

  props: {
    lead: { type: Object, required: true }
  },

  watch: {
    lead(value) {
      this.selectedStatus = value.status
    }
  },

  mounted() {
    this.selectedStatus = this.lead.status
  },

  computed: {
    submitModalMessage() {
      return this.$t(
        '.submitModal.message',
        { status: this.translateStatus(this.selectedStatus) }
      )
    }
  },

  methods: {
    translateStatus(status) {
      return this.$t(`.statuses.${status}`)
    },

    statusOptions(status) {
      if (!status) return []

      const allowedOptions = {
        new_lead: ['new_lead', 'contacted'],
        contacted: ['contacted', 'lost']
      }
      const options = allowedOptions[status] || [status]

      return options.map(option => (
        { id: option, text: this.translateStatus(option) }
      ))
    },

    reset() {
      this.selectedStatus = this.lead.status || null
    },

    closeModal() {
      this.reset()

      this.showSubmitModal = false
    },

    submit() {
      this.submitting = true

      const params = { kurole_lead: { status: this.selectedStatus } }

      this.$http
        .put(`/admins/kurole/leads/${this.lead.id}`, params)
        .then(() => {
          this.$notifications.info(
            this.$t(".notifications.submit.success")
          )

          this.lead.status = this.selectedStatus
        })
        .catch(() => {
          this.$notifications.error(
            this.$t(".notifications.submit.failure")
          )
        })
        .finally(() => {
          this.reset()

          this.showSubmitModal = false
          this.submitting = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.status {
  .status-container {
    border-radius: 0.5rem;

    width: min-content;

    color: $white-color;
    text-transform: uppercase;
    font-size: 1.4rem;

    padding: 0.5rem 2rem;

    &.success {
      background-color: $green-color;
    }

    &.lost {
      background-color: $red-color;
    }
  }

  .select-field {
    margin: 0;
  }
}
</style>

<template lang="pug">
.trials
  step-table(
    :stepName="stepName",
    :tableData="data",
    :columnNames="columnNames",
    :dateColumns="dateColumns"
  )
</template>
<script>
import StepTable from './format/step-table.vue'

export default {
  components: { StepTable },

  data() {
    return {
      // table info
      stepName: 'trials',
      columnNames: [
        'trialNumber',
        'uf',
        'court',
        'deliveryDate',
        'defendant',
        'disapproved',
        'area',
        'trialClassName'
      ],
      dateColumns: ['deliveryDate']
    }
  },

  props: {
    data: { type: Array, default: () => ([]) }
  }
}
</script>
<style scoped lang="scss">
.trials {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
}
</style>

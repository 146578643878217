<template lang="pug">
.billing-page
  expansible-card(:title="$t('.title.tenant')", :expanded="true")
    .info
      h3.info-title {{ $t('.tenantInfo.name') }}
      .loading-content(v-if="loading")
      p.info-content(v-else) {{ tenantName || '-' }}

    .info
      h3.info-title {{ $t('.tenantInfo.email') }}
      .loading-content(v-if="loading")
      p.info-content(v-else) {{ tenantEmail || '-' }}

  .billing-container(v-if="!editFirstBill")
    .billing-header
      h2.title {{ $t('.title.billing') }}

      .row.counter-container
        span.counter(v-if="!loading && pagination.total > 0")
          | {{ $t('.card.counter.total', { length: bills.length, total: pagination.total }) }}
        span.counter(v-if="loading")
          | {{ $t('.card.counter.loading') }}

      .no-data-container(v-if="!loading && bills.length == 0")
        img.image(src="@/assets/images/public/no-data.svg")
        h2
          | {{ $t('.card.counter.zero') }}

    .cards-container(@scroll="autoLoad")
      .card(v-for="bill in bills", :key="key")
        .bill-info
          .info
            h3.info-title {{ $t('.card.dueDate') }}
            p.info-content {{$l('date.formats.default', bill.dueDate) || '-' }}

          .info
            h3.info-title {{ $t('.card.value') }}
            p.info-content R$ {{ bill.value || '-' }}

          .info
            h3.info-title {{ $t('.card.status') }}
            p(:class="bill.status ?  `flag ${bill.status}` : 'info-content'")
              | {{ bill.status ? $t(`.flag.${bill.status}`) : '-'}}

          .info
            h3.info-title {{ $t('.card.paymentDate') }}
            p.info-content {{ $l('date.formats.default', bill.transferDate) || '-' }}

        .bill-buttons
          a.button.button-primary(
            v-if="['unpaid', 'normal'].includes(bill.status)"
            :href="bill.bankSlipUrl"
            target="_blank"
          )
            | {{ $t('.buttons.ticketGenerate') }}

          router-link.button.button-primary(
            :to="{ name: 'realEstateContractBillShow', params: { contractId: this.contractId, billId: bill.id } }",
          )
            | {{ $t('.buttons.details') }}

  billing-card(
    v-else
    :billsPath="fetchPath"
    :callback="reloadAfterEditFirstBill"
  )
</template>
<script>
import objects from "lib/objects"

import ExpansibleCard from "../../../components/expansible-card.vue"
import BillingCard from "./new/billing-card.vue"

export default {
  components: { ExpansibleCard, BillingCard },

  props: {
    contractId: { type: String, required: true },
    tenantName: { type: String },
    tenantEmail: { type: String }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.contract.billing",

      bills: [],

      pagination: { total: 0 },
      sort: 'dt_due_recb',
      order: 'asc',

      loading: false,

      editFirstBill: false
    }
  },

  mounted() {
    this.fetch()
  },

  computed: {
    fetchPath() {
      return `${this.$user.prefixUrlAgency}/contracts/${this.contractId}/bills`
    }
  },

  methods: {
    reloadAfterEditFirstBill() {
      this.editFirstBill = false

      this.fetch()
    },

    agencyIsEnabledToEditFirstBill() {
      const config = this.$firebase.remoteConfigValue('enableFirstBillEdition')
      const allowedAgenciesIds = config['real-estate-agency'] || []

      return allowedAgenciesIds.includes(this.bills[0]?.realEstateAgencyId)
    },

    enableToEditFirstBill() {
      const firstBill = this.bills?.[0] || {}

      this.editFirstBill = firstBill.issuer !== 'superlogica'
        && firstBill.status === 'waiting'
        && !firstBill.bankSlipUrl
        && this.agencyIsEnabledToEditFirstBill()
    },

    init({ data, pagination }) {
      this.bills.push(...objects.camelize(data))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total"),
      }

      this.enableToEditFirstBill()
    },

    parseRoute() {},

    fetch(page = null) {
      this.loading = true

      const params = { page, sort: this.sort, order: this.order }

      return this.$http
        .get(`${this.fetchPath}.json`, { params })
        .then(({ data }) => {
          this.init(data)

          this.loading = false
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
          this.loading = false
        })
    },

    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target

      if (((scrollTop + clientHeight) < (scrollHeight - 5)) || !this.pagination.next) {
        return
      }

      this.loading = true

      this.fetch(this.pagination.next)
    }
  }
}
</script>
<style lang="scss" scoped>
.info {
  word-break: break-word;
}

.info-title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: $second-color-light;
}

.info-content {
  font-size: 1.5rem;
  font-family: Lato;
}

.loading-content {
  background: $loading-gradient;
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  width: 100%;
  height: 3.5rem;
  margin-bottom: 1.5rem;
}

.billing-container {
  .billing-header {
    .title {
      color: $primary-color;
      font-size: 2rem;
      font-weight: 400;
      border-bottom: 1.5px solid $primary-color;
      margin-top: 2.5rem;
    }

    .counter-container {
      margin-bottom: 1.5rem;
      text-align: right;
      width: 100%;
    }

    .counter {
      color: $grey-color-dark;
      font-style: italic;
    }

    .no-data-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      img {
        width: 20rem;
        margin-bottom: 1rem;
      }

      h2 {
        color: $primary-color;
        font-size: 2rem;
        font-weight: 400;
      }
    }
  }

  .cards-container {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    gap: 2rem;
    padding: 0;
    margin-bottom: 5rem;

    .card {
      background-color: $white-color;
      border-radius: 0.7rem;
      box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
      max-width: 100%;
      padding: 1.5rem;
      height: fit-content;

      .bill-info {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(2, 1fr);
        margin-bottom: 2rem;

        @media (orientation: portrait) {
          grid-template-rows: repeat(4, 1fr);
          gap: 0.5rem;
        }

        .info-content {
          margin-bottom: 0;
        }

        .flag {
          color: $white-color;
          font-size: 11px;
          font-weight: 600;
          line-height: 38px;
          width: 100%;
          text-align: center;
          text-transform: uppercase;
          border-radius: 0.5rem;
          margin-bottom: 0;

          &.normal, &.waiting {
            background-color: $blue-color;
          }

          &.paid, &.manual_payment {
            background-color: $green-color;
          }

          &.unpaid {
            background-color: $red-color;
          }

          &.agreement, &.canceled, &.chargeback {
            background-color: $yellow-color;
          }
        }
      }

      .bill-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.5rem;

        @media (orientation: landscape) {
          justify-content: flex-end;
        }

        .button {
          margin: 0;
        }
      }
    }
  }
}
</style>
<template lang="pug">
form(@submit.prevent="submit" ref="form")
  .inline-section(v-for="component in components")
    .three-columns(v-for="field in component")
      h3.info-title(:class="errors[field.errorPath] && 'error'")
        | {{ field.label }}

      p.info-content(v-if="!editing" @click="editing = true")
        | {{ field.value || '-' }}

      component(
        v-else,
        :is="getComponent(field.inputType)",
        :name="field.name",
        :error="errors[field.errorPath]?.message",
        v-model="field.value",
        hideLabel
      )

  .buttons(v-if="editing")
    button.button-primary(
      type="submit",
      :disabled="submitting",
    )
      i.fa.fa-check

    button.button-primary.close(
      :disabled="submitting",
      @click="disableEditableField()"
    )
      i.fa.fa-times
</template>

<script>
import { resolveComponent } from 'vue'

export default {
  props: {
    path: { type: String, required: true },
    callback: { type: Function, default: () => {} },
    components: { type: Array, required: true }
  },

  data() {
    return {
      defaultI18nScope: "components.editableInputGroup",

      submitting: false,
      editing: false,
      errors: {}
    }
  },

  methods: {
    getComponent(component) {
      if (component && resolveComponent(component)) return component

      return 'input-field'
    },

    disableEditableField() {
      this.editing = false

      this.callback()
    },

    submit() {
      this.submitting = true

      const formData = new FormData(this.$refs.form)

      this.$http.put(this.path, formData)
        .then(() => {
          this.errors = {}
          this.$notifications.clear()

          this.disableEditableField()
        })
        .catch((err) => {
          this.errors = err.response?.data?.errors || {}

          this.$notifications.error(this.$t(".notifications.submit.failure"))
        })
        .finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .info-content:hover {
    background-color: $grey-color-high-light;
  }

  .inline-section {
    display: flex;
    gap: 1rem;

    .info-title.error {
      color: #FF0000;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.3rem;

    button {
      padding: 0 10px;
      width: 38px;

      &.close {
        background-color: $red-color;
      }

      .fa {
        font-size: 1.2rem;
        line-height: 32px;
      }
    }
  }
</style>

import strings from 'lib/strings'
import _ from 'lodash'
import _object from 'lodash/object'

/**
 * Helper para manipulação de objetos.
 */
const objects = Object.assign({}, _object, {

  /**
   * Cria um novo objeto transformando todas as propriedades em nomes no formato
   * 'camelCase'. Bastante útil na adaptação de objetos JSON provenientes de API.
   *
   * Ex: let json = { first: 1, second-place: 2, third_one: 3 }
   *     =>
   *     objects.camelize(json) == { first: 1, secondPlace: 2, thirdOne: 3 }
   *
   * @param  {Object} object objeto a ter suas "chaves" transformadas
   * @return {Object}        objeto "clone", com todas as "chaves" em formato "camelCase"
   */
  camelize(object) {
    let camelized = _.cloneDeep(object)

    Object.keys(object).forEach((key) => {
      let value = object[key]

      // checks that a value is a plain object or an array - for recursive key conversion
      // recursively update keys of any values that are also objects
      if (_.isPlainObject(value) || _.isArray(value)) {
        value = objects.camelize(value)
        camelized[key] = value
      }

      const camelizedKey = strings.camelCase(key)
      if (camelizedKey !== key) {
        camelized[camelizedKey] = value
        delete camelized[key]
      }
    })

    return camelized
  },

  /**
   * Remove 'keys' com valores 'null', 'undefined' ou objetos vazios
   *
   * Ex: let obj = { value1: "valor1", value2: undefined, value3: null, value4: {} }
   *     =>
   *     objects.deleteBlanks(obj) === { value1: "valor1" }
   *
   * @param  {Object}    object Objeto a ser "limpado"
   * @return {Object}           Cópia do objeto sem os elementos em branco.
   */
  deleteBlanks(object) {
    let presents = _.cloneDeep(object)

    Object.keys(presents).forEach((key) => {
      let value = presents[key]

      if (_.isPlainObject(value) || _.isArray(value)) {
        presents[key] = objects.deleteBlanks(value)
        if (_.isEmpty(presents[key])) delete presents[key]

      } else if (value === null || value === undefined) {
        delete presents[key]
      }
    })
    return presents
  },

  /**
   * Busca por propriedades aninhadas em um Objeto, verificando a existência
   * do "caminho" todo.
   * Inspirado no método Ruby Object#dig
   *
   * Ex: let family = { parent: { child: "Okay!" } }
   *     =>
   *     objects.dig(family, 'parent', 'child')  === "Okay!"
   *     objects.dig(family, 'parent', 'child', 'grandchild')  === undefined
   *     objects.dig(family, 'no', 'not really') === undefined
   *
   * @param  {Object}    object Objeto a ser "perfurado"
   * @param  {...String} keys   Chaves sequenciais que definem o caminho da "perfuração"
   * @return {Object}           Valor da propriedade aninhada ou `undefined` se não existir
   */
  dig(object, ...keys) {
    let dug = object

    for (let i = 0; i < keys.length; i++) {
      if (!_.isObjectLike(dug)) {
        return dug
      }

      const key = keys[i]
      dug = dug[key]
    }

    return dug
  },

  /**
   * Cria um novo objeto transformando todas as propriedades em nomes no formato
   * 'snake_case'. Bastante útil na adaptação de objetos para envio de parâmetros
   * a APIs.
   *
   * Ex: let json = { first: 1, secondPlace: 2, ThirdOne: 3 }
   *     =>
   *     objects.snakeize(json) == { first: 1, second_place: 2, third_one: 3 }
   *
   * @param  {Object} object objeto a ter suas "chaves" transformadas
   * @return {Object}        objeto "clone", com todas as "chaves" em formato "snake_case"
   */
  snakeize(object) {
    let snakeized = _.cloneDeep(object)

    Object.keys(object).forEach((key) => {
      let value = object[key]

      // checks that a value is a plain object or an array - for recursive key conversion
      // recursively update keys of any values that are also objects
      if (_.isPlainObject(value) || _.isArray(value)) {
        value = objects.snakeize(value)
        snakeized[key] = value
      }

      const snakeizedKey = strings.snakeCase(key)
      if (snakeizedKey !== key) {
        snakeized[snakeizedKey] = value
        delete snakeized[key]
      }
    })

    return snakeized
  },

  // Metodos lodash
  clone:    _.cloneDeep,
  extend:   _.extend,
  merge:    _.merge,
  isEqual:  _.isEqual,
  contains: _.isMatch
})

export default objects

<doc lang="markdown">
  Card especifico para eventos de troca de situação de um imóvel
</doc>

<template lang="pug">
.card
  .header
    label.date {{ $l('time.formats.long', event.createdAt) }}
  .content
    .icon
      i.fa.fa-exchange
    p.message
      span.creator {{ creator }}
      span.action
        | {{ $t('.message') }}
        property-state-tag.state-tag(:value="event.from", size="micro")
        | {{ $t('.to') }}
        property-state-tag.state-tag(:value="event.to", size="micro")

  p.comment(v-if="hasComment") {{ event.comment }}

  hr
</template>

<script>
import Card from "./card.vue";

export default {
  extends: Card,

  props: {
    event: { type: Object, required: true },
  },

  data() {
    return {
      defaultI18nScope: "timeline.property-state-change-card",
    };
  },

  computed: {
    hasComment() {
      return isPresent(this.event.comment);
    },

    from() {
      return this.$t(`models.property.enums.state.${this.event.from}`);
    },

    to() {
      return this.$t(`models.property.enums.state.${this.event.to}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import "card";
</style>
<template lang="pug">
select-field(
  :name="name",
  :options="options",
  :includeBlank="true",
  :label="$t('admin.realEstateAgencies.leads.roleField.label')"
  :error="error"
)
</template>

<script>
  export default {
    data() {
      return {
        options: [
          { id: 'owner', text: this.optionTranslation('owner') },
          { id: 'founder', text: this.optionTranslation('founder') },
          { id: 'manager', text: this.optionTranslation('manager') },
          { id: 'director', text: this.optionTranslation('director') },
          { id: 'other', text: this.optionTranslation('other') }
        ]
      }
    },

    methods: {
      optionTranslation(option) {
        return this.$t(
          `admin.realEstateAgencies.leads.roleField.options.${option}`
        )
      }
    },

    props: {
      name: { type: String },
      error: { type: String }
    }
  }
</script>

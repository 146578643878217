<template lang="pug">
.person-field(:class="{ error, disabled, readonly }")
  label(v-if="!hideLabel",  :class="{ 'required': required }", :for="inputId") {{ labelText }}
  input(
    :id="inputId",
    :autocomplete="autocomplete",
    :autofocus="autofocus",
    :disabled="disabled",
    :readonly="readonly",
    :name="name",
    :placeholder="placeholder",
    type="text",
    :value="inputValue",
    :required="required",
    @input="onInput"
  )
  input-error(:message="error")
</template>

<script>
import InputField from "./input-field.vue";

export default {
  extends: InputField,

  props: {
    placeholder: { type: String, default: "" },
  },

  data() {
    return {
      inputValue: ""
    };
  },

  mounted() {
    this.inputValue = this.modelValue
  },

  methods: {
    formatValue(value) {
      if (/^\d{11}$/.test(value)) {
        return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
      }

      return value;
    },

    onInput(event) {
      this.inputValue = this.formatValue(event.target.value);

      this.$emit("update:modelValue", this.inputValue);
    },
  },
};
</script>

<style scoped lang="scss">
// TODO definir cor de erro!
$input-error-color: red;
$input-error-border-color: red;
$input-disabled-color: #ccc;
$input-disabled-border-color: #e3e3e3;

.person-field {
  margin-bottom: 1.5rem;
  label.required::after {
    content: " *";
    color: #9b53d7;
  }

  input {
    width: 100%;
    height: 38px;
    margin-bottom: 0;
    padding: 6px 10px;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
  }
}

// error
.person-field.error {
  label {
    color: $input-error-color;
  }

  input {
    border-color: $input-error-border-color;
  }
}

// disabled
.person-field.disabled {
  label {
    color: $input-disabled-color;
  }

  input {
    border-color: $input-disabled-border-color;
    color: $input-disabled-color;
  }
}

// readonly
.person-field.readonly {
  input {
    color: $second-color-dark;
  }
}
</style>

/**
 *   Define o comportamento para autenticar usuário.
 *
 *   Authenticate é um mixin para permitir a realização de autenticação
 *   no lado do cliente, garantindo a autenticação do usuário.
 *
 *   Para isso, é adicionado listeners para login, logout e para atualização
 *   de perfil, garantindo a reatividade do atributo `userName` sempre que atualizado.
 *
 *   Ao adicionar este mixin, o componente poderá utilizar:
 *     -  logout() - para desutenticar o usuário.
 *     -  userName - atributo que retorna o primeiro nome do usuário logado.
 *
 * @type {VueMixin}
 */
const authenticate = {
  // Dados "mergeados" no componente, para controle da ferramenta
  data() {
    return {
      authenticated: app.auth.isAuthenticated(),
      loggingOut:    false,
      userName:      this.buildUserName(),
      wildCard:      app.store.get('wildCard'),
      role:          app.auth.role,
      camelizedRole: app.auth.camelizedRole
    }
  },

  created() {
    app.$on('login', this.onLogin)
    app.$on('logout', this.onLogout)
    app.$on('update-user', this.updateUserName)

  },

  beforeUnmount() {
    app.$off('login', this.onLogin)
    app.$off('logout', this.onLogout)
    app.$off('update-user', this.updateUserName)
  },

  computed: {
    // overridable
    logoutRedirectRoute() {
      return { name: 'home' }
    },

    // overridable
    logoutUrl() {
      if (this.role === 'admin') return 'admin/sign_out'

      return 'real_estate/user/sign_out'
    }
  },

  methods: {
    // handler para click no botão de "login"
    login() {
      this.$router.push({ name: 'realEstateLogin'})
    },

    // handler para click no botão de "logout"
    logout() {
      this.loggingOut = true

      return this.$http.delete(this.logoutUrl)
        .then(() => {
          app.auth.clear()
          app.store.set('authenticated', false)

          this.$router.push(this.logoutRedirectRoute)
        })
        .catch(() => {
          // TODO feedback de falha
        })
        .then(() => {
          this.loggingOut = false
        })
    },

    // overridable
    buildUserName() {
      return app.auth.firstName
    },

    updateUserName() {
      this.userName = this.buildUserName()
    },

    // handler para evento de app "login"
    onLogin() {
      this.authenticated = true
    },

    // handler para evento de app "logout"
    onLogout() {
      this.authenticated = false
    }
  }
}

export default authenticate

// módulos da app
import auth from './modules/auth'
import authToken from './modules/auth_token'
import dom from './modules/dom'
import events from './modules/events'
import geocoder from './modules/geocoder'
import http from './modules/http'
import i18n from './modules/i18n'
import loading from './modules/loading'
import notifications from './modules/notifications'
import params from './modules/params'
import store from './modules/store'
import firebase from './modules/firebase'
import permissions from './modules/permissions'
import user from './modules/user'
import agency from './modules/agency'

import './app.scss'

// todo extrair módulo router.js
function flatMapRoutes(routes) {
  if (!routes) return []

  let map = []

  routes.forEach((route) => {
    map.push(route)
    if (route.children) {
      map = map.concat(flatMapRoutes(route.children))
    }
  })

  return map
}

const cache = {
  data:    undefined,
  started: false
}

const app = {
  env:   process.env.NODE_ENV,
  stage: process.env.VUE_APP_STAGE,

  get envIsProduction() {
    return this.env === 'production'
  },

  get stageIsProduction() {
    return this.stage === 'production'
  },

  // módulos da app
  auth,
  authToken,
  dom,
  events,
  geocoder,
  http,
  i18n,
  loading,
  notifications,
  params,
  store,
  firebase,
  permissions,
  user,
  agency,

  // "Proxy" para métodos bastante usados do módulo `events`
  $emit(...args) {
    return events.$emit(...args)
  },
  $on(...args) {
    return events.$on(...args)
  },
  $off(...args) {
    return events.$off(...args)
  },
  $once(...args) {
    return events.$once(...args)
  },

  // Dados "expostos" via tag <meta> pelo server.
  // Veja `ApplicationController#app_data`
  get data() {
    if (cache.data !== undefined) {
      return cache.data
    }

    cache.data = dom.fetchMetaJSON('app-data', {})
    return cache.data
  },

  /**
   * Estado de carregamento inicial (navegacional) da app.
   * É definido pelo Vue Mixin Tool, após o primeiro carregamento navegacional
   * de uma ferramenta - consumindo o payload em `app.data`.
   *
   * @return {Boolean} Carregamento navegacional inicial da app.
   */
  get started() {
    return cache.started
  },

  /**
   * Define o estado de carregamento inicial navegacional da app.
   * Não deve ser manipulado programaticamente! Quem o define é o Vue Mixin
   * `tool.js` - responsável pelo controle de carregamento síncrono
   * (navegacional - consome `app.data`) ou assíncrono (interativo - consome
   * uma XHR).
   *
   * @param  {Boolean} value Estado do carregamento inicial
   * @return {Boolean}       Novo estado de carregamento inicial
   */
  set started(value) {
    cache.started = !!value
  },

  // TODO extrair módulo router!
  get flattenRoutes() {
    if (this._flattenRoutes !== undefined) {
      return this._flattenRoutes
    }

    this._flattenRoutes = flatMapRoutes(this.routes)
    return this._flattenRoutes
  },


  findRouteByName(name) {
    return this.flattenRoutes.find(route => route.name === name)
  }
}

// XXX exportando "globalmente" - para window
window.app = app

export default app

<doc lang="markdown">
  Card especifico para eventos de alugel de um imóvel
</doc>

<template lang="pug">
.card
  .header
    label.date {{ $l('time.formats.long', event.createdAt) }}
  .content
    .icon
      i.fa.fa-handshake-o
    p.message
      span.creator {{ creator }}
      span.action {{ $t('.rent') }}

  hr
</template>

<script>
import Card from "./card.vue";

export default {
  extends: Card,

  props: {
    event: { type: Object, required: true },
  },

  data() {
    return {
      defaultI18nScope: "timeline.property-close-rent-card",
    };
  },
};
</script>

<style scoped lang="scss">
@import "card";

.content {
  .icon {
    line-height: 32px;

    .fa {
      font-size: 18px;
    }
  }
}
</style>
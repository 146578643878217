<template lang="pug">
.process-payment-situation
  step-info(
    :color="approved ? 'green' : 'red'",
    :info="$t(`.approved.${approved}`)"
  )
  step-table(
    v-if="formattedContracts.length",
    :stepName="stepTableName",
    :tableData="formattedContracts",
    :columnNames="columnNames",
    :dateColumns="dateColumns"
  )
</template>
<script>
import StepInfo from './format/step-info.vue'
import StepTable from './format/step-table.vue'

export default {
  components: { StepInfo, StepTable },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.scoreReports.scoreCard.scoreSteps.details.processPaymentSituation",

      // table info
      stepTableName: 'processPaymentSituation.contracts',
      columnNames: ['code', 'startDate', 'endDate'],
      dateColumns: ['startDate', 'endDate']
    }
  },

  props: {
    data: { type: Object, default: () => ({}) }
  },

  computed: {
    approved() {
      return this.data.disapprovalSituation != 'debtor'
    },

    formattedContracts() {
      const contracts = this.data.contracts

      if (!contracts) return []

      return contracts.map((contract) => ({
        code: contract.code,
        startDate: contract.startDate,
        endDate: contract.endDate,
        openData: {
          path: `/administracao/gerenciamento/contratos/${contract.id}`
        }
      }))
    }
  }
}
</script>
<style scoped lang="scss">
.process-payment-situation {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 1rem;
  height: 100%;
}
</style>

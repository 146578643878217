<template lang="pug">
Modal(v-model="isShow", :close="close")
  .modal
    h1 {{ $t('.title') }}

    form(ref="form" @submit.prevent="submit")
      file-field(
        type="file",
        name="file[file]",
        :accept="'.csv'",
        :label="$t('.label.file')",
        :error="errors?.file?.message",
        :reset="resetFile",
        @reseted="resetedFile"
        @input="val => { file = val }"
        required
        destroyable
      )

      select-field(
        v-model="selectedValue",
        name="file[kind]",
        :options="options",
        :label="$t('.label.kind')",
        :error="errors?.kind?.message",
        required
      )

    .buttons-container
      button.close(@click="close" :disabled="submitting")
        | {{ $t(".buttons.close") }}

      button.confirm(@click="submit" :disabled="disableSubmit")
        | {{ $t(".buttons.confirm") }}

</template>
<script>
import { ref } from 'vue'

export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "admin.realEstateAgencies.billsMassiveUpdate.uploadModal",

      // form
      resetFile: false,
      options: [{ id: 'csv', text: 'csv' }],

      // submit
      file: null,
      submitting: false,
      errors: {},
      realEstateAgency: null,
      selectedValue: '-1'
    }
  },

  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  props: {
    show: { type: Boolean, default: false },
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  computed: {
    disableSubmit() {
      return !this.file
        || this.selectedValue == '-1'
        || this.submitting
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },

    submit() {
      this.submitting = true

      const formData = new FormData(this.$refs.form)
      const config = { headers: { "Content-Type": "multipart/form-data" } }
      const basePath = '/admins/real-estate/agencies'
      const path = `${basePath}/${this.$route.params.id}/bulk-edit-bill/files`

      this.$http
        .post(path, formData, config)
        .then(() => {
          this.resetFile = true

          this.cleanModal()
          this.close()

          this.$emit("reloadFiles")

          this.$notifications.clear()
          this.$notifications.info(this.$t(".notifications.submit.success"))
        })
        .catch((err) => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))

          this.errors = err.response?.data?.errors || {}
          this.resetFile = true
        })
        .finally(() => {
          this.submitting = false
        })
    },

    resetedFile() {
      this.resetFile = false
    },

    cleanModal() {
      this.file = null
      this.realEstateAgency = null
      this.selectedValue = '-1'
    }
  }
}
</script>
<style scoped lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}

.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  height: fit-content;
  max-width: 60%;

  h1 {
    font-size: 1.7rem;
    font-weight: 550;
    color: $second-color-dark;
    margin-bottom: 1rem;
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;
    margin-top: 5rem;
    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}
</style>
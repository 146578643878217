<template lang="pug">
#page.agreement-page
  navbar(currentPage="adminManagementIndex")

  .agreement-container
    breadcrumbs(:items="breadcrumbItems")

    .contract-container
      expansible-card(:title="$t('.titles.contractInfos')", :expanded="true")
        .inline-section
          .columns
            h3.info-title {{ $t('.overview.code') }}
            p.info-content
              | {{ contract.code || '-' }}
          .columns
            h3.info-title {{ $t('.overview.tenantName') }}
            p.info-content
              | {{ contract.tenantName || '-' }}
          .columns
            h3.info-title {{ $t('.overview.agency') }}
            p.info-content
              | {{ contract.realEstateAgencyName || '-' }}

        .inline-section
          .columns
            h3.info-title {{ $t('.overview.propertyAddress') }}
            p.info-content
              | {{ contract.propertyAddress || '-' }}
          .columns
            h3.info-title {{ $t('.overview.unpaidInstallmentsCount') }}
            p.info-content
              | {{ contract.unpaidBillsCount || '-' }}
          .columns
            h3.info-title {{ $t('.overview.totalValue') }}
            p.info-content
              | {{ valueFormat(contract.unpaidBillsValue) }}

    .bills-container
      expansible-card(:title="$t('.titles.bills')", :expanded="true")
        .table-section
          .table-container
            table
              thead
                tr
                  th
                    .checkbox
                      input(
                        type="checkbox",
                        :checked="allChecked",
                        @change="toogleAllCheck"
                      )
                  th(v-for="name in columnNames")
                    p {{ $t(`.bills.table.${name}`) }}

              tbody
                tr(v-for="bill in bills")
                  td.checkbox
                    input(
                      type="checkbox",
                      @change="() => { toggleCheck(bill.id) }"
                      :checked="isChecked(bill.id) || allChecked"
                    )
                  td.value
                    | {{ valueFormat(bill.value) }}
                  td.due-date
                    | {{ $l('date.formats.default', bill.dueDate) || '-' }}
                  td.interest
                    | {{ valueFormat(bill.interestValue) }}
                  td.fine
                    | {{ valueFormat(bill.fineValue) }}
                  td.updated-value
                    | {{ valueFormat(bill.updatedValue) }}

                tr.loading(v-if="loading")
                  td.checkbox
                    .cell
                  td(v-for="cell in columnNames")
                    .cell
          footer
            p {{ $t('.footer.text', { count: bills.length }) }}

        .selected-container
          p {{ $t('.actions.selectedItems', { count: checkedIds.length }) }}

          button.button-primary(
            @click="showCreateAgreementModal = true",
            :disabled="checkedIds.length < 1"
          )
            | {{ $t('.actions.button.createAgreement') }}

  create-agreement-modal(
    :contractId="getContractParam",
    :billIds="checkedIds",
    :show="showCreateAgreementModal",
    @close="showCreateAgreementModal = false"
  )
</template>
<script>
import ExpansibleCard from "../../../components/expansible-card.vue"
import CheckboxField from '../../../../../../components/desktop/checkbox-field.vue'
import CreateAgreementModal from '../../../components/agreements/new/create-modal.vue'
import Overview from '../../../components/overview.vue'
import Navbar from "../../../components/navbar.vue"
import Breadcrumbs from '../../../../components/breadcrumbs.vue'

import objects from "lib/objects"
import currencyFormatter from 'lib/currency-formatter'

export default {
  components: {
    ExpansibleCard,
    CheckboxField,
    Overview,
    Navbar,
    Breadcrumbs,
    CreateAgreementModal
  },

  beforeCreate() {
    this.$permissions.requiredPermissions([
      { moduleName: 'financial_agreements_contracts', permissions: ['get'] },
      { moduleName: 'contracts', permissions: ['get'] },
      {
        moduleName: 'financial_agreements_contracts_bills',
        permissions: ['get']
      },
      { moduleName: 'pre_agreements', permissions: ['post'] },
      { moduleName: 'bill_services', permissions: ['get'] },
      { moduleName: 'agreements', permissions: ['post'] }
    ])
  },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.financial.agreement.new",

      // tool
      routePath: "administracao/financeiro",

      // overview
      contract: {},

      // table
      bills: [],
      loading: false,
      allChecked: false,
      checkedIds: [],

      // modal
      showCreateAgreementModal: false
    }
  },

  methods: {
    initContract(contract) {
      this.contract = objects.camelize(contract)
    },

    initBills(bills) {
      this.bills.push(...objects.camelize(bills))
    },

    fetch() {
      this.$http
        .get(`/admins/contracts/${this.getContractParam}.json`)
        .then((response) => {
          const contract = response.data

          this.initContract(contract)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.fetchBills()
        })
    },

    fetchBills() {
      this.loading = true

      this.$http
        .get(
          `/admins/financial/agreements/contracts/${this.getContractParam}/bills.json`
        )
        .then((response) => {
          const body = response.data
          const bills = body.data

          this.initBills(bills)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.loading = false
        })
    },

    valueFormat(value) {
      return value ? currencyFormatter.format(value) : '-'
    },

    parseRoute() { },

    isChecked(id) {
      return this.checkedIds.includes(id)
    },

    toogleAllCheck(event) {
      if (event.currentTarget.checked) {
        this.allChecked = true
        this.checkedIds = this.bills.map(bill => bill.id)

        return
      }

      this.allChecked = false
      this.checkedIds = []
    },

    toggleCheck(id) {
      if (this.isChecked(id)) {
        this.allChecked = false
        this.checkedIds.splice(this.checkedIds.indexOf(id), 1)

        return
      }

      this.checkedIds.push(id)

      if (this.checkedIds.length == this.bills.length) this.allChecked = true
    },
  },

  computed: {
    columnNames() {
      return ['value', 'dueDate', 'interest', 'fine', 'updatedValue']
    },

    getContractParam() {
      return this.$route.query.contract_id
    },

    breadcrumbItems() {
      return [
        {
          name: this.$t('.breadcrumbs.financial'),
          url: this.routePath
        },
        {
          name: this.$t('.breadcrumbs.agreements'),
          url:  this.routePath,
          hash: 'agreements'
        },
        {
          name: this.$t('.breadcrumbs.newAgreement'),
          url:  this.routePath,
          hash: 'agreements'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.agreement-container {
  width: 75%;
  height: 100vh;
  padding-top: 2rem;
  padding-bottom: 4rem;
  margin: auto;
}

.inline-section {
  display: flex;
  justify-content: space-between;
  word-break: break-word;

  .columns {
    width: 30%;
  }
}

.info-title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: $second-color-light;
}

.info-content {
  font-size: 1.5rem;
  font-family: Lato;
}

.table-container {
  height: 40vh !important;

  table {
    tbody {
      tr {
        height: 5rem !important;
      }
    }

    td {
      text-transform: capitalize !important;

      &.value, &.due-date, &.interest, &.fine, &.updated-value {
        min-width: 13rem !important;
      }
    }

    .checkbox {
      min-width: 0 !important;
      height: 100%;

      display: flex;
      align-items: center;

      padding-right: 1rem;
      border-right: 1.5px solid $grey-color-light;

      input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;

        background-color: #fff;

        color: $primary-color;

        width: 2.5rem;
        height: 2.5rem;
        margin: 0;

        border: 0.16rem solid $second-color-light;
        border-radius: 0.2rem;

        transform: translateY(0.15rem);

        display: grid;
        place-content: center;

        &::before {
          content: "";
          width: 2.5rem;
          height: 2.5rem;

          transform: scale(0);
          transition: 120ms transform ease-in-out;

          box-shadow: inset 2.5em 2.5em $primary-color;
          border-radius: 0.2rem;
        }

        &:checked::before {
          transform: scale(1);
        }
      }
    }
  }
}

footer {
  p {
    margin: 0;
  }
}

.selected-container {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;

  p {
    font-size: 1.4rem;

    margin: 0;
  }

  button {
    font-size: 1rem;

    padding: 0.5 1rem;
    margin: 0
  }
}
</style>
<template lang="pug">
Modal(class="details-modal" :show="show" :onClose="() => { $emit('close') }")
  .details
    h1.title {{ currentStep.formattedName || '-' }}

    process-payment-situation(
      v-if="isCurrent('alpopProcessPaymentSituation')",
      :data="currentStepData"
    )
    block-list(
      v-if="isCurrent('alpopBlockList')",
      :data="currentStepData"
    )
    family-holdings(
      v-if="isCurrent('proScoreFamilyHoldings')",
      :data="currentStepData"
    )
    bounced-checks(
      v-if="isCurrent('proScoreBouncedChecks')",
      :data="currentStepData"
    )
    trials(
      v-if="isCurrent('proScoreTrials')",
      :data="currentStepData"
    )
    presumed-income(
      v-if="isCurrent('proScorePresumedIncome')",
      :data="currentStepData"
    )
    serasa(
      v-if="isCurrent('serasaFintechReport')",
      :data="currentStepData"
    )
    boa-vista-acerta-essencial(
      v-if="isCurrent('boaVistaAcertaEssencial')",
      :data="currentStepData"
    )
    commercial-relations(
      v-if="isCurrent('proScoreCommercialRelations')",
      :data="currentStepData"
    )
    predictions(
      v-if="isCurrent('predictions')",
      :data="currentStepData"
    )
    provenir-big-data-corp(
      v-if="isCurrent('provenirBigDataCorp')",
      :data="currentStepData"
    )
</template>
<script>
import strings from "lib/strings"
import objects from "lib/objects"

import Modal from "../../../../../../../components/modal.vue"
import ProcessPaymentSituation from "./details/process-payment-situation.vue"
import BlockList from "./details/block-list.vue"
import FamilyHoldings from "./details/family-holdings.vue"
import BouncedChecks from "./details/bounced-checks.vue"
import Trials from "./details/trials.vue"
import PresumedIncome from "./details/presumed-income.vue"
import Serasa from "./details/serasa.vue"
import BoaVistaAcertaEssencial from "./details/boa-vista-acerta-essencial.vue"
import CommercialRelations from "./details/commercial-relations.vue"
import Predictions from "./details/predictions.vue"
import ProvenirBigDataCorp from "./details/provenir-big-data-corp.vue"

export default {
  components: {
    Modal,
    ProcessPaymentSituation,
    BlockList,
    FamilyHoldings,
    BouncedChecks,
    Trials,
    PresumedIncome,
    Serasa,
    BoaVistaAcertaEssencial,
    CommercialRelations,
    Predictions,
    ProvenirBigDataCorp
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.scoreReports.scoreCard.scoreSteps.details",

      stepsData: {},
    }
  },

  props: {
    show: { type: Boolean, default: false },
    scoreId: { type: Number, required: true },
    currentStep: { type: Object, required: true }
  },

  watch: {
    currentStep() {
      this.getStepData()
    }
  },

  computed: {
    currentStepName() {
      return strings.camelize(this.currentStep.name)
    },

    currentStepData() {
      return this.stepsData[this.currentStepName]
    }
  },

  methods: {
    getStepData() {
      return this.$http
        .get(
          `/admins/scores/${this.scoreId}/steps/${this.currentStep.id}.json`
        )
        .then(({ data }) => {
          const stepData = data.data

          if (stepData) {
            const camelizedName = strings.camelize(data.name)
            const camelizedData = objects.camelize(stepData)

            this.stepsData[camelizedName] = camelizedData
          }
        })
        .catch(() => {
          this.$notifications.error(
            this.$t(".notifications.getStepData.failure")
          )
        })
    },

    isCurrent(stepName) {
      return this.currentStepName == stepName
    }
  }
}
</script>
<style scoped lang="scss">
.title {
  font-size: 1.8rem;
  font-weight: 550;
  margin-bottom: 1rem;
  color: $primary-color;
}

.details {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}
</style>

<style>
.details-modal .modal {
  height: 90vh;
  width: 90vw;
}

@media (orientation: portrait) {
  .details-modal .modal {
    transform: rotate(-90deg);
    height: 90vw;
    width: 90vh;
  }
}
</style>

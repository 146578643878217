<template lang="pug">
#page.team-page
  navbar

  loading-div.team-container-phone
    .team-container
      breadcrumbs(
        :items="currentPage == 'attendants' ? breadcrumbAttendant : breadcrumbManager"
      )

      expansible-card.menu-container(:title="$t('.menu')", :expanded="true")
        .menu-items
          button(
            :class="currentPage == 'attendants' && 'actived'",
            @click="currentPage = 'attendants'"
          )
            | {{ $t('.attendants') }}

          button(
            v-if="$user.isRealEstateAdmin",
            :class="currentPage == 'managers' && 'actived'",
            @click="currentPage = 'managers'"
          )
            | {{ $t('.managers') }}

      attendants-page(v-if="currentPage == 'attendants'")
      managers-page(v-if="currentPage == 'managers'")
</template>
<script>
import tool from "lib/vue-tool"
import vueAuthenticate from "lib/vue-authenticate"
import Navbar from "../../components/navbar.vue"
import Breadcrumbs from '../../../components/breadcrumbs.vue'
import ExpansibleCard from "../../../components/expansible-card.vue"
import AttendantsPage from "../../components/team/attendants-page.vue"
import ManagersPage from "../../components/team/managers-page.vue"

export default {
  mixins: [tool, vueAuthenticate],
  components: {
    Navbar, Breadcrumbs, ExpansibleCard, AttendantsPage, ManagersPage
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.team.index",

      // tool
      routeName: "realEstateTeamIndex",
      routePath: "imobiliaria/equipe",
      viewName: "index",

      currentPage: 'attendants',

      loading: true
    }
  },

  computed: {
    breadcrumbAttendant() {
      return [
        {
          name: this.$t('.team'),
          url: this.routePath
        },
        {
          name: this.$t('.attendants'),
          url: this.routePath
        }
      ]
    },

    breadcrumbManager() {
      return [
        {
          name: this.$t('.team'),
          url: this.routePath
        },
        {
          name: this.$t('.managers'),
          url: this.routePath
        }
      ]
    }
  },

  methods: {
    fetch() {},

    parseRoute() {}
  }
}
</script>

<style lang="scss" scoped>
.team-container-phone {
  padding: 0rem;
  width: 100vw;

  .menu-container {
    .menu-items {
      width: 100%;
      height: max-content;

      button {
        text-align: start;
        color: $second-color-light;
        font-weight: 400;
        border-radius: 0.5rem;
        border: none;
        padding: 0.5rem 1.5rem;
        height: auto;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: none;

        &.actived{
          background-color: $primary-high-light;
          color: $primary-color;
        }
      }
    }
  }

  .team-container {
    width: 100%;
    padding: 2.5rem;
    padding-bottom: 10rem;
  }
}
</style>
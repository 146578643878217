<template lang="pug">
.card-container
  .loading-container(v-if="status == 'wip'")
    .loader {{ $t('.loading') }}
    .loading-info {{ $t('.loadingInfo') }}

  .data-container
    div
      h3 {{ $t('.fields.cpfConsulted') }}
      expansible-container(:fixedChildren="1")
        .cpf-content(v-for="score in scores")
          .name-container
            h4 {{ $t('.fields.fullName') }}:
            p.name {{ score.name || '***** ** *****' }}

          .cpf-container
            h4 {{ $t('.fields.cpf') }}:
            p.cpf {{ score.cpf }}

    div
      h3 {{ $t('.fields.infoTitle') }}
      expansible-container(:fixedChildren="3")
        .valid-until-container(v-if="!contract")
          h4 {{ $t('.fields.validUntil') }}:
          p {{ $l('date.formats.default', validUntil) || '-' }}

        .contract-code-container(v-if="contract")
          h4 {{ $t('.fields.contractCode') }}:
          p {{ contract.code }}

        .property-type-container
          h4 {{ $t('.fields.propertyType') }}:
          p {{ $t(`.propertyTypes.${propertyType}`) }}

        .contract-value-container(v-if="approved")
          h4 {{ $t('.fields.contractValue') }}:
          p.contract-value
            | R$ {{ contractOpeningValue && contractOpeningValue.toFixed(2) }}

        .property-address-container(v-if="contract")
          h4 {{ $t('.fields.propertyAddress') }}:
          p {{ getAddress }}

      .flag.done-flag(:class="getPredictionClass") {{ getPredictionText }}

    .buttons-container
      router-link.button.button-primary.newContract(
        v-if="contract",
        :to="{ name: 'realEstateContractShow', params: { id: contract.id } }",
      ) {{ $t('.buttons.hasContract') }}

      router-link.button.button-primary.newContract(
        :to="{ name: 'realEstateContractNew', query: { score_report_id: id } }",
        :class="{ disabled: $user.isLocked || !isValid }"
        v-if="!contract"
      ) {{ $t('.buttons.newContract') }}

      button.remove-contract(
        :disabled="$user.isLocked",
        v-if="!contract",
        @click="showConfirmationModal"
      ) {{ $t('.buttons.removeContract') }}

  confirmation-modal(
    @close="showModal = false",
    :show="showModal",
    :title="$t('.modal.title')",
    :message="$t('.modal.message')",
    :callback="removeContract"
  )
</template>

<script>
import ConfirmationModal from "../../../components/confirmation-modal.vue";
import ExpansibleContainer from "../../../components/expansible-container.vue";
export default {
  components: { ConfirmationModal, ExpansibleContainer },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.contract.card",

      submitting: false,
      showModal: false
    }
  },

  props: {
    id: { type: Number, required: true },
    scores: { type: Array, default: () => { return [{}] } },
    prediction: { default: 'unexecuted' },
    approved: { type: Boolean, default: false },
    status: { type: String, default: 'wip' },
    propertyType: { type: String, required: true },
    contract: { type: Object },
    fetchCallback: { type: Function, required: true },
    contractOpeningValue: { type: Number },
    validUntil: { type: String }
  },

  computed: {
    getPredictionClass() {
      if (this.discarded) { return 'discarded' }
      if (this.isValid) { return 'approved' }

      return 'defeated'
    },

    discarded() {
      return this.contract && this.contract.discardedAt
    },

    getAddress() {
      if (!this.contract.propertyAttributes.street) return '-'

      return this.contract.propertyAttributes.street + ", " + this.contract.propertyAttributes.number +
             " - " + this.contract.propertyAttributes.neighborhood + ", " + this.contract.propertyAttributes.city +
             " - " + this.contract.propertyAttributes.state + ", " + this.contract.propertyAttributes.zipCode
    },

    getPredictionText() {
      if (this.discarded) {
        return this.$t('.discarded')
      }

      if (this.isValid) {
        return this.$t('.predictions.approved', { prediction: this.prediction });
      }

      return this.$t('.defeated')
    },

    isValid() {
      return this.contract || (!this.validUntil || new Date(this.validUntil) >= new Date())
    }
  },

  methods: {
    showConfirmationModal() {
      this.showModal = true
    },

    removeContract() {
      const data = {
        score_report: {
          sent_to_contract: false
        }
      }

      this.$http
        .put(`${this.$user.prefixUrlAgency}/score-reports/${this.id}`, data)
        .then(() => {
          // apaga possíveis notificações de erro anteriores
          this.errors = {};
          this.$notifications.clear();
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.removeContract.failure"));
        })
        .then(() => { this.fetchCallback(); });
    },
  }
}
</script>

<style scoped lang="scss">
.card-container {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  position: relative;
  max-width: 100%;
  padding: 2rem;
  height: fit-content;

  .data-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(235, 235, 235, 0.85);
    margin: -2rem 0 0 -2rem;
    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 1.7rem;
    font-weight: 400;
    color: $primary-color;
  }

  .loading-info {
    font-size: 1.1rem;
  }

  h3 {
    font-size: 1.7rem;
    font-weight: 500;
    color: $second-color-light;
    margin: 0;
    margin-bottom: 1rem;
    text-align: center;
  }

  .cpf-content {
    margin-bottom: 1rem;
  }

  .newContract.disabled {
    opacity: 0.5;
    pointer-events: none;

    background-color: $grey-color;
    border-color: $grey-color;
  }

  .valid-until-container,
  .name-container,
  .cpf-container,
  .property-address-container,
  .contract-code-container,
  .property-type-container,
  .contract-value-container {
    display: flex;

    h4 {
      font-size: 1.5rem;
      font-weight: 700;
      margin: 0;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      min-width: max-content;
    }

    p {
      font-size: 1.5rem;
      color: $second-color;
      margin: 0;
      text-overflow: ellipsis;
      white-space: break-spaces;
    }
  }

  .flag {
    padding: 1rem;

    font-size: 1.3rem;
    font-weight: 500;
    color: $white-color;
    width: fit-content;
    margin-top: 0.5rem;
    border-radius: 0.6rem;

    &.unapproved, &.debtor {
      background-color: $red-color;
    }

    &.approved {
      background-color: $green-color;
    }

    &.error, &.unexecuted, &.defeated, &.discarded {
      background-color: $yellow-color;
    }
  }

  .buttons-container {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    justify-content: flex-end;

    margin-top: 2.5rem;
  }

  button.remove-contract {
    background-color: $white-color;
    color: $red-color;
    border: 1.5px solid $red-color;
  }

  button.details {
    border: 1.5px solid $primary-color;
    width: 100%;
    color: $primary-color;
  }

  @media (max-width: 450px) {
    .buttons-container {
      flex-direction: column;

      button {
        width: 100%;
        margin: 0;
      }
    }
  }

  .loader {
    border: 8px solid transparent;
    border-radius: 50%;
    border-top: 8px solid $primary-color;
    width: 70px;
    height: 70px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-bottom: 1rem;
  }

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
</style>

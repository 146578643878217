<template lang="pug">
div.progress-bar
  div.progress-bar__fill(:class="{ 'loaded': loaded }")
</template>

<script>
export default {
  props: {
    loaded: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
  .progress-bar {
    width: 100%;
    height: 2.5rem;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    margin: 2rem 0;

    &__fill {
      height: 100%;
      background-color: $primary-color;
      transition: width 1s ease-in-out;

      &.loaded {
        width: 100%;
      }
    }

    .progress-bar__fill:not(.loaded) {
      width: 80%;
      animation: load-animation 10s ease-in-out;
    }

    @keyframes load-animation {
      from {
        width: 0%;
      }
      to {
        width: 80%;
      }
    }
  }
</style>
<doc lang="markdown">
  Card com inforações referente ao evento ocorrido.
  Classe base com informações em comum a todos os _cards_.
  Não devemos adicionar nenhum conteúdo em _template_ pois este
  componente tem como papel ser extendido por outros componentes
  de cards especializados em um evento.
</doc>

<script>
export default {
  computed: {
    creator() {
      let creatorType = this.$t(`models.${this.event.creatorType}`, {
        count: 1,
      });
      let creatorTypeAbbr = creatorType.toUpperCase().substring(0, 2);

      return `@${creatorTypeAbbr} ${this.event.creatorName}`;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
<template lang="pug">
.wrapper
  //- XXX implementando botão de "clear" aqui mesmo pois o JS do Google Maps
  //-  precisa de acesso ao elemento <input>!
  input(
    ref="input",
    type="text",
    :name="name",
    :value="value",
    :autofocus="autofocus",
    :placeholder="placeholder",
    @focus="refreshPlaceholder",
    @input="$emit('input', $event.target.value)",
    @keyup.esc="clear",
    @keypress.enter.prevent=""
  )
  button.clear(v-show="value", type="button", @click="clear(true)") ×
</template>

<script>
import objects from "lib/objects";

export default {
  props: {
    // Indica se deve tentar achar a localização atual caso não exista
    // um valor pré-definido de endereço.
    autoGeolocate: { type: Boolean, default: true },
    autofocus: { type: Boolean, default: false },
    country: { type: String, default: null },
    enableGeolocation: { type: Boolean, default: false },

    // Atributo "name" do <input>. Útil para utilizá-lo em forms.
    name: { type: String, default: null },
    placeholder: { type: String, default: "" },
    types: { type: String, default: "" },
    value: { type: String, default: null },
  },

  data() {
    return {
      defaultI18nScope: "components.places-autocomplete",

      place: {
        address: this.value,
        lat: null,
        lng: null,
        bounds: {},
      },

      // google autocomplete instance
      autocomplete: null,

      // google geocoder instance
      geocoder: null,

      // flag indicando se está tentando localizar (nagivator.geolocation)
      geolocating: false,
    };
  },

  methods: {
    clear(focus = false) {
      this.$refs.input.value = "";

      if (focus) this.$refs.input.focus();

      this.updatePlace(null);
    },

    focus() {
      this.$refs.input.focus();
    },

    blur() {
      this.$refs.input.blur();
    },

    hasFocus() {
      this.$refs.input.hasFocus();
    },

    refreshPlaceholder() {
      this.$refs.input.setAttribute("placeholder", this.placeholder);
    },

    geolocate() {
      if (!this.enableGeolocation || !navigator.geolocation) {
        return;
      }

      this.geolocating = true;

      navigator.geolocation.getCurrentPosition(
        (position) => {
          let location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          // Bias the autocomplete object to the user's geographical location
          let circle = new google.maps.Circle({
            center: location,
            radius: position.coords.accuracy,
          });
          this.autocomplete.setBounds(circle.getBounds());

          // Reverse geocoding - finding the location address name
          this.geocoder.geocode({ location }, (results, status) => {
            if (status === "OK" && results[1]) {
              // Ignora o processamento caso o componente já tenha sido
              // removido do DOM (Promise - assincronia)
              if (!objects.dig(this, "$refs", "input")) return;

              // results[1] is a googlePlace
              // XXX preenchendo resultado no input apenas se o usuário não
              // o modificou (nem está com foco nele)
              if (!this.$refs.input.value) {
                this.updatePlace(results[1]);
              }
            } else {
              this.$notifications.error(this.$t(".geocode.error"));
            }

            this.geolocating = false;
          });
        },
        (error) => {
          if (!error.PERMISSION_DENIED) {
            this.$notifications.error(this.$t(".geolocate.error"));
          }

          this.geolocating = false;
        }
      );
    },

    updatePlace(googlePlace) {
      if (!googlePlace) {
        this.place = this._blankGooglePlace();
      } else {
        this.place = this._googlePlaceToJSON(googlePlace);
      }

      this.$refs.input.value = this.place.address;

      this.$emit("input", this.place.address);
      this.$emit("place-changed", this.place);
    },

    _googlePlaceToJSON(googlePlace) {
      let location = googlePlace.geometry.location;
      let viewport = googlePlace.geometry.viewport;
      let bounds = {};

      // XXX: after Google Places API update, there's a new way to fetch lat/lng of bounds
      if (typeof viewport.getNorthEast === "function") {
        let northeast = viewport.getNorthEast();
        let southwest = viewport.getSouthWest();

        bounds = {
          north: northeast.lat(),
          east: northeast.lng(),
          south: southwest.lat(),
          west: southwest.lng(),
        };
      } else {
        // old code. probably not working anymore after Google Maps API update
        bounds = {
          north: viewport.f.f,
          south: viewport.f.b,
          west: viewport.b.b,
          east: viewport.b.f,
        };
      }

      return {
        bounds,
        viewport: googlePlace.geometry.viewport,
        location: googlePlace.geometry.location,
        address: googlePlace.formatted_address,
        lat: location.lat(),
        lng: location.lng(),
      };
    },

    _blankGooglePlace() {
      return {
        address: null,
        lat: null,
        lng: null,
        bounds: null,
      };
    },
  },

  mounted() {
    const options = {};

    if (this.types) {
      options.types = [this.types];
    }

    if (this.country) {
      options.componentRestrictions = {
        country: this.country,
      };
    }

    app.loadGoogleMaps().then((google) => {
      // Ignora o processamento caso o componente já tenha sido
      // removido do DOM (Promise - assincronia)
      if (!objects.dig(this, "$refs", "input")) return;

      this.autocomplete = new google.maps.places.Autocomplete(
        this.$refs.input,
        options
      );
      this.geocoder = new google.maps.Geocoder();

      this.autocomplete.addListener("place_changed", () => {
        let googlePlace = this.autocomplete.getPlace();
        if (!googlePlace.geometry) {
          // TODO feedback UI para erro - não há informações sobre o local
          console.warn(
            "[vue:places-autocomplete] nenhuma informação sobre o local"
          );
          return;
        }

        // HACK ignorando propriedade "formatted address", preservando o texto
        // exibido pelo autocomplete do Google (conhecendo o funcionamento do
        // `this.updatePlace`)
        googlePlace.formatted_address = this.$refs.input.value;
        this.updatePlace(googlePlace);
      });

      // bootstraping: se não houver valor de endereço, tente utilizar
      // a localização atual (Geolocation API)
      if (this.autoGeolocate && !this.value) {
        this.geolocate();
      }
    });
  },

  beforeUnmount() {
    let node = document.querySelector(".pac-container");
    if (node && node.parentNode) node.parentNode.removeChild(node);
  },

  watch: {
    geolocating(geolocating) {
      let placeholder = this.placeholder;

      if (geolocating) {
        placeholder = this.$t(".geolocating");
      }

      this.$refs.input.setAttribute("placeholder", placeholder);
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
  display: inline-block;
}

.wrapper > input {
  padding-right: 1.4em;
  width: 100%;
}

.clear {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.6em;
  padding: 0 0.4em;
  line-height: 1em;
  outline: 0;
  border: 0;
  cursor: pointer;
  color: #c9c9c9;
}
</style>
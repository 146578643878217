<template lang="pug">
.transfers-index
  expansible-card(:title="$t('.titles.overview')", :expanded="true")
    overview(:items="overviewItems")

  .transfers-index-container
    card
      .filtering-container
        h2 {{ $t('.titles.index') }}

        .actions
          dropdown(
            :callbacks="dropDownCallbacks",
            icon="fa-download",
            orientation="left"
          )
          button.icon.button-primary(
            @click="filterOpened = !filterOpened"
            :class="{ 'opened': filterOpened }"
          )
            i.fa.fa-filter

          button.icon.button-blue(@click.prevent="showNewTransferModal = true")
            i.fa.fa-plus

      filter-container(
        :filter="filter"
        :opened="filterOpened"
        :i18nPath="defaultI18nScope"
      )
        date-field(
          v-model="query.liquidationDate",
          :label="$t('.label.liquidationDate')",
          :range="true",
          name="liquidation_date"
        )

        date-field(
          v-model="query.dueDate",
          :label="$t('.label.dueDate')",
          :range="true",
          name="due_date"
        )

        agency-field(
          v-if="!this.contractId"
          v-model="query.realEstateAgency",
          name="real_estate_agency",
          :label="$t('.label.realEstateAgency')",
          fetchUrl="/admins",
          path="real-estate/agencies"
        )
          option(value="-1") {{ $t('none') }}

        input-field(
          v-if="!this.contractId"
          v-model="query.contractCode",
          name="contract_code",
          :label="$t('.label.contractCode')",
          :placeholder="$t('.placeholder.contractCode')"
        )

        person-field(
          v-model="query.tenant",
          name="tenant",
          :label="$t('.label.tenant')",
          :placeholder="$t('.placeholder.tenant')"
        )

        select-field(
          v-model="query.issuer"
          name="issuer"
          :label="$t('.label.issuer')"
          :options="issuerOptions"
          multiple
        )

      .table-section
        .table-container(@scroll="autoLoad")
          table
            thead
              tr
                th
                  .checkbox
                    input(
                      type="checkbox",
                      @change="toogleAllInPageCheck"
                      :checked="allPageChecked || allChecked"
                    )
                th(v-for="name in columnNames")
                  p {{ $t(`.table.columns.${name}`) }}

            tbody
              tr(v-for="transfer in transfers")
                td.checkbox
                  input(
                    type="checkbox",
                    @change="() => { toggleCheck(transfer.id) }"
                    :checked="isChecked(transfer.id) || allChecked"
                  )
                td.contract(@click="openTransfer(transfer.id)")
                  .contract-info
                    span
                      b {{ $t('.table.contractInfo.code', { contractCode: transfer.contractCode }) }}
                    span
                      | {{ `, ${transfer.realEstateAgencyName}, ` }}
                    span
                      | {{ transfer.contractAddress }}
                td.due-date(@click="openTransfer(transfer.id)")
                  | {{ $l('date.formats.default', transfer.dueDate) }}
                td.transfer-date(@click="openTransfer(transfer.id)")
                  | {{ $l('date.formats.default', transfer.liquidationDate) }}
                td.value(@click="openTransfer(transfer.id)")
                  | {{ $t('.table.currency', { value: transfer.value }) }}
                td.status(@click="openTransfer(transfer.id)")
                  .status-container(:class="transfer.status")
                    | {{ transferTranslate(`statusOptions.${transfer.status}`) }}
              tr.loading(v-if="loading")
                td.checkbox
                  .cell
                td(v-for="cell in columnNames")
                  .cell
        footer
          p {{ $t('.pagination.text', { length: transfers.length, total: pagination.total }) }}

      .actions-container
        table-selection-field(:callback="toogleAllChecked")

        .selected-container
          p {{ $t('.table.actions.selectedItems', { count: checkedCount }) }}

          .buttons-container
            button.button-primary(
              @click="batchUpdate(true)",
              :disabled="checkedCount < 1"
            )
              | {{ $t('.table.actions.buttons.sendToFinance') }}

            button.button-red(
              @click="batchUpdate(false)",
              :disabled="checkedCount < 1"
            )
              | {{ $t('.table.actions.buttons.removeFromFinance') }}

  create-modal(
    :show="showNewTransferModal"
    :contractId="contractId"
    @close="showNewTransferModal = false"
  )

</template>
<script>
import ExpansibleCard from "../expansible-card.vue"
import AgencyField from "../../../components/agency-field.vue"
import CheckboxField from "../../../../../components/desktop/checkbox-field.vue"
import TableSelectionField from "../../../../../components/desktop/table-selection-field.vue"
import Overview from "../overview.vue"
import CreateModal from "./create-modal.vue"
import FilterContainer from "../../../components/filter-container.vue"

import objects from "lib/objects"

export default {
  components: {
    ExpansibleCard,
    AgencyField,
    CheckboxField,
    TableSelectionField,
    Overview,
    CreateModal,
    FilterContainer
  },

  mounted() {
    this.fetch()
    this.fetchOverview()
  },

  props: {
    contractId: { type: Number, default: null }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.transfers.index",

      // filter
      filterOpened: false,

      query: {
        realEstateAgency: null,
        contractCode: null,
        liquidationDate: null,
        tenant: null,
        issuer: null,
        dueDate: (() => {
          if (this.contractId) return null

          const date = new Date()
          const firstDayOfMonth = new Date(
            date.getFullYear(), date.getMonth(), 1
          )
          const lastDayOfMonth = new Date(
            date.getFullYear(), date.getMonth() + 1, 0
          )

          return [firstDayOfMonth, lastDayOfMonth]
        })()
      },

      // table
      transfers: [],
      pagination: { total: 0 },
      loading: false,
      allPageChecked: false,
      allChecked: false,
      checkedIds: [],

      // modal
      showNewTransferModal: false,

      // overview
      overviewData: {
        total: { count: 0 },
        pending: { count: 0 },
        transfered: { count: 0 }
      }
    }
  },

  methods: {
    init(transfers, pagination) {
      this.transfers.push(...objects.camelize(transfers))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total"),
      }
    },

    filter() {
      this.replaceQuery()

      this.transfers = []

      this.fetch()
      this.fetchOverview()
      this.filterOpened = false
    },

    fetch(page = null) {
      this.loading = true

      this.$http
        .get(`/admins/transfers.json`, { params: this.httpParams(page) })
        .then((response) => {
          const body = response.data
          const transfers = body.data
          const pagination = body.pagination || {}

          this.init(transfers, pagination)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.allPageChecked = false
          this.loading = false
        })
    },

    fetchOverview() {
      this.$http
        .get(`/admins/infos/transfers.json`, { params: this.httpParams() })
        .then((response) => {
          const overview = response.data

          this.overviewData = overview
        })
        .catch(() => {
          this.overviewData = {
            total: { count: 0 },
            pending: { count: 0 },
            transfered: { count: 0 }
          }

          this.$notifications.error(
            this.$t(".notifications.fetchOverview.failure")
          )
        })
    },

    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target
      const loadingHeight = scrollHeight - 5

      if ((scrollTop + clientHeight < loadingHeight) || !this.pagination.next) {
        return
      }

      this.loading = true

      this.fetch(this.pagination.next)
    },

    formattedRangeFilter(range) {
      if (!range) return null

      return { start_date: range[0], end_date: range[1] }
    },

    httpParams(page = null) {
      return this.$params.toRouteParams({
        due_date: this.formattedRangeFilter(this.query.dueDate),
        liquidation_date: this.formattedRangeFilter(this.query.liquidationDate),
        real_estate_agency: this.query.realEstateAgency,
        contract_code: this.query.contractCode,
        contractId: this.contractId,
        tenant: this.query.tenant,
        issuer: this.query.issuer,
        page: page
      })
    },

    batchUpdateParams(sendToFinance) {
      if (this.allChecked) {
        return {
          status: sendToFinance ? "sent_to_finance" : "waiting",
          select_all: true,
          due_date: this.formattedRangeFilter(this.query.dueDate),
          liquidation_date: this.formattedRangeFilter(this.query.liquidationDate),
          real_estate_agency: this.query.realEstateAgency,
          contract_code: this.query.contractCode,
          tenant: this.query.tenant,
          issuer: this.query.issuer,
          except_ids: this.checkedIds,
          contract_id: this.contractId
        }
      }

      return {
        status: sendToFinance ? "sent_to_finance" : "waiting",
        transfer_ids: this.checkedIds,
        contract_id: this.contractId
      }
    },

    batchUpdate(sendToFinance) {
      this.submitting = true

      this.$http
        .post(
          "/admins/transfers/batch-changes",
          this.batchUpdateParams(sendToFinance)
        )
        .then(() => {
          this.allPageChecked = false
          this.allChecked = false
          this.checkedIds = []

          this.$notifications.info(
            this.$t(".notifications.batchUpdate.success")
          )

          this.filter()
        })
        .catch(() => {
          this.$notifications.error(
            this.$t(".notifications.batchUpdate.failure")
          )
        })
        .finally(() => {
          this.submitting = false
        })
    },

    parseRoute() {},

    isChecked(id) {
      return this.checkedIds.includes(id)
    },

    toogleAllChecked(event) {
      this.tableSelectionField = null
      this.allPageChecked = false
      this.checkedIds = []
      this.allChecked = event == 1 ? true : false
    },

    toogleAllInPageCheck(event) {
      this.allChecked = false

      if (event.currentTarget.checked) {
        this.allPageChecked = true
        this.checkedIds = this.transfers.map((transfer) => transfer.id)

        return
      }

      this.allPageChecked = false
      this.checkedIds = []
    },

    toggleCheck(id) {
      if (this.isChecked(id)) {
        this.allPageChecked = false
        this.checkedIds.splice(this.checkedIds.indexOf(id), 1)

        return
      }

      this.checkedIds.push(id)
    },

    openTransfer(transferId) {
      this.$router.push({
        path: `/administracao/gerenciamento/repasses/${transferId}`
      })
    },

    transferTranslate(attribute) {
      return this.$t(`models.transfer.attributes.${attribute}`)
    }
  },

  computed: {
    overviewItems() {
      return [
        {
          description: this.$t(".overview.total"),
          quantity: this.overviewData.total.count
        },
        {
          description: this.$t(".overview.pending"),
          quantity: this.overviewData.pending.count
        },
        {
          description: this.$t(".overview.transfered"),
          quantity: this.overviewData.transfered.count
        }
      ]
    },

    columnNames() {
      return ["contractInfo", "dueDate", "transfer", "value", "status"]
    },

    checkedCount() {
      if (this.allChecked)
        return this.pagination.total - this.checkedIds.length

      return this.checkedIds.length
    },

    issuerOptions() {
      const options = ['superlogica', 'alpop']

      return options.map((option) => ({
        id: option,
        text: this.transferTranslate(`issuerOptions.${option}`)
      }))
    }
  }
}
</script>
<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: repeat(3, auto) repeat(2, max-content);
  column-gap: 0.5em;

  .button-primary {
    margin-bottom: 1.4rem;
    margin-top: auto;
  }

  .new-transfer-container {
    margin-bottom: 1.4rem;
    margin-top: auto;

    padding-left: 0.5em;
    border-left: 2px solid $grey-color;

    .button-blue {
      margin: 0;
      padding: 0 1.5rem;
    }
  }
}

.actions-container {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  .selected-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
      font-size: 1.4rem;

      margin: 0;
    }

    .buttons-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      button {
        flex: 1;
        font-size: 1rem;

        padding: 0.5 1rem;
        margin: 0;
      }
    }
  }
}

.filtering-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 2rem;
    font-weight: 500;
    color: $primary-color;
  }

  .actions {
    display: flex;

    button.opened {
      opacity: 0.5;
    }

    button.icon {
      font-size: 1.5rem;
      width: 4rem;
      padding: 0;
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 5rem;

  p,
  button {
    margin: 0;
  }
}

.table-section {
  margin-top: 1.5rem;
}

.table-container {
  height: 40vh !important;
}

table {
  tbody {
    tr {
      min-width: 0 !important;

      &:hover {
        td {
          background-color: darken($white-color-dark, 6%);

          &.contract,
          &.due-date,
          &.transfer-date,
          &.value,
          &.status {
            cursor: pointer;
          }
        }
      }
    }
  }

  td {
    text-transform: capitalize !important;

    .contract-info {
      display: inline-block;
      vertical-align: top;

      width: 28.2rem;

      overflow-wrap: break-word;
      line-break: anywhere;
      white-space: normal;
    }
  }

  .due-date,
  .transfer-date,
  .value,
  .status {
    min-width: 13rem !important;
  }

  .status-container {
    border-radius: 0.5rem;

    width: min-content;

    color: $white-color;
    text-transform: uppercase;
    font-size: 1.4rem;

    padding: 0.5rem 2rem;

    &.waiting {
      background-color: $yellow-color;
    }

    &.sent_to_finance {
      background-color: $blue-color;
    }

    &.liquidated {
      background-color: $primary-color;
    }

    &.canceled {
      background-color: $red-color;
    }
  }

  .checkbox {
    min-width: 0 !important;
    height: 100%;

    display: flex;
    align-items: center;

    padding-right: 1rem;
    border-right: 1.5px solid $grey-color-light;

    input[type="checkbox"] {
      -webkit-appearance: none;
      appearance: none;

      background-color: #fff;

      color: $primary-color;

      width: 2.5rem;
      height: 2.5rem;
      margin: 0;

      border: 0.16rem solid $second-color-light;
      border-radius: 0.2rem;

      transform: translateY(0.15rem);

      display: grid;
      place-content: center;

      &::before {
        content: "";
        width: 2.5rem;
        height: 2.5rem;

        transform: scale(0);
        transition: 120ms transform ease-in-out;

        box-shadow: inset 2.5em 2.5em $primary-color;
        border-radius: 0.2rem;
      }

      &:checked::before {
        transform: scale(1);
      }
    }
  }
}
</style>

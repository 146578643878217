<template lang="pug">
Modal(v-model="isShow")
  .modal
    .form-container
      h1 {{ $t('.title') }}

      p {{ $t(`.description.${step}`) }}

      form(id="create-agency-form" action="/")
        identification(
          v-if="step == 'identification'"
          :formData="formData"
          :errors="errors"
        )

        location(
          v-if="step == 'location'"
          :formData="formData"
          :errors="errors"
        )

        bank-information(
          v-if="step == 'bankInformation'"
          :formData="formData"
          :errors="errors"
        )

    .buttons-container
      button.close(v-if="step == 'identification'" @click="close")
        | {{ $t(".buttons.close") }}

      button.button-grey(
        v-else-if="step == 'location' || step == 'bankInformation'"
        @click="currentStepIndex--",
        :disabled="submitting"
      )
        | {{ $t(".buttons.return") }}

      button.button-primary(
        v-if="step == 'identification' || step == 'location'"
        @click="currentStepIndex++",
        :disabled="disableNextStep"
      )
        | {{ $t(".buttons.next") }}

      button.button-primary(
        v-else-if="step == 'bankInformation'"
        @click="submit",
        :disabled="disableSubmitButton"
      )
        | {{ $t(".buttons.confirm") }}
</template>
<script>
import objects from "lib/objects"
import { ref } from 'vue'

import Identification from "./create_modal/identification.vue"
import Location from "./create_modal/location.vue"
import BankInformation from "./create_modal/bank-information.vue"

export default {
  components: { Identification, Location, BankInformation },

  props: {
    show: { type: Boolean, default: false },
    callback: { type: Function, required: true }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.realEstateAgencies.createModal",

      // data
      steps: ["identification", "location", "bankInformation"],
      currentStepIndex: 0,

      submitting: false,

      errors: {},

      // form
      formData: {
        name: null,
        cnpj: null,
        creci: null,
        identificator: null,
        superlogicaId: null,
        zipCode: null,
        state: null,
        city: null,
        neighborhood: null,
        street: null,
        number: null,
        bankCode: null,
        account: null,
        agency: null,
        pix: null
      },
    }
  },

  setup () {
    const isShow = ref(false)

    function showModal() {
      isShow.value = true
    }

    function closeModal() {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  methods: {
    close() {
      this.formDataKeys.forEach(key => this.formData[key] = null)

      this.currentStepIndex = 0

      this.$emit('close')
    },

    formattedFormData() {
      return { agency: objects.snakeize(this.formData) }
    },

    submit() {
      this.submitting = true

      this.$http
        .post(`/admins/real-estate/agencies`, this.formattedFormData())
        .then((response) => {
          this.errors = {};
          this.$notifications.clear();
          this.$notifications.info(this.$t(".notifications.submit.success"))

          const agency = response.data

          this.callback(agency)

          this.close()
        })
        .catch((err) => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))

          this.errors = objects.camelize(
            objects.dig(err, "response", "data", "errors") || {}
          )

          this.currentStepIndex = 0
        })
        .finally(() => {
          this.submitting = false
        })
    },

    anyKeyIsBlank(keysToValidate) {
      const filteredFormData = this.formDataKeys
        .filter(key => keysToValidate.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.formData[key]

          return obj
        }, {})

      return Object.values(filteredFormData).some(
        value => value === null || value === ""
      )
    }
  },

  computed: {
    step() {
      return this.steps[this.currentStepIndex]
    },

    formDataKeys() {
      return Object.keys(this.formData)
    },

    disableSubmitButton() {
      if (this.submitting) return this.submitting

      const keysToValidate = this.formDataKeys.filter(key => key != "pix")

      return this.anyKeyIsBlank(keysToValidate)
    },

    disableNextStep() {
      const keysToValidate = (
        this.step == "identification" &&
        ["name", "cnpj", "creci", "identificator", "superlogicaId"] ||
        this.step == "location" &&
        ["cep", "state", "city", "neighborhood", "street", "number"]
      )

      return this.anyKeyIsBlank(keysToValidate)
    }
  }
}
</script>
<style scoped lang="scss">
.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  height: fit-content;

  .form-container {
    width: 30rem;

    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.5rem;
      color: $second-color-light;
      overflow-wrap: break-word;
      white-space: pre-line;
      margin: 1rem 0;
    }
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;
    margin-top: 5rem;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}
</style>

<style lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}
</style>

<template lang="pug">
.editable-currency-field
  p.info-content(
    v-if="editingDisabled",
    @click="editingDisabled = false"
  )
    | {{ getValue }}

  .edit-container(v-else)
    form(ref="form", action="/", @submit.prevent="submit")
      input(
        ref="inputRef",
        :v-model="modelValue",
        :name="name",
        :type="type"
      )

      .buttons
        button.button-primary(
          type="submit",
          :disabled="submitting"
        )
          i.fa.fa-check

        button.button-primary.close(
          :disabled="submitting",
          @click="editingDisabled = true"
        )
          i.fa.fa-times
</template>

<script>
import InputField from "../input-field.vue"
import { useCurrencyInput } from 'vue-currency-input'
import currencyFormatter from 'lib/currency-formatter'

export default {
  extends: InputField,

  props: {
    name: { type: String, required: true },
    modelValue: { type: [String, Number] },
    value: { type: [String, Number] },
    type: { type: String, default: "text" },
    path: { type: String, required: true },
    callback: { type: Function, default: () => {} },
    options: { type: Object, default: () => {
      return {
        "locale": "pt-BR",
        "currency": "BRL",
        "currencyDisplay": "symbol",
        "valueRange": {
          "min": 0,
          "max": 20000
        },
        "hideCurrencySymbolOnFocus": false,
        "hideGroupingSeparatorOnFocus": true,
        "hideNegligibleDecimalDigitsOnFocus": false,
        "autoDecimalDigits": true,
        "useGrouping": false,
        "accountingSign": true
      }
    }},
  },

  watch: {
    value() {
      this.inputValue = this.value
    }
  },

  setup(props) {
    const { inputRef } = useCurrencyInput(props.options)

    return { inputRef }
  },

  data() {
    return {
      defaultI18nScope: "components.editableCurrencyField",

      submitting: false,
      editingDisabled: true,
      inputValue: this.value
    }
  },

  methods: {
    formatCurrencyValue(value) {
      return value && value.replace('R$', '').replace(',', '.').trim()
    },

    disableEditableField() {
      this.editingDisabled = true

      this.callback()
    },

    submit() {
      this.submitting = true

      const formData = new FormData(this.$refs.form)
      formData.set(this.name, this.formatCurrencyValue(formData.get(this.name)))

      this.$http
        .put(this.path, formData)
        .then(() => {
          this.inputValue = formData.get(this.name)

          this.errors = {}
          this.$notifications.clear()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))
        })
        .then(() => {
          this.submitting = false
          this.disableEditableField()
        })
    }
  },

  computed: {
    getValue() {
      return this.inputValue == null ? '-' : currencyFormatter.format(this.inputValue)
    }
  }
}
</script>

<style scoped lang="scss">
.editable-currency-field {
  .edit-container {
    width: 100%;
    display: inline-flex;
    gap: 0.5rem;

    input {
      width: 100%;
      padding: 0px 10px;
      font-family: Lato;
      font-size: 1.5rem;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 0.3rem;
    }

    .buttons {
      display: flex;
      gap: 0.3rem;

      button {
        padding: 0 10px;
        width: 38px;

        &.close {
          background-color: $red-color;
        }

        .fa {
          font-size: 1.2rem;
          line-height: 32px;
        }
      }
    }
  }

  p {
    &.info-content {
      padding: 0px 0px;
      font-size: 1.5rem;
      font-family: Lato;

      &:hover {
        background-color: darken($white-color, 5%);
      }
    }
  }
}
</style>
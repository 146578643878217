import _string from 'lodash/string'

/**
 * Helper para manipulação de strings.
 * Tem todos os métodos do lodash e mais alguns específicos.
 */
const strings = Object.assign({}, _string, {
  // alguns alias para facilitar o trânsito entre Rails <-> JS
  camelize:   _string.camelCase,
  dasherize:  _string.kebabCase,
  snakeize:   _string.snakeCase,
  underscore: _string.snakeCase,
  upperFirst: _string.upperFirst,
  capitalize: _string.capitalize
})

export default strings

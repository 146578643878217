<template lang="pug">
Navbar(
  :items="items"
  :currentPage="currentPage"
)
</template>
<script>
import Navbar from "../../components/navbar.vue"

export default {
  components: { Navbar },

  props: {
    currentPage: { type: String, default: '' }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.components.navbar"
    }
  },

  computed: {
    items() {
      let items = ['adminRealEstateAgencyIndex', 'adminManagementIndex']

      if (this.financialPermission) items.push('adminFinancialIndex')

      items.push('adminReportIndex')

      return items.map(routeName => (
        { routeName, title: this.$t(`.${routeName}`) }
      ))
    },

    financialPermission() {
      return this.$permissions.somePermission([
        { moduleName: 'agreements', permissions: ['get'] },
        { moduleName: 'transfers', permissions: ['get'] },
        { moduleName: 'bills', permissions: ['get'] }
      ])
    }
  }
}
</script>

<template lang="pug">
select-field(
  :name="name"
  :options="options"
  :error="error"
  :label="$t('.label')"
  multiple
)
</template>

<script>
  export default {
    data() {
      return {
        // i18n
        defaultI18nScope: "admin.bills.statusField"
      }
    },

    computed: {
      options() {
        const statusOptions = [
          'waiting', 'normal', 'paid', 'manual_payment', 'unpaid', 'canceled', 
          'agreement'
        ]
        
        return statusOptions.map(option => ({
          id: option,
          text: this.$t(`models.bill.attributes.statusOptions.${option}`)
        }))
      }
    },

    props: {
      name: { type: String },
      error: { type: String }
    }
  }
</script>
<style lang="scss" scoped>
</style>

<template lang="pug">
input-field(
  v-model="formData.bankCode"
  type="number"
  name="bank_code"
  :label="$t('.label.bankCode')"
  :placeholder="$t('.placeholder.bankCode')"
  :error="errors?.bankCode?.message"
  required
)

input-field(
  v-model="formData.account"
  type="number"
  name="account"
  :label="$t('.label.account')"
  :placeholder="$t('.placeholder.account')"
  :error="errors?.account?.message"
  required
)

input-field(
  v-model="formData.agency"
  type="number"
  name="agency"
  :label="$t('.label.agency')"
  :placeholder="$t('.placeholder.agency')"
  :error="errors?.agency?.message"
  required
)

input-field(
  v-model="formData.pix"
  type="text"
  name="pix"
  :label="$t('.label.pix')"
  :placeholder="$t('.placeholder.pix')"
  :error="errors?.pix?.message"
)
</template>

<script>
export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "admin.realEstateAgencies.createModal.bankInformation"
    }
  },

  props: {
    formData: {type: Object, default: () => ({}) },
    errors: { type: Object, default: () => ({}) }
  }
}
</script>

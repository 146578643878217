<doc lang="markdown">
    Componente para campos de seleção (`select`) em formulários, já derivando
  `label` e mensagem de `erro` (para validações).

  # Exemplo de uso:

  ## básico
  ```pug
  select-field(
    name="user[role]",
    v-model="user.role",
    :options="[{ id: 0, text: 'guest' }, { id: 1, text: 'operator' }]",
    :placeholder="$t('.something')",
    :error="errors.role"
  )
  ```

  ## avançado
  ```pug
  select-field(
    id="secret",
    name="special",
    label="Meu label específico",
    value="1",
    :options="[{ id: 0, text: 'guest' }, { id: 1, text: 'operator' }]"
    :placeholder="$t('.something')",
    :disabled="flagControlandoEstadoDeDesabilitado",
    :error="mensagemDeErroControlandoEstadoDeErro"
  )
  ```
</doc>

<template lang="pug">
.field.select-field(:class="{ error, disabled }")
  label(v-if="!hideLabel", :for="inputId", :class="{ 'required': required }") {{ labelText }}
  select2(
    ref="inputRef",
    :id="inputId",
    :disabled="disabled",
    :name="name",
    :options="options",
    :overlay="overlay",
    :placeholder="placeholder || defaultPlaceHolderText",
    :type="type",
    :required="required",
    :value="modelValue",
    :includeBlank="includeBlank",
    :hideSearch="hideSearch",
    :url="url",
    :dataFormatting="dataFormatting",
    :processResults="processResults",
    :multiple="multiple",
    @input="onInput"
  )
    slot
  input-error(:message="error")
</template>

<script>
import InputField from "./input-field.vue"

export default {
  extends: InputField,

  // propriedades extras e/ou sobrecarregadas
  props: {
    hideSearch: { type: Boolean, default: true },
    includeBlank: { type: Boolean, default: false },
    type: null,
    modal: null,
    options: null,
    overlay: null,
    required: { type: Boolean, default: false },
    placeholder: { type: String, default: null },
    url: { type: String, required: false },
    dataFormatting: { type: Function, required: false },
    processResults: { type: Function, required: false },
    multiple: { type: Boolean, default: false }
  },

  computed: {
    defaultPlaceHolderText() {
      return this.$t("options.blank")
    },
  },

  methods: {
    onInput(val) {
      const newValue = val == -1 || !val.length ? null : val

      this.$emit('update:modelValue', newValue)
      this.$emit('input', newValue)
    }
  }
}
</script>

<style lang="scss">
// XXX sem scoped por causa do select2!

// TODO definir cor de erro!
$select-error-color: red;
$select-error-border-color: red;
$select-disabled-color: #ababab;
$select-disabled-border-color: #d9d6d5;

.select-field {
  margin-bottom: 1.5rem;

  label.required::after {
    content: ' *';
    color: #9b53d7;
  }

  .select2.select2-container--default {
    width: 100%;
    // Sobrecarregando estilo do Skeleton, trocando a margem para o "container",
    // fazendo com que as mensagens de erro fiquem próximas ao input (antes da
    // margem)
    // margin-bottom: 1.5rem;
    margin-bottom: 0;
  }
}

// error
.select-field.error {
  & > label {
    color: $select-error-color;
  }

  .select2.select2-container--default {
    border-color: $select-error-border-color !important;
  }
}

// disabled
.select-field.disabled {
  & > label {
    color: $grey-color-dark;
  }

  .select2.select2-container--default {
    border-color: $grey-color-light;

    .select2-selection--single .select2-selection__rendered {
      color: $grey-color-light;
    }
  }
}

.select2-container--default.select2-container--disabled
.select2-selection--single {
  background-color: $white-color !important;
}

.select2-search--inline {
    display: contents;
}

.select2-search__field:placeholder-shown {
    width: 100% !important;
}
</style>

// Singleton de eventos - Vue Bus Event

import emitter from 'tiny-emitter/instance'

// events é um Proxy para o bus!
const events = {
  /**
   * Emite evento globalmente
   * @param  {...[type]} args [description]
   * @return {[type]}         [description]
   */
  $emit(...args) {
    return emitter.emit(...args)
  },

  /**
   * Registra observer para evento
   * @param  {...[type]} args [description]
   * @return {[type]}         [description]
   */
  $on(...args) {
    return emitter.on(...args)
  },

  /**
   * Registra observer para evento uma única vez
   * @param  {...[type]} args [description]
   * @return {[type]}         [description]
   */
  $once(...args) {
    return emitter.once(...args)
  },

  /**
   * Remove observer para evento
   * @param  {...[type]} args [description]
   * @return {[type]}         [description]
   */
  $off(...args) {
    return emitter.off(...args)
  }
}

export default events

<template lang="pug">
Navbar
</template>
<script>
import Navbar from '../../desktop/components/navbar.vue'

export default {
  components: { Navbar }
}
</script>

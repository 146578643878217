<template lang="pug">
.card-container
  .title(@click="toggle")
    h2(v-if="title")
      | {{ title }}

  slot
</template>
<script>
export default {
  props: {
    title: { type: String, required: true }
  }
}
</script>
<style lang="scss" scoped>
.card-container {
  background-color: $white-color;
  padding: 2.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  margin-bottom: 3rem;

  .title {
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: $primary-color;
    }
  }
}
</style>

import { createApp } from 'vue'
import { createHead } from '@vueuse/head'

import Desktop from './views/desktop/App.vue'
import DesktopRouter from './routers/desktop'

import Phone from './views/phone/App.vue'
import PhoneRouter from './routers/phone'

import Marketing from './views/marketing/App.vue'
import StaticPages from './views/static_pages/index.vue'

import browserType from './modules/browser_type'
import i18n from './modules/i18n'
import VueI18nJS from './lib/vue-i18n-js'
import VueInitProps from 'lib/vue-init-props'
import VueIsVisible from 'lib/vue-is-visible'
import VueAnimateOnScroll from 'vue-animate-onscroll'

// modal component
import 'vue-universal-modal/dist/index.css'
import VueUniversalModal from 'vue-universal-modal'

// mask
import Maska from 'maska'

// date-picker
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

// popper
import Popper from 'vue3-popper'

//mixin
import Queries from './mixins/queries'

// importing global components
import Components from './components'
import DesktopComponents from './components/desktop'
import PhoneComponents from './components/phone'

import './fonts'

import app from './app'

import './initializers'

let App = null

const buildApp = async () => {
  await app.firebase.fetchConfig()

  if (browserType.isDesktop()) {
    App = createApp(Desktop)
    app.router = DesktopRouter

    DesktopComponents(App)
    App.use(DesktopRouter)
  } else {
    App = createApp(Phone)
    app.router = PhoneRouter

    PhoneComponents(App)
    App.use(PhoneRouter)
  }

  // instalando VueI18nJS
  App.use(VueI18nJS, { i18n: i18n })

  // instalando Modal
  App.use(VueUniversalModal, {
    teleportTarget: '#modals'
  })

  // instalando VueInitProps
  App.use(VueInitProps)

  App.use(createHead())

  // instalando VueIsVisible
  App.use(VueIsVisible)

  // instalando v-mask
  App.use(Maska)

  //instalando animation
  App.use(VueAnimateOnScroll)

  // instalando date-picker
  App.component('date-picker', VueDatePicker);

  // instalando popper
  App.component('popper', Popper)

  // instalando componentes marketing
  App.use(Marketing)

  // instalando componentes static pages
  App.use(StaticPages)

  App.use({
    install(Vue) {
      Vue.$firebase = app.firebase

      Vue.config.globalProperties.$firebase = app.firebase
    }
  })

  // Adicionando módulo http.js ao Vue, facilitando seu uso nos componentes
  App.use({
    install(Vue) {
      // add global method or property
      Vue.$http = app.http

      // add an instance method
      Vue.config.globalProperties.$http = app.http
    }
  })

  // Adicionando módulo params.js ao Vue, facilitando seu uso nos componentes
  App.use({
    install(Vue) {
      // add global method or property
      Vue.$params = app.params

      // add an instance method
      Vue.config.globalProperties.$params = app.params
    }
  })

  // Adicionando módulo notifications.js ao Vue, facilitando seu uso nos componentes
  App.use({
    install(Vue) {
      // add global method or property
      Vue.$notifications = app.notifications

      // add an instance method
      Vue.config.globalProperties.$notifications = app.notifications
    }
  })

  // Adicionando variável de ambiente ao Vue, facilitando seu uso nos componentes
  App.use({
    install(Vue) {
      // add global method or property
      Vue.$env = process.env

      // add an instance method
      Vue.config.globalProperties.$env = process.env
    }
  })

  App.use({
    install(Vue) {
      // add global method or property
      Vue.$permissions = app.permissions

      // add an instance method
      Vue.config.globalProperties.$permissions = app.permissions
    }
  })

  App.use({
    install(Vue) {
      // add global method or property
      Vue.$user = app.user

      // add an instance method
      Vue.config.globalProperties.$user = app.user
    }
  })

  App.use({
    install(Vue) {
      // add global method or property
      Vue.$agency = app.agency

      // add an instance method
      Vue.config.globalProperties.$agency = app.agency
    }
  })

  App.mixin(Queries)

  Components(App)

  App.mount('#app')
}

buildApp()

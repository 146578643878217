<template lang="pug">
div.fee-input-container
  input-field(
    v-if="manualFee",
    :disabled="disabled",
    v-model="value",
    :label="$t('.label')",
    @change="e => { $emit('update:modelValue', e.target.value) }",
  )

  select-field(
    v-else,
    :disabled="disabled",
    type="string",
    v-model="value",
    :includeBlank="true"
    :options="options",
    :label="$t('.label')",
    @update:modelValue="id => { $emit('update:modelValue', parseFloat(id)) }",
  )

  button.button-blue.edit-fee(
    @click.prevent="manualFee = !manualFee",
    :disabled="disabled"
  )
    i.fa.fa-pencil
</template>

<script>
import SelectField from "src/components/desktop/select-field.vue"

export default {
  extends: SelectField,

  mounted() {
    this.manualFee = this.value &&
      !this.options.some(element => element.id == this.value)
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.components.feeField",

      manualFee: true,

      // data
      options: [
        { id: 8.5, text: '8,5%' },
        { id: 9.5, text: '9,5%' },
        { id: 10.5, text: '10,5%' },
        { id: 11.5, text: '11,5%' },
        { id: 12.5, text: '12,5%' },
        { id: 14, text: '14%' },
      ]
    }
  },

  computed: {
    value() {
      if (typeof this.modelValue === Number) return this.modelValue.toString()

      return this.modelValue
    }
  }
}
</script>
<style lang="scss" scoped>
  .fee-input-container {
    display: grid;
    grid-template-columns: auto min-content;
    width: 100%;
    margin: 0;

    .select-field, .input-field {
      margin: 0;
    }
  }

  button.edit-fee {
    width: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 0;
    margin-left: 1rem;
  }
</style>

<template lang="pug">
Modal(v-model="isShow" :close="close")
  .modal
    .info-container
      h1 {{ $t('.title') }}
      p {{ $t('.description') }}

    .buttons-container
      button.close(
        v-if="!loading"
        @click="close"
      )
        | {{ $t(".buttons.close") }}

      button.confirm(
        :class="loading && 'button-progress'"
        :disabled="loading"
        @click="confirm"
      )
        | {{ $t(".buttons.confirm") }}
</template>

<script>
import { ref } from 'vue'

export default {
  components: { },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.contract.new.billsItems.deleteModal",

      options: { closeClickDimmed: true }
    }
  },

  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  props: {
    show: { type: Boolean, default: false },
    callback: { type: Function }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  methods: {
    close() {
      this.$emit('close')
    },

    disableDimmedClick() {
      this.options['closeClickDimmed'] = false
    },

    confirm() {
      this.disableDimmedClick()
      this.callback()
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  width: 27%;
  height: fit-content;

  .info-container {
    min-height: 11em;

    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
    }

    p {
      font-size: 1.5rem;
      color: $second-color-light;
      overflow-wrap: break-word;
      white-space: pre-line;
    }
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }

    .button-progress {
      background-image: linear-gradient(90deg, $primary-color 45%, $primary-light 45%);
      background-size: 200%;
      animation: progress 20s ease forwards;
    }

    @keyframes progress {
      from { background-position: right; }
      to { background-position: left; }
    }
  }
}
</style>


<style lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}
</style>

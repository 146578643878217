<template lang="pug">
.wrapper
  transition(name="fade", mode="out-in", @after-enter="$emit('load')")
    div
      i.fa.fa-spinner.fa-pulse.fa-fw(v-if="loading")

      .content(v-if="!loading", key="content")
        slot
</template>

<script>
export default {
  props: {
    loading: { type: Boolean, default: false },
    loadingClass: { type: Object, default: () => ({}) },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
  min-height: 60px;
  height: 100%;
}
</style>
<template lang="pug">
#page.score-report-show
  navbar(currentPage="realEstateManagementIndex")

  loading-div.score-report-container-desktop
    .score-report-container
      breadcrumbs(:items="breadcrumbItems")

      .row.counter-container
        span.counter(v-if="!loading && scores.length > 0")
          | {{ $t('.totalCount', { count: scores.length }) }}
        span.counter(v-if="loading")
          | {{ $t('.totalCount.loading') }}

      .no-data-container(v-if="!loading && scores.length == 0")
        img.image(src="@/assets/images/public/no-data.svg")
        h2 {{ $t('.totalCount.zero') }}

      .cards-container
        score-card(v-for="score in scores", :score="score")
</template>

<script>
import objects from "lib/objects"
import tool from "lib/vue-tool"
import vueAuthenticate from "lib/vue-authenticate"

import Breadcrumbs from "../../../../components/breadcrumbs.vue"
import Navbar from "../../../components/navbar.vue"
import ScoreCard from "../../../components/score_report/score-card.vue"

export default {
  mixins: [tool, vueAuthenticate],
  components: { Breadcrumbs, Navbar, ScoreCard },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.management.scoreReport.show",

      // tool
      routeName: "realEstateScoreReportShow",
      routePath: "imobiliaria/gerenciamento/consultas",
      viewName: "index",

      // data
      scores: [],
      pagination: { total: 0 },
      loading: false
    }
  },

  computed: {
    getScoreReportParam() {
      return this.$route.params.id
    },

    breadcrumbItems() {
      const managementPath = this.routePath.replace('/consultas', '')

      return [
        {
          name: this.$t('.breadcrumbs.management'),
          url: managementPath
        },
        {
          name: this.$t('.breadcrumbs.scores'),
          url: managementPath,
          hash: 'scoreReports'
        },
        {
          name: `#${this.getScoreReportParam}`,
          url: `${this.routePath}/${this.getScoreReportParam}`
        }
      ]
    }
  },

  methods: {
    parseRoute() {},

    fetch() {
      this.loading = true

      return this.$http
        .get(`${this.$user.prefixUrlAgency}/score-reports/${this.getScoreReportParam}`)
        .then(({ data }) => {
          this.scores = objects.camelize(data.scores)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss">
#page.score-report-show {
  overflow: hidden;

  .score-report-container-desktop {
    padding: 0;
    margin: 0 !important;
    width: 100%;
    max-width: fit-content;
    height: 100vh;
    overflow: hidden;

    .content {
      display: flex;
      width: 100vw;
      overflow: hidden;
    }

    .score-report-container {
      width: 100%;
      height: 100vh;
      padding: 2.5rem;
      overflow-y: auto;
      padding-bottom: 10rem;

      .counter-container {
        margin-bottom: 1.5rem;
      }

      .counter {
        color: $grey-color-dark;
        font-style: italic;
      }

      .no-data-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        img {
          width: 31rem;
          margin-bottom: 1rem;
        }

        h2 {
          color: $primary-color;
          font-size: 3rem;
          font-weight: 400;
        }
      }

      .cards-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
      }
    }
  }
}
</style>

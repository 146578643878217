<template lang="pug">
Navbar(
  :items="items"
  :currentPage="currentPage"
)
</template>
<script>
import Navbar from "../../components/navbar.vue"

export default {
  components: { Navbar },

  props: {
    currentPage: { type: String, default: '' }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: 'realEstate.components.navbar'
    }
  },

  computed: {
    items() {
      const items =  [
        {
          routeName: 'realEstateManagementIndex',
          roles: [
            'real-estate-admin', 'real-estate-manager', 'real-estate-attendant'
          ]
        },
        {
          routeName: 'realEstateTeamIndex',
          roles: ['real-estate-admin', 'real-estate-manager']
        },
      ]

      if (this.showCnab) {
        items.push({
          routeName: 'realEstateCnabIndex',
          roles: ['real-estate-admin']
        })
      }

      return items.filter(page => this.isAllowed(page)).map(({ routeName }) => (
        { routeName, title: this.$t(`.${routeName}`) }
      ))
    },

    showCnab() {
      const config = this.$firebase.remoteConfigValue('enableCnabPage')
      const roleValue = config[app.auth.role]

      return roleValue && roleValue.includes(app.auth.userId)
    },
  },

  methods: {
    isAllowed(page) {
      const role = this.$user.role

      return page.roles.includes(role)
    }
  }
}
</script>

<template lang="pug">
.overview-container
  .overview-item(v-for="item in items", :key="item.description")
    h3 {{ item.quantity }}
    p {{ item.description }}
    p.value(v-if="item.value != null") {{ valueFormat(item.value) }}
    p.percentage(v-if="item.percentage != null") {{ item.percentage }}%
</template>
<script>
import currencyFormatter from 'lib/currency-formatter'

export default {
  props: {
    items: { type: Array, required: true }
  },

  methods: {
    valueFormat(value) {
      return currencyFormatter.format(value)
    }
  }
}
</script>
<style lang="scss" scoped>
.overview-container {
  display: flex;
  justify-content: space-around;
  gap: 1.5rem;
  overflow-x: auto;

  .overview-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    min-width: fit-content;

    h3 {
      color: $primary-color;
      font-weight: 500;
      font-size: 4rem;
      font-family: "Lato", Arial, sans-serif;

      margin-bottom: 0;
    }

    p {
      color: $second-color-light;
      font-weight: 500;
      font-size: 1.7rem;

      margin-top: -0.5rem;
      margin-bottom: 0;
    }

    .value, .percentage {
      color: $primary-color;
      font-weight: 600;
      font-size: 1.5rem;
      font-family: "Lato", Arial, sans-serif;
    }
  }
}
</style>
<doc lang="markdown">
  Este componente representa o controlador de várias `tab-content`s, e realiza
  o controle de `tab-content` ativa. Sendo assim só tem utilidade se tiver algum
  componente `tab-content` como _child_

  IMPORTANTE: Este componente deve conter componente(s) `tab-content`
              para que funcione corretamente.

  Ex:
  -----
  tab-bar
    .content
      tab-content(id='tab1', :label="$t('.traducao.para.label')")
      tab-content(id='tab2', :label="$t('.traducao.para.label2')")
</doc>

<template lang="pug">
.component.tab-bar
  .tabs
    template(v-for="tab in tabs")
      input(type="radio", :value="tab.id", v-model="active")
      label(@click="updateActive(tab.id)") {{ tab.label }}

  slot
</template>

<script>
export default {
  name: "tab-bar",
  mounted() {
    // Mapeia todos os componentes `tab` presente.
    let tabComponents = this.$slots.default().filter(
      (child) => child.type.name === "tab-content"
    );
    this.tabs = tabComponents.map((child) => ({
      id: child.props.id,
      label: child.props.label,
    }));

    this.active = this.tabs[0].id;
  },

  data() {
    return {
      defaultI18nScope: "tab-bar",

      tabs: [],
      active: null,
    };
  },

  methods: {
    updateActive(newTab) {
      this.active = newTab;
    },
  },

  computed: {
    // Necessário para expor variavel `active` para outros componentes
    // sem precisar passar por _props_
    activeTab() {
      return this.active;
    },
  },
};
</script>

<style scoped lang="scss">
.tabs {
  padding-left: 10px;

  label {
    display: inline-block;
    margin: 0 0 -1px;
    padding: 16px 14px;
    font-weight: 600;
    text-align: center;
    color: #bbb;
    border: 1px solid transparent;
  }

  input {
    display: none;
  }

  label:hover {
    color: #888;
    cursor: pointer;
  }

  input:checked + label {
    color: #555;
    border: 1px solid #ddd;
    border-top: 2px solid #9b53d7;
    border-bottom: 1px solid #fff;
  }
}
</style>
<template lang="pug">
.cpf-input-container(:class="{ 'error': error.length > 0 }")
  label
    | {{ $t(`models.${this.modelName}.attributes.${this.attrName}`) }}
  .cpf-input
    .cpf-input__cpf(
      v-for="(cpf, index) in cpfs", :key="cpf",
      :class="{'error': cpfHasError(cpf)}"
    )
      span(@click='removeCpf(index)')
        i.fa.fa-close
      | {{ cpf }}
    input.cpf-input__text(
      type="text",
      :placeholder="placeholder",
      v-maska="'###.###.###-##'",
      v-on:keydown.enter="addCpf",
      v-on:keydown.188="addCpf",
      v-on:keydown.delete='removeLastCpf',
      @input="onInput"
    )
    input(type="hidden", :name="name", :value="`[${cpfs.toString()}]`")
  p.hint
    | * Para adicionar um CPF, pressione ENTER
</template>
<script>
import strings from "lib/strings"

  export default {
    props: {
      placeholder: { type: String, required: true },
      name: { type: String, required: true },
      cpfs: { type: Array, default: () => { return [] } },
      error: { type: Array, default: () => { return [] } }
    },
    computed: {
      attrName() {
        let match = /\[([^\]]+)\]$/.exec(this.name)
        return match ? strings.camelize(match[1]) : null
      },

      modelName() {
        let match = /^([^[]+)\[/.exec(this.name)
        return match ? strings.camelize(match[1]) : null
      }
    },
    methods: {
      addCpf (event) {
        event.preventDefault()
        const cpf = event.target.value.trim()

        if (cpf.length == 14) {
          this.cpfs.push(cpf)

          event.target.value = ''
        }

        this.$emit('cpfAdded')
        this.$emit('change', event)
      },

      removeLastCpf(event) {
        if (event.target.value.length === 0) {
          this.removeCpf(this.cpfs.length - 1)
        }
      },

      removeCpf(index) {
        this.cpfs.splice(index, 1)
      },

      cpfHasError(cpf) {
        return this.error.includes(cpf)
      },

      onInput(element) {
        this.$emit('change', element)
      }
    }
  }
</script>
<style scoped lang="scss">
.cpf-input {
  width: 100%;
  border: 1px solid $grey-color-high-light;
  padding: 0 1rem;
  border: 1px solid $grey-color;
  background: $white-color;
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0.5rem;
  margin-bottom: 1rem;
}

.cpf-input__cpf {
  background-color: $grey-color-high-light;
  border-radius: 16px;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  margin: 0.5rem 0;
  width: 100%;
  font-size: 1.4rem;
}

.cpf-input__cpf > span {
  cursor: pointer;
  float: right;
  opacity: 0.7;
  padding-left: 8px;
}

.cpf-input__text[type="text"] {
  width: 100%;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
}

.cpf-input__text:focus[type="text"] {
  border: none;
}

// error
.cpf-input-container.error {
  background: inherit;
  border: none;
  text-align: initial;
  margin: 0;
  padding: 0;

  label {
    color: $red-color;
  }

  .cpf-input {
    border-color: $red-color;
  }

  .cpf-input__cpf.error {
    background-color: $red-color;
    color: $white-color;
  }
}

.hint {
  font-size: 1.25rem;
  font-style: italic;
  color: $grey-color;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  margin-top: -0.5rem;
}

@media (max-width: 450px) {
  .cpf-input__cpf {
    font-size: 1.3rem;
  }
}
</style>

import store from 'store/dist/store.modern'

// Módulo de persistência, utilizando store.js (https://github.com/marcuswestin/store.js)

// para criar uma chave
// store.set('key', value)
//
// para recuperar uma chave
// store.get('key')

export default store

<template lang="pug">
billing(
  :fetchPath="fetchPath",
  :billShowRoute="billShowRoute"
  :tenantName="tenantName"
  :tenantEmail="tenantEmail"
)
</template>
<script>
import Billing from "../billing.vue"

export default {
  components: { Billing },

  props: {
    contractId: { type: String, required: true },
    tenantName: { type: String },
    tenantEmail: { type: String }
  },

  computed: {
    fetchPath() {
      return `${this.$user.prefixUrlAgency}/contracts/${this.contractId}/bills`
    },

    billShowRoute() {
      return {
        name: 'realEstateContractBillShow',
        params: {
          contractId: this.contractId
        }
      }
    }
  }
}
</script>
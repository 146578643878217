<template lang="pug">
#page
  navbar

  edit(
    :redirect="redirect",
    url="/real_estate/user/password",
  )
</template>

<script>
import Navbar from "../../../components/navbar.vue";
import Edit from "../../../tools/password/edit.vue";

export default {
  components: { Navbar, Edit },

  data() {
    return {
      redirect: "/imobiliaria/login"
    }
  }
};
</script>

<style scoped lang="scss">
#page {
  background-color: $grey-color-high-light;
}
</style>

<template lang="pug">
select-field(
  :name="name",
  :options="options",
  :required="true",
  :includeBlank="true",
  :error="error"
)
</template>

<script>
  export default {
    data() {
      return {
        options: [
          { id: 1, text: this.$t('monthsField.abbrMonthNames.one') },
          { id: 2, text: this.$t('monthsField.abbrMonthNames.two') },
          { id: 3, text: this.$t('monthsField.abbrMonthNames.three') },
          { id: 4, text: this.$t('monthsField.abbrMonthNames.four') },
          { id: 5, text: this.$t('monthsField.abbrMonthNames.five') },
          { id: 6, text: this.$t('monthsField.abbrMonthNames.six') },
          { id: 7, text: this.$t('monthsField.abbrMonthNames.seven') },
          { id: 8, text: this.$t('monthsField.abbrMonthNames.eight') },
          { id: 9, text: this.$t('monthsField.abbrMonthNames.nine') },
          { id: 10, text: this.$t('monthsField.abbrMonthNames.ten') },
          { id: 11, text: this.$t('monthsField.abbrMonthNames.eleven') },
          { id: 12, text: this.$t('monthsField.abbrMonthNames.twelve') },
        ]
      }
    },

    props: {
      name: { type: String },
      error: { type: String }
    }
  }
</script>
<style lang="scss" scoped>

</style>
<template lang="pug">
#page.management-page
  navbar

  loading-div.real-estate-management-container
    .menu-container
      h2 {{ $t('.menu') }}

      .menu-items
        button(
          v-for="page in pages"
          :class="currentPage == page && 'actived'"
          @click="() => { setCurrentPage(page) }"
        )
          | {{ $t(`.menus.${page}`) }}

    .content-container(@scroll="autoLoad")
      breadcrumbs(:items="generateBreadcrumb")

      score-reports(
        v-if="currentPage == 'scoreReports'"
        :shouldFetch="shouldFetch"
      )

      contracts(
        v-if="currentPage == 'contracts'"
        :shouldFetch="shouldFetch"
      )
</template>
<script>
import tool from "lib/vue-tool"
import vueAuthenticate from "lib/vue-authenticate"

import Navbar from "../../components/navbar.vue"
import Breadcrumbs from '../../../components/breadcrumbs.vue'
import ScoreReports from '../../components/score_report/index.vue'
import Contracts from '../../components/contract/index.vue'

export default {
  mixins: [tool, vueAuthenticate],
  components: { Navbar, Breadcrumbs, ScoreReports, Contracts },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.management.index",

      // tool
      routeName: "realEstateManagementIndex",
      routePath: "imobiliaria/gerenciamento",
      viewName: "index",

      shouldFetch: false
    }
  },

  methods: {
    fetch() {},

    parseRoute() { },

    setCurrentPage(anchor) {
      if (this.$route.hash.slice(1) == anchor) return

      this.$router.push({name: this.routeName, hash: `#${anchor}` })
    },

    autoLoad(event) {
      if (this.shouldFetch) this.shouldFetch = false

      const { scrollTop, scrollHeight, clientHeight } = event.target
      const loadingHeight = scrollHeight - 5

      if ((scrollTop + clientHeight < loadingHeight)) return

      this.shouldFetch = true
    }
  },

  computed: {
    currentPage() {
      const hash = this.$route.hash.slice(1)

      if (this.pages.includes(hash)) return hash

      if (hash) this.$router.replace({ hash: null })

      return this.pages[0]
    },

    pages() {
      const pages = ['scoreReports']

      if (this.$user.isRealEstateAdmin) pages.push('contracts')

      return pages
    },

    generateBreadcrumb() {
      return [
        {
          name: this.$t('.breadcrumbs.management'),
          url: this.routePath,
          hash: this.currentPage
        },
        {
          name: this.$t(`.breadcrumbs.${this.currentPage}`),
          url: this.routePath,
          hash: this.currentPage
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.real-estate-management-container {
  .content {
    display: flex;
    width: 100vw;
    overflow: hidden;
  }
}
</style>

<style lang="scss" scoped>
#page.management-page {
  overflow: hidden;
  height: 100%;
}

.real-estate-management-container {
  padding: 0;
  margin: 0 !important;
  width: 100%;
  max-width: fit-content;
  height: 100%;
  overflow: hidden;

  .menu-container {
    width: 25%;
    height: 100vh;
    overflow-y: visible;

    background-color: $white-color-dark;
    box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);

    padding: 0 1.5rem;

    h2 {
      color: $primary-color;
      font-size: 2rem;
      font-weight: 400;
      border-bottom: 1.5px solid $primary-color;
      margin-top: 2.5rem;
    }

    .menu-items {
      width: 100%;
      height: max-content;

      button {
        text-align: start;
        color: $second-color-light;
        font-weight: 400;
        border-radius: 0.5rem;
        border: none;
        padding: 0.5rem 1.5rem;
        height: auto;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: none;

        &.actived{
          background-color: $primary-high-light;
          color: $primary-color;
        }

        &:hover {
          background-color: darken($primary-high-light, 5%);
          color: $primary-color;
        }
      }
    }
  }

  .content-container {
    width: 75%;
    height: 100vh;
    padding: 2.5rem;
    overflow: scroll;
    overflow-y: visible;
    padding-bottom: 5rem;
  }
}
</style>
<template lang="pug">
.agency-page
  .agency-container
    breadcrumbs(:items="breadcrumbItems")

    .agency-show-container
      expansible-card(:title="$t('.title')", :expanded="true")
        .inline-section
          .four-columns
            h3.info-title {{ $t('.columns.name') }}
            editable-input-field(
              v-if="agencyLoaded",
              name="agency[name]",
              :value="agency.name",
              :path="agencyPath"
            )
          .four-columns
            h3.info-title {{ $t('.columns.cnpj') }}
            editable-input-field(
              v-if="agencyLoaded",
              name="agency[cnpj]",
              :value="agency.cnpj",
              :path="agencyPath",
              mask="##.###.#######-##"
            )
          .four-columns
            h3.info-title {{ $t('.columns.creci') }}
            editable-input-field(
              v-if="agencyLoaded",
              name="agency[creci]",
              :value="agency.creci",
              :path="agencyPath"
            )
          .four-columns
            h3.info-title {{ $t('.columns.identificator') }}
            editable-input-field(
              v-if="agencyLoaded",
              name="agency[identificator]",
              :value="agency.identificator",
              :path="agencyPath"
            )

        .inline-section
          .four-columns
            h3.info-title {{ $t('.columns.bankCode') }}
            editable-input-field(
              v-if="agencyLoaded",
              name="agency[bank_code]",
              :value="agency.bankCode",
              :path="agencyPath"
            )
          .four-columns
            h3.info-title {{ $t('.columns.agency') }}
            editable-input-field(
              v-if="agencyLoaded",
              name="agency[agency]",
              :value="agency.agency",
              :path="agencyPath"
            )
          .four-columns
            h3.info-title {{ $t('.columns.account') }}
            editable-input-field(
              v-if="agencyLoaded",
              name="agency[account]",
              :value="agency.account",
              :path="agencyPath"
            )
          .four-columns
            h3.info-title {{ $t('.columns.pix') }}
            editable-input-field(
              v-if="agencyLoaded",
              name="agency[pix]",
              :value="agency.pix",
              :path="agencyPath"
            )

        .inline-section
          .four-columns
            h3.info-title {{ $t('.columns.zipCode') }}
            p.info-content.zip-code(
              v-show="!editZipCode"
              @click="editZipCode = true"
            )
              | {{ agency.zipCode || '-' }}
            .edit-container(v-show="editZipCode")
              form(ref="form", action="/", @submit.prevent="submitAddress")
                input-field(
                  v-if="agencyLoaded",
                  :modelValue="agency.zipCode",
                  :hideLabel="true",
                  @input="getAddress",
                  mask="#####-###"
                )

                .buttons
                  button.button-primary(
                    type="submit",
                    :class="submitting && 'button-progress'"
                    :disabled="waitingZipCodeInput || submitting",
                    @click="editAgencyNumber"
                  )
                    i.fa.fa-check

                  button.button-primary.close(
                    type="button",
                    :disabled="submitting",
                    @click="resetAddressValues"
                  )
                    i.fa.fa-times

          .four-columns
            h3.info-title {{ $t('.columns.state') }}
            p.info-content
              | {{ address.state || agency.state || '-' }}
          .four-columns
            h3.info-title {{ $t('.columns.city') }}
            p.info-content
              | {{ address.city || agency.city || '-' }}
          .four-columns
            h3.info-title {{ $t('.columns.neighborhood') }}
            p.info-content
              | {{ address.neighborhood || agency.neighborhood || '-' }}
        .inline-section
          .four-columns
            h3.info-title {{ $t('.columns.street') }}
            p.info-content
              | {{ address.street || agency.street || '-' }}
          .four-columns
            h3.info-title {{ $t('.columns.number') }}
            editable-input-field(
              v-if="agencyLoaded",
              ref="agencyNumberField",
              name="agency[number]",
              :value="agency.number",
              :path="agencyPath"
            )
          .four-columns
            h3.info-title {{ $t('.columns.agencyFeeValue') }}
            editable-input-field(
              v-if="agencyLoaded",
              name="agency[agency_fee_value]",
              :value="agency.agencyFeeValue",
              :path="agencyPath"
            )
          .four-columns
            h3.info-title {{ $t('.columns.agencyOpenValue') }}
            editable-currency-field(
              v-if="agencyLoaded",
              name="agency[agency_opening_value]",
              :value="agency.agencyOpeningValue",
              :path="agencyPath"
            )

        .inline-section
          .four-columns
            h3.info-title {{ $t('.columns.createdAt') }}
            p.info-content
              | {{ $l('date.formats.default', agency.createdAt) || '-' }}

        .inline-section
          .full-size
            h3.info-title {{ $t('.columns.observation') }}
            .loading-content.observation(v-if="loading")
            editable-text-area-field(
              v-if="agency",
              name="agency[observation]",
              :value="agency.observation",
              :path="agencyPath"
            )

      expansible-card(:title="$t('.responsibleTitle')", :expanded="true")
        .inline-section
          .four-columns
            h3.info-title {{ $t('.columns.corporateName') }}
            editable-input-field(
              v-if="agencyLoaded",
              name="agency[corporate_name]",
              :value="agency.corporateName",
              :path="agencyPath"
            )
          .four-columns
            h3.info-title {{ $t('.columns.responsibleFullName') }}
            editable-input-field(
              v-if="agencyLoaded",
              name="agency[responsible_full_name]",
              :value="agency.responsibleFullName",
              :path="agencyPath"
            )
          .four-columns
            h3.info-title {{ $t('.columns.responsibleCpf') }}
            editable-input-field(
              v-if="agencyLoaded",
              name="agency[responsible_cpf]",
              :value="agency.responsibleCpf",
              :path="agencyPath"
              mask="###.###.###-##"
            )
          .four-columns
            h3.info-title {{ $t('.columns.responsibleEmail') }}
            editable-input-field(
              v-if="agencyLoaded",
              name="agency[responsible_email]",
              :value="agency.responsibleEmail",
              :path="agencyPath"
            )

        .inline-section
          .four-columns
            h3.info-title {{ $t('.columns.responsiblePhone') }}
            editable-input-field(
              v-if="agencyLoaded",
              name="agency[responsible_phone]",
              :value="agency.responsiblePhone",
              :path="agencyPath"
              mask="(##) #####-####"
            )

      documents-index(
        :path="`/admins/real-estate/agencies/${getIdParam}/documents`"
      )
</template>
<script>
import ExpansibleCard from "../expansible-card.vue"
import DocumentsIndex from "../../../components/documents/index.vue"
import EditableTextAreaField from "../../../../../components/desktop/editable-fields/editable-text-area-field.vue"
import EditableInputField from "../../../../../components/desktop/editable-fields/editable-input-field.vue"
import EditableCurrencyField from "../../../../../components/desktop/editable-fields/editable-currency-field.vue"

import objects from "lib/objects"
import cep from 'cep-promise'

export default {
  components: {
    ExpansibleCard,
    EditableInputField,
    EditableCurrencyField,
    DocumentsIndex,
    EditableTextAreaField
  },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.realEstateAgencies.show.details",

      // tool
      routePath: "administracao/gerenciamento/repasses",

      address: {
        zipCode: null,
        state: null,
        neighborhood: null,
        city: null,
        street: null
      },

      editZipCode: false,
      agencyLoaded: false,
      submitting: false,
      waitingZipCodeInput: true,

      agency: {}
    }
  },

  methods: {
    init(agency) {
      this.agency = objects.camelize(agency)
      this.agencyLoaded = true
    },

    fetch() {
      this.$http
        .get(`${this.agencyPath}.json`)
        .then((response) => {
          const agency = response.data

          this.init(agency)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
    },

    submitAddress() {
      this.submitting = true

      const updateParams = { agency: objects.snakeize(this.address) }

      this.$http
        .put(this.agencyPath, updateParams)
        .then(() => {
          this.errors = {}
          this.$notifications.clear()

          this.fetch()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))
        })
        .finally(() => {
          this.editZipCode = false
          this.submitting = false
        })
    },

    editAgencyNumber() {
      this.$refs.agencyNumberField.editingDisabled = false
      this.$refs.agencyNumberField.$el.querySelector('input').focus();
    },

    resetAddressValues() {
      this.address = {
        state: null,
        neighborhood: null,
        city: null,
        street: null,
        zipCode: null
      }

      this.editZipCode = false
      this.waitingZipCodeInput = true
    },

    getAddress(value) {
      let zipCodeValue = value.target.value.replace('-', '')

      if (zipCodeValue.length < 8) return this.waitingZipCodeInput = true

      this.submitting = true

      cep(zipCodeValue)
        .then((result) => {
          this.address = {
            state: result.state,
            neighborhood: result.neighborhood,
            city: result.city,
            street: result.street,
            zipCode: value.target.value
          }

          this.waitingZipCodeInput = false
        })
        .finally(() => {
          this.submitting = false
        })
    }
  },

  computed: {
    getIdParam() {
      return this.$route.params.id
    },

    agencyPath() {
      return `/admins/real-estate/agencies/${this.getIdParam}`
    },
  }
}
</script>
<style lang="scss" scoped>
.inline-section {
  display: flex;
  word-break: break-word;

  .four-columns {
    width: 25%;
    padding-right: 2.5rem;
  }

  .full-size {
    width: 100%;
  }

  .info-title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: $second-color-light;
  }

  .info-content {
    font-size: 1.5rem;
    font-family: Lato;

    &.zip-code {
      &:hover {
        background-color: darken($white-color, 5%);
      }
    }
  }

  .edit-container {
    width: 100%;
    display: inline-flex;
    gap: 0.5rem;

    input {
      width: 100%;
      padding: 0px 10px;
      font-family: Lato;
      font-size: 1.5rem;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 0.3rem;
    }

    .buttons {
      display: flex;
      gap: 0.3rem;

      button {
        padding: 0 10px;
        width: 38px;

        &.close {
          background-color: $red-color;
        }

        .fa {
          font-size: 1.2rem;
          line-height: 32px;
        }
      }

      .button-progress {
        background-image: linear-gradient(90deg, $primary-color 45%, $primary-light 45%);
        background-size: 200%;
        animation: progress 20s ease forwards;
      }

      @keyframes progress {
        from { background-position: right; }
        to { background-position: left; }
      }
    }
  }
}
</style>

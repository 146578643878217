<template lang="pug">
Modal(v-model="isShow")
  .modal
    .info-container
      h1 {{ $t(`.${step}.title`) }}
      p {{ $t(`.${step}.description`) }}

      items(
        v-if="step == 'items'",
        :items="items",
        :itemsValue="itemsValue"
      )

      installments(
        v-if="step == 'installments'",
        :installments="installments",
        :installmentsValue="installmentsValue",
        :itemsValue="itemsValue",
        :differenceValue="differenceValue",
        :hasChanged="installmentsHasChanged",
        @update:hasChanged="updateInstallmentsHasChanged"
      )

    .buttons-container(v-if="step == 'items'")
      button.close(@click="close")
        | {{ $t(".buttons.close") }}

      button.button-primary(
        @click="step = 'installments'",
        :disabled="disableNextStep"
      )
        | {{ $t(".buttons.next") }}

    .buttons-container(v-if="step == 'installments'")
      button.button-grey(
        @click="step = 'items'",
        :disabled="submitting"
      )
        | {{ $t(".buttons.return") }}

      button.button-primary(
        @click="createAgreement",
        :disabled="disableCreate"
      )
        | {{ $t(".buttons.confirm") }}
</template>

<script>
import { ref } from 'vue'

import objects from 'lib/objects'

import Items from './create_modal/items.vue'
import Installments from './create_modal/installments.vue'

export default {
  components: { Items, Installments },

  data() {
    return {
      // i18n
      defaultI18nScope: 'admin.agreements.createModal',

      // data
      step: 'items',
      items: [],
      installments: [],
      installmentsHasChanged: false,
      submitting: false
    }
  },

  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true

      this.preAgreement()
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  props: {
    show: { type: Boolean, default: false },
    contractId: { type: String, required: true },
    billIds: { type: Array, required: true }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  computed: {
    itemsValue() {
      let total = 0

      this.items.forEach(item => item.value && (total += item.value))

      return this.formattedFloat(total)
    },

    installmentsValue() {
      let total = 0

      this.installments.forEach(installment =>
        installment.value && (total += installment.value)
      )

      return this.formattedFloat(total)
    },

    differenceValue() {
      return this.formattedFloat(this.installmentsValue - this.itemsValue)
    },

    disableNextStep() {
      if (this.items.length == 0) return true

      let missingInfo = 0

      this.items.forEach(item =>
        (!item.value || !item.billServiceId) && missingInfo++
      )

      return missingInfo != 0
    },

    disableCreate() {
      if (
        this.installments.length == 0 ||
        this.differenceValue != 0 ||
        this.submitting
      ) {
        return true
      }

      let missingInfo = 0

      this.installments.forEach(installment =>
        (!installment.value || !installment.dueDate) && missingInfo++
      )

      return missingInfo != 0
    },

    createAgreementParams() {
      return {
        agreement: {
          total_value: this.itemsValue,
          contract_id: this.contractId,
          agreement_bills_attributes: this.billIds.map(id => ({ bill_id: id })),
          bill_items_attributes: objects.snakeize(this.items),
          installments_attributes: objects.snakeize(this.installments)
        }
      }
    }
  },

  methods: {
    close() {
      this.items = []
      this.installments = []
      this.step = 'items'

      this.$emit('close')
    },

    updateInstallmentsHasChanged(value) {
      this.installmentsHasChanged = value
    },

    formattedFloat(value) {
      return value ? parseFloat(value.toFixed(2)) : 0
    },

    initItems(items) {
      this.items = objects.camelize(items)
    },

    preAgreement() {
      this.$http
        .post('/admins/pre-agreements', { bill_ids: this.billIds })
        .then((response) => {
          const data = response.data

          this.initItems(data.bill_items)
        })
        .catch(() => {
          this.$notifications.error(
            this.$t('.notifications.preAgreement.failure')
          )
        })
    },

    createAgreement() {
      this.submitting = true

      this.$http
        .post('/admins/agreements', this.createAgreementParams)
        .then((response) => {
          const body = response.data

          this.$router.push({
            path: `/administracao/gerenciamento/acordos/${body.id}`
          })
        })
        .catch(() => {
          this.$notifications.error(
            this.$t('.notifications.createAgreement.failure')
          )
        })
        .finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  height: fit-content;
  width: 75vw;

  .info-container {
    min-height: 11em;

    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
    }

    p {
      font-size: 1.5rem;
      color: $second-color-light;
      overflow-wrap: break-word;
      white-space: pre-line;
    }
  }

  .buttons-container {
    margin-top: 1rem;
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}
</style>

<style lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}
</style>

<template lang="pug">
.page
  navbar

  section.first
    .content.animation.left.animated
      h1 {{ $t('.firstSection.title.firstPart') }} 
        span {{ $t('.firstSection.title.secondPart') }}

      p {{ $t('.firstSection.content') }}

      .buttons-container
        router-link.button.button-primary(
          :to="{ name: 'contact' }"
        )
          | {{ $t('.buttons.knowMore') }}

    .img-grid
      img.animation.top.animated(:src="mediaPath('first-column.webp')")
      img.animation.bottom.animated(:src="mediaPath('second-column.webp')")

  section.second
    .animation.left
      h2 {{ $t('.secondSection.title.firstPart') }} 
        span {{ $t('.secondSection.title.secondPart') }}

      p {{ $t('.secondSection.content') }}

    img.animation.right(:src="mediaPath('second-section.webp')")

  section.third
    img.animation.left(:src="mediaPath('third-section.webp')")

    .infos
      .info.animation.bottom(v-for="info in ['first', 'second', 'third']")
        h3 {{ $t(`.thirdSection.infos.${info}.title`) }}

        p {{ $t(`.thirdSection.infos.${info}.content`) }}

  section.fourth
    .animation.left
      p.subtitle {{ $t('.fourthSection.subTitle') }}

      h2 {{ $t('.fourthSection.title') }}

      .buttons-container
        router-link.button.button-yellow(
          :to="{ name: 'contact' }"
        )
          | {{ $t('.buttons.knowMore') }}

    .infos
      .info.animation.bottom(v-for="(value, key) in iconsFourthSection")
        .img-container
          img(:src="mediaPath(value)")

        .content
          h3 {{ $t(`.fourthSection.infos.${key}.title`) }}

          p {{ $t(`.fourthSection.infos.${key}.content`) }}

  Footer

  sign-up-modal(:show="showSignUpModal" @close="showSignUpModal = false")
</template>

<script>
import Navbar from './components/navbar.vue'
import Footer from './components/footer.vue'
import SignUpModal from './components/sign-up-modal.vue'

export default {
  components: { Navbar, Footer, SignUpModal },

  data() {
    return {
      // i18n
      defaultI18nScope: "staticPages.forLandlords",

      showSignUpModal: false,

      iconsFourthSection: {
        first: 'house-circle-check.svg',
        second: 'comment-dots.svg',
        third: 'file-signature.svg',
        fourth: 'circle-check.svg'
      },
    }
  },

  mounted() {
    window.addEventListener("scroll", this.animateElements);
  },

  methods: {
    animateElements() {
      const elements = document.querySelectorAll(".animation");

      elements.forEach((element) => {
        const windowHeight = window.innerHeight
        const elementTop = element.getBoundingClientRect().top
        const elementVisible = 50

        if (elementTop < (windowHeight - elementVisible) && !element.classList.contains('animated')) {
          element.classList.add("animated");
        }
      })
    },

    mediaPath(file) {
      return require(`@/assets/images/for_landlords/${file}`)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../styles/static_pages/section_padding";

  $primary-high-light-color: #EEE5FB;
  $primary-light-color: #A575EC;
  $primary-color: #8E4EC5;
  $primary-dark-color: #452763;
  $yellow-color: #FFE040;

  .animation {
    opacity: 0;

    &.animated {
      opacity: 1;
      animation-timing-function: ease-out;
      animation-duration: 1.5s;

      &.left {
        animation-name: moveInLeft;
      }

      &.right {
        animation-name: moveInRight;
      }

      &.bottom {
        animation-name: moveInBottom;
      }

      &.top {
        animation-name: moveInTop;
      }
    }
  }

  section {
    width: 100%;
    padding: 12rem 6rem;
  }

  p {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.7rem;

    &.subtitle {
      margin-bottom: 1.6rem;
      font-size: 1.6rem;
      font-weight: 600;
    }
  }

  h2 {
    font-size: 4.8rem;
  }

  h1, h2, h3 {
    font-weight: 800;
  }

  h1, h2, h3, p {
    margin: 0;
    font-family: 'Open Sans';
    color: $primary-dark-color;

    span {
      color: $primary-color;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5rem;
    height: auto;

    button, .button {
      margin: 0;
      padding: 0 2.4rem;
      height: 4.8rem;
      line-height: 4.8rem;

      text-transform: none;
      font-size: 1.6rem;
      font-weight: 700;

      &.button-yellow {
        color: $primary-dark-color;
        border-color: $yellow-color;
        background-color: $yellow-color;

        &:hover, &:focus {
          color: $primary-dark-color;
          border-color: darken($yellow-color, 15%);
          background-color: darken($yellow-color, 15%);
        }
      }

      &.button-primary-dark-outline {
        color: $primary-dark-color;
        border-color: $primary-dark-color;
        background-color: inherit;

        &:hover, &:focus {
          color: $white-color;
          border-color: $primary-dark-color;
          background-color: $primary-dark-color;
        }
      }
    }
  }

  section.first {
    background-color: $primary-high-light-color;
    padding: 6rem 6rem 0 6rem;
    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    align-items: center;

    .content {
      margin-block: 5rem;

      h1 {
        color: $primary-dark-color;
        font-size: 5.6rem;
        line-height: 6.7rem;

        margin-bottom: 2.4rem;

        span {
          color: $primary-color;
        }
      }

      p {
        margin-bottom: 4rem;
        color: $primary-dark-color;
      }
    }


    .img-grid {
      height: 100%;

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  section.second {
    background-color: $white-color;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    align-items: center;

    h2 {
      color: $primary-color;
      margin-bottom: 2.4rem;

      span {
        color: $primary-dark-color;
      }
    }

    p {
      color: $primary-dark-color;
      opacity: 0.7;
    }

    img {
      object-fit: cover;
      width: 100%;
    }
  }

  section.third {
    background-color: $primary-color;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    align-items: center;

    img {
      object-fit: cover;
      min-width: 100%;
      height: 100%
    }

    .infos {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 4rem;

      .info {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        img {
          height: 4rem;
          width: 4rem;
        }

        p, h3 {
          color: $white-color;
        }

        h3 {
          font-size: 3.2rem;
        }

        p {
          font-size: 1.6rem;
        }
      }
    }
  }

  section.fourth {
    background-color: $primary-dark-color;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;

    p, h2, h3 {
      color: $white-color;
    }

    p {
      font-size: 1.6rem;
    }

    h2 {
      margin-bottom: 4.8rem;
    }

    .infos {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      .info {
        display: grid;
        grid-template-columns: 5rem 1fr;
        gap: 4rem;

        .img-container {
          height: 18rem;
          background-image: linear-gradient($primary-light-color, $primary-light-color);
          background-size: 2px 100%;
          background-repeat: no-repeat;
          background-position: center center;

          display: flex;
          align-items: flex-start;
          justify-content: center;

          img {
            background-color: $primary-dark-color;
            padding-bottom: 2rem;
            justify-self: center;
          }
        }

        .content {
          h3 {
            margin-bottom: 1rem;
            font-size: 2rem;
          }

          p {
            color: $primary-high-light-color;
          }
        }

        &:last-child {
          .img-container {
            background: none;
            height: auto;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    p {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
    }

    h2 {
      font-size: 3.6rem;
    }

    section.first {
      .content {
        h1 {
          font-size: 4rem;
          line-height: 4.8rem;
        }
      }
    }
  }

  @media only screen and (max-width: 930px) {
    section {
      padding: 6.4rem 2rem;
    }

    h1, h2, h3 {
      font-weight: 700;
    }

    section.first {
      grid-template-columns: auto auto;
      gap: 2rem;
      padding: 6.6rem 2rem 0 2rem;

      .content {
        h1 {
          margin-bottom: 2rem;
        }

        p {
          margin-bottom: 2.4rem;
        }
      }
    }

    section.second {
      gap: 2rem;

      h2 {
        font-size: 3.6rem;
        margin-bottom: 2rem;
      }

      img {
        height: 100%;
      }
    }

    section.third {
      gap: 2rem;

      .infos {
        .info {
          h3 {
            font-size: 2.4rem;
          }
        }
      }

      img {
        height: 100%;
      }
    }

    section.fourth {
      gap: 2rem;

      .infos {
        gap: 3.2rem;

        .info {
          gap: 2.4rem;

          .img-container {
            height: auto;

            img {
              width: 70%;
            }
          }

          .content h3 {
            font-size: 1.8rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 700px) {
    section.first {
      grid-template-columns: auto;
      gap: 6.4rem;

      padding-top: 12rem;

      .content {
        margin-block: 0;
      }
    }

    section.second {
      grid-template-columns: auto;
      gap: 4.8rem;
    }

    section.third {
      grid-template-columns: auto;
      gap: 4.8rem;

      .infos {
        order: -1;
      }
    }

    section.fourth {
      grid-template-columns: auto;
      gap: 4.8rem;
    }
  }
</style>

<template lang="pug">
.report-history
  .report-index-container
    card
      .filtering-container
        h2 {{ $t('.title')  }}

        .actions
          button.icon.button-primary(
            @click="filterOpened = !filterOpened"
            :class="{ 'opened': filterOpened }"
          )
            i.fa.fa-filter

      filter-container(
        :filter="filter"
        :opened="filterOpened"
        :i18nPath="defaultI18nScope"
      )
        date-field(
          v-model="query.period"
          :label="$t('.label.period')"
          :range="true"
        )

        select-field(
          v-model="query.className"
          :label="$t('.label.className')"
          :options="classNameOptions"
        )
          option(value="-1") {{ $t('none') }}

        status-field(name="status" v-model="query.status")

      .table-section
        .table-container(@scroll="autoLoad")
          table
            thead
              tr
                th(v-for="name in columnNames")
                  p {{ $t(`.table.columns.${name}`) }}

            tbody
              tr(v-for="report in reports")
                td.report-type {{ report.fileNameTranslation || '-' }}

                td.created-at {{ locationCreatedAt(report) }}

                td.size {{ report.fileSize || '-'  }}

                td.file-extension {{ report.fileExtension || '-' }}

                td.status {{ $t(`.table.status.${report.status}`) || '-' }}

                td.actions(v-if="report.status == 'wip'")
                  button.button-blue(disabled)
                    i.fa.fa-download

                  button.button-red(disabled)
                    i.fa.fa-trash

                td.actions(v-else)
                  a.button.button-blue(
                    :href="report.fileUrl"
                    target="_blank"
                  )
                    i.fa.fa-download
                
                  button.button-red(
                    @click="openDeleteModal(report)"
                  )
                    i.fa.fa-trash
                    
              tr.loading(v-if="loading")
                td(v-for="cell in columnNames")
                  .cell

        footer
          p {{ paginationText }}

  delete-modal(
    :show="showDeleteReportModal",
    :callback="deleteReport",
    @close="showDeleteReportModal = false"
  )
</template>
<script>
import FilterContainer from '../../../components/filter-container.vue'
import DeleteModal from '../../components/reports/delete-modal.vue'
import StatusField from "./status-field.vue"
import objects from "lib/objects"

export default {
  components: { FilterContainer, DeleteModal, StatusField },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      defaultI18nScope: 'admin.reports.history',

      filterOpened: false,

      // table
      reports: [],
      report: {},
      pagination: { total: 0 },
      loading: false,

      query: {
        period: (() => {
          const date = new Date()
          const firstDayOfMonth = new Date(
            date.getFullYear(), date.getMonth(), 1
          )
          const lastDayOfMonth = new Date(
            date.getFullYear(), date.getMonth() + 1, 0
          )

          return [firstDayOfMonth, lastDayOfMonth]
        })(),
        className: null,
        status: null
      },

      columnNames: [
        'reportType',
        'createdAt',
        'size',
        'fileExtension',
        'status',
        'actions'
      ],

      showDeleteReportModal: false,
    }
  },

  methods: {
    init(reports, pagination) {
      this.reports.push(...objects.camelize(reports))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total"),
      }
    },

    filter() {
      this.replaceQuery()

      this.reports = []

      this.fetch()
      this.filterOpened = false
    },
    
    fetch(page = null) {
      this.loading = true

      this.$http
        .get('/admins/reports.json', { params: this.httpParams(page) })
        .then((response) => {
          const body = response.data
          const reports = body.data
          const pagination = body.pagination || {}

          this.init(reports, pagination)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.loading = false
        })
    },

    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target
      const loadingHeight = scrollHeight - 5

      if ((scrollTop + clientHeight < loadingHeight) || !this.pagination.next) {
        return
      }

      this.loading = true

      this.fetch(this.pagination.next)
    },

    httpParams(page = null) {
      return this.$params.toRouteParams({
        created_at: {
          start_date: this.query.period && this.query.period[0],
          end_date: this.query.period && this.query.period[1]
        },
        class_name: this.query.className,
        status: this.query.status,
        page: page
      })
    },

    deleteReport() {
      this.$http
        .delete(`/admins/reports/${this.report.id}`)
        .then(() => {
          this.errors = {}

          this.$notifications.info(
            this.$t(".notifications.deleteReport.success")
          )
          this.$router.replace(
            { path:"/administracao/relatorios", hash: "#reports" }
          )
        })
        .catch(() => {
          this.$notifications.error(
            this.$t(".notifications.deleteReport.failure")
          )
        })
        .finally(() => {
          this.showDeleteReportModal = false
        })
    },
    
    openDeleteModal(report) {
      this.report = report
      this.showDeleteReportModal = true
    },

    getReportExtension(fileExtension) {
      const extensions = {
        'pdf': 'application/pdf',
        'csv': 'text/csv'
      }

      return extensions[fileExtension]
    },   

    locationCreatedAt(report) {
      if (report.createdAt) {
        return this.$l('time.formats.micro', report.createdAt) 
      }
      return '-'
    }
  },

  computed: {
    paginationText() {
      return this.$t(
        '.pagination.text',
        { length: this.reports.length, total: this.pagination.total }
      )
    },

    classNameOptions() {
      const classNames = [
        'DelinquencySummaryCsvService',
        'PendantAndConsolidatedReceiptsService',
        'ContractsCsvService',
        'BillsAndTransfersCsvService',
        'PendantAndConsolidatedExpensesReportService',
        'Financial::Transfer::StatementsPdfService',
        'TransfersCompositionReportService'
      ]

      return classNames.map(className => ({
        id: className, 
        text: this.$t(`models.report.classNames.${className}`)
      }))
    }
  }
}
</script>
<style lang="scss" scoped>
  form {
    display: grid;
    grid-template-columns: repeat(3, auto) repeat(1, max-content);
    column-gap: 0.5em;

    .button-primary {
      margin-bottom: 1.4rem;
      margin-top: auto;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 5rem;

    p, button {
      margin: 0;
    }
  }

  .table-container {
    height: 40vh !important;
  }


  .filtering-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        font-size: 2rem;
        font-weight: 500;
        color: $primary-color;
      }

      .actions {
        display: flex;

        button.opened {
          opacity: 0.5;
        }

        button.icon {
          font-size: 1.5rem;
          width: 4rem;
          padding: 0;
          margin-bottom: 0;
          margin-left: 1rem;
        }
      }
    }

  .table-section{
    margin-top: 1.5rem
  }

  table {
    tbody {
      tr {
        td {
          &.file-name-translation,
          &.file-size,
          &.file-extension,
          &.created-at,
          &.actions {
            min-width: fit-content;
            text-align: center;

            a, button {
              margin: 0;
              width: 3.8rem;
              padding: 0;

              i {
                font-size: 1.8rem;
              }

              &.button-red, &.button-blue {
                padding: 0;
                height: 38px;
                width: 38px;
                margin-left: 1rem;
              }
            }
          }
        }
      }
    }
  }
</style>
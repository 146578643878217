<template lang="pug">
.family-holdings
  step-table(
    :stepName="stepName",
    :tableData="formattedData",
    :columnNames="columnNames",
  )
</template>
<script>
import StepTable from './format/step-table.vue'

export default {
  components: { StepTable },

  data() {
    return {
      // table info
      stepName: 'familyHoldings',
      columnNames: ['cpf', 'name', 'degreeOfKinship']
    }
  },

  props: {
    data: { type: Array, default: () => ([]) }
  },

  computed: {
    formattedData() {
      return this.data.map((score) => {
        if (score.scoreReportId) {
          score['openData'] = {
            path: `/administracao/gerenciamento/consultas/${score.scoreReportId}`
          }
        }

        return score
      })
    }
  }
}
</script>
<style scoped lang="scss">
.family-holdings {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
}
</style>

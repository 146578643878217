<doc lang="markdown">
  ### usage

  #### Com texto simples (`prop`)

  Defina o conteúdo textual através da `prop` `content`:

  ```
  text-box(
    :label="$t('bedrooms')",
    :content="bedroomsCount",
    :subtitle="suitesText"
  )
  ```


  #### Com conteúdo "rico" (`<slot>`)

  Defina o conteúdo "rico" (HTML) via `<slot>`:

  ```pug
  text-box(:label="$t('petFriendly')", :subtitle="smallPetText")
    i.fa.fa-check(v-if="petFriendly")
    i.fa.fa-times(v-else)
  ```


  #### Tamanhos de texto menor

  Basta adicionar a classe `small` ao componente:

  ```
  text-box.small(
    :label="label",
    :content="content"
  )
  ```
</doc>

<template lang="pug">
.box
  label.label {{ label }}
  .content
    slot
      span {{ content }}
  label.subtitle(v-if="subtitle") {{ subtitle }}
</template>

<script>
export default {
  props: ["label", "content", "subtitle"],
};
</script>

<style scoped lang="scss">
.box {
  display: inline-block;
  font-family: Raleway;
  color: #4a4a4a;

  label {
    margin-bottom: 0;
    cursor: inherit; // permitindo que "pais" definam, por ex, `cursor: pointer`
  }

  .label {
    font-size: 16px;
  }

  .content {
    display: block;

    font-family: Lato;
    font-size: 24px;
    font-weight: bold;
  }

  .subtitle {
    font-size: 12px;
  }
}

.box.small {
  .label {
    font-size: 14px;
  }

  .content {
    font-size: 20px;
  }

  .subtitle {
    font-size: 10px;
  }
}

.box.micro {
  .label {
    font-size: 14px;
  }

  .content {
    font-size: 14px;
  }

  .subtitle {
    font-size: 10px;
  }
}
</style>
<template lang="pug">
input-field(
  v-model="formData.name"
  type="text"
  name="name"
  :label="$t('.label.name')"
  :placeholder="$t('.placeholder.name')"
  :error="errors?.name?.message"
  required
)

input-field(
  v-model="formData.cnpj"
  type="text"
  name="cnpj"
  :label="$t('.label.cnpj')"
  :placeholder="$t('.placeholder.cnpj')"
  v-maska="'##.###.###/####-##'"
  :error="errors?.cnpj?.message"
  required
)

input-field(
  v-model="formData.responsibleEmail"
  type="email"
  name="email"
  :label="$t('.label.responsibleEmail')"
  :placeholder="$t('.placeholder.email')"
  :error="errors?.email?.message"
  required
)

input-field(
  v-model="formData.creci"
  type="text"
  name="creci"
  :label="$t('.label.creci')"
  :placeholder="$t('.placeholder.creci')"
  :error="errors?.creci?.message"
  required
)

input-field(
  v-model="formData.identificator"
  type="text"
  name="identificator"
  :label="$t('.label.identificator')"
  :placeholder="$t('.placeholder.identificator')"
  :error="errors?.identificator?.message"
  required
)

input-field(
  v-model="formData.superlogicaId"
  type="text"
  name="superlogica_id"
  :label="$t('.label.superlogicaId')"
  :placeholder="$t('.placeholder.superlogicaId')"
  :error="errors?.superlogicaId?.message"
  required
)
</template>

<script>
export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "admin.realEstateAgencies.createModal.identification"
    }
  },

  props: {
    formData: {type: Object, default: () => ({}) },
    errors: { type: Object, default: () => ({}) }
  }
}
</script>

// Veja como cria um plugin em https://vuejs.org/v2/guide/plugins.html

/**
 *   Vue Plugin para facilitar a "herança" de props que definam apenas o valor
 * inicial, como descrito no ponto 1 das docs (https://vuejs.org/v2/guide/components.html#One-Way-Data-Flow).
 *
 *   A convenção é a de que props de valor inicial tenham o nome começando com "_"
 * - exemplos: "_count", "_value", "_id", "_type", ...
 *
 *   Assim, na definição de `data()` do seu Componente, aproveite-se disso para
 * definir propriedades com os nomes sem o "_" inicial, fazendo:
 * ```javascript
 *   data() {
 *     return {
 *       ...this.$initProps,
 *       other: true,
 *       another: 11
 *     }
 *   }
 * ```
 *
 *   Assim, o `this.data` desse componente seria:
 * ```javascript
 *   {
 *     count: this._count,
 *     value: this._value,
 *     id: this._id,
 *     type: this._type,
 *     other: true,
 *     another: 11
 *   }
 * ```
 *
 * @type {VuePlugin}
 */
const VueInitProps = {
  installed: false,

  install(Vue) {
    if (VueInitProps.installed) return
    VueInitProps.installed = true

    function getInitProps(vm) {
      const initProps = {}

      // Vamos ler os nomes de `$options.props` (definição da prop ~ metadado)
      // pois, se a prop não for repassada pelo componente-pai,
      // `$options.propsData` nem contará com a "chave" (nome da prop)
      const props = vm.$options.props

      Object.keys(props)
        .filter(name => name.startsWith('_'))
        .forEach((name) => {
          const dataName = name.replace(/^_/, '')

          // O valor "bruto" da propriedade pode ser acessado por
          // `.propsData[name]`, mas não haverá o fallback
          // para um possível valor `default` definido na declaração
          // da `prop` no componente. Assim, utilizaremos a instância Vue `vm`
          // para acessar a propriedade, deixando que o Vue se responsabilize
          // por aplicar o valor `default` quando cabível.
          //   - `vm.$options.propsData[name]` seria o valor "bruto", sem fallback para `default`
          //   - `vm[name]` é o valor da prop calculado na instância Vue, ou seja,
          //     com fallback para valor `default`
          initProps[dataName] = vm[name]
        })

      return initProps
    }

    // adiciona propriedade `$initProps` (getter) nas instâncias Vue
    Vue.config.globalProperties.$initProps = {
      get() { return getInitProps(this) }
    }
  }
}

export default VueInitProps

<template lang="pug">
.bill-services-index
  .bill-services-index-container
    card
      .filtering-container
        h2 {{ $t('.titles.index') }}

        .edit
          button.icon.button-primary(
            @click="filterOpened = !filterOpened"
            :class="{ 'opened': filterOpened }"
          )
            i.fa.fa-filter

          button.icon.button-blue(
            v-if="$permissions.hasPermission('bill_services', 'post')"
            @click.prevent="showCreateModal = true"
          )
            i.fa.fa-plus

      filter-container(
        :filter="filter"
        :opened="filterOpened"
        :i18nPath="defaultI18nScope"
      )
        input-field(
          v-model="query.description",
          name="description",
          :label="$t('.label.description')",
          :placeholder="$t('.placeholder.description')"
        )

        select-field(
          v-model="query.enabled"
          name="enabled",
          :label="$t('.label.enabled')",
          :options="booleanOptions",
          :includeBlank="true"
        )
          option(value="-1") {{ $t('none') }}

        select-field(
          v-model="query.systemService"
          name="system_service",
          :label="$t('.label.systemService')",
          :options="booleanOptions",
          :includeBlank="true"
        )
          option(value="-1") {{ $t('none') }}

      .table-section
        .table-container(@scroll="autoLoad")
          table
            thead
              tr
                th(v-for="name in columnNames")
                  p {{ $t(`.table.columns.${name}`) }}

            tbody
              tr(v-for="billService in billServices")
                td.id {{ billService.id }}

                td.description {{ billService.description }}

                td.enabled
                  enable-disable-switch(
                    :disabled="!enableDisablePermission"
                    :billService="billService"
                  )

                td.system-service
                  | {{ $t(`.values.${billService.systemService}`) }}

                td.default-transfer-type
                  | {{ defaultTransferTypeTranslate(billService) }}

                td.service-nature
                  popper(
                    class="popper-table"
                    :content="serviceNatureTranslate(billService)"
                    hover
                  )
                    span {{ serviceNatureTranslate(billService) }}

                td.created-at
                  | {{ $l('time.formats.micro', billService.createdAt) }}

                td.edit(v-if="updatePermission")
                  button.button-blue(@click="openEditModal(billService)")
                    i.fa.fa-edit

              tr.loading(v-if="loading")
                td(v-for="cell in columnNames")
                  .cell
        footer
          p {{ paginationText }}

  create-modal(
    :show="showCreateModal"
    :contractId="contractId"
    :callback="reloadBillServices"
    @close="showCreateModal = false"
  )
  edit-modal(
    :show="showEditModal"
    :contractId="contractId"
    :billService="billService"
    :callback="reloadBillServices"
    @close="showEditModal = false"
  )
</template>
<script>
import FilterContainer from '../../../../components/filter-container.vue'
import CreateModal from './create-modal.vue'
import EditModal from './edit-modal.vue'
import EnableDisableSwitch from './enable-disable-switch.vue'

import objects from "lib/objects"

export default {
  components: { FilterContainer, CreateModal, EditModal, EnableDisableSwitch },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.billServices.index",

      // filter
      filterOpened: false,

      query: {
        description: null,
        enabled: null,
        systemService: null,
      },

      // table
      billServices: [],
      pagination: { total: 0 },
      loading: false,

      // modal
      showCreateModal: false,
      showEditModal: false,

      billService: {}
    }
  },

  computed: {
    enableDisablePermission() {
      return this.$permissions.hasPermission(
        'bill_services_enable_disables', 'put'
      )
    },

    updatePermission() {
      return this.$permissions.hasPermission('bill_services', 'put')
    },

    columnNames() {
      const columnNames = [
        'id',
        'description',
        'enabled',
        'systemService',
        'defaultTransferType',
        'serviceNature',
        'createdAt'
      ]

      this.updatePermission && columnNames.push('edit')

      return columnNames
    },

    booleanOptions() {
      return [
        { id: true, text: this.$t('.values.true') },
        { id: false, text: this.$t('.values.false') }
      ]
    },

    paginationText() {
      return this.$t(
        '.pagination.text',
        { length: this.billServices.length, total: this.pagination.total }
      )
    }
  },

  methods: {
    reloadBillServices() {
      this.billServices = []
      this.billService = {}

      this.fetch()
    },

    openEditModal(billService) {
      this.billService = billService
      this.showEditModal = true
    },

    init(billServices, pagination) {
      this.billServices.push(...objects.camelize(billServices))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total"),
      }
    },

    filter() {
      this.replaceQuery()

      this.billServices = []

      this.fetch()

      this.filterOpened = false
    },

    fetch(page = null) {
      this.loading = true

      this.$http
        .get('/admins/bill-services.json', { params: this.httpParams(page) })
        .then((response) => {
          const body = response.data
          const billServices = body.data
          const pagination = body.pagination || {}

          this.init(billServices, pagination)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.loading = false
        })
    },

    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target
      const loadingHeight = scrollHeight - 5

      if ((scrollTop + clientHeight < loadingHeight) || !this.pagination.next) {
        return
      }

      this.loading = true

      this.fetch(this.pagination.next)
    },

    httpParams(page = null) {
      return this.$params.toRouteParams({
        selectable: false,
        description: this.query.description,
        enabled: this.query.enabled,
        system_service: this.query.systemService,
        page: page
      })
    },

    modelAttributeTranslate(attribute) {
      return this.$t(`models.billService.attributes.${attribute}`)
    },

    defaultTransferTypeTranslate({ defaultTransferType }) {
      return this.modelAttributeTranslate(
        `defaultTransferTypeOptions.${defaultTransferType}`
      )
    },

    serviceNatureTranslate({ serviceNature }) {
      return this.modelAttributeTranslate(
        `serviceNatureOptions.${serviceNature}`
      )
    },
  }
}
</script>
<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 0.5em;

  .button-primary {
    margin-bottom: 1.4rem;
    margin-top: auto;
  }
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 5rem;

  p, button {
    margin: 0;
  }
}

.table-container {
  height: 60vh !important;
}

.filtering-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 2rem;
    font-weight: 500;
    color: $primary-color;
  }

  .edit {
    display: flex;

    button.opened {
      opacity: 0.5;
    }

    button.icon {
      font-size: 1.5rem;
      width: 4rem;
      padding: 0;
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}

.table-section{
  margin-top: 1.5rem
}

table {
  tbody {
    tr {
      td {
        &.id,
        &.enabled,
        &.system-service,
        &.default-transfer-type,
        &.created-at {
          min-width: fit-content;
        }

        &.service-nature {
          max-width: 25rem;
        }

        &.edit {
          min-width: fit-content;
          text-align: center;

          button {
            margin: 0;
            width: 3.8rem;
            padding: 0;
            padding-top: 0.2rem;

            i {
              margin-left: 0.5rem;
              font-size: 1.8rem;
            }
          }
        }
      }
    }
  }
}
</style>
<template lang="pug">
.predictions
  step-table(
    :stepName="stepName",
    :tableData="data",
    :columnNames="columnNames",
    :dateTimeColumns="dateTimeColumns"
  )
</template>
<script>
import StepTable from './format/step-table.vue'

export default {
  components: { StepTable },

  data() {
    return {
      // table info
      stepName: 'predictions',
      columnNames: ['label', 'approved', 'result', 'createdAt'],
      dateTimeColumns: ['createdAt']
    }
  },

  props: {
    data: { type: Array, default: () => ([]) }
  }
}
</script>
<style scoped lang="scss">
.predictions {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
}
</style>

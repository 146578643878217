<template lang="pug">
.leads-index
  expansible-card(
    :title="$t('.titles.overview', { year: new Date().getFullYear()})"
    :expanded="true"
  )
    overview(:items="overviewItems")

  .leads-index-container
    card
      .filtering-container
        h2 {{ $t('.titles.index') }}

        .actions
          button.icon.button-primary(
            @click="filterOpened = !filterOpened"
            :class="{ 'opened': filterOpened }"
          )
            i.fa.fa-filter

      filter-container(
        :filter="filter"
        :opened="filterOpened"
        :i18nPath="defaultI18nScope"
      )
        input-field(
          v-model="query.name"
          name="name"
          :label="$t('.label.name')"
          :placeholder="$t('.placeholder.name')"
        )

        input-field(
          v-model="query.cnpj"
          name="cnpj"
          v-maska="'##.###.###/####-##'"
          :label="$t('.label.cnpj')"
          :placeholder="$t('.placeholder.cnpj')"
        )

        email-field(
          v-model="query.email"
          name="email"
          :label="$t('.label.email')"
          :placeholder="$t('.placeholder.email')"
        )

      .table-section
        .table-container(@scroll="autoLoad")
          table
            thead
              tr
                th(v-for="name in columnNames")
                  p {{ $t(`.table.columns.${name}`) }}

            tbody
              tr(v-for="lead in leads")
                td.status
                  status(:lead="lead")

                td.email
                  span {{ lead.emailContato || '-' }}

                td.name
                  span {{ lead.nomeFantasia || '-' }}

                td.corporate-name
                  span {{ lead.razaoSocial || '-' }}

                td.cnpj
                  span {{ lead.cnpj || '-' }}

                td.id-agency
                  span {{ lead.idImobiliaria || '-' }}

                td.id-client-ksi
                  span {{ lead.idClienteKsi || '-' }}

                td.phone
                  span {{ lead.telefone || '-' }}

                td.address
                  span {{ lead.endereco || '-' }}

                td.neighborhood
                  span {{ lead.bairro || '-' }}

                td.city
                  span {{ lead.cidade || '-' }}

                td.state
                  span {{ lead.uf || '-' }}

                td.zip-code
                  span {{ lead.cep || '-' }}

                td.created-at
                  span {{ $l('date.formats.default', lead.createdAt) }}

              tr.loading(v-if="loading")
                td(v-for="cell in columnNames")
                  .cell
        footer
          p {{ paginationText }}
</template>
<script>
import ExpansibleCard from "../../expansible-card.vue"
import Overview from "../../overview.vue"
import FilterContainer from '../../../../components/filter-container.vue'
import Status from "./status.vue"

import objects from "lib/objects"

export default {
  components: { ExpansibleCard, Overview, FilterContainer, Status },

  mounted() {
    this.fetch()
    this.fetchOverview()
  },

  data() {
    return {
      defaultI18nScope: "admin.realEstateAgencies.leadsKurole.index",

      // overview
      overviewData: {
        currentWeek: { count: 0 },
        currentMonth: { count: 0 },
        total: { count: 0 }
      },

      // filter
      filterOpened: false,

      query: {
        name: null,
        cnpj: null,
        email: null
      },

      // table
      leads: [],
      pagination: { total: 0 },
      columnNames: [
        "status",
        "email",
        "name",
        "corporateName",
        "cnpj",
        "idAgency",
        "idClientKsi",
        "phone",
        "address",
        "neighborhood",
        "city",
        "state",
        "zipCode",
        "createdAt"
      ],
      loading: false,
    }
  },

  methods: {
    init(leads, pagination) {
      this.leads.push(...objects.camelize(leads))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total"),
      }
    },

    reloadLeads() {
      this.leads = []

      this.fetch()
      this.fetchOverview()
    },

    filter() {
      this.replaceQuery()

      this.reloadLeads()

      this.filterOpened = false
    },

    fetch(page = null) {
      this.loading = true

      this.$http
        .get('/admins/kurole/leads', { params: this.httpParams(page) })
        .then(({ data }) => {
          const leads = data.data
          const pagination = data.pagination || {}

          this.init(leads, pagination)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.loading = false
        })
    },

    fetchOverview() {
      this.$http
        .get('/admins/infos/kurole/leads.json',{ params: this.httpParams() })
        .then(({ data }) => {
          this.overviewData = objects.camelize(data)
        })
        .catch(() => {
          this.overviewData = {
            currentWeek: { count: 0 },
            currentMonth: { count: 0 },
            total: { count: 0 }
          }

          this.$notifications.error(
            this.$t(".notifications.fetchOverview.failure")
          )
        })
    },

    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target
      const loadingHeight = scrollHeight - 5

      if ((scrollTop + clientHeight < loadingHeight) || !this.pagination.next) {
        return
      }

      this.loading = true

      this.fetch(this.pagination.next)
    },

    httpParams(page = null) {
      return this.$params.toRouteParams(
        {
          nome_fantasia: this.query.name,
          cnpj: this.query.cnpj,
          email_contato: this.query.email,
          page: page
        }
      )
    }
  },

  computed: {
    overviewItems() {
      return [
        {
          description: this.$t(".overview.currentWeek"),
          quantity: this.overviewData.currentWeek.count
        },
        {
          description: this.$t(".overview.currentMonth"),
          quantity: this.overviewData.currentMonth.count
        },
        {
          description: this.$t(".overview.total"),
          quantity: this.overviewData.total.count
        }
      ]
    },

    paginationText() {
      return this.$t(
        '.pagination.text',
        { length: this.leads.length, total: this.pagination.total }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.filtering-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 2rem;
    font-weight: 500;
    color: $primary-color;
  }

  .actions {
    display: flex;

    button.opened {
      opacity: 0.5;
    }

    button.icon {
      font-size: 1.5rem;
      width: 4rem;
      padding: 0;
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 5rem;

  p,
  button {
    margin: 0;
  }
}

.table-section {
  margin-top: 1.5rem;
}

.table-container {
  height: 40vh !important;
}

table {
  tbody {
    tr {
      min-width: 0 !important;

      &:hover {
        td {
          background-color: darken($white-color-dark, 6%);
        }
      }
    }
  }
}
</style>
